import { AnimatedSidebar } from "../../../components/interactives";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const InternationalTravelInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        {/* header title */}
        <h2 className="text-xl font-bold mb-4">International Travel Days</h2>
        {/* Body Text */}
        <p>This includes business travel, associated leisure travel and private travel days outside of Australia.</p>
        <p>1 day = 1 travel day, per person.</p>
        <Button className="mt-10" type={ButtonType.PRIMARY} htmlType="button" onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
