import travelImg from "../../assets/images/travel.svg";
import Header from "../auth/layout/Header";
import { useTranslation } from "react-i18next";
import { useClientId } from "../../hooks/useClientId";
import A11yH1 from "../../components/basics/A11YH1";
import { LoginState, useAuth } from "../../hooks/useAuth";

interface IProps {
  hideAccount?: boolean;
  logoLink?: string;
}

const TravelHeader = (props: IProps) => {
  const clientId = useClientId();
  const userType = clientId ? "client" : "agent";
  const hideAccount = clientId ? true : false;
  const { t } = useTranslation("pages");
  const { user } = useAuth();
  const home = userType === "client" ? "travel" : user === LoginState.LoggedOut ? "" : "/";

  return (
    <Header home={home} className="gap-4" {...props} hideAccount={hideAccount}>
      <A11yH1>{t("travel.header.title")}</A11yH1>
      <div className="my-auto text-xl font-serif md:text-3xl whitespace-nowrap hidden md:block">{t("travel.header.title")}</div>
      <img src={travelImg} alt="travel insurance logo" className="h-10 md:h-16" />
    </Header>
  );
};

export default TravelHeader;
