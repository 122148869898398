import { FC } from "react";
import { RadioInput } from "../../../components/form-fields";
import { FormGroup } from "../../../components/forms";

type Props = {
  limit: any;
  limitTranslationObject: any;
  ariaLabel: string;
  options: any;
  state: any;
  mainClassName: string;
  labelClassName: string;
  fieldsClassName: string;
};

const LimitRadioGroup: FC<React.PropsWithChildren<Props>> = ({
  limit,
  limitTranslationObject,
  ariaLabel,
  options,
  state,
  mainClassName,
  labelClassName,
  fieldsClassName,
}) => {
  return (
    <div className={mainClassName}>
      <div className={labelClassName}>
        <p className="font-bold text-lg">{limitTranslationObject("formLabels.limit")}</p>
        <div className="text-lg">{limitTranslationObject("assistoryLabels.limit")}</div>
      </div>
      <div className={fieldsClassName}>
        <FormGroup title="" fullWidth>
          <RadioInput
            error={limit.error}
            name={limit.name}
            onValueChange={limit.onValueChange}
            value={state.limit}
            aria-label={ariaLabel}
            options={options}
          />
        </FormGroup>
      </div>
    </div>
  );
};
export default LimitRadioGroup;
