import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "./EventHeader";
import Footer from "./EventFooter";
import QuoteTable from "./components/QuoteTable";
import ServerError from "../errors/ServerError";
import Spinner from "../../components/icons/Spinner";
import { fetchQuoteList } from "../../helpers/event_public_liability/api/retriveList";
import { useAuth } from "../../hooks/useAuth";
import { getRegion } from "../../helpers/shared/getRegion";
import { Region } from "../../enums/shared/forms/Region";
import FilterPanel, { FilterPanelState } from "../../components/interactives/FilterPanel";
import { ProductType } from "../../enums/shared/api/Api";
import { useFetch } from "../../hooks/useFetch";
import { filterByGroup } from "../group_travel/bind/quotes/QuoteList";

export const filterOption = {
  title: "Status",
  options: [
    { label: "Bound", value: "bound", filterGroup: "status" },
    { label: "Expired", value: "expired", filterGroup: "status" },
    { label: "Pending", value: "pending", filterGroup: "status" },
    { label: "Valid", value: "valid", filterGroup: "status" },
  ],
  positionClass: "inset-0 md:-left-0 md:-top-0 top-4",
};

const selectButtons = [filterOption];
type AttrType = Partial<Record<Region, Record<"productCode" | "supportEmailId", string>>>;

const QuoteList: FC = () => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product" });
  const { t: tHkSupportMailId } = useTranslation("pages", { keyPrefix: "eventplHk.product" });
  const [filterState, setFilterState] = useState<FilterPanelState>([] as FilterPanelState);

  const productsAttribute: AttrType = {
    [Region.HK]: {
      productCode: ProductType.CLV,
      supportEmailId: tHkSupportMailId("customerSupport.emailId"),
    },
    [Region.SG]: {
      productCode: ProductType.APL,
      supportEmailId: t("customerSupport.emailId"),
    },
  };

  const { user } = useAuth();
  const region = getRegion(user);
  const { data, loadingError, isLoading } = useFetch(fetchQuoteList, { productType: productsAttribute[region as Region]?.productCode });
  const updatedState = filterByGroup(data, filterState);
  const showFilterPanel = data.length > 0;

  return (
    <main>
      <div className="flex flex-col min-h-screen gap-0">
        <Header hideAccount={false} />
        <div className={`container-lg flex-grow md:pt-8 pt-4 md:pb-20 pb-8`}>
          {showFilterPanel && (
            <FilterPanel panelButtons={selectButtons} filterState={filterState} setFilterState={setFilterState} title={t("header.title")} />
          )}
          {loadingError && <ServerError supportEmailId={productsAttribute[region as Region]?.supportEmailId} />}
          {isLoading ? (
            <div className="py-32 flex justify-around">
              <Spinner width="100" height="100" color="#3F9293"></Spinner>
            </div>
          ) : (
            <QuoteTable region={region} rows={updatedState} />
          )}
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default QuoteList;
