import { useNavigate } from "react-router-dom";
import { getRole } from "../../helpers/shared/getRole";
import { useAuth } from "../../hooks/useAuth";
import AuSignupForm from "./signup/AuSignupForm";
import SignupResetForm from "./SignupResetForm";
import SignupForm from "./signup/SignupForm";
import { isUserAdmin } from "./layout/header/NavbarMobile";

const Signup: React.FC = () => {
  const { user, isSignedIn } = useAuth();
  const role = getRole(user);
  const isAdmin = isUserAdmin(role);
  const navigate = useNavigate();
  if (isSignedIn()) {
    return isAdmin ? (
      <SignupResetForm title={"Signup form Agent"}>
        <SignupForm />
      </SignupResetForm>
    ) : (
      <>{navigate("/")}</>
    );
  } else {
    return (
      <SignupResetForm title={"AuSignup form"} isAuFooter={true}>
        <AuSignupForm />
      </SignupResetForm>
    );
  }
};

export default Signup;
