import { PolicySearchType } from "../../pages/tpa/Home";
import { Validations } from "../../types/shared/Validation";

const AU_PREFIX_ALPHA_NUMERIC_LENGTH_REGEX = /^[A-Za-z]{6}\d{10}$/;

enum TPAProducts {
  ACT = "ACT",
  APA = "APA",
}

const validateAuthorizationForGPA = (policyNo: string, productCode: string) => {
  if (productCode === TPAProducts.ACT && (policyNo as string).substring(1, 4) !== TPAProducts.ACT) {
    return "Access to policy is restricted";
  }
  return null;
};

const validateProductCode = (policyNo: string) => {
  const productCode = policyNo.substring(1, 4).toUpperCase();
  if (!(productCode === TPAProducts.APA || productCode === TPAProducts.ACT)) {
    return "Please check the policy number format";
  }
  return null;
};

export const validations: Validations<PolicySearchType> = {
  fields: {
    productCode: {
      required: true,
    },
    policyNo: {
      required: {
        message: "Please key in policy number",
      },
      pattern: {
        regex: AU_PREFIX_ALPHA_NUMERIC_LENGTH_REGEX,
        message: "Please check the policy number format",
      },
      custom: {
        validate: (policyNo: unknown, { productCode }) => {
          let error: string | null = null;
          error = error || validateProductCode(policyNo as string);
          error = error || validateAuthorizationForGPA(policyNo as string, productCode as string);
          return error;
        },
      },
    },
  },
};
