import { FC } from "react";
import { Section } from "../interactives";
import CheckInputAgree from "../form-fields/CheckInputAgree";
import { ErrorMessage } from "../forms";
import { Alert } from "../attentions";
import RawHTML from "../basics/RawHTML";
import cls from "../../utils/cls";

type Props = {
  t: any;
  accepted: any;
  acceptedNotice: any;
  state: any;
  policyWordingUrl: string;
};

const AgreementSection: FC<Props> = ({ t, accepted, acceptedNotice, state, policyWordingUrl }) => {
  const hasError = accepted?.error || acceptedNotice?.error;
  return (
    <div className="grid gap-2">
      <h3 className="col-span-full font-bold md:text-xl mt-6">{t("agree.title")}</h3>
      <Section
        className={cls("flex cursor-pointer border border-grey-light ringed rounded-md !shadow-none", {
          "!border-red": !!hasError,
        })}
      >
        <p className="flex">
          <CheckInputAgree
            id={"accepted"}
            onValueChange={(value: boolean) => accepted?.onValueChange(value)}
            error={accepted?.error}
            label={t("agree.acceptedPolicy")}
            checked={state.accepted}
          />
        </p>
        <p className="flex">
          <CheckInputAgree
            error={acceptedNotice?.error}
            checked={state.acceptedNotice}
            onValueChange={(value: boolean) => acceptedNotice?.onValueChange(value)}
            label={t("agree.acceptedNotice")}
            id={"acceptedNotice"}
          />
        </p>
      </Section>
      <div className="-mt-2">{hasError && <ErrorMessage error={hasError} />}</div>
      <Alert type={"warning"} icon={"icon-info"}>
        <div className="space-y-4">
          <RawHTML boldedLinks content={t("fullDetails", { policyWordingUrl })} />
        </div>
      </Alert>
    </div>
  );
};

export default AgreementSection;
