import { Role } from "../../enums/shared/forms/Role";
import { IUser, UserState } from "../../hooks/useAuth";

const checkAdminAgentRoles = (role: string[], userRole: Role) => {
  if (role?.includes(Role.Agent) && !role?.includes(Role.Admin)) userRole = Role.Agent;
  if (role?.includes(Role.Admin) && !role?.includes(Role.Agent)) userRole = Role.Admin;
  if (role?.includes(Role.Admin) && role?.includes(Role.Agent)) userRole = Role.AdminAgent;
  return userRole;
};

const checkOtherRoles = (role: string[], userRole: Role) => {
  if (role?.includes(Role.OPS)) userRole = Role.OPS;
  if (role?.includes(Role.TPA)) userRole = Role.TPA;
  return userRole;
};

export const getRole = (user: UserState): Role => {
  let userRole = Role.Unknown;
  const { role } = user as IUser;
  userRole = role?.includes(Role.Admin) || role?.includes(Role.Agent) ? checkAdminAgentRoles(role, userRole) : checkOtherRoles(role, userRole);
  return userRole;
};
