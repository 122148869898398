import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import useForm from "../../../../hooks/useForm";
import Heading from "../../components/Heading";
import LiabilityBindStepNavigation from "../../bind/LiabilityBindStepNavigation";
import { HkLiabilityBindContext } from "../BindQuote";
import { proposerCompanyDetailsValidations } from "../../../../helpers/event_public_liability/HkValidations";
import { FormGroup } from "../../../../components/forms";
import TextInput from "../../../../components/form-fields/TextInput";
import { removeExtraSpaces } from "../../../../helpers/shared/formHandlers";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const HkProposerDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product.proposer" });
  const { state, setFormState, onChange } = useContext(HkLiabilityBindContext);
  const { field, validateField, validateForm } = useForm(state, proposerCompanyDetailsValidations, onChange);

  const handleNext = () => {
    onNext && validateForm(onNext);
  };

  return (
    <>
      <div className="container-flow flex-grow">
        <Heading headingTitle={t("step1Title")} />
        <FormGroup title={t("companyGroupName")}>
          <TextInput label={t("companyName")} disabled value={state.proposerName} {...field.proposerName} aria-label="proposer-name" />
        </FormGroup>
        <FormGroup title={t("businessNoGroupName")} subTitleFontSize="md:text-lg">
          <TextInput
            label={t("uenNumber")}
            value={state.uenNumber}
            {...field.uenNumber}
            aria-label="proposer-uenNo"
            onBlur={removeExtraSpaces("uenNumber", setFormState, validateField)}
          />
        </FormGroup>
        <FormGroup title={t("mobileGroupName")} subTitleFontSize="md:text-lg">
          <TextInput
            label={t(`mobileNumber`)}
            value={state.mobileNumber}
            {...field.mobileNumber}
            aria-label="proposer-mobileno"
            onBlur={removeExtraSpaces("mobileNumber", setFormState, validateField)}
          />
        </FormGroup>
        <FormGroup title={t("emailGroupName")} subtitle={t("subTitle.emailGroupName")} subTitleFontSize="md:text-lg w-[22rem]">
          <TextInput
            label={t("email")}
            value={state.email}
            {...field.email}
            aria-label="proposer-email"
            onBlur={removeExtraSpaces("email", setFormState, validateField)}
          />
        </FormGroup>
      </div>
      <LiabilityBindStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
    </>
  );
};

export default HkProposerDetails;
