import cls from "../../utils/cls";
import { useTranslation } from "react-i18next";
import classNamePresets from "../../utils/classNamePresets";

interface Props {
  current: number;
  total: number;
  className?: string;
}

const FormStep = ({ current, total, className = "" }: Props) => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.step" });

  return (
    <div className={cls("w-full", { [`step-${current}`]: !!current }, className)}>
      <div className="h-1.5 bg-grey-light">
        <span className="block h-full rounded-r-full bg-green transition-all delay-200" style={{ width: `${(current / total) * 100}%` }} />
      </div>

      <div className={cls("container-flow mt-6", "md:mt-10 md:mb")}>
        <div className={classNamePresets.secondaryHeading}>{t("title", { current: current, total: total })}</div>
      </div>
    </div>
  );
};

export default FormStep;
