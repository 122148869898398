import Header from "../auth/layout/Header";
import { useTranslation } from "react-i18next";
import A11yH1 from "../../components/basics/A11YH1";
import { useAuth, LoginState } from "../../hooks/useAuth";

interface IProps {
  hideAccount?: boolean;
  logoLink?: string;
}

const EventHeader = (props: IProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.header" });
  const { user } = useAuth();
  const home = user === LoginState.LoggedOut ? "" : "/";

  return (
    <Header home={home} className="gap-4" {...props}>
      <A11yH1>{t("title")}</A11yH1>
    </Header>
  );
};

export default EventHeader;
