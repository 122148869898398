import Ajv from "ajv";
import {
  getCheckoutResponseSchema,
  getQuoteResponseSchema,
  pricingResponseSchema,
  sendPDFResponseSchema,
} from "../../../schemas/group_travel/PricingResponse";

const ajv = new Ajv();

export const validatePricingResponse = ajv.compile(pricingResponseSchema);
export const validateCreateQuoteResponse = ajv.compile(getQuoteResponseSchema);
export const validateCheckoutResponse = ajv.compile(getCheckoutResponseSchema);
export const validateSendEmailResponse = ajv.compile(sendPDFResponseSchema);
