import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoginState, useAuth } from "../../../../hooks/useAuth";
import { ConfirmLogoutDialog } from "./ConfirmLogoutDialog";
import { useTranslation } from "react-i18next";
import NavbarMobile from "./NavbarMobile";

import ActionMenu from "../../../../components/interactives/ActionMenu";
import { Role } from "../../../../enums/shared/forms/Role";
import { getRole } from "../../../../helpers/shared/getRole";
import { getRegion } from "../../../../helpers/shared/getRegion";
import { Region } from "../../../../enums/shared/forms/Region";

const activeClassName = "font-bold";
const hoverClassName = ` before:transition-all before:duration-500 before:ease-in-out before:hover:cursor-pointer before:hover:text-red before:hover:absolute before:hover:top-22 before:hover:bg-red before:hover:h-2 before:hover:w-[7.5rem]`;

const getNavLinkStyle = (isActive = false) =>
  `relative  w-[7.5rem] shrink-0 flex items-center justify-center font-sans text-center text-red hover:cursor-pointer hover:text-red  ${
    isActive && activeClassName
  } ${hoverClassName}`;

const isUserAgent = (role: string) => role !== Role.Unknown && (role === Role.Agent || role === Role.AdminAgent);
const isUserAdmin = (role: string) => role !== Role.Unknown && (role === Role.Admin || role === Role.AdminAgent);

const isUserTPA = (role: string) => role !== Role.Unknown && role === Role.TPA;
const isUserOPS = (role: string) => role !== Role.Unknown && role === Role.OPS;
const isNonAdminOrAgentRoles = (role: string) => isUserTPA(role) || isUserOPS(role);

const AdminOptions = [
  { label: "Reset Agent Account", to: "/user/reset" },
  { label: "Create Agent Account", to: "/user/signup" },
  // { label: "Create Travel Campaign", to: "/campaign/create" },
  // { label: "See Travel Campaigns", to: "/campaign/list" },
];

const Navbar = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const role = getRole(user);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const { t } = useTranslation("pages", { keyPrefix: "travel.overview" });
  const region = getRegion(user);

  const handleLogout = () => {
    signOut();
    navigate("/user/login");
  };

  const links = (
    <div className="flex gap-4">
      {isNonAdminOrAgentRoles(role) ? null : (
        <div className="flex gap-4">
          {isUserAdmin(role) && (
            <ActionMenu title={t("header.button.admin")} options={AdminOptions} classNames={`${getNavLinkStyle()}`} hoverEnabled={true} />
          )}

          {isUserAgent(role) && (
            <NavLink to="/products" className={({ isActive }) => `${getNavLinkStyle(isActive)}`}>
              {t("header.button.products")}
            </NavLink>
          )}
          <NavLink to="/downloads" className={({ isActive }) => `${getNavLinkStyle(isActive)}`}>
            {t("header.button.downloads")}
          </NavLink>
          {isUserAgent(role) && region !== Region.AU && (
            <NavLink
              to={`//claims.alliedworldinsurance.io/${region}/personal/travel`}
              className={({ isActive }) => `${getNavLinkStyle(isActive)}`}
              target="_blank"
            >
              {t("header.button.travelClaim")}
            </NavLink>
          )}
        </div>
      )}

      <button
        type="button"
        className="relative w-[7.5rem] shrink-0 flex items-center justify-center mx-auto font-sans text-center hover:cursor-pointer hover:text-red"
        onClick={() => {
          setShowLogoutDialog(true);
        }}
      >
        <span className="font-bold font-sans text-red underline text-base">Logout</span>
      </button>
    </div>
  );

  if (user === LoginState.LoggedOut || user === LoginState.Unknown) {
    return null;
  }
  return (
    <nav>
      <div className="h-16 md:h-24 ml-auto hidden md:flex">
        <div className="flex text-base xxl:pt-0 md:mt-0">
          {links}
          {showLogoutDialog && <ConfirmLogoutDialog onCancel={() => setShowLogoutDialog(false)} onConfirm={handleLogout} />}
        </div>
      </div>
      <div className="md:hidden">
        <NavbarMobile />
      </div>
    </nav>
  );
};

export default Navbar;
