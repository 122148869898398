import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedAgentRoute from "../../components/authentication/ProtectedAgentRoute";
import { Region } from "../../enums/shared/forms/Region";
import NotFound from "../errors/NotFound";
import GroupTravelSuccess from "../group_travel/Success";
import CreateGroupPersonalAccident from "./CreateQuote";
import GroupPersonalAccidentBindQuote from "./BindQuote";
import GroupTravelQuoteList from "../group_travel/bind/quotes/QuoteList";
import { ProductsName } from "../../enums/shared/api/Api";
import PolicyDocuments from "../components/PolicyDocuments";

const RoutesForGPA = () => (
  <Routes>
    <Route element={<ProtectedAgentRoute redirectPath="/user/login" allowedRegion={Region.AU} />}>
      <Route path="" element={<Navigate to="form" />} />
      {/* AU Group Personal Accident */}
      <Route path="form/*" element={<CreateGroupPersonalAccident />} />
      <Route path="bind/*" element={<GroupPersonalAccidentBindQuote />} />
      <Route path="retrieve-list" element={<GroupTravelQuoteList />} />
      <Route path="documents" element={<PolicyDocuments productName={ProductsName.APAG} region={Region.AU} />} />
    </Route>
    <Route path="payment/success" element={<GroupTravelSuccess />} />
    <Route path="*" element={<NotFound homePage="/" />} />
  </Routes>
);

export default RoutesForGPA;
