import { FC, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Details from "./QuotationDetails";
import ProposerDetails from "./ProposerDetails";
import Summary from "./Summary";
import TermsConditions from "./TermsConditions";
import PaymentLinkSent from "../PaymentLinkSent";
import { LiabilityBindContext } from "../BindQuote";
import { FeedbackDetails } from "../../../types/travel/Policy";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
  sendPaymentLink?: VoidFunction | null;
  creditTerms?: VoidFunction | null;
  region: string;
  onFeedback: (details: FeedbackDetails) => Promise<void>;
};

const LiabilityBindRoutes: FC<Props> = props => {
  const { state, setFormState } = useContext(LiabilityBindContext);
  const contextProps = { state, setFormState, ...props };
  return (
    <Routes>
      <Route path={"quoteDetails"} element={<Details {...contextProps} />} />
      <Route path={"proposerDetails"} element={<ProposerDetails {...contextProps} />} />
      <Route path={"summary"} element={<Summary {...contextProps} />} />
      <Route path={"terms"} element={<TermsConditions {...contextProps} />} />
      <Route path="payment-link-sent" element={<PaymentLinkSent onFeedback={props.onFeedback} />} />
      <Route path="" element={<Navigate to="quoteDetails" />} />
    </Routes>
  );
};

export default LiabilityBindRoutes;
