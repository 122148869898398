import React from "react";

type VariableFunction<T extends unknown[]> = (...args: T) => void;
/**
 * Cancels calls to the given updates when the component is unmounted
 * This is mainly so we don't have to work with complex cancellations, yet..
 * @param {VariableFunction<T>} callback The given callback to no-op when unmounted
 * @returns {VariableFunction<T>} A function adhering to the same interface as the given function
 */
const useCancelWhenUnmounted = <T extends unknown[]>(callback: VariableFunction<T>) => {
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return React.useCallback<VariableFunction<T>>(
    (...args: T) => {
      isMounted.current && callback(...args);
    },
    [callback]
  );
};

export default useCancelWhenUnmounted;
