import { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RadioInput } from "../../../../components/form-fields";
import { DateInput, NumberInput } from "../../../../components/form-fields";
import { IOption } from "../../../../components/form-fields/RadioInput";
import SelectInput from "../../../../components/form-fields/SelectInput";
import { FormGroup } from "../../../../components/forms";
import { States } from "../../enums/forms/DomicileRegion";
import { policyDetailsValidation } from "../../../../helpers/group_travel/validations";
import useForm from "../../../../hooks/useForm";
import { addDays, addYears } from "date-fns";
import { CreateQuoteContext } from "../../CreateQuote";
import AuGroupBusinessFormStepNavigation from "../CreateQuoteStepNavigation";
import { CompanyDomicileInfo } from "../../more-info/CompanyDomicileInfo";
import { DomesticTravelInfo } from "../../more-info/DomesticTravelInfo";
import { InternationalTravelInfo } from "../../more-info/InternationalTravelInfo";
import { getStampDutyPercentage } from "../../../../helpers/group_travel/labels";
import GroupTravelHeading from "../../components/Heading";
import { GroupTravelFormState } from "../types";
import TextInput from "../../../../components/form-fields/TextInput";

const yesNoOptions: IOption<"yes" | "no">[] = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

type ExemptedStateOptionProps = {
  value: "yes" | "no" | null;
  onValueChange: (value: "yes" | "no" | null) => void;
  formLabel: string;
  label: string;
  error?: string | boolean | null;
};

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};
const ExemptedStateOption: FC<PropsWithChildren<ExemptedStateOptionProps>> = ({ value, error, onValueChange, formLabel, label, children }) => (
  <div className="flex justify-between flex-wrap gap-x-4">
    <div className="mt-8 flex-shrink max-w-[34rem]">
      <p className="mr-4 text-xl">{label}</p>
      {/* labels on the left side */}
      {children}
    </div>
    <div className="min-w-[12rem] md:max-w-[12rem] flex-1">
      <FormGroup margin="mt-4 md:mt-8 " title="" fullWidth>
        <RadioInput error={error} name={formLabel} onValueChange={onValueChange} value={value} options={yesNoOptions} aria-label={formLabel} />
      </FormGroup>
      {/* List of Radio buttons for the group travel policy details options */}
    </div>
  </div>
);
export type FieldChange<T> = <K extends keyof T>(key: K, value: T[K]) => void;
const PolicyDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct" });
  const { state, onChange } = useContext(CreateQuoteContext);
  const { validateField, validateForm, field, handleFieldChange }: { validateField: any; validateForm: any; field: any; handleFieldChange: any } =
    useForm(state, policyDetailsValidation, onChange);
  const handleNext = () => validateForm(() => onNext?.());
  const [moreInfo, setMoreInfo] = useState<string | null>(null);
  const [initialRender, setInitialRender] = useState(false);
  const [initialRenderforDates, setinitialRenderforDates] = useState(false);

  useEffect(() => {
    onChange("internationalTravelDays", state.internationalAvgTripDuration * state.numberOfInternationalTrip);
  }, [state.internationalAvgTripDuration, state.numberOfInternationalTrip]);

  useEffect(() => {
    onChange("domesticTravelDays", state.domesticAvgTripDuration * state.numberOfDomesticTrip);
  }, [state.domesticAvgTripDuration, state.numberOfDomesticTrip]);

  useEffect(() => {
    //update end date based on start date
    if (initialRenderforDates) {
      const endDate = addYears(new Date(state.coverageStartDate as Date), 1);
      onChange("coverageEndDate", endDate);
      validateField("coverageStartDate", state.coverageStartDate);
    }
  }, [state.coverageStartDate]);

  useEffect(() => {
    if (state.exemptedStampDuty === "yes" || state.exemptedStampDuty === null) {
      onChange("domicileRegion", "");
      onChange("regionCode", "");
      onChange("stampDutyRate", 0);
      validateField("domicileRegion", "");
    }
  }, [state.exemptedStampDuty]);

  useEffect(() => {
    const stampDuty = getStampDutyPercentage(state.domicileRegion);
    if (state.exemptedStampDuty !== null && stampDuty !== null) {
      onChange("stampDutyRate", Number(stampDuty.percentage));
      onChange("regionCode", stampDuty.name);
    }
  }, [state.domicileRegion]);

  useEffect(() => {
    if (initialRender) {
      validateField("internationalTravelDays", state.internationalTravelDays);
      validateField("domesticTravelDays", state.domesticTravelDays);
    }
    setInitialRender(true);
  }, [state.internationalTravelDays, state.domesticTravelDays]);

  useEffect(() => {
    if (initialRenderforDates) {
      validateField("coverageStartDate", state.coverageStartDate);
      validateField("coverageEndDate", state.coverageEndDate);
    }
    setinitialRenderforDates(true);
  }, [state.coverageStartDate, state.coverageEndDate]);

  const handleDateChange: FieldChange<GroupTravelFormState> = (key, value) => {
    handleFieldChange(key, value);
    validateField(key, value);
  };

  return (
    <>
      <div className="container-flow flex-1">
        <GroupTravelHeading title={t("steps.policyDetails")} alt="GroupTravel" />
        <ExemptedStateOption
          formLabel="claims-made"
          label={t("policyDetailLabels.exemptedStampDuty")}
          value={state.exemptedStampDuty}
          {...field.exemptedStampDuty}
        />
        <div className="flex justify-between flex-wrap">
          <div className="flex-1">
            <div className="min-w-[20rem]">
              <FormGroup hasVerticalFieldsOrientation title={t("policyDetailLabels.companyDomicile")} onMoreInfo={() => setMoreInfo("domicle-cover")}>
                <SelectInput
                  aria-label="title"
                  label={t("policyDetailLabels.formProperties.selectState")}
                  value={state.domicileRegion}
                  options={Object.values(States)}
                  disabled={state.exemptedStampDuty === "yes" || state.exemptedStampDuty === null}
                  {...field.domicileRegion}
                />
              </FormGroup>
              <div className="inline-flex items-center font">
                <p className="text-xl mt-4">{t("policyDetailLabels.travelDetailsText")} </p>
              </div>
              <FormGroup className="!mt-4" title={t("policyDetailLabels.numberOfInternationalTrip")}>
                <NumberInput {...field.numberOfInternationalTrip} min={0} max={2500} value={state.numberOfInternationalTrip} />
              </FormGroup>

              <FormGroup className="!mt-4 whitespace-nowrap" title={t("policyDetailLabels.internationalAvgTripDuration")}>
                <NumberInput {...field.internationalAvgTripDuration} min={0} max={2500} value={state.internationalAvgTripDuration} />
              </FormGroup>

              <FormGroup
                title={t("policyDetailLabels.internationalTravelDays")}
                onMoreInfo={() => setMoreInfo("international-cover")}
                className="!mt-4"
              >
                <TextInput
                  disabled={true}
                  aria-label={`internationalTravelDays`}
                  value={state.internationalTravelDays}
                  span="text-center text-xl"
                  {...field.internationalTravelDays}
                />
              </FormGroup>

              <FormGroup className="!mt-4" title={t("policyDetailLabels.numberOfDomesticTrip")}>
                <NumberInput {...field.numberOfDomesticTrip} min={0} max={5000} value={state.numberOfDomesticTrip} />
              </FormGroup>

              <FormGroup className="!mt-4" title={t("policyDetailLabels.domesticAvgTripDuration")}>
                <NumberInput {...field.domesticAvgTripDuration} min={0} max={5000} value={state.domesticAvgTripDuration} />
              </FormGroup>

              <FormGroup title={t("policyDetailLabels.domesticTravelDays")} className="!mt-4" onMoreInfo={() => setMoreInfo("domestic-cover")}>
                <TextInput
                  disabled={true}
                  aria-label={`domesticTravelDays`}
                  value={state.domesticTravelDays}
                  span="text-center text-xl"
                  {...field.domesticTravelDays}
                />
              </FormGroup>

              <FormGroup title={t("policyDetailLabels.startdateOfCoverage")}>
                <DateInput
                  value={state.coverageStartDate}
                  minDate={addDays(new Date(), 0)}
                  label={t("policyDetailLabels.startdateOfCoverage")}
                  {...field.coverageStartDate}
                  onValueChange={v => {
                    handleDateChange("coverageStartDate", v);
                    field.coverageStartDate.onValueChange(v);
                  }}
                />
              </FormGroup>
              <FormGroup title={t("policyDetailLabels.enddateOfCoverage")}>
                <DateInput
                  value={state.coverageEndDate}
                  minDate={addDays(new Date(), 0)}
                  {...field.coverageEndDate}
                  label={t("policyDetailLabels.enddateOfCoverage")}
                  onValueChange={v => {
                    field.coverageEndDate.onValueChange(v);
                    validateField("coverageEndDate", v, { coverageStartDate: state.coverageStartDate });
                  }}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <AuGroupBusinessFormStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} nextButtonLabel={"See Plans"} />
      <CompanyDomicileInfo show={moreInfo === "domicle-cover"} onClose={() => setMoreInfo(null)} />
      <DomesticTravelInfo show={moreInfo === "domestic-cover"} onClose={() => setMoreInfo(null)} />
      <InternationalTravelInfo show={moreInfo === "international-cover"} onClose={() => setMoreInfo(null)} />
    </>
  );
};

export default PolicyDetails;
