const DeclarationTerms = () => (
  <>
    <p>On behalf of the Proposer:</p>
    <ol>
      <li>I/We declare and warrant that:</li>
      <ol type="a">
        <li>
          all answers given in this Application are true, complete and accurate in every respect, and all material factors affecting an insurer’s
          assessment of the risks have been disclosed to Allied World Assurance Company, Ltd (Hong Kong Branch) (the “Company” or “Allied World”);
        </li>
        <li>no material fact has been omitted, misstated or suppressed;</li>
        <li>no insurer has terminated any of the Proposer’s insurances;</li>
        <li>I/we are authorised by the Proposer to make this declaration, agree to the statements herein and provide the consents herein;</li>
        {/* Point6 */}
        <li>
          I / we have read and understand and agree to the Important Product Information and Important Notices contained herein. I/We have read,
          understand and accept the terms, conditions and exclusions outlined in the Policy Wording.
        </li>
      </ol>
      <li>I/We agree:</li>
      <ol type="a">
        <li>
          that the I/we/ the Proposer has a duty to fully and faithfully disclose to the Company all the facts as I/we/the Proposer knows or ought to
          know them, otherwise, I/we/the Proposer may receive nothing from the Policy;
        </li>
        {/* Point7c */}
        <li>
          that should any of the information given by I/us/the Proposal alter between the date of this Application and the inception date of the
          insurance to which this Application relates, I/we/the Proposer will give immediate notice thereof to the Company;
        </li>
        <li>to the Company verifying the information provided in the Application with the relevant parties;</li>
        <li>
          that if this Application is accepted by the Proposer, to the Company paying the insurance intermediary through whom the Policy is arranged
          commission during the continuance of the Policy, including renewals, for arranging the Policy; and
        </li>
        {/* Point7f*/}
        <li>
          that the Policy will not become effective until this Quotation has been accepted in writing by the Proposer and agree that this Application
          and Declaration shall form the basis of the insurance contract between the Proposer and the Company.
        </li>
      </ol>
      <li>
        I/We consent to Allied World collecting, using, processing and disclosing my/our/ the Proposer’s personal data in accordance with the Personal
        Information Collection Statement (which is provided along with this Quotation). If I/we have provided or will provide information to Allied
        World about any other individuals, I/we confirm that I/we are authorised to disclose their personal data, agree to the Personal Information
        Collection Statement and to make this Declaration on their behalf.
      </li>
    </ol>
  </>
);

export default DeclarationTerms;
