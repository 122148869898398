import { Address, Requester } from "../../../../../types/travel/Policy";
import { ContentCell, LabelCell, NormalRow, SummaryTable } from "./SummaryTable";
import { useTranslation } from "react-i18next";
import { formatToDisplayDate } from "../../../../../utils/date";
import formatToAddressLines from "../../../../../helpers/travel/address";

interface IProps {
  policyHolder: Requester;
  address: Address;
  isPolicyHolderTravelling: boolean;
}
interface ICombinedAddressProps {
  address: Address;
}
const CombinedAddress = ({ address }: ICombinedAddressProps) => {
  const formattedAddressLines = formatToAddressLines(address);
  return (
    <>
      {formattedAddressLines.map((line, index) => {
        return <div key={index}>{line}</div>;
      })}
    </>
  );
};

const PersonalDetailsSummary = ({ policyHolder, address, isPolicyHolderTravelling }: IProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.summary.summarySection.policyHolderDetails" });
  const { t: commonTranslations } = useTranslation("pages", { keyPrefix: "common" });

  return (
    <SummaryTable className="w-full mb-4 md:mt-4 table-fixed">
      <NormalRow>
        <LabelCell>{t("title.label")}</LabelCell>
        <ContentCell>{policyHolder.title}</ContentCell>
      </NormalRow>
      <NormalRow>
        <LabelCell>{t("givenName.label")}</LabelCell>
        <ContentCell>{policyHolder.givenName}</ContentCell>
      </NormalRow>
      <NormalRow>
        <LabelCell>{t("familyName.label")}</LabelCell>
        <ContentCell>{policyHolder.familyName}</ContentCell>
      </NormalRow>
      <NormalRow>
        <LabelCell>{t("identificationNumber.label")}</LabelCell>
        <ContentCell>{policyHolder.identificationNumber}</ContentCell>
      </NormalRow>
      <NormalRow>
        <LabelCell>{t("dateOfBirth.label")}</LabelCell>
        <ContentCell>{formatToDisplayDate(policyHolder.dateOfBirth)}</ContentCell>
      </NormalRow>
      {policyHolder.phoneNumber && (
        <NormalRow>
          <LabelCell>{t("phoneNumber.label")}</LabelCell>
          <ContentCell>{policyHolder.phoneNumber}</ContentCell>
        </NormalRow>
      )}
      {policyHolder.email && (
        <NormalRow>
          <LabelCell>{t("email.label")}</LabelCell>
          <ContentCell>{policyHolder.email}</ContentCell>
        </NormalRow>
      )}
      <NormalRow>
        <LabelCell>{t("address.label")}</LabelCell>
        <ContentCell>
          <CombinedAddress address={address} />
        </ContentCell>
      </NormalRow>

      <NormalRow>
        <LabelCell>{t("policyHolderIsTravelling.label")}</LabelCell>
        <ContentCell>{commonTranslations(isPolicyHolderTravelling ? "yes" : "no")}</ContentCell>
      </NormalRow>
    </SummaryTable>
  );
};

export default PersonalDetailsSummary;
