import { useEffect } from "react";
import { Article } from "../../components/groups";
import Link from "../../components/basics/Link";
import { ButtonType, createTextClasses } from "../../helpers/shared/buttonStyling";
import Header from "../auth/layout/Header";
import ManulifeFooter from "./components/ManulifeFooter";
import briefcaseImage from "../../assets/icons/briefcase.svg";
import { useTranslation } from "react-i18next";
import { clearPISessionStorage } from "../../helpers/shared/sessionStorage";

const Success = () => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity" });
  useEffect(() => {
    clearPISessionStorage("professional_indemnity/manulife");
  }, []);
  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <Header home={t("header.home")} hideAccount>
        <div className="my-auto text-xl font-serif md:text-3xl whitespace-nowrap hidden md:block">{t("header.title")}</div>
        <img src={briefcaseImage} alt="professional indemnity logo" className="ml-2 h-10 md:h-16" />
      </Header>
      <div className="container-flow flex-grow mt-6 lg:mt-20">
        <div className="flex gap-10">
          <div className="w-full">
            <h2 className="font-serif font-bold text-4xl mb">{t("newProduct.payment.success.title")}</h2>
            <Article>
              <p>{t("newProduct.payment.success.content")}</p>
              <p className="mt-12 mb-12">
                <div>{t("newProduct.payment.success.sub-title")}</div>
                <div>{t("newProduct.payment.success.paragraph")}</div>
              </p>
              <p className="mt-12 mb-12">
                <Link to={t("header.home")} type={ButtonType.PRIMARY}>
                  <span className={createTextClasses(ButtonType.PRIMARY)}>{t("newProduct.payment.success.button.back")}</span>
                </Link>
              </p>
            </Article>
          </div>
        </div>
      </div>
      <ManulifeFooter />
    </div>
  );
};

export default Success;
