import { FC } from "react";
import cls from "../../../utils/cls";
import { H2 } from "../../../components/basics";
import classNamePresets from "../../../utils/classNamePresets";
import travelImg from "../../../assets/images/travel.svg";
import gpaImg from "../../../assets/images/gpa.svg";

interface HeadingProps {
  title: string;
  alt: "GroupTravel" | "GroupPersonalAccident";
  className?: string;
}

const Heading: FC<HeadingProps> = ({ title, className, alt }) => {
  const imgSrc = alt === "GroupTravel" ? travelImg : gpaImg;

  return (
    <div className={cls("flex items-center justify-between pb-4", className)}>
      <H2 className={classNamePresets.mainHeading}>{title}</H2>
      <div className="hidden md:block">
        <img src={imgSrc} className="h-10 md:h-16" alt={alt} />
      </div>
    </div>
  );
};

export default Heading;
