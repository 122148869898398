import agentApiService from "./../../../services/event_public_liability/agentApiService";
import { CorrespondenceAddress, liabilityBindState } from "../../../pages/event_public_liability/bind/types";
import { formatToServerDate } from "../../../utils/date";
import { createQuoteRequest } from "./quoteApi";
import { premiumQuoteRequest } from "../../../types/event_public_liability/api/Quote";
import { Region } from "../../../enums/shared/forms/Region";

const mapProposerAddressDetails = (quote: liabilityBindState, region: string) => {
  const { district, territory, floorNumber, building, streetName, houseNumber, postalCode, unitNumber } = quote;
  const address =
    region === Region.HK
      ? {
          district,
          territory,
          floorNumber: houseNumber,
          building,
          streetName,
        }
      : {
          postalCode,
          floorNumber,
          unitNumber,
          streetName,
          number: houseNumber,
        };
  return address;
};

const mapEventAddressDetails = (quote: liabilityBindState, region: string): Partial<CorrespondenceAddress> => {
  const address =
    region === Region.HK
      ? {
          territory: quote.eventTerritory,
          district: quote.eventDistrict,
          building: quote.eventBuilding,
          floorNumber: quote.eventHouseNumber,
          streetName: quote.eventStreetName,
        }
      : {
          postalCode: quote.eventPostalCode,
          floorNumber: quote.eventfloorNumber,
          unitNumber: quote.eventunitNumber,
          number: quote.eventHouseNumber,
          streetName: quote.eventStreetName,
        };
  return address;
};
const proposerDetailRequest = (quote: liabilityBindState, region: string) => {
  return {
    uen: quote.uenNumber,
    email: quote.email,
    address: mapProposerAddressDetails(quote, region),
    skip_phaddr_update: quote.uenNumberStatus === "verified" ? true : false,
  };
};
const eventDetailsRequest = (quote: liabilityBindState, region: string) => {
  const selectedPlan = quote.plans.find((plan: any) => plan.id.replace(/_/g, ",") === quote.limit);
  return {
    name: quote.eventTitle,
    address: mapEventAddressDetails(quote, region),
    eventPlan: selectedPlan,
    mobileNumber: quote.mobileNumber,
    startDate: formatToServerDate(quote.coverageStartDate),
    endDate: formatToServerDate(quote.coverageEndDate),
    additionalInsured: quote.additionalInsured,
    landlordNames: quote.landlord,
    principalNames: quote.principal,
    limit: quote.limit,
  };
};
export const createBindQuoteRequest = (quote: liabilityBindState, paymentMethod: string, region: string): premiumQuoteRequest => {
  return {
    ...createQuoteRequest(quote, region),
    quoteId: quote.quoteId,
    quoteNo: quote.quoteNo,
    proposerDetails: proposerDetailRequest(quote, region),
    eventDetails: eventDetailsRequest(quote, region),
    termsConditions: {
      acceptedAt: new Date(),
      ...(region === Region.HK
        ? {
            promotionalServices: quote.promotionalServices,
          }
        : {
            marketingVoiceCall: quote.marketingVoiceCall,
            marketingTextMessage: quote.marketingTextMessage,
          }),
    },
    paymentDetails: {
      method: paymentMethod,
    },
    validityDate: quote.validityDate,
    status: quote.status,
    agentEmail: quote.agentEmail,
    createdAt: quote.createdAt,
  };
};

export const checkout = (quote: liabilityBindState, paymentMethod: string, region: string) => agentApiService.checkout(quote, paymentMethod, region);
