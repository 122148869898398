import { Link, useNavigate } from "react-router-dom";
import { Article } from "../../../../components/groups";
import { Button } from "../../../../components/basics/Button";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";

type Props = {
  onCompletion: VoidFunction;
};

const PaymentLinkSent: React.FC<React.PropsWithChildren<Props>> = ({ onCompletion }) => {
  const navigate = useNavigate();

  const newQuote = () => {
    onCompletion();
    navigate("/");
  };

  return (
    <>
      <div className="container-flow flex-grow w-3/4 mt-6 lg:mt-32">
        <h2 className="font-serif font-bold text-4xl mb icon-check-circle icon-12 icon-green icon-bottom-1 after:ml-5">Payment Link Sent</h2>
        <Article>
          <p>A payment link has been sent to the Policyholder at the email provided. This link is valid for 24 hours.</p>
          <p>Once payment has been made, both the Policyholder and you will receive an email notification.</p>
          <p>
            Please note that the policy will not become effective until the application has been accepted by Allied World Assurance Company, Ltd
            (Singapore Branch) and payment in full has been successfully made.
          </p>
          <p>
            If you require another payment link to be sent to the Policyholder, you will have to{" "}
            <Link to="/travel/details" onClick={onCompletion}>
              create a new quote
            </Link>
          </p>
        </Article>
      </div>
      <div className="flex justify-end container-flow bg-white border-grey-light border-t py-4 mt-10 md:mt-20">
        <div className="md:flex md:justify-end">
          <Button type={ButtonType.PRIMARY} onClick={newQuote}>
            Back to Homepage
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentLinkSent;
