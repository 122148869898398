/**
 * Represents a default preset of tailwind classNames for basic components
 */
const classNamePresets = {
  dialogTitle: "font-serif font-bold text-3xl md:text-4xl",
  mainHeading: "font-serif font-bold text-3xl md:text-4xl",
  secondaryHeading: "font-serif font-semibold text-2xl md:text-[2.25rem]",
  quaternary: "col-span-full font-bold md:text-xl",
};

export default classNamePresets;
