import { PriceObject } from "../../types/shared/Pricing";

export const applyPercentages = (price: number, percentages: { discount: number; commission?: number }): PriceObject => {
  return {
    originalPrice: price,
    price: roundPrice(price * ((100 - percentages.discount) / 100)),
    discount: roundPrice(price * (percentages.discount / 100)),
    commission: percentages.commission ? roundPrice(price * (percentages.commission / 100)) : undefined,
    discountPercentage: percentages.discount,
    commissionPercentage: percentages.commission,
  };
};

const roundPrice = (price: number) => Math.round(price * 100) / 100;
