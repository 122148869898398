import cls from "../../utils/cls";
import { FC } from "react";
import { Button, LoadingButton } from "../basics/Button";
import { ButtonType } from "../../helpers/shared/buttonStyling";

export type PropsWithoutLabels = {
  isLoading?: boolean;
  loadingText?: string;
} & (
  | {
      onNext?: VoidFunction | null;
      nextButtonLabel?: string;
      onSubmit?: undefined;
    }
  | {
      onSubmit?: VoidFunction | null;
      onNext?: undefined;
      nextButtonLabel?: string;
    }
) & {
    onBack?: VoidFunction | null;
    backButtonLabel?: string;
  };

type Props = PropsWithoutLabels & {
  backButtonLabel?: string;
  nextButtonLabel?: string;
};

type NavigateProps = {
  isLoading: boolean;
  onClick: VoidFunction;
  title: string;
  loadingText?: string;
};

const NavigateFurtherButton: FC<React.PropsWithChildren<NavigateProps>> = ({ isLoading, onClick, title, loadingText }) => (
  <div className="md:w-1/2 md:flex md:justify-end">
    <LoadingButton
      isLoading={isLoading || false}
      loadingText={loadingText || "Submitting"}
      htmlType="button"
      onClick={onClick}
      type={ButtonType.PRIMARY}
    >
      {title}
    </LoadingButton>
  </div>
);

// Uses flex reverse for tab order, the submit button should have precedence over the previous / back button
const FormStepNavigation: FC<React.PropsWithChildren<Props>> = ({ isLoading, onBack, onNext, onSubmit, loadingText, ...props }) => {
  return (
    <div className={cls("md:flex md:flex-row-reverse container-flow pt-4 pb-8 mt-10", "md:mt-20")}>
      {onSubmit && <NavigateFurtherButton isLoading={isLoading || false} onClick={onSubmit} title={props.nextButtonLabel as string} />}
      {onNext && (
        <NavigateFurtherButton isLoading={isLoading || false} onClick={onNext} loadingText={loadingText} title={props.nextButtonLabel as string} />
      )}

      {onBack && (
        <div className="md:w-1/2 md:justify-start flex justify-center mr-auto">
          <Button onClick={onBack} htmlType="button" className="px-0 w-auto md:w-auto" type={ButtonType.LINK}>
            {props.backButtonLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FormStepNavigation;
