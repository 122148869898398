import { FC } from "react";

type Props = {
  state: any;
};

const OptionalCover: FC<Props> = ({ state }) => {
  if (state.drone === "no" && state.setupAndDismantling === "no" && state.volunteers === "no") {
    return <p>-</p>;
  }

  return (
    <>
      {state.drone === "yes" && <p>Drones Third Party Liability Cover</p>}
      {state.setupAndDismantling === "yes" && <p>Setting up and/or Dismantling of Event Venue</p>}
      {state.volunteers === "yes" && <p>Claims Arising From Volunteers</p>}
    </>
  );
};

export default OptionalCover;
