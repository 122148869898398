import { LoginState, useAuth } from "../../hooks/useAuth";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import Main from "../../pages/Main";
import { Role } from "../../enums/shared/forms/Role";
import { getRole } from "../../helpers/shared/getRole";

interface IProps {
  component: JSX.Element;
  routePath: string;
}
export const isTPARole = (role: string) => role !== Role.Unknown && role === Role.TPA;
export const isOPSRole = (role: string) => role !== Role.Unknown && role === Role.OPS;
const getTPAOrOPSHomeUrl = (role: string) => {
  let routeUrl = "/tpa";
  if (isOPSRole(role)) routeUrl = "/contractor";
  return routeUrl;
};
const AuthRoute: FC<React.PropsWithChildren<IProps>> = ({ component, routePath }) => {
  const { user } = useAuth();
  const role = getRole(user);

  if (user === LoginState.LoggedOut) return component;
  if (user === LoginState.Unknown) return null;
  if (isTPARole(role) || isOPSRole(role)) return <Navigate to={getTPAOrOPSHomeUrl(role)} replace />;

  return routePath === "/products" ? <Main /> : <Navigate to={"/products"} replace />;
};

export default AuthRoute;
