import { FC, PropsWithChildren } from "react";
import { formatToDisplayQuoteReadableDate } from "../../../utils/date";
import { liabilityFormState } from "../form/types";
import { CommonTable, ContentCell, LabelCell, NormalRow } from "../components/CommonTable";
import OptionalCover from "../components/OptionalCovers";

type Props = PropsWithChildren<{
  state: liabilityFormState;
  t: any;
}>;
const QuotationInfoSection: FC<Props> = ({ state, t }) => {
  const DetailsLabels = {
    staticOutdoor: "Static Event (Outdoor Setting)",
    staticIndoor: "Static Event (Indoor Setting)",
    dynamicIndoor: "Dynamic Event (Indoor Setting)",
  };
  return (
    <CommonTable className="w-full mt-2 table-fixed">
      <NormalRow>
        <ContentCell>{t("PricingLabels.Quotationno")}</ContentCell>
        <LabelCell>{state?.quoteNo}</LabelCell>
      </NormalRow>
      <NormalRow>
        <ContentCell>{t("PricingLabels.validityDate")}</ContentCell>
        <LabelCell>{formatToDisplayQuoteReadableDate(new Date(state.validityDate))}</LabelCell>
      </NormalRow>
      <NormalRow>
        <ContentCell>{t("PricingLabels.eventType")}</ContentCell>
        <LabelCell>{state.eventType && DetailsLabels[state.eventType]}</LabelCell>
      </NormalRow>
      <NormalRow>
        <ContentCell>{t("PricingLabels.eventDuration")}</ContentCell>
        <LabelCell>
          {state?.duration} {state.duration === 1 ? "Day" : "Days"}
        </LabelCell>
      </NormalRow>
      <NormalRow>
        <ContentCell>{t("PricingLabels.noOfAttendees")}</ContentCell>
        <LabelCell>{state.attendees}</LabelCell>
      </NormalRow>
      <NormalRow>
        <ContentCell>{t("PricingLabels.optionalCover")}</ContentCell>
        <LabelCell>
          <OptionalCover state={state} />
        </LabelCell>
      </NormalRow>
    </CommonTable>
  );
};

export default QuotationInfoSection;
