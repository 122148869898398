import { FC } from "react";
import { NewTravelPolicyState } from "../../../../types/travel/Policy";
import { H2 } from "../../../../components/basics";
import SummarySection from "../../../../components/forms/SummarySection";
import TravelDetailsSummary from "./summary/TravelDetailsSummary";
import TravelPlanSummary from "./summary/TravelPlanSummary";
import PersonalDetailsSummary from "./summary/PersonalDetailsSummary";
import { formatToTwoDecimalsWithThousandSeparator } from "../../../../helpers/shared/format";
import TravellerDetailsSummary from "./summary/TravellerSummary";
import { useTranslation } from "react-i18next";
import classNamePresets from "../../../../utils/classNamePresets";
import { useAuth } from "../../../../hooks/useAuth";
import { useClientId } from "../../../../hooks/useClientId";
import { createLink } from "../../../../helpers/shared/links";
import PolicyFormStepNavigation from "../PolicyFormStepNavigation";

export interface SummaryProps {
  flowState: NewTravelPolicyState;
  onBack: () => void;
  onNext: () => void;
}

const getNavigationLinks = (isAgentSignedIn: boolean, clientId: string) => {
  const detailsPage = createLink("/travel/details", isAgentSignedIn ? undefined : clientId);
  const planPage = createLink("/travel/plan", isAgentSignedIn ? undefined : clientId);
  const personalPage = createLink("/travel/personal-details", isAgentSignedIn ? undefined : clientId);
  return { detailsPage, planPage, personalPage };
};
const Summary: FC<React.PropsWithChildren<SummaryProps>> = ({ flowState, onBack, onNext }) => {
  const totalPrice = flowState.travelPlan.planPrice?.price || 0;
  const originalTotal = flowState.travelPlan.planPrice?.originalPrice || 0;

  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.summary" });

  const clientId = useClientId();
  const { isSignedIn: isAgentSignedIn } = useAuth();
  const { detailsPage, personalPage, planPage } = getNavigationLinks(isAgentSignedIn(), clientId);
  const discountPercentage = flowState.travelPlan.planPrice?.discountPercentage || null;

  return (
    <>
      <div className="container-flow flex-grow top-2">
        <H2 className={classNamePresets.mainHeading}>{t("title")}</H2>

        <div className="mx-[-1.5rem] md:mx-0">
          <div className="bg-white px-8 md:px-16 py-2 mt-8 md:mt-10 rounded shadow-xl">
            <SummarySection name={t("groups.policyDetails")} link={detailsPage}>
              <TravelDetailsSummary {...flowState.travelDetails} />
            </SummarySection>
            <SummarySection name={t("groups.plan")} link={planPage}>
              <TravelPlanSummary {...flowState.travelPlan} />
            </SummarySection>
            <SummarySection name={t("groups.policyHolderDetails")} link={personalPage}>
              <PersonalDetailsSummary
                policyHolder={flowState.personalDetails.requester}
                isPolicyHolderTravelling={flowState.personalDetails.requestingPersonIsTravelingPerson}
                address={flowState.personalDetails.address}
              />
            </SummarySection>
            {flowState.personalDetails.travellers.length > 0 &&
              flowState.personalDetails.travellers.map((traveller, index) => (
                <SummarySection
                  key={`Traveller-${flowState.personalDetails.requestingPersonIsTravelingPerson ? index + 2 : index + 1}-Details`}
                  name={t("groups.travellerDetails", { number: flowState.personalDetails.requestingPersonIsTravelingPerson ? index + 2 : index + 1 })}
                  link="/travel/personal-details"
                >
                  <TravellerDetailsSummary traveller={traveller} />
                </SummarySection>
              ))}
          </div>

          <div className="bg-red rounded-b text-white px-8 md:px-16 py-8">
            <div className="flex flex-row flex-wrap justify-between">
              <p className="text-xl md:font-bold mr-auto pr-1">{t("summarySection.total.content")}</p>
              <div className="flex flex-row flex-wrap">
                {discountPercentage ? (
                  <p className="text-xl line-through pr-1">
                    {t("summarySection.total.price", { totalPrice: formatToTwoDecimalsWithThousandSeparator(originalTotal) })}
                  </p>
                ) : null}
                <div className="flex flex-wrap">
                  <p className="text-xl font-bold pr-1">
                    {t("summarySection.total.price", { totalPrice: formatToTwoDecimalsWithThousandSeparator(totalPrice) })}
                  </p>
                  {discountPercentage ? <p className="text-xl font-bold">{`(${discountPercentage}% off)`}</p> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PolicyFormStepNavigation onBack={onBack} onNext={onNext} />
    </>
  );
};

export default Summary;
