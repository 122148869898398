import { useEffect, useState } from "react";
import TextInput from "../../components/form-fields/TextInput";
import { FormGroup } from "../../components/forms";
import Header from "../auth/layout/Header";
import { Button } from "../../components/basics/Button";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import { DocumentListResponse, tpaApiServices } from "../../services/tpa/tpaApiServices";
import useForm from "../../hooks/useForm";
import ResultSection from "./components/ResultSection";
import { validations } from "../../helpers/tpa/validations";
import { IUser, useAuth } from "../../hooks/useAuth";
import TpaFooter from "./components/Footer";
import cls from "../../utils/cls";

export type PolicySearchType = {
  policyNo: string;
  productCode: string;
};

const policyScheduleFileName = "policy_schedule.pdf";
const getPolicySchedule = (policyDetails: DocumentListResponse): DocumentListResponse => {
  const policySchedule = policyDetails.find(({ filename }) => filename === policyScheduleFileName);
  const premiumSchedule = policyDetails
    .filter(({ filename }) => filename.substring(0, 4) === "SCH_" || filename.substring(0, 4) === "END_")
    .sort((a, b) => b.createdAt - a.createdAt)
    .filter((value, index, self) => index === self.findIndex(obj => obj.filename === value.filename));

  return policySchedule ? [policySchedule] : premiumSchedule.length > 0 ? premiumSchedule : [];
};

const Home: React.FC = () => {
  const [policyForm, setPolicyForm] = useState({ policyNo: "", productCode: "" });
  const [policyDetails, setPolicyDetails] = useState<DocumentListResponse>([]);
  const { field, validateForm } = useForm(policyForm, validations, (k, v) => setPolicyForm({ ...policyForm, [k]: v }));
  const [loading, setLoading] = useState<boolean>(false);
  const [isNoActivity, setIsNoActivity] = useState<boolean>(true);
  const { user } = useAuth();
  const productCode = (user as IUser)?.branch?.split("-")[2];

  useEffect(() => {
    setPolicyForm({ ...policyForm, productCode: productCode });
  }, [productCode]);

  useEffect(() => {
    setIsNoActivity(true);
  }, [policyForm.policyNo]);
  const searchPolicyHandle = async () => {
    validateForm(async () => {
      setPolicyDetails([] as DocumentListResponse);
      setIsNoActivity(false);
      try {
        setLoading(true);
        const response = await tpaApiServices.getPolicyDocuments({ policyNumber: policyForm.policyNo.toUpperCase() });
        const policySchedule = getPolicySchedule(response);
        setPolicyDetails(policySchedule);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    });
  };
  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <Header home="/tpa" />
      <div className={cls("container-flow flex flex-grow flex-col gap-4 my-8")}>
        <FormGroup title={"Search by Policy Number"}>
          <TextInput value={policyForm.policyNo.toUpperCase()} label="Policy Number" aria-label="policyNo" {...field.policyNo} disabled={loading} />
        </FormGroup>
        <div className="flex flex-col md:flex-row md:justify-end">
          <Button type={ButtonType.PRIMARY} className={`md: !w-auto ${loading && "!bg-grey"}`} onClick={searchPolicyHandle}>
            Go
          </Button>
        </div>
        <ResultSection isNoActivity={isNoActivity} loading={loading} policyDetails={policyDetails} />
      </div>
      <TpaFooter />
    </div>
  );
};

export default Home;
