import TextInput from "./TextInput";
import { Dispatch, InputHTMLAttributes, SetStateAction, useRef } from "react";
import cls from "../../utils/cls";
import { capitalize } from "lodash";
import { Identity } from "../../types/travel/Policy";
import { LETTERS_AND_NUMBERS_REGEX } from "../../helpers/travel/validations";
import { BlacklistCheckStatus } from "../../enums/shared/api/Api";
import useCancelWhenUnmounted from "../../hooks/useCancelWhenUnmounted";
import Spinner from "../icons/Spinner";
import validateIdentificationNumber from "../../helpers/travel/new-policy/api/validateIdentificationNumber";
import { useClientId } from "../../hooks/useClientId";
import { BlacklistCheckResponse } from "../../types/travel/new-policy/api/BlacklistCheck";
import useOnComponentDidMount from "../../hooks/useOnComponentDidMount";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  identificationNumber: string;
  identificationNumberStatus: Identity["identificationNumberStatus"];
  onValueChange: (value: string) => void;
  setIdentificationNumberStatus: (value: Identity["identificationNumberStatus"]) => void;
  validate: (value: string) => void;
  error?: string | boolean | null;
  isReferral: () => void;
  setServerError: Dispatch<SetStateAction<boolean>>;
}

const identificationStatusClassNameMapping = {
  checking: "text-green-dark delayed-show animate-duration-500",
  unknown: "",
  valid: "icon-valid icon-green-dark text-green-dark",
  invalid: "icon-invalid icon-red text-red",
  unsuccessful: "icon-warning icon-red text-red",
};

const handleBlacklistResponse = (
  { result, identity_number: idNo }: BlacklistCheckResponse["blacklist"],
  currentValue: string,
  setIdentificationNumberStatus: (value: Identity["identificationNumberStatus"]) => void,
  isReferral: VoidFunction
) => {
  const didInputChange = idNo !== currentValue;
  if (didInputChange) {
    return;
  }
  switch (result) {
    case BlacklistCheckStatus.SUCCESS:
      setIdentificationNumberStatus("valid");
      break;
    default:
      setIdentificationNumberStatus("invalid");
      isReferral();
  }
};

const IdentificationNumberTextInput = ({
  label,
  identificationNumber,
  identificationNumberStatus,
  onValueChange,
  error,
  validate,
  setIdentificationNumberStatus: setIdStatus,
  isReferral,
  setServerError,
}: IProps) => {
  const setIdentificationNumberStatus = useCancelWhenUnmounted(setIdStatus);
  const clientId = useClientId();
  const validFormat = identificationNumber && identificationNumber.match(LETTERS_AND_NUMBERS_REGEX);

  const onBlur = () => {
    validate(identificationNumber);
    validFormat && handleBlacklistCheck();
  };

  useOnComponentDidMount(() => {
    if (!validFormat) return;
    validFormat && handleBlacklistCheck();
  });

  // This is needed so we can refer to the most up to date value when updating
  const currentIdentificationNumber = useRef(identificationNumber);
  const handleBlacklistCheck = async () => {
    setServerError(false);
    setIdentificationNumberStatus("checking");

    try {
      const response = await validateIdentificationNumber(clientId, identificationNumber);
      handleBlacklistResponse(response.blacklist, currentIdentificationNumber.current, setIdentificationNumberStatus, isReferral);
    } catch (err) {
      console.error(err);
      setIdentificationNumberStatus("unsuccessful");
      setServerError(true);
    }
  };

  return (
    <TextInput
      label={label}
      name="identificationNumber"
      value={identificationNumber}
      onValueChange={value => {
        currentIdentificationNumber.current = value;
        onValueChange(value);
      }}
      onBlur={onBlur}
      error={error}
    >
      {validFormat && identificationNumberStatus !== "unknown" && (
        <div
          role="status"
          className={cls(
            "!absolute flex gap-1 font-light text-xs right-4 top-2 pointer-events-none icon-3 icon-left icon-top-0.5 pl-7",
            identificationStatusClassNameMapping[identificationNumberStatus]
          )}
        >
          {identificationNumberStatus === "checking" && (
            <div className="m-auto w-3 h-3">
              <Spinner width="0.75rem" height="0.75rem" color="#3F9293" />
            </div>
          )}
          {capitalize(identificationNumberStatus)}
        </div>
      )}
    </TextInput>
  );
};

export default IdentificationNumberTextInput;
