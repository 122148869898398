import { FC, PropsWithChildren } from "react";
import { formatToTwoDecimalsWithThousandSeparator } from "../../../helpers/shared/format";
import { proposerEventDetailsState } from "../bind/types";

type Props = {
  state: proposerEventDetailsState;
  t: any;
};
const TotalLabel: FC<PropsWithChildren<unknown>> = ({ children }) => <div className="min-w-[12rem]">{children}</div>;

const getPlan = (plans: any, selectedLimit: number | null) => {
  const selectedPlan = plans.find((plan: any) => plan.id.replace(/_/g, ",") === selectedLimit);
  return {
    price: selectedPlan?.price,
    currency: selectedPlan?.currency,
    total: selectedPlan?.total,
    gst: (selectedPlan?.price * parseFloat(selectedPlan?.gst)) / 100,
  };
};

const SummaryCalculations: FC<Props> = ({ state, t }) => {
  const selectedLimit = state.limit;
  const { gst, price, total } = getPlan(state.plans, selectedLimit);
  return (
    <div className="container-flow flex-1">
      <div className="bg-red rounded-b-lg text-white md:pl-[4.375rem] px-4 font-normal md:py-8 md:pr-[4.375rem] py-4">
        <div>
          <div className="flex justify-between flex-wrap">
            <TotalLabel>{t("SummaryLabels.premium")}</TotalLabel>
            {t("SummaryLabels.currency")} {formatToTwoDecimalsWithThousandSeparator(price)}
          </div>
          <div className="flex justify-between flex-wrap mt-2 xs:mt-0">
            <TotalLabel>{t("SummaryLabels.GST")}</TotalLabel>
            {t("SummaryLabels.currency")} {formatToTwoDecimalsWithThousandSeparator(gst)}
          </div>
          <div className="flex justify-between flex-wrap mt-4">
            <div className="font-bold md:text-xl">{t("SummaryLabels.totalDue")}</div>
            <div className="font-bold md:text-xl">
              {t("SummaryLabels.currency")} {formatToTwoDecimalsWithThousandSeparator(total)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryCalculations;
