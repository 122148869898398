import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import useForm from "../../../../hooks/useForm";
import Heading from "../../components/Heading";
import { FormGroup } from "../../../../components/forms";
import TextInput from "../../../../components/form-fields/TextInput";
import AddressFormGroup from "../../components/HkCommonAddressInput";
import LiabilityBindStepNavigation from "../../bind/LiabilityBindStepNavigation";
import ProposerDetailsMoreInfo from "../../components/ProposerDetailsMoreInfo";
import { HkLiabilityBindContext } from "../BindQuote";
import { eventDetailsValidations } from "../../../../helpers/event_public_liability/HkValidations";
import { removeExtraSpaces } from "../../../../helpers/shared/formHandlers";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const EventDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product.proposer" });
  const { state, setFormState, onChange } = useContext(HkLiabilityBindContext);
  const { validateField, field, validateForm, handleFieldChange } = useForm(state, eventDetailsValidations, onChange);
  const handleNext = () => {
    onNext && validateForm(onNext);
  };

  return (
    <>
      <div className="container-flow flex-grow">
        <Heading headingTitle={t("step3Title")} />
        <FormGroup title={t("eventName")}>
          <TextInput
            aria-label="proposer-eventName"
            label={t("eventName")}
            {...field.eventTitle}
            value={state.eventTitle}
            onBlur={removeExtraSpaces("eventTitle", setFormState, validateField)}
          />
        </FormGroup>
        <AddressFormGroup
          ariaLabel="event"
          buildingValue={state.eventBuilding}
          buildingProps={{ ...field.eventBuilding, onBlur: removeExtraSpaces("eventBuilding", setFormState, validateField) }}
          streetNameValue={state.eventStreetName}
          streetNameProps={{
            ...field.eventStreetName,
            onBlur: removeExtraSpaces("eventStreetName", setFormState, validateField),
          }}
          houseNumberValue={state.eventHouseNumber}
          houseNumberProps={{
            ...field.eventHouseNumber,
            onBlur: removeExtraSpaces("eventHouseNumber", setFormState, validateField),
          }}
          districtValue={state.eventDistrict}
          districtProps={field.eventDistrict}
          territoryValue={state.eventTerritory}
          territoryProps={field.eventTerritory}
          labelForBlockHouseNo={t("RoomFloorBlock")}
          labelForBuilding={t("Building")}
          title={t("eventAddress")}
          t={t}
        />
        <ProposerDetailsMoreInfo
          state={state}
          handleFieldChange={handleFieldChange}
          field={field}
          validateField={validateField}
          t={t}
          setFormState={setFormState}
        />
      </div>
      <LiabilityBindStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
    </>
  );
};

export default EventDetails;
