import { Dialog } from "../../../../components/interactives";

interface IProps {
  onClose: () => void;
  message: string;
}

const AgentFormDialog = ({ onClose, message }: IProps) => {
  return <Dialog onClose={onClose} title={message} />;
};

export default AgentFormDialog;
