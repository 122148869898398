import { useNavigate } from "react-router-dom";
import { FC, PropsWithChildren, useContext, useState } from "react";
import { H2 } from "../../../../components/basics";
import { eligibilityValidations } from "../../../../helpers/professional_indemnity/validations";
import useForm from "../../../../hooks/useForm";
import classNamePresets from "../../../../utils/classNamePresets";
import { EligibilityState } from "../types";
import { IndemnityFormContext } from "../../NewProduct";
import ReferralDialog from "../../components/ReferralDialog";
import { ReferralDetails } from "../../../../types/travel/Policy";
import clientApiService from "../../../../services/professional_indemnity/clientApiService";
import ThankYouDialog from "../../../travel/new-policy/dialog/ThankYouDialog";
import { useTranslation } from "react-i18next";
import IndemnityFormStepNavigation from "../IndemnityFormStepNavigation";
import CustomRadioInput from "../../../../components/interactives/CustomRadioInput";

type EligibilityOptionProps = {
  value: "yes" | "no" | null;
  onValueChange: (value: "yes" | "no" | null) => void;
  formLabel: string;
  label: string;
  error?: string | boolean | null;
};

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const EligibilityOption: FC<PropsWithChildren<EligibilityOptionProps>> = ({ value, error, onValueChange, formLabel, label, children }) => (
  <div className="flex justify-between flex-wrap gap-x-4">
    <div className="mt-8 flex-shrink max-w-[40rem]">
      <p className="font-bold mr-4">{label}</p>
      {children}
    </div>
    <div className="min-w-[10rem] flex-1">
      <CustomRadioInput error={error} formLabel={formLabel} onValueChange={onValueChange} value={value} />
    </div>
  </div>
);

const keys: (keyof EligibilityState)[] = [
  "hasBeenDeregistered",
  "hasClaims",
  "pendingClaims",
  "refusedRegularPaymentOrProposal",
  "subjectToDisciplinaryActions",
];
const areAllAnsweredAsNo = (state: EligibilityState) => {
  return keys.every(key => state[key] === "no");
};

const Eligibility: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.newProduct" });
  const [showReferral, setShowReferral] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const navigate = useNavigate();
  const { state, onChange, clientId } = useContext(IndemnityFormContext);
  const form = useForm(state, eligibilityValidations, onChange);
  const {
    field: { hasBeenDeregistered, hasClaims, pendingClaims, refusedRegularPaymentOrProposal, subjectToDisciplinaryActions },
  } = form;

  const handleProceed = () => {
    if (areAllAnsweredAsNo(state)) {
      onNext?.();
    } else {
      setShowReferral(true);
    }
  };

  const handleNext = () => form.validateForm(handleProceed);

  const handleReferral = async (details: ReferralDetails) => {
    await clientApiService.createReferral(clientId, details, state);
    setShowThankYou(true);
  };
  const onClose = () => {
    setShowThankYou(false);
    navigate("/professional_indemnity/manulife");
  };

  return (
    <>
      <div className="container-flow">
        <H2 className={classNamePresets.mainHeading}>{t("steps.eligibility")}</H2>
        <EligibilityOption formLabel="claims-made" label={t("formLabels.hasClaims")} value={state.hasClaims} {...hasClaims} />
        <EligibilityOption
          formLabel="subject-to-disciplinary-action"
          label={t("formLabels.subjectToDisciplinaryAction")}
          value={state.subjectToDisciplinaryActions}
          {...subjectToDisciplinaryActions}
        />
        <EligibilityOption
          formLabel="pending-or-expected-claims"
          label={t("formLabels.pendingClaims")}
          value={state.pendingClaims}
          {...pendingClaims}
        >
          <ul className="list-disc list-outside ml-[1em]">
            {t("assistoryLabels.pendingOrExpectedClaims", { returnObjects: true }).map(label => (
              <li key={label}>{label}</li>
            ))}
          </ul>
        </EligibilityOption>
        <EligibilityOption
          formLabel="deregistered-as-agent"
          label={t("formLabels.hasBeenDeregistered")}
          value={state.hasBeenDeregistered}
          {...hasBeenDeregistered}
        />
        <EligibilityOption
          formLabel="claim-denial"
          label={t("formLabels.refusedRegularPaymentOrProposal")}
          value={state.refusedRegularPaymentOrProposal}
          {...refusedRegularPaymentOrProposal}
        />
      </div>

      <IndemnityFormStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
      {showReferral && <ReferralDialog onClose={() => setShowReferral(false)} onConfirm={handleReferral} />}
      {showThankYou && <ThankYouDialog onClose={onClose} thankYou={t} />}
    </>
  );
};

export default Eligibility;
