import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BindContext } from "../BindQuote";
import useForm from "../../../hooks/useForm";
import { Accordion, AccordionSection } from "../../../components/interactives";
import BindQuoteStepNavigation from "./BindQuoteStepNavigation";
import { termsConditionsValidations } from "../../../helpers/group_travel/validations";
import ServerError from "../../errors/ServerError";
import AgreementSection from "../../../components/terms/AgreementSection";
import ImportantNoticesTerms from "../components/ImportantNoticesTerms";
import DeclarationTerms from "../components/DeclarationTerms";
import GroupTravelHeading from "../components/Heading";

type Props = {
  onSubmit: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const Terms: FC<Props> = ({ onSubmit, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct.bind.terms" });
  const { t: tError } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct.serverError" });
  const { state, onChange, isSubmitting, serverError, setServerError } = useContext(BindContext);
  const form = useForm(state, termsConditionsValidations, onChange);
  const policyWordingUrl = "/documents/AU Business Travel Policy Wording.pdf";
  const {
    field: { accepted, acceptedNotice },
  } = form;
  const handleNext = () => {
    onSubmit && form.validateForm(onSubmit);
  };
  return (
    <>
      <div className="container-flow flex-1">
        <GroupTravelHeading title={t("title")} alt="GroupTravel" />
        <Accordion id="terms-info" omitMarginTop>
          <AccordionSection id="important-notices" label="Important Notices">
            <ImportantNoticesTerms />
          </AccordionSection>
          <AccordionSection id="declaration" label="Declaration">
            <DeclarationTerms productName="Business Travel" />
          </AccordionSection>
          <AgreementSection t={t} accepted={accepted} acceptedNotice={acceptedNotice} state={state} policyWordingUrl={policyWordingUrl} />
        </Accordion>
      </div>
      {serverError && (
        <ServerError displayType="modal" onClose={() => setServerError(false)} contactDetailsWithMailId={tError("contactDetailsWithMailId")} />
      )}
      <BindQuoteStepNavigation onBack={onPrevious} onSubmit={handleNext} isLoading={isSubmitting} nextButtonLabel="Submit" />
    </>
  );
};

export default Terms;
