import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Validations } from "../../../types/shared/Validation";
import { validate } from "../../../helpers/shared/validation";
import { parseState } from "../../../helpers/shared/serialization";
import useEvent from "../../../hooks/useEvent";
import { windowScroll } from "../../../helpers/shared/useNavigationEffects";
import Header from "../EventHeader";
import Footer from "../EventFooter";
import { QueryClient, QueryClientProvider } from "react-query";
import createInitialState from "../form/state";
import { liabilityFormState } from "../form/types";
import LiabilityFormRoutes from "./form/routes";
import { createQuote } from "../../../helpers/event_public_liability/api/quoteApi";
import { useAuth } from "../../../hooks/useAuth";
import { getRegion } from "../../../helpers/shared/getRegion";
import { hkDetailsValidations } from "../../../helpers/event_public_liability/HkValidations";

type ChangeHandler = <T extends keyof liabilityFormState>(key: T, value: liabilityFormState[T]) => void;
type test = (t: liabilityFormState) => void;
type submitErr = (t: boolean) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};

export const HkLiabilityFormContext = createContext<{
  // context for Hk event Pl
  state: liabilityFormState;
  onChange: ChangeHandler;
  setFormState: test;
  isSubmitting: boolean;
  submitError: unknown;
  setSubmitError: submitErr;
}>({
  onChange: noop,
  state: createInitialState(),
  setFormState: noop,
  isSubmitting: false,
  submitError: null,
  setSubmitError: noop,
});

const changeNullToNo = (sendState: liabilityFormState) => {
  sendState.drone = sendState.drone ?? "no";
  sendState.volunteers = sendState.volunteers ?? "no";
  return sendState;
};
const getURLForStep = (step: string) => `/event_public_liability/hk${step}`;
const stepsURLS = [getURLForStep("/"), getURLForStep("/form/details"), getURLForStep("/form/pricing")];
const stepValidations = [hkDetailsValidations];

const findInvalidPage = (validations: Validations<unknown>[], state: liabilityFormState): number =>
  validations.findIndex(validationSchema => Object.keys(validate(state, validationSchema)).length > 0);

const storageKey = "event_public_liability/hk";
const getInitialState = () => {
  let sendState;
  // session storage for HK event pl
  try {
    const sessionStorageState = sessionStorage.getItem(storageKey);
    sendState = createInitialState(sessionStorageState ? (parseState(sessionStorageState) as liabilityFormState) : {});
    return changeNullToNo(sendState);
  } catch (e) {
    console.error("Session storage could not be parsed", e);
    sendState = createInitialState();
    return changeNullToNo(sendState);
  }
};
const submit = async (formtate: liabilityFormState, region: string) => {
  try {
    const response = await createQuote(formtate, region);

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const CreateQuoteForm: FC = () => {
  const [formState, setFormState] = useState<liabilityFormState>(getInitialState());
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const navigate = useNavigate();
  const location = useLocation();
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const persistState = useEvent(() => sessionStorage.setItem(storageKey, JSON.stringify(formState)));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const { user } = useAuth();
  const region = getRegion(user);
  const isAccountHidden = false;

  useEffect(() => {
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);

  useEffect(() => {
    const validationsToRun = stepValidations.slice(0);
    findInvalidPage(validationsToRun, formState);
  }, [formState]);

  useEffect(() => {
    windowScroll();
  }, [location]);

  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - 1];
    newURL && navigate(newURL);
  }, [step, navigate]);
  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await submit(formState, region);
      setFormState({
        ...formState,
        quoteId: response.quoteId,
        quoteNo: response.quoteNo,
        validityDate: response.validityDate,
        status: response.status,
        agentEmail: response.agentEmail,
      });
      setSubmitError(false);
      navigate("/event_public_liability/hk/form/quoteDetails");
    } catch {
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <HkLiabilityFormContext.Provider
      value={{
        setFormState: setFormState,
        onChange: onFieldChange,
        state: formState,
        isSubmitting: isSubmitting,
        submitError: submitError,
        setSubmitError: setSubmitError,
      }}
    >
      <main>
        <form>
          <div className="flex flex-col min-h-screen gap-0">
            <Header hideAccount={isAccountHidden} />
            <LiabilityFormRoutes onPrevious={goToPreviousStep} onNext={step === 2 ? onSubmit : goToNextStep} />
            <Footer />
          </div>
        </form>
      </main>
    </HkLiabilityFormContext.Provider>
  );
};

const CreateQuote: FC = () => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <CreateQuoteForm />
    </QueryClientProvider>
  );
};

export default CreateQuote;
