import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionSection, Section } from "../../../components/interactives";
import useForm from "../../../hooks/useForm";
import { termsConditionsValidations } from "../../../helpers/event_public_liability/validations";
import Extentions from "../terms/Extentions";
import PersonalDataTerms from "../terms/PersonalDataTerms";
import { CheckInputBlock } from "../../../components/form-fields";
import DeclarationTerms from "../../../components/terms/DeclarationTerms";
import ImportantNoticesTerms from "../../../components/terms/ImportantNoticesTerms";
import AgreementAlert from "../../../components/terms/AgreeAlert";
import Heading from "../components/Heading";
import { LiabilityBindContext } from "../BindQuote";
import ServerError from "../../errors/ServerError";
import TermButtons from "../components/TermButtons";

type Props = {
  sendPaymentLink?: VoidFunction | null;
  creditTerms?: VoidFunction | null;
};
const Terms: FC<Props> = ({ sendPaymentLink, creditTerms }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product.terms" });
  const { t: tSupportMailId } = useTranslation("pages", { keyPrefix: "eventpl.product.customerSupport" });
  const { state, onChange, submitError, setSubmitError, isSubmitting } = useContext(LiabilityBindContext);
  const navigateBackUrl = "/event_public_liability/bind/summary";
  const form = useForm(state, termsConditionsValidations, onChange);
  const {
    field: { accepted, marketingVoiceCall, marketingTextMessage },
  } = form;
  const handleSendPaymentLink = () => {
    sendPaymentLink && form.validateForm(sendPaymentLink);
  };
  const handleCreditTerms = () => {
    creditTerms && form.validateForm(creditTerms);
  };
  return (
    <>
      <div className="container-flow flex-1">
        <Heading headingTitle={t("title")} />
        <Extentions />
        <Accordion id="terms-info" omitMarginTop>
          <AccordionSection id="important-notices" label="Important Notices">
            <ImportantNoticesTerms />
          </AccordionSection>
          <AccordionSection id="declaration" label="Declaration">
            <DeclarationTerms />
          </AccordionSection>
        </Accordion>
        <div className="grid gap-5">
          <h3 className="col-span-full font-bold md:text-xl mt-6">{t("personalDataTerms.title")}</h3>
          <Section>
            <PersonalDataTerms state={state} t={t} marketingVoiceCall={marketingVoiceCall} marketingTextMessage={marketingTextMessage} />
          </Section>
          <h3 className="col-span-full font-bold md:text-xl mt-6">{t("agree.title")}</h3>
          <CheckInputBlock {...accepted} id="accepted" checked={state.accepted}>
            {t("agree.content")}
          </CheckInputBlock>
          <AgreementAlert t={t} />
        </div>
        <TermButtons
          sendPaymentLink={sendPaymentLink}
          handleSendPaymentLink={handleSendPaymentLink}
          creditTerms={creditTerms}
          creditTermLabel={t("navigation.button.creditTerms")}
          handleCreditTerms={handleCreditTerms}
          isSubmitting={isSubmitting}
          t={t}
          navigateBackUrl={navigateBackUrl}
        />
      </div>
      {submitError && <ServerError supportEmailId={tSupportMailId("emailId")} displayType="modal" onClose={() => setSubmitError(false)} />}
    </>
  );
};

export default Terms;
