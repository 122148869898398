import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PolicyHolderDetails from "./PolicyHolderDetails";
import Summary from "./QuoteSummary";
import Terms from "./Terms";
import ProposerSummary from "./ProposerSummary";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
  onSubmit: VoidFunction | null;
};

const BindRoutes: FC<Props> = props => (
  <Routes>
    <Route path="quote-summary" element={<Summary {...props} />} />
    <Route path={"proposer-details"} element={<PolicyHolderDetails {...props} />} />
    <Route path="proposer-summary" element={<ProposerSummary {...props} />} />
    <Route path="terms" element={<Terms {...props} />} />
    <Route path="*" element={<Navigate to="/au/group_personal_accident/retrieve-list" />} />
  </Routes>
);

export default BindRoutes;
