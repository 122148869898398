import { H2 } from "../../../components/basics";
import { DocumentListResponse } from "../../../services/tpa/tpaApiServices";
import emptyBox from "../../../assets/images/empty_box.png";
import Documents from "./Documents";
interface ResultProps {
  isNoActivity: boolean;
  loading: boolean;
  policyDetails: DocumentListResponse;
}

const NoActivity: React.FC = () => (
  <div className="flex flex-col gap-2 justify-center items-center mt-8">
    <img src={emptyBox} alt="no results" className="w-[12rem] block" />
    <p className="text-disabled">Nothing here at the moment.</p>
  </div>
);

const ResultSection: React.FC<ResultProps> = ({ isNoActivity, loading, policyDetails }) => {
  return (
    <>
      <H2 className="font-bold text-4xl font-serif mb-4">Results</H2>
      <table className=" flex flex-col table-shadow overflow-hidden">
        <thead className="md:px-8 px-0 py-2">
          <tr className="text-disabled uppercase text-xxs font-bold">Document Name</tr>
        </thead>
        <tbody>{isNoActivity ? <NoActivity /> : <Documents loading={loading} policyDetails={policyDetails} isTpa={true} />}</tbody>
      </table>
    </>
  );
};

export default ResultSection;
