import cls from "../../../utils/cls";
import { HTMLAttributes } from "react";

interface IProps extends Omit<HTMLAttributes<HTMLElement>, "disabled"> {
  disabled?: boolean;
  className?: string;
}

const PlusMinButton = ({ disabled, className, ...props }: IProps) => {
  return (
    <button
      type="button"
      className={cls(
        "absolute-middle z-10 w-8 h-8 rounded-full icon-5 cursor-pointer",
        { "icon-red hover:icon-red-dark": !disabled },
        { "icon-grey-light": !!disabled },
        className
      )}
      disabled={disabled}
      {...props}
    />
  );
};

export default PlusMinButton;
