import { useTranslation } from "react-i18next";
import { clearEventPLSessionStorage } from "../../helpers/shared/sessionStorage";
import Header from "./EventHeader";
import ThankYouMessage from "../components/ThankYouMessage";
import useOnComponentDidMount from "../../hooks/useOnComponentDidMount";
import SuccessBackButton from "./SuccessBackButton";
import EventFooter from "../auth/layout/Footer";

const HkSuccess = () => {
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product.payment" });
  const { t: tHkFooter } = useTranslation("pages", { keyPrefix: "eventplHk.footer" });
  useOnComponentDidMount(() => clearEventPLSessionStorage("event_public_liability/hk"));
  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <Header />
      <ThankYouMessage t={t} />
      <SuccessBackButton backButtonText={t("button.back")} />
      <EventFooter footer={tHkFooter} />
    </div>
  );
};
export default HkSuccess;
