import { GroupPersonalAccidentBindState } from "./types";
import { createInitialState as createFormInitialState } from "../Form/state";

const createInitialState = (persistedState: Partial<GroupPersonalAccidentBindState> = {}): GroupPersonalAccidentBindState => {
  return {
    acceptedNotice: false,
    accepted: false,
    streetNumber: "",
    unitNumber: "",
    floorNumber: "",
    streetName: "",
    suburb: "",
    state: "",
    australiaBusinessNo: "",
    postalCode: "",
    ...createFormInitialState(),
    ...persistedState,
  };
};

export default createInitialState;
