import Footer from "../auth/layout/Footer";
import { useTranslation } from "react-i18next";

const GroupTravelFooter = () => {
  const abnUrlOfAu = "https://abr.business.gov.au/ABN/View?id=54163304907";
  const { t } = useTranslation("pages", { keyPrefix: `groupTravel.footer` });
  return <Footer footer={t} abnUrlOfAu={abnUrlOfAu} />;
};

export default GroupTravelFooter;
