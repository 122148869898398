import { ITable } from "../../helpers/travel/planTables";
import { FC } from "react";

const Table: FC<React.PropsWithChildren<{ data: ITable }>> = ({ data }) => {
  const isRowSubHeading = (rowValue: string) => !rowValue;

  return (
    <table className="flex flex-col shadow-xl rounded-md overflow-hidden bg-white">
      <thead>
        <tr className="flex justify-between bg-red p-2 text-white font-bold">
          {data.headers.map((header, index) => (
            <th key={index}>{header.value}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows.map((row, index) => (
          <tr key={index} className="flex justify-between p-2 odd:bg-white even:bg-grey-lightest">
            <td className={isRowSubHeading(row.cells[1].value) ? "w-full font-bold" : "w-3/5"}>{row.cells[0].value}</td>
            <td className={`text-right whitespace-pre-line ${isRowSubHeading(row.cells[1].value) ? "w-0" : "w-2/5"}`}>{row.cells[1].value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
