import QuotationDetails from "../components/Quotation";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import { useNavigate } from "react-router";
import { Button } from "../../../components/basics/Button";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LiabilityFormContext } from "../CreateQuote";
import KeyExclusionsList from "../components/KeyExclusionsList";

const QuotationDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product" });
  const { state } = useContext(LiabilityFormContext);
  const eventRetrieveListLink = "/event_public_liability/retrieveList";
  return (
    <>
      <div className="container-flow flex md:pt-8 pt-4 font-bold mr-4 md:mx-0 gap-1 md:items-center items-start">
        <i className="icon icon-check-circle icon-8 icon-green md:icon-right-4 icon-right-2 md:-mt-2 mt-1"></i>
        <span className="md:-ml-2">{t("formLabels.quotationMessage")}</span>
      </div>
      <div className="container-flow flex-1 mb-8">
        <div className="mx-[-0.5rem] md:px-0 md:mx-0 md:w-full">
          <div className="bg-white px-4 md:pr-24 md:pl-[4.375rem] md:mt-8 mt-4 rounded shadow-xl">
            <QuotationDetails
              t={t}
              state={state}
              eventRetrieveListLink={eventRetrieveListLink}
              renderExclusion={<KeyExclusionsList state={state} t={t} />}
            />
          </div>
        </div>
        <div className="md:flex md:justify-between md:flex-row-reverse md:pt-28 md:pb-0 pb-8">
          <Button onClick={() => navigate("/products")} type={ButtonType.PRIMARY} className="md:my-0 mt-8">
            Back to Homepage
          </Button>
        </div>
      </div>
    </>
  );
};

export default QuotationDetail;
