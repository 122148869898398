import { Button, LoadingButton } from "../../../../components/basics/Button";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";
import classNamePresets from "../../../../utils/classNamePresets";
import cls from "../../../../utils/cls";
import { Dialog } from "../../../../components/interactives";
import { DialogFooter } from "../../../../components/interactives/Dialog";
import { useCallback, useState } from "react";
import { FormGroup } from "../../../../components/forms";
import { CheckInput } from "../../../../components/form-fields";
import useForm from "../../../../hooks/useForm";
import TextInput from "../../../../components/form-fields/TextInput";
import { sendEmailPDF } from "../../../../helpers/event_public_liability/api/quoteApi";
import { useEmailPdf } from "../useQuotation";
import ServerError from "../../../errors/ServerError";
import { emailRecipientValidations } from "../../../../helpers/event_public_liability/HkValidations";

interface IProps {
  quoteId: string;
  onSuccess: () => void;
  onClose: () => void;
  t: any;
}

export type Recipients = {
  sendToSelf: boolean;
  clientEmail: string;
};

const recipientsInitialState: Recipients = {
  sendToSelf: true,
  clientEmail: "",
};
const QuotationEmailDialog = ({ quoteId, onClose, onSuccess, t }: IProps) => {
  const [state, setState] = useState<Recipients>(recipientsInitialState);
  const onFieldChange = useCallback(
    (key: keyof typeof recipientsInitialState, value: string | boolean) => {
      setState(prevState => ({ ...prevState, [key]: value }));
    },
    [setState]
  );
  const { field, validateForm } = useForm(state, emailRecipientValidations, onFieldChange);
  const emailPayload = { quoteId, isAgentEmail: state.sendToSelf, clientEmail: state.clientEmail };
  const { showSpinner, serverError, handleLinkClick } = useEmailPdf(emailPayload, sendEmailPDF, onSuccess);
  const sendEmailHandler = () => validateForm(() => handleLinkClick());

  return (
    <Dialog onClose={onClose}>
      <div className="container-flow flex md:pt-4  pt-2 font-bold md:mx-0 gap-12 items-start">
        <h2 className={cls(classNamePresets.dialogTitle, "font-normal")}>{"Send Quotation PDF:"}</h2>
      </div>

      {serverError && <ServerError supportEmailId={t("customerSupport.emailId")} />}

      <FormGroup title={"To yourself"}>
        <CheckInput checked={state.sendToSelf} onValueChange={v => onFieldChange("sendToSelf", v)} label="Yes" name="sendToSelf" />
      </FormGroup>

      <FormGroup title="To your client">
        <TextInput value={state.clientEmail} label="Enter Email" {...field.clientEmail} />
      </FormGroup>

      <DialogFooter>
        <Button type={ButtonType.SECONDARY} onClick={onClose} className="md:!w-[308px] md:mr-28 mb-4">
          Close
        </Button>
        <LoadingButton type={ButtonType.PRIMARY} onClick={sendEmailHandler} className="md:!w-[308px]" isLoading={showSpinner} loadingText="">
          Send
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  );
};

export default QuotationEmailDialog;
