import classNamePresets from "../../../utils/classNamePresets";

type IProps = {
  title?: string;
  children?: React.ReactNode;
  addonBeforeTitle?: string;
};

const SecondaryLayout = ({ title, children, addonBeforeTitle }: IProps) => {
  return (
    <div className="relative flex-1">
      <div className="hidden md:block w-5/12 bg-cover absolute right-0 top-0 bottom-0" />
      <div className="container-flow flex-grow">
        <div className="w-full md:w-7/12 flex justify-center mt-6 md:mt-16">
          <div className="md:max-w-lg mx-4 sm:mx-0 mb-3 space-y-4">
            {addonBeforeTitle && <h2 className={classNamePresets.secondaryHeading}>{addonBeforeTitle}</h2>}
            {title && <h2 className={classNamePresets.mainHeading}>{title}</h2>}
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryLayout;
