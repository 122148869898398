import { FC, useContext, useEffect, useMemo, useState } from "react";
import { FormGroup } from "../../../../components/forms";
import { Group } from "../../../../components/groups";
import { RadioInput } from "../../../../components/form-fields";
import { createTravelPlanPriceTable, formatValue, ITable } from "../../../../helpers/group_travel/planTables";
import { getPlanLabel } from "../../../../helpers/group_travel/labels";
import { planValidations } from "../../../../helpers/group_travel/validations";
import ServerError from "../../../errors/ServerError";
import useForm from "../../../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { IOption } from "../../../../components/form-fields/RadioInput";
import Spinner from "../../../../components/icons/Spinner";
import RawHTML from "../../../../components/basics/RawHTML";
import useWithAsyncLoadingIndicator from "../../../../hooks/useWithAsyncLoadingIndicator";
import AuGroupBusinessFormStepNavigation from "../CreateQuoteStepNavigation";
import { CreateQuoteContext } from "../../CreateQuote";
import SegmentedButtons from "../../../../components/interactives/SegmentedButtons";
import { fetchTravelPlans } from "../../../../helpers/group_travel/api/pricingApi";
import GroupTravelHeading from "../../components/Heading";
import PlanTableGroup from "../../../../components/basics/PlanTableGroup";
import PremiumLabel from "../../../components/PremiumLabel";
import { Plans, PlansWithBrokerage } from "../../../../types/group_travel/api/Pricing";

export interface PlanProps {
  onPrevious: VoidFunction | null;
  onNext: VoidFunction | null;
}
export type StringOrNull = string | null;
const TotalPremiumLabel: FC<{ premium: number }> = ({ premium }) => (
  <PremiumLabel label="Total Premium" value={formatValue(premium)} currency="AUD" />
);

const pricingLabelOptions = (value: string, label: string, total: number): IOption<string> => ({
  value,
  label,
  rightLabel: <TotalPremiumLabel premium={total} />,
});

const getPlansByBrokerage = (allPlans: PlansWithBrokerage, brokerage: number) => allPlans[brokerage];

const createOptions = (plans: Plans): IOption<string>[] => {
  return plans.map(plan => pricingLabelOptions(plan.id, getPlanLabel(plan?.id), plan.total));
};
const LoadingSpinner = () => (
  <div className="flex justify-around mt-32">
    <Spinner width="100" height="100" color="#3F9293"></Spinner>
  </div>
);

const Plan: FC<React.PropsWithChildren<PlanProps>> = ({ onNext, onPrevious }) => {
  const [serverError, setServerError] = useState(false);
  const [plans, setPlans] = useState<Plans>([] as Plans);
  const [rawPlans, setRawPlans] = useState<PlansWithBrokerage>({} as PlansWithBrokerage);
  const [planTables, setPlanTables] = useState<ITable[]>([]);

  const { state, onChange: onFieldChange, setFormState } = useContext(CreateQuoteContext);
  const { validateForm, field } = useForm(state, planValidations, onFieldChange);

  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct" });
  const handleNext = () => validateForm(() => onNext && onNext());
  const { action: fetchPrices } = useWithAsyncLoadingIndicator(fetchTravelPlans, { defaultLoadingState: true });

  useEffect(() => {
    const setPrices = async () => {
      try {
        const response = await fetchPrices(state);
        setRawPlans(response.plans);
      } catch (error) {
        console.error(error);
        setServerError(true);
      }
    };
    setPrices();
  }, []);

  useEffect(() => {
    if (Object.keys(rawPlans)?.length !== 0) {
      const plansByBrokerage = getPlansByBrokerage(rawPlans, state.brokerageRate as number);
      setPlans(plansByBrokerage);
      setPlanTables(createTravelPlanPriceTable(plansByBrokerage, "groupTravel"));
    }
  }, [rawPlans, state.brokerageRate]);
  useEffect(() => {
    handlePlanUpdate(state.planName);
  }, [state.planName, plans]);

  const planOptions: IOption<string>[] = useMemo(() => createOptions(plans), [plans]);

  const handlePlanUpdate = (planName: StringOrNull) => {
    const plan = plans.find(({ id }) => id === planName);
    plan &&
      planName &&
      setFormState({
        ...state,
        basePrice: plan.price,
        totalPrice: plan.total,
        stampDutyRate: plan.stampDuty.percent,
        stampDutyAmount: plan.stampDuty.amount,
        gstAmount: plan.gst.amount,
        gstRate: plan.gst.percent,
        brokerageRate: plan.brokerage.percent,
        brokerageAmount: plan.brokerage.amount,
      });
  };
  const arePricesLoaded = planOptions.length > 0;
  const brokerageRate = state.brokerageRate;
  const stampDutyPercentage = state.stampDutyRate;

  return (
    <>
      <div className="container-flow flex-grow">
        <GroupTravelHeading title={t("steps.plans")} alt="GroupTravel" />
        <SegmentedButtons
          defaultOptions={[0, 5, 10, 15, 20, 25]}
          value={state?.brokerageRate}
          onValueChange={field.brokerageRate.onValueChange}
          labelName={`Brokerage Rate`}
          xsAppendLabel=" (%)"
          mdAppendOption="%"
          endLabelWith=":"
        />
        {serverError && <ServerError contactDetailsWithMailId={t("serverError.contactDetailsWithMailId")} />}
        {!arePricesLoaded ? (
          <LoadingSpinner />
        ) : (
          <>
            <Group aria-label="plan-table" role="list" className="gap-0">
              <PlanTableGroup planTables={planTables} />
            </Group>

            <div className="mt inline-flex items-center font-bold">
              <RawHTML boldedLinks wrap={false} content={t("steps.plan.table.subtitle.text")} />
            </div>
            <FormGroup
              title={t("steps.plan.form.groupTitle.price")}
              subtitle={t("steps.plan.form.groupTitle.subTitle", { brokerageRate, stampDutyPercentage })}
              subTitleFontSize="md:text-xl max-w-80 my-2"
            >
              <RadioInput aria-label="plan-selection" {...field.planName} value={state.planName} options={planOptions} />
            </FormGroup>
          </>
        )}
      </div>
      <AuGroupBusinessFormStepNavigation onBack={onPrevious} onNext={!arePricesLoaded ? undefined : handleNext} />
    </>
  );
};

export default Plan;
