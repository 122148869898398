export enum ButtonType {
  PRIMARY,
  SECONDARY,
  LINK,
  CENTERED_LINK,
  DISABLED,
}

export const createButtonClasses = (style?: ButtonType, disabled?: boolean) => {
  return [
    "w-full md:w-[280px] h-15 md:h-14",
    "px-8 md:py-2 py-1",
    style === ButtonType.PRIMARY && "rounded-full",
    style === ButtonType.PRIMARY && "bg-red hover:bg-red-dark",
    style === ButtonType.SECONDARY && "rounded-full border-2 border-red",
    style === ButtonType.DISABLED && "rounded-full border-2 border-[#979797]",
    style === ButtonType.LINK && "text-left",
    disabled && "cursor-not-allowed",
  ]
    .filter(Boolean)
    .join(" ");
};

export const createTextClasses = (style?: ButtonType) => {
  return [
    "text-xl font-bold",
    style === ButtonType.PRIMARY && "text-white",
    style === ButtonType.SECONDARY && "text-red",
    style === ButtonType.DISABLED && "text-[#979797]",
    style && [ButtonType.LINK, ButtonType.CENTERED_LINK].includes(style) && "text-red ",
  ]
    .filter(Boolean)
    .join(" ");
};
