import { useTranslation } from "react-i18next";
import ThankYouMessage from "../../components/ThankYouMessage";
import Header from "../../auth/layout/Header";
import Footer from "../../group_travel/Footer";

const AuSignupSuccess = () => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct.auSignupSuccess" });

  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      {/* au user success page */}
      <Header hideAccount={false} home={"/"} />
      <div className="mb-8">
        <ThankYouMessage t={t} additionalcontent={true} />
      </div>
      <Footer />
    </div>
  );
};
export default AuSignupSuccess;
