import { getUnixTime, endOfWeek, isBefore, addWeeks, isAfter, isEqual } from "date-fns";
import { groupBy } from "lodash/fp";
import { ITable } from "../../helpers/travel/planTables";
import { Filters, Row } from "./types";

const matchesDateFilters = (createdAt: Date, filters: Filters) => {
  return (
    (isEqual(createdAt, filters.startDate) || isAfter(createdAt, filters.startDate)) &&
    (isEqual(createdAt, filters.endDate) || isBefore(createdAt, filters.endDate))
  );
};

/**
 * Checks whether the given row satisfies the supplied filters
 * @param row The row to check
 * @param filters The filters to validate against
 * @returns boolean signalling whether the row satisfies the filters
 */
export const matchesFilters = (row: Row, filters: Filters) => {
  const createdAt = new Date(row.createdAt);
  const quoteStatus = row.status;
  return matchesDateFilters(createdAt, filters) && filters.quoteStatuses.includes(quoteStatus);
};

/**
 * Counts the backend data per status, and places it in a format as known by the bar plot
 * @param data The backend api data
 * @returns A grouping of quote count per status
 */
export const toStatusOverviewBarData = (data: Row[]) =>
  data.reduce<{ [status: string]: number }>((acc, row) => {
    acc[row.status] = (acc[row.status] || 0) + 1;
    return acc;
  }, {});

/**
 * Creates the time based x-axis based on the date range between the min and max date. The steps are per week
 * @param minDate the iso string min date
 * @param maxDate  the iso string max date
 * @returns array of xAxis dates
 */
const createDateRangeXAxis = (minDate: string, maxDate: string) => {
  const xAxis = [endOfWeek(new Date(minDate))];
  let currentEndOfDate = xAxis[0];
  // Keeps grouping entries per week for the minDate up to the maxDate, otherwise the plot explodes with data entries! each item being the end of that week and cumulative counted
  while (isBefore(currentEndOfDate, endOfWeek(new Date(maxDate)))) {
    const lastStartOfMonth = xAxis[xAxis.length - 1];
    xAxis.push(addWeeks(lastStartOfMonth, 1));
    currentEndOfDate = addWeeks(currentEndOfDate, 1);
  }
  return xAxis;
};

/**
 * Converts the api data to create a cumulative overview of how many quotes are made as known in the dataset
 * Data is grouped per week, from the earliest week in the data to its latest week. The date is converted to unix timestamp so the plot understands how to interact with it
 * @param data The data by the api
 * @returns Time based cumulative series
 */
export const toQuoteOverTimeData = (data: Row[]) => {
  if (data.length === 0) {
    return [];
  }

  const sortedRows = data.sort((a, b) => getUnixTime(new Date(a.createdAt)) - getUnixTime(new Date(b.createdAt)));
  const minDate = sortedRows[0].createdAt;
  const maxDate = sortedRows[sortedRows.length - 1].createdAt;

  const xAxis = createDateRangeXAxis(minDate, maxDate);
  return xAxis.map(date => {
    const filteredItems = sortedRows.filter(row => new Date(row.createdAt) < date);
    return { date: date.getTime(), count: filteredItems.length };
  }, {});
};

const roundToTwoDecimals = (value: number) => value.toFixed(2);

/**
 * Creates rows that can be interpreted by the Table component, placing the anonymized agent name on the left, and its quote count and percentage to the total on the right.
 * Data is sorted from most quotes to least quotes
 * @param backendData The data by the API
 * @returns table data
 */
export const toTableRowPerAgent = (backendData: Row[]): ITable["rows"] =>
  Object.entries(groupBy(item => item.agentEmail, backendData))
    // Sorts by the most policies first
    .sort(([, agent1Quotes], [, agent2Quotes]) => agent2Quotes.length - agent1Quotes.length)
    .map(([_, items], index) => {
      const anonimizedAgentName = `Agent ${index + 1}`;
      const quoteCountWithPercentage = `${items.length} (${roundToTwoDecimals((items.length / backendData.length) * 100)}%)`;

      return {
        cells: [{ value: anonimizedAgentName }, { value: quoteCountWithPercentage }],
      };
    });
