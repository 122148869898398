import { Navigate, useLocation } from "react-router-dom";
import { Region } from "../../enums/shared/forms/Region";
import cls from "../../utils/cls";
import ProductsFooter from "../ProductsFooter";
import Header from "../auth/layout/Header";
import Documents from "../tpa/components/Documents";
import { tpaApiServices } from "../../services/tpa/tpaApiServices";
import { useFetch } from "../../hooks/useFetch";
import { ProductsName } from "../../enums/shared/api/Api";

interface LocationState {
  policyNumber: string;
  productCode: Region;
}

const PolicyDocuments: React.FC<{ productName: ProductsName; region: Region }> = ({ productName, region }) => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const policyNumber = locationState?.policyNumber;
  const productCode = locationState?.productCode;
  const { data, isLoading } = useFetch(tpaApiServices.getPolicyDocuments, { policyNumber, productCode });
  const uniqueDocuments = data.filter((doc, index, self) => self.findIndex(d => d.filename === doc.filename) === index);

  if (!policyNumber) return <Navigate to="/" />;

  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <Header home="/" />
      <div className={cls("container-flow flex flex-grow flex-col gap-8 mt-16 mb-28")}>
        <div className="flex gap-8">
          <span className="font-bold text-2xl">{`${productName} (${policyNumber})`}</span>
        </div>
        <table className=" flex flex-col table-shadow overflow-hidden">
          <thead className="md:px-8 px-0 py-2">
            <tr className="text-disabled uppercase text-xxs font-bold">Document Name</tr>
          </thead>
          <tbody>{<Documents loading={isLoading} policyDetails={uniqueDocuments} />}</tbody>
        </table>
      </div>
      <ProductsFooter region={region} />
    </div>
  );
};

export default PolicyDocuments;
