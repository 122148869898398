import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FormStep from "../../components/forms/FormStep";
import ManulifeFooter from "./components/ManulifeFooter";
import ProfessionalIndemnityFormRoutes from "./Form/routes";
import { eligibilityValidations, limitValidations, termsConditionsValidations } from "../../helpers/professional_indemnity/validations";
import { IndemnityFormState } from "./Form/types";
import createInitialState from "./Form/state";
import { personalDetailsValidations } from "../../helpers/travel/validations";
import useEvent from "../../hooks/useEvent";
import ManulifeHeader from "./ManulifeHeader";
import { QueryClient, QueryClientProvider, useMutation } from "react-query";
import { parseState } from "../../helpers/shared/serialization";
import clientApiService from "../../services/professional_indemnity/clientApiService";
import professionalIndemnity from "./models/professionalIndemnity";
import { useTranslation } from "react-i18next";
import { useStepNavigation } from "../../hooks/useStepNavigation";

type ChangeHandler = <T extends keyof IndemnityFormState>(key: T, value: IndemnityFormState[T]) => void;
type test = (t: IndemnityFormState) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};

export const IndemnityFormContext = createContext<{
  state: IndemnityFormState;
  onChange: ChangeHandler;
  clientId: string;
  isSubmitting: boolean;
  submitError: unknown;
  setFormState: test;
}>({
  onChange: noop,
  state: createInitialState(),
  clientId: "",
  isSubmitting: false,
  submitError: null,
  setFormState: noop,
});

enum Steps {
  "Eligibility",
  "Limit",
  "PersonalDetails",
  "Summary",
  "Terms",
}

const getURLForStep = (step: string) => `/professional_indemnity/manulife/form/${step}`;

// Tracks the url instead of step since it allows to respond to browser location seamlessly
const stepsURLS = [getURLForStep("eligibility"), getURLForStep("limit"), getURLForStep("personal"), getURLForStep("summary"), getURLForStep("terms")];
const stepValidations = [eligibilityValidations, limitValidations, personalDetailsValidations, {}, termsConditionsValidations];
const steps: Steps[] = [Steps.Eligibility, Steps.Limit, Steps.PersonalDetails, Steps.Summary, Steps.Terms];
const amountOfSteps = steps.length;

const storageKey = "professional_indemnity/manulife";
const getInitialState = () => {
  const sessionStorageState = sessionStorage.getItem(storageKey);
  try {
    return createInitialState(sessionStorageState ? (parseState(sessionStorageState) as IndemnityFormState) : {});
  } catch (e) {
    console.error("Session storage could not be parsed", e);
    return createInitialState();
  }
};

const useSubmit = (state: IndemnityFormState, clientId: string) => {
  return useMutation(`${clientId}/professional_indemnity/checkout`, () => clientApiService.checkout(clientId, professionalIndemnity(state)), {
    onSuccess: ({ checkoutUrl }) => {
      window.location.href = checkoutUrl;
    },
  });
};

const NewProductForm: FC = () => {
  const [formState, setFormState] = useState<IndemnityFormState>(getInitialState());
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const navigate = useNavigate();
  const location = useLocation();
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const persistState = useEvent(() => sessionStorage.setItem(storageKey, JSON.stringify(formState)));
  useStepNavigation<IndemnityFormState>(step, stepValidations as [], formState, stepsURLS);

  useEffect(() => {
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const clientId = "manulife";
  const onSubmit = useSubmit(formState, clientId);
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity" });

  return (
    <IndemnityFormContext.Provider
      value={{
        setFormState: setFormState,
        onChange: onFieldChange,
        state: formState,
        clientId,
        isSubmitting: onSubmit.isLoading,
        submitError: onSubmit.error,
      }}
    >
      <main>
        <form>
          <div className="flex flex-col min-h-screen gap-0">
            <ManulifeHeader hideAccount home={t("header.home")} />
            <FormStep current={step + 1} total={amountOfSteps} />
            <ProfessionalIndemnityFormRoutes
              onPrevious={step > 0 ? goToPreviousStep : null}
              onNext={step === steps.length - 1 ? onSubmit.mutate : goToNextStep}
            />
            <ManulifeFooter />
          </div>
        </form>
      </main>
    </IndemnityFormContext.Provider>
  );
};

const NewProduct: FC = () => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <NewProductForm />
    </QueryClientProvider>
  );
};

export default NewProduct;
