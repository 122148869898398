import { FC, useState } from "react";
import { Dialog } from "../../../../components/interactives";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/basics/Button";
import { DialogFooter } from "../../../../components/interactives/Dialog";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";

interface IProps {
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
}

type Steps = "confirm-residency" | "request-assistance";

const ResidentDialog: FC<React.PropsWithChildren<IProps>> = ({ onConfirm, onCancel, onClose }) => {
  const [step, setStep] = useState<Steps>("confirm-residency");
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy" });

  return (
    <Dialog onClose={onClose} title={step === "confirm-residency" ? t("dialog.resident.firstStep.title") : t("dialog.resident.secondStep.title")}>
      {step === "confirm-residency" ? (
        <>
          <p className="my">{t("dialog.resident.firstStep.content")}</p>
          <DialogFooter>
            <Button type={ButtonType.SECONDARY} onClick={() => setStep("request-assistance")}>
              {t("dialog.resident.firstStep.button.decline")}
            </Button>
            <Button type={ButtonType.PRIMARY} onClick={onConfirm}>
              {t("dialog.resident.firstStep.button.understand")}
            </Button>
          </DialogFooter>
        </>
      ) : (
        <>
          <p className="my">
            Should you have any questions, please do not hesitate to call us at +65 6423 0888, during regular office hours (8.30am - 5.30pm, Mondays
            to Fridays, excluding Public Holidays) or email us at{" "}
            <a className="text-red font-bold hover:underline" href={`mailto:${t("customerSupport.emailId")}`} target="_blank" rel="noreferrer">
              {t("customerSupport.emailId")}
            </a>
            .
          </p>
          <DialogFooter>
            <Button className="mt-6 ml-auto" type={ButtonType.PRIMARY} onClick={onCancel}>
              {t("dialog.resident.secondStep.button.back")}
            </Button>
          </DialogFooter>
        </>
      )}
    </Dialog>
  );
};

export default ResidentDialog;
