import { isDate } from "date-fns";
import { liabilityBindState } from "../../pages/event_public_liability/bind/types";
import { DetailsState } from "../../pages/event_public_liability/form/types";
import { Validations } from "../../types/shared/Validation";

const isValidDate = (date: unknown): date is Date => isDate(date);

export const validateStartCoverageDate = (value: Date) => {
  const futureDate = new Date();
  const currentDate = new Date();
  futureDate.setDate(currentDate.getDate() + 59);
  if (value > futureDate) {
    return `Must be within 60 days from today`;
  }

  return null;
};

export const detailsValidations: Validations<DetailsState> = {
  fields: {
    proposerName: {
      required: true,
    },
    eventTitle: {
      required: true,
    },
    eventType: {
      required: true,
    },
    duration: {
      required: true,
    },
    attendees: {
      required: true,
    },
    drone: {
      required: true,
    },
    setupAndDismantling: {
      required: true,
    },
    volunteers: {
      required: true,
    },
  },
};

export const termsConditionsValidations: Validations<liabilityBindState> = {
  fields: {
    accepted: {
      required: {
        message: "Please agree to proceed",
      },
    },
  },
};

export const proposerEventDetailsValidations: Validations<liabilityBindState> = {
  fields: {
    proposerName: {
      required: true,
    },

    uenNumber: {
      required: true,
    },
    uenNumberStatus: {
      required: true,
      pattern: {
        regex: /verified/i,
        message: "Has to be valid",
      },
    },
    mobileNumber: {
      required: true,

      pattern: {
        regex: /^[0-9]{8}$/,
        message: "Must be 8 digits",
      },
    },

    email: {
      required: true,
      pattern: {
        regex: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
        message: "Please check email format",
      },
    },

    houseNumber: {
      validateIf: (_, { uenNumberStatus }) => {
        return uenNumberStatus !== "verified";
      },
      required: true,
      length: {
        maxLength: 12,
        message: "Text exceeds max. length",
      },
    },

    streetName: {
      validateIf: (_, { uenNumberStatus }) => {
        return uenNumberStatus !== "verified";
      },
      required: false,
      length: {
        maxLength: 60,
        message: "Text exceeds max. length",
      },
    },
    floorNumber: {
      validateIf: (_, { uenNumberStatus }) => {
        return uenNumberStatus !== "verified";
      },
      required: false,
      length: {
        maxLength: 16,
        message: "Text exceeds max. length",
      },
    },

    unitNumber: {
      validateIf: (_, { uenNumberStatus }) => {
        return uenNumberStatus !== "verified";
      },
      required: false,
      length: {
        maxLength: 40,
        message: "Text exceeds max. length",
      },
    },

    eventHouseNumber: {
      required: true,
      length: {
        maxLength: 12,
        message: "Text exceeds max. length",
      },
    },

    eventStreetName: {
      required: true,
      length: {
        maxLength: 60,
        message: "Text exceeds max. length",
      },
    },

    eventTitle: {
      required: true,
    },

    eventfloorNumber: {
      required: false,
      length: {
        maxLength: 16,
        message: "Text exceeds max. length",
      },
    },

    eventunitNumber: {
      required: false,
      length: {
        maxLength: 40,
        message: "Text exceeds max. length",
      },
    },

    limit: {
      required: true,
    },
    coverageStartDate: {
      required: true,
      custom: {
        validate: coverageStartDate => {
          if (!isValidDate(coverageStartDate)) return "Incorrect coverage start date format";
          let error = null;
          error = error || validateStartCoverageDate(coverageStartDate);
          return error;
        },
      },
    },
    additionalInsured: {
      required: true,
    },

    landlord: {
      validateIf: (_, { additionalInsured }) => {
        return additionalInsured !== "no";
      },
      required: true,
      pattern: {
        regex: /.*\S.*/,
        message: " ",
      },
    },

    principal: {
      validateIf: (_, { additionalInsured }) => {
        return additionalInsured !== "no";
      },
      required: true,
      pattern: {
        regex: /.*\S.*/,
        message: " ",
      },
    },

    postalCode: {
      required: true,
    },

    eventPostalCode: {
      required: true,
    },
  },
};
