import { FC } from "react";
import { useTranslation } from "react-i18next";
import LiabilityBindStepNavigation from "./LiabilityBindStepNavigation";
import { addDays } from "date-fns";
import { liabilityBindState, proposerEventDetailsState } from "./types";
import SummaryHeader from "../components/SummaryHeader";
import SummaryCalculations from "../components/SummaryCalculations";
import SummaryMain from "../components/SummaryMain";
import KeyExclusionsList from "../components/KeyExclusionsList";
import useOnComponentDidMount from "../../../hooks/useOnComponentDidMount";

type Props = {
  onPrevious: VoidFunction | null;
  onNext: VoidFunction | null;
  state: proposerEventDetailsState;
  setFormState: (t: liabilityBindState) => void;
};

const getEndDate = (date: Date | null, duration: number) => {
  return date && addDays(new Date(date), duration - 1);
};
const Summary: FC<Props> = ({ onPrevious, onNext, state, setFormState }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product" });
  useOnComponentDidMount(() =>
    setFormState({
      ...state,
      coverageEndDate: getEndDate(state.coverageStartDate, state.duration),
    })
  );
  return (
    <>
      <SummaryHeader />
      <SummaryMain
        state={state}
        t={t}
        editProposalDetailsLink="/event_public_liability/bind/proposerDetails"
        renderExclusion={<KeyExclusionsList state={state} t={t} />}
      />
      <SummaryCalculations state={state} t={t} />
      <LiabilityBindStepNavigation isLoading={false} onBack={onPrevious} onNext={onNext} />
    </>
  );
};

export default Summary;
