import { useEffect, useRef, useState } from "react";

const useDelayedUnmount = (show: boolean, delay: number) => {
  const [mount, setMount] = useState(show);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const maybeClearTimeout = () => {
    if (timeout.current) clearTimeout(timeout.current);
  };

  useEffect(() => {
    if (!show && !mount) return;

    if (!show) {
      timeout.current = setTimeout(() => {
        setMount(false);
      }, delay);
    } else {
      maybeClearTimeout();
      setMount(true);
    }

    return () => maybeClearTimeout();
  }, [show, mount, delay]);

  return show || mount;
};

export default useDelayedUnmount;
