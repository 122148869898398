import { proposerEventDetailsState } from "./types";

/**
 * Loads the initial form state
 * Allows passing already known values to override values. This way we can load persisted values and add new properties without breaking persisted state
 */
const createInitialState = (persistedState: Partial<proposerEventDetailsState> = {}): proposerEventDetailsState => {
  return {
    proposerName: "",
    uenNumber: "",
    prevSearchedUenNumber: "",
    mobileNumber: "",
    email: "",
    postalCode: "",
    houseNumber: "",
    building: "",
    streetName: "",
    floorNumber: "",
    unitNumber: "",
    eventPostalCode: "",
    eventHouseNumber: "",
    eventStreetName: "",
    eventfloorNumber: "",
    eventunitNumber: "",
    eventBuilding: "",
    limit: null,
    coverageStartDate: null,
    coverageEndDate: null,
    additionalInsured: null,
    landlord: "",
    principal: "",
    accepted: false,
    acceptedNotice: false,
    marketingVoiceCall: false,
    marketingTextMessage: false,
    promotionalServices: true,
    eventTitle: "",
    eventType: null,
    duration: 1,
    attendees: null,
    drone: null,
    setupAndDismantling: null,
    volunteers: null,
    plans: [],
    quoteId: "",
    quoteNo: "",
    agentEmail: "",
    validityDate: "",
    createdAt: "",
    status: "",
    policyNumber: "",
    uenNumberStatus: "unknown",
    district: "",
    territory: "",
    eventDistrict: "",
    eventTerritory: "",
    ...persistedState,
  };
};

export default createInitialState;
