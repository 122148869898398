import { FC, ReactNode } from "react";
import A11yH1 from "../../components/basics/A11YH1";
import Header from "./layout/Header";
import TravelFooter from "../travel/TravelFooter";
import GroupTravelFooter from "../group_travel/Footer";

interface SignupResetFormProps {
  title?: string;
  children: ReactNode;
  isAuFooter?: boolean;
}

const SignupResetForm: FC<SignupResetFormProps> = ({ title, children, isAuFooter }: SignupResetFormProps) => (
  <main className="flex flex-col justify-start min-h-[inherit]">
    <A11yH1>{title}</A11yH1>
    <Header home="/" className="gap-4" hideAccount={false} />
    {children}
    {isAuFooter ? <GroupTravelFooter /> : <TravelFooter />}
  </main>
);

export default SignupResetForm;
