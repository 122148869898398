import Ajv from "ajv";

export type InsuredDetails = {
  email: string;
  agentCode: string;
};
export type InsuredResponse = {
  quoteId: string;
  result: string;
};

export const RenewalSchema = {
  type: "object",
  properties: {
    quoteId: { type: "string" },
    result: { type: "string" },
  },
  required: ["result"],
  additionalProperties: true,
};

export const validateRenewalResponse = new Ajv().compile(RenewalSchema);
