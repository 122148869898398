import { createTravelPricingRequest } from "../../helpers/group_travel/api/pricingApi";
import { createQuoteRequest } from "../../helpers/group_travel/api/quoteApi";
import { createCheckoutRequest } from "../../helpers/group_travel/api/checkout";
import { validateCheckoutResponse, validateCreateQuoteResponse, validatePricingResponse } from "../../helpers/group_travel/api/validators";
import { agentPost } from "../../helpers/travel/new-policy/api/agent";
import { GroupTravelFormState } from "../../pages/group_travel/Form/types";
import { GroupTravelBindState } from "../../pages/group_travel/bind/types";
import { PricingRequest, PricingResponse } from "../../types/group_travel/api/Pricing";
import { CheckoutRequest, CheckoutResponse, CreateQuoteResponse, PremiumQuote, SendEmailQuoteRequest } from "../../types/group_travel/api/Quote";
import { validateSendEmailResponse } from "../event_public_liability/agentApiService";

const PRICING = "v1/agent/pricing";
const CREATE_QUOTE = "v1/agent/submit";
const CHECKOUT = "v1/agent/payment/checkout";
const sendEmail = "v1/agent/quotationMail";

const agentApiService = {
  getTravelPricing: (data: GroupTravelFormState): Promise<PricingResponse> =>
    agentPost<PricingRequest, PricingResponse>(process.env.REACT_APP_BACKEND_API, PRICING, createTravelPricingRequest(data), (response: unknown) =>
      validatePricingResponse(response)
    ),
  sendEmailPDF: (emailPayload: SendEmailQuoteRequest): Promise<string> =>
    agentPost<SendEmailQuoteRequest, string>(process.env.REACT_APP_BACKEND_API, sendEmail, emailPayload, (response: unknown) =>
      validateSendEmailResponse(response)
    ),

  createQuote: (data: GroupTravelFormState): Promise<CreateQuoteResponse> =>
    agentPost<PremiumQuote, CreateQuoteResponse>(process.env.REACT_APP_BACKEND_API, CREATE_QUOTE, createQuoteRequest(data), (response: unknown) => {
      return validateCreateQuoteResponse(response);
    }),

  checkout: (data: GroupTravelBindState): Promise<CheckoutResponse> =>
    agentPost<CheckoutRequest, CheckoutResponse>(process.env.REACT_APP_BACKEND_API, CHECKOUT, createCheckoutRequest(data), (response: unknown) => {
      return validateCheckoutResponse(response);
    }),
};

export default agentApiService;
