import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { clearPISessionStorage } from "../../../helpers/shared/sessionStorage";
import ManulifeFooter from "../components/ManulifeFooter";
import RenewalHeader from "../components/RenewalHeader";
import ThankYouMessage from "../../components/ThankYouMessage";

const Success = () => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.renewal.payment" });
  useEffect(() => {
    clearPISessionStorage("professional_indemnity/manuliferenewal");
  }, []);
  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <RenewalHeader />
      <ThankYouMessage t={t} />
      <ManulifeFooter />
    </div>
  );
};

export default Success;
