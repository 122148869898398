import { FC } from "react";
import { Article } from "../../../components/groups";
import PolicyWordings from "../PolicyWordings";
import { liabilityFormState } from "../form/types";

type Props = {
  state: liabilityFormState;
  t: any;
};

const KeyExclusionsList: FC<Props> = ({ state, t }) => {
  const policyWordingUrl = "/documents/HK_EventPL_PolicyWording.pdf";
  return (
    <Article>
      <p className="font-bold mt-6 mb-2">Key Exclusions:</p>
      <ol className="list-decimal pl-4">
        <li>Special Effects Exclusion</li>
        <li>Special Person Exclusion</li>
        <li>Participant to Participant Liability Exclusion</li>
        <li>Exclude liability to performers &/or their crews &/or any employees of their Contractor &/or its Sub-Contractors of any tier</li>
        <li>Exclude pyrotechnics/ fireworks</li>
        <li>Products Liability and/or Completed Operations Exclusion</li>
        <li>Cyber Limited Data Exclusion Endorsement</li>
        <li>Communicable Disease Exclusion</li>
        {state.setupAndDismantling === "no" && <li>Exclude setting up and/or dismantling of event venue</li>}
      </ol>
      <PolicyWordings t={t} policyWordingUrl={policyWordingUrl} />
    </Article>
  );
};
export default KeyExclusionsList;
