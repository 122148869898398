import { Location } from "history";

type LocationState = {
  from?: Location;
};

export const getOriginatingPath = (location: Location, fallback: string) => {
  if (!location.state) return fallback;
  const state = location.state as LocationState;
  if (!state.from) return fallback;
  return state.from.pathname;
};
