import { AnimatedSidebar } from "../../../components/interactives";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const EngagedInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">More Information</h2>
        <ul className="list-disc pl-4">
          <li>Amateur sport means any organized competitive sport events</li>
          <li>
            Professional sport means any sport for which an Insured Person receives any fee, monetary reward or sponsorship as a result of their
            participation
          </li>
          <li>Manual Labour means physically exerting work</li>
          <li>
            Extreme Sport means high speed and high physical hazard sport, generally requiring specialized gear or equipment, but does not include
            skiing or snowboarding
          </li>
        </ul>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
