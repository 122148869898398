import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clearEventPLSessionStorage } from "../../helpers/shared/sessionStorage";
import Header from "./EventHeader";
import ThankYouMessage from "../components/ThankYouMessage";
import SuccessBackButton from "./SuccessBackButton";
import Footer from "../auth/layout/Footer";
import { FeedbackDetails } from "../../types/travel/Policy";
import createFeedback from "../../helpers/travel/new-policy/api/createFeedback";
import { ProductType } from "../../enums/shared/api/Api";
import FeedbackDialog from "../travel/new-policy/dialog/FeedbackDialog";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

type LocationState = {
  quoteId: string;
};

const Success = () => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [serverError, setServerError] = useState("false");
  const [isLoading, setIsLoading] = useState(false);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const { isSignedIn } = useAuth();

  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product.payment" });
  const { t: tFooter } = useTranslation("pages", { keyPrefix: "eventpl.footer" });
  const storageKey = "/event_public_liability/retrieveList";

  useEffect(() => {
    const timer = setTimeout(() => setShowFeedback(true), 2000);
    return () => clearTimeout(timer);
  }, []);
  const location = useLocation();
  useEffect(() => {
    clearEventPLSessionStorage(storageKey);
  }, []);
  const handleFeedbackConfirm = async (details: FeedbackDetails) => {
    setServerError("false");
    setIsLoading(true);
    try {
      await createFeedback(details, (location.state as LocationState)?.quoteId, ProductType.APL);
      setIsLoading(false);
      setIsFeedbackSubmitted(true);
    } catch {
      setIsLoading(false);
      setServerError("true");
    }
  };

  const handleCloseFeedback = () => {
    setShowFeedback(false);
    clearEventPLSessionStorage(storageKey);
  };
  return (
    <>
      <div className="flex flex-col justify-start min-h-[inherit]">
        <Header hideAccount={false} />
        <ThankYouMessage t={t} />
        <SuccessBackButton backButtonText={t("button.back")} />
        <Footer footer={tFooter} />
      </div>

      {showFeedback && isSignedIn() && (
        <FeedbackDialog
          onCancel={handleCloseFeedback}
          onConfirm={handleFeedbackConfirm}
          serverError={serverError}
          isLoading={isLoading}
          isSubmitted={isFeedbackSubmitted}
          customerSupportMail={t("customerSupport.emailId")}
        />
      )}
    </>
  );
};

export default Success;
