import { FC, PropsWithChildren } from "react";

type TableProps = PropsWithChildren<{
  className: string;
}>;

type RowColumnProps = PropsWithChildren<unknown>;

const Cell: FC<PropsWithChildren<{ className: string }>> = ({ children, className }) => <td className={className}>{children}</td>;
const Row: FC<PropsWithChildren<{ className: string }>> = ({ children, className }) => <tr className={className}>{children}</tr>;

export const LabelCell: FC<RowColumnProps> = ({ children }) => <Cell className="md:align-baseline pt-4 md:py-2 md:w-2/4">{children}</Cell>;
export const ContentCell: FC<RowColumnProps> = ({ children }) => (
  <Cell className="font-bold md:align-baseline md:py-2 md:w-2/4 break-words">{children}</Cell>
);

export const HeadingRow: FC<RowColumnProps> = ({ children }) => <Row className="flex flex-col md:flex-row">{children}</Row>;
export const NormalRow: FC<RowColumnProps> = ({ children }) => <Row className="flex flex-col md:flex-row">{children}</Row>;

export const CommonTable: FC<TableProps> = ({ className, children }) => {
  return (
    <table className={className}>
      <thead>
        <tr>{children}</tr>
      </thead>
      <tbody></tbody>
    </table>
  );
};
