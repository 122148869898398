import Dialog from "./Dialog";
import { Button } from "../basics/Button";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import { useTranslation } from "react-i18next";

interface IProps {
  onClose: () => void;
  onHome: () => void;
  onRetrieve: () => void;
}

const UserInput = ({ onClose, onHome, onRetrieve }: IProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product.dialog.error" });
  return (
    <Dialog onClose={onClose} title={t("title")}>
      <div className="flex-col-reverse md:flex-row justify-between gap-4 mt-4">
        <Button type={ButtonType.SECONDARY} onClick={onRetrieve} className="md:!w-[308px] md:mr-28 mb-4">
          {t("button.quote")}
        </Button>

        <Button type={ButtonType.PRIMARY} onClick={onHome} className="md:!w-[308px]">
          {t("button.back")}
        </Button>
      </div>
    </Dialog>
  );
};

export default UserInput;
