export const createQuoteResponse = {
  type: "object",
  properties: {
    quoteId: {
      type: "string",
    },
  },
  required: ["quoteId"],
  additionalProperties: true,
};
