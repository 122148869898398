export const blacklistCheckResponse = {
  type: "object",
  properties: {
    blacklist: {
      type: "object",
      properties: {
        identity_number: { type: "string" },
        result: { type: "string" },
      },
      required: [],
      additionalProperties: false,
    },
  },
  required: [],
  additionalProperties: false,
};
