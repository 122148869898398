import { FC } from "react";
import { Alert } from "../attentions";
import RawHTML from "../basics/RawHTML";

type Props = {
  t: any;
};

const AgreementAlert: FC<Props> = ({ t }) => {
  const policyWordingUrl = "/documents/EventPL_PolicyWording.pdf";
  return (
    <Alert type="warning" icon="icon-info">
      <div className="space-y-4">
        <RawHTML boldedLinks content={t("agree.details", { policyWordingUrl })} />
        <RawHTML boldedLinks content={t("agree.details_sub")} />
      </div>
    </Alert>
  );
};

export default AgreementAlert;
