import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { textEllipsis } from "../../../helpers/shared/textEllipsis";
import { ArrowCell } from "../../../components/basics/ArrowCell";
import Status from "../../event_public_liability/components/status";
import { ProductType } from "../../../enums/shared/api/Api";
import { QuoteListResponse } from "../../../types/travel/Listing";

interface TableProps {
  rows: QuoteListResponse;
}

const QuoteTable: FC<React.PropsWithChildren<TableProps>> = ({ rows }) => {
  const navigate = useNavigate();

  return (
    <span>
      <table className="flex flex-col table-shadow overflow-hidden mt-8">
        <thead className="px-8">
          <tr className="flex justify-between pt-2 pb-2 text-left md:text-[0.625rem] text-[0.7rem] th-text-color leading-3">
            <th className="w-[5.125rem] md:w-60 shrink-1">CLIENT NAME</th>
            <th className="w-[9rem] hidden md:block shrink-0">POLICY NUMBER</th>
            <th className="w-[5.315rem] md:hidden shrink-0">POLICY NO.</th>

            <th className="w-[5.25rem] shrink-0">STATUS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.length <= 0 ? (
            <tr className="flex justify-between px-8 py-2 text-sm bg-white border-b border-color">
              <td className="font-bold md:text-left">No Record Found!</td>
            </tr>
          ) : (
            rows.map((row, index) => (
              <tr
                key={index}
                className={`flex justify-between  px-8 py-2 text-sm bg-white border-b border-color hover:shadow-hover cursor-pointer`}
                onClick={() => navigate("/travel/documents", { state: { policyNumber: row.policyNumber, productCode: ProductType.ATR } })}
              >
                <td className="w-[5.125rem] md:w-60 shrink-1 md:text-left whitespace-nowrap">
                  <span className="hidden md:block">{textEllipsis(`${row.title} ${row.firstName} ${row.lastName}`, 28, 2)}</span>
                  <span className="md:hidden">{textEllipsis(`${row.title} ${row.firstName} ${row.lastName}`, 9, 2)}</span>
                </td>

                <td className="w-[5.315rem] md:w-[9rem] shrink-0">
                  <span className="hidden md:block">{row.policyNumber}</span>
                  <span className="md:hidden">{row.policyNumber?.substring(row.policyNumber.length - 9)}</span>
                </td>
                <td className="w-[5.25rem] uppercase shrink-0">
                  <Status status={"bound"} />
                </td>
                <ArrowCell isClickable={true} />
              </tr>
            ))
          )}
        </tbody>
      </table>
    </span>
  );
};

export default QuoteTable;
