const levyAttachment = "//donline.alliedworldgroup.com.hk/file/IALevy.pdf";

const ImportantNoticesTerms = () => (
  <ol>
    <li>
      For the full terms, conditions and exclusions please read the Policy Wording, a copy of which is available from your insurance representative
      for this scheme.
    </li>
    <li>
      This Professional Indemnity Insurance Policy is issued on a claims made and notified basis:
      <ol type="a">
        <li>
          This means that the policy responds to claims first made against you during the policy period and notified to the insurer during that policy
          period.
        </li>
        <li>
          The policy does not respond to any claim arising out of facts or circumstances of which you were aware at any time prior to the policy
          period which would have put a reasonable person in your position on notice that a claim may be made against you. (Some cover may be
          available under the Continuous Cover extension for innocent non-disclosure of facts and circumstances – but only if you are continuously
          insured under a professional indemnity policy issued by Allied World Assurance Company, Ltd (Hong Kong Branch) from the time when you first
          become aware of such facts or circumstances to the time when you notify a claim arising from those facts and circumstances to the Company,
          subject to the terms and conditions of the policy).
        </li>
        <li>
          The policy does not respond to any claim resulting from an act, error or omission occurring or committed by you prior to the retroactive
          date, where a retroactive date is specified in the policy terms which are offered to you.
        </li>
        <li>
          Once the policy period has expired, no notification of claims or facts or circumstances can be made on the policy even though:
          <ol type="i">
            <li>the event giving rise to the claim against you may have occurred during the policy period</li>
            <li>you may have first become aware of the facts or circumstances giving rise to the claim during the policy period.</li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      The questions in this application (this “Application” or your “Application”) relate to facts which Allied World Assurance Company, Ltd (Hong
      Kong Branch), a company incorporated in Bermuda with limited liability (the “Company”) considers material to underwriting this insurance. As
      these questions are not exhaustive, please advise the Company if there is any other material information which could influence the Company’s
      assessment and acceptance of the proposal.
    </li>
    <li>
      Prior to entering into a contract of insurance with the Company, you are under a duty to disclose to the Company, every fact you know, or could
      reasonably be expected to know, that may influence the Company’s assessment and acceptance of the risk and the terms of such acceptance. If you
      are uncertain as to whether or not particular information is material, these facts should be disclosed to the Company.
    </li>
    <li>
      Your duty does not require disclosure of any fact:
      <ol type="a">
        <li>That diminishes the risk to be undertaken by the Company;</li>
        <li>That is of common knowledge;</li>
        <li>That the Company knows or, in the ordinary course of its business, ought to know; or</li>
        <li> As to which compliance with your duty is waived by the Company.</li>
      </ol>
    </li>
    <li>You have the same duty to disclose those matters to the Company before you renew, extend, vary or reinstate a contract of insurance.</li>
    <li>
      All information provided by you in support of your Application for insurance must be correct, as you will be bound by the answers and by the
      information you have provided. If you do not comply with your duty of disclosure or make a misrepresentation, the Company may be entitled to
      reduce its liability under the policy in respect of a claim or may cancel or avoid the policy from its inception. If the non-disclosure or
      misrepresentation is fraudulent, the Company may also have the additional option of avoiding the policy from its inception and retaining the
      premiums paid.
    </li>
    <li>
      If your Application was submitted via an insurance intermediary and is accepted, the Company will pay the authorised insurance broker / the
      agent appointed by the Company to arrange the insurance commission during the continuance of the policy, including renewals, for arranging the
      policy.
    </li>
    <li>
      This product is underwritten by the Hong Kong branch of Allied World Assurance Company, Ltd (the “Company”) which is regulated by the Insurance
      Authority.
    </li>
    <li>
      This product is for Hong Kong distribution only. It is not an offer to sell, a solicitation to buy nor provision of any insurance product
      outside Hong Kong. The Company does not offer or sell any insurance product in any jurisdictions outside Hong Kong in which such offering or
      sale of the insurance product is illegal under the laws of such jurisdictions.
    </li>
    <li>
      This Policy shall be governed by and construed in accordance with the laws of Hong Kong and any dispute or difference that arises under this
      Policy shall be settled in accordance with the laws of Hong Kong.
    </li>
    <li>
      IA Levy imposed by the Insurance Authority applies to this Policy at the applicable rate. You can find further information on the IA Levy{" "}
      <a href={levyAttachment} rel="noreferrer" target="_blank">
        here
      </a>
      .
    </li>
  </ol>
);

export default ImportantNoticesTerms;
