import { FC } from "react";
import { FormGroup } from "../../../components/forms";
import TextInput from "../../../components/form-fields/TextInput";

type AddressFormGroupProps = {
  title: string;
  ariaLabel: string;
  postalCodeValue: string;
  postalCodeProps: any;
  houseNumberValue: string;
  houseNumberProps: any;
  streetNameValue: string;
  streetNameProps: any;
  floorNumberValue: string;
  floorNumberProps: any;
  unitNumberValue: string;
  unitNumberProps: any;
  disableForm?: boolean;
  labelForStreetName: string;
  t: any;
};

const AddressFormGroup: FC<AddressFormGroupProps> = ({
  streetNameValue,
  streetNameProps,
  floorNumberValue,
  floorNumberProps,
  unitNumberValue,
  unitNumberProps,
  title,
  // Common component for address input
  ariaLabel,
  postalCodeValue,
  postalCodeProps,
  houseNumberValue,
  houseNumberProps,
  t,
  disableForm,
  labelForStreetName,
}) => {
  return (
    <FormGroup title={title}>
      <TextInput disabled={disableForm} aria-label={`${ariaLabel}-postalCode`} value={postalCodeValue} {...postalCodeProps} label={t("postalCode")} />
      <TextInput
        disabled={disableForm}
        aria-label={`${ariaLabel}-blockHouseNo`}
        value={houseNumberValue}
        {...houseNumberProps}
        label={t("blockHouseNumber")}
      />
      <TextInput disabled={disableForm} aria-label={`${ariaLabel}-street`} value={streetNameValue} {...streetNameProps} label={labelForStreetName} />
      <TextInput
        disabled={disableForm}
        aria-label={`${ariaLabel}-floorNumber`}
        value={floorNumberValue}
        {...floorNumberProps}
        label={t("floorNumber")}
      />
      <TextInput disabled={disableForm} aria-label={`${ariaLabel}-unitNumber`} value={unitNumberValue} {...unitNumberProps} label={t("unitNumber")} />
    </FormGroup>
  );
};
export default AddressFormGroup;
