export enum ProductType {
  ATR = "ATR",
  APL = "APL",
  CLV = "CLV",
  ACTL = "ACTL",
  APAG = "APAG",
}

export enum Branch {
  SG = "SG",
  AU = "AU",
}

export enum BlacklistCheckStatus {
  SUCCESS = "success",
  REFERRAL = "referral",
  ERROR = "error",
}
export enum ProductsName {
  APAG = "Group Personal Accident",
  ACTL = "Group Travel",
  APL = "Event Public Liability",
  ATR = "Travel Protector",
  CLV = "Event Public Liability",
}
