import { Traveller } from "../../types/travel/Policy";
import { defaultTraveller } from "./newTravelPolicyInitialization";

export const getSpouseFromTravellers = (travellers: Traveller[], isRequesterTravelling: boolean) =>
  isRequesterTravelling ? travellers[0] : travellers[1];

export const updateTravellersOnAdultCount = (adultCount: number, requestingPersonIsTravellingPerson: boolean, currentTravellers: Traveller[]) => {
  const newTravellersAmount = adultCount - (requestingPersonIsTravellingPerson ? 1 : 0);

  const newTravellers: Traveller[] = new Array<Traveller>(newTravellersAmount)
    .fill(defaultTraveller)
    .map((_, i) => currentTravellers[i] || defaultTraveller);

  return newTravellers;
};

export const updateTravellersOnRequesterChange = (requestingPersonIsTravellingPerson: boolean, currentTravellers: Traveller[]) => {
  let newTravellers: Traveller[];

  if (requestingPersonIsTravellingPerson) {
    newTravellers = currentTravellers.slice(1);
  } else {
    newTravellers = [defaultTraveller, ...currentTravellers];
  }

  return newTravellers;
};
