import axios, { AxiosRequestHeaders } from "axios";

export const HEADER_CONTENT_TYPE = { "Content-Type": "application/json; charset=utf-8" };

export const validateStatusCode = (code: number) => {
  return code < 500;
};

export const post = async <TBody, TResponse>(
  url: string | undefined,
  endpoint: string,
  headers: AxiosRequestHeaders,
  body: TBody,
  responseMatchesSchema: (data: unknown) => boolean
) => {
  const response = await axios.post(`${url}${endpoint}`, body, { headers });
  const matchesSchema = (responseData: unknown): responseData is TResponse => responseMatchesSchema(responseData);
  if (!matchesSchema(response.data)) throw new Error("Invalid response schema");
  return response.data;
};

export const get = async <TResponse>(
  url: string | undefined,
  endpoint: string,
  headers: AxiosRequestHeaders,
  responseMatchesSchema: (data: unknown) => boolean
) => {
  const response = await axios.get(`${url}${endpoint}`, { headers });
  const matchesSchema = (responseData: unknown): responseData is TResponse => responseMatchesSchema(responseData);
  if (!matchesSchema(response.data)) throw new Error("Invalid response schema");
  return response.data;
};
