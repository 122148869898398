import PlanTableGroup from "../../../../../components/basics/PlanTableGroup";
import RawHTML from "../../../../../components/basics/RawHTML";
import { RadioInput } from "../../../../../components/form-fields";
import { FormGroup } from "../../../../../components/forms";
import { Group } from "../../../../../components/groups";
import SegmentedButtons from "../../../../../components/interactives/SegmentedButtons";
import { FC, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IOption } from "../../../../../components/form-fields/RadioInput";
import { createTravelPlanPriceTable, formatValue } from "../../../../../helpers/group_travel/planTables";
import { Plans, PlansWithBrokerage } from "../../../../../types/group_travel/api/Pricing";
import PremiumLabel from "../../../../components/PremiumLabel";

import { GroupPersonalAccidentFormState } from "../../types";
import { getPlanLabel } from "../../../../../helpers/group_travel/labels";

export interface PlanProps {
  onPrevious: VoidFunction | null;
  onNext: VoidFunction | null;
}
export interface PlanProps {
  onPrevious: VoidFunction | null;
  onNext: VoidFunction | null;
}
export type StringOrNull = string | null;
const TotalPremiumLabel: FC<{ premium: number }> = ({ premium }) => (
  <PremiumLabel label="Total Premium" value={formatValue(premium)} currency="AUD" />
);

const pricingLabelOptions = (value: string, label: string, total: number): IOption<string> => ({
  value,
  label,
  rightLabel: <TotalPremiumLabel premium={total} />,
});

const getPlansByBrokerage = (allPlans: PlansWithBrokerage, brokerage: number) => allPlans[brokerage];

const createOptions = (plans: Plans): IOption<string>[] => {
  return plans?.map(plan => pricingLabelOptions(plan.id, getPlanLabel(plan?.id), plan.total));
};

interface NonReferralProps {
  state: GroupPersonalAccidentFormState;
  setFormState: (t: GroupPersonalAccidentFormState) => void;
  field: any;
}

const NonReferral: React.FC<NonReferralProps> = ({ state, setFormState, field }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupPersonalAccident.newProduct" });
  const { brokerageRate, stampDutyRate } = state;
  const allPlans = state.allPlans ?? ({} as PlansWithBrokerage);
  const plans = getPlansByBrokerage(allPlans, state.brokerageRate as number);
  const planTables = createTravelPlanPriceTable(plans, state.coverageType);

  useEffect(() => {
    handlePlanUpdate(state.planName);
  }, [state.planName, state.brokerageRate]);

  const planOptions: IOption<string>[] = useMemo(() => createOptions(plans), [plans]);
  const handlePlanUpdate = (planName: StringOrNull) => {
    const plan = plans?.find(({ id }) => id === planName);
    plan && setFormState({ ...state, selectedPlan: plan });
  };
  return (
    <>
      <SegmentedButtons
        mdAppendOption="%"
        endLabelWith=":"
        defaultOptions={[0, 5, 10, 15, 20, 25]}
        labelName={`Brokerage Rate`}
        xsAppendLabel=" (%)"
        value={state?.brokerageRate}
        onValueChange={field.brokerageRate.onValueChange}
      />
      <Group aria-label="plan-table" role="list" className="gap-0">
        {planTables.length > 0 && <PlanTableGroup planTables={planTables} />}
      </Group>
      <div className="mt font-bold inline-flex items-center ">
        <RawHTML content={t("steps.plans.plan.table.subtitle.text")} boldedLinks wrap={false} />
      </div>
      <FormGroup
        title={t("steps.plans.plan.form.groupTitle.price")}
        subTitleFontSize="md:text-xl max-w-80 my-2"
        subtitle={t("steps.plans.plan.form.groupTitle.subTitle", { brokerageRate, stampDutyRate })}
      >
        <RadioInput aria-label="plan-selection" {...field.planName} value={state.planName} options={planOptions || []} />
      </FormGroup>
    </>
  );
};

export default NonReferral;
