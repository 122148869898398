const PolicyDetailsSchema = {
  type: "object",
  properties: {
    insuredName: { type: "string" },
    policyNumber: { type: "string" },
    policyEndDate: { type: "string" },
    contactNumber: { type: "string" },
    email: { type: "string" },
    amount: { type: "number" },
    status: { type: "string" },
    renewalCount: { type: "number" },
    endorsementCount: { type: "number" },
    validity: { type: "string" },
  },
  additionalProperties: true,
};

export const getNewRenewalPolicyResponse = {
  type: "object",
  properties: {
    data: {
      type: "array",
      items: PolicyDetailsSchema,
    },
    status: {
      type: "string",
    },
  },
  required: ["data"],
  additionalProperties: true,
};

export const checkoutResponse = {
  type: "object",
  properties: {
    url: { type: "string" },
  },
  required: ["url"],
  additionalProperties: true,
};

export const genericCheckoutResponse = {
  type: "object",
  properties: {
    url: { type: "string" },
    status: { type: "string" },
  },
  required: ["url"],
  additionalProperties: true,
};
