import pagesTranslationsEN from "./translations/en/pages.json";
import pagesTranslationPIEN from "./translations/en/pages.professionalIndemnity.json";
import pagesTranslationTravelEN from "./translations/en/pages.travel.json";
import pagesTranslationEventPlEN from "./translations/en/pages.eventpl.json";
import pagesTranslationEventPlHK from "./translations/en/pages.eventplHk.json";
import pagesTranslationAuthEN from "./translations/en/pages.auth.json";
import componentsTranslationsEN from "./translations/en/components.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pagesTranslationCampaign from "./translations/en/pages.campaign.json";
import pagesTranslationGroupTravel from "./translations/en/pages.groupTravel.json";
import pagesTranslationGroupPersonalAccident from "./translations/en/pages.groupPersonalAccident.json";
import pagesTranslationContractor from "./translations/en/pages.contractor.json";

export const defaultNS = "general";
export const resources = {
  en: {
    pages: {
      ...pagesTranslationsEN,
      professionalIndemnity: pagesTranslationPIEN,
      auth: pagesTranslationAuthEN,
      travel: pagesTranslationTravelEN,
      eventpl: pagesTranslationEventPlEN,
      eventplHk: pagesTranslationEventPlHK,
      campaign: pagesTranslationCampaign,
      groupTravel: pagesTranslationGroupTravel,
      groupPersonalAccident: pagesTranslationGroupPersonalAccident,
      contractor: pagesTranslationContractor,
    },
    components: componentsTranslationsEN,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  defaultNS,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
