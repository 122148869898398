import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Eligibility from "./steps/Eligibility";
import Limit from "./steps/Limit";
import PersonalDetails from "./steps/PersonalDetails";
import Summary from "./steps/Summary";
import Terms from "./steps/Terms";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const ProfessionalIndemnityFormRoutes: FC<Props> = props => (
  <Routes>
    <Route path="eligibility" element={<Eligibility {...props} />} />
    <Route path="limit" element={<Limit {...props} />} />
    <Route path="personal" element={<PersonalDetails {...props} />} />
    <Route path="summary" element={<Summary {...props} />} />
    <Route path="terms" element={<Terms {...props} />} />
    <Route path="" element={<Navigate to="eligibility" />} />
  </Routes>
);

export default ProfessionalIndemnityFormRoutes;
