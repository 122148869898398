import { RenewalFormState } from "./types";

const createRenewalInitialState = (): RenewalFormState => {
  return {
    limit: null,
    agentCode: "",
    email: "",
    accepted: false,
    promotionalServices: true,
    gp: 0,
    levy: 0,
    total: 0,
    quoteId: "",
  };
};

export default createRenewalInitialState;
