import { LoadingButton } from "../../../components/basics/Button";
import TextInput from "../../../components/form-fields/TextInput";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import { removeExtraSpaces } from "../../../helpers/shared/formHandlers";
import AgentFormDialog from "../../travel/new-policy/dialog/AgentFormDialog";
import { AgentDetailsType } from "./SignupForm";

type SignupInputFormProps = {
  isLoading: boolean;
  hasErrors: boolean;
  errorMessage?: string; // can be empty
  errors: {
    email?: string;
    givenName?: string;
    surName?: string;
    agentCode?: string;
    agentName?: string;
  };
  state: AgentDetailsType;
  field: any;
  validateField: (name: string, value: unknown, newData?: Record<string, unknown>) => void;
  onClose: () => void;
  setState: any;
};
const SignupInputForm = ({ isLoading, hasErrors, errorMessage, errors, state, field, validateField, onClose, setState }: SignupInputFormProps) => {
  return (
    <>
      <div className="space-y-6">
        <TextInput
          aria-label="email"
          error={errors.email}
          label="Email"
          backgroundColor="bg-grey-lighter"
          autoComplete="username"
          value={state.email}
          onBlur={field.email.onBlur}
          onValueChange={value => {
            const trimmedEmail = value.trim();
            field.email.onValueChange(trimmedEmail);
            errors.email && validateField("email", trimmedEmail);
          }}
        />
        <TextInput
          error={errors.givenName}
          label={"Given Name"}
          backgroundColor="bg-grey-lighter"
          value={state.givenName}
          onBlur={removeExtraSpaces("givenName", setState, validateField)}
          onValueChange={field.givenName.onValueChange}
        />
        <TextInput
          error={errors.surName}
          label={"Family Name"}
          backgroundColor="bg-grey-lighter"
          value={state.surName}
          onBlur={removeExtraSpaces("surName", setState, validateField)}
          onValueChange={field.surName.onValueChange}
        />
        <TextInput
          error={errors.agentCode}
          label="Billing Code"
          backgroundColor="bg-grey-lighter"
          value={state.agentCode}
          onBlur={removeExtraSpaces("agentCode", setState, validateField)}
          onValueChange={field.agentCode.onValueChange}
        />
        <TextInput
          error={errors.agentName}
          label={"Agency Name"}
          backgroundColor="bg-grey-lighter"
          value={state.agentName}
          onBlur={removeExtraSpaces("agentName", setState, validateField)}
          onValueChange={field.agentName.onValueChange}
        />
      </div>
      {errorMessage && <AgentFormDialog onClose={onClose} message="Something went wrong. Please try again later." />}
      <LoadingButton disabled={hasErrors} isLoading={isLoading} className="mt-6 md:!w-full" type={ButtonType.PRIMARY} loadingText={"Loading"}>
        Sign Up
      </LoadingButton>
    </>
  );
};

export default SignupInputForm;
