import { Region } from "../../../enums/shared/forms/Region";
import { liabilityFormState } from "../../../pages/event_public_liability/form/types";
import agentApiService from "../../../services/event_public_liability/agentApiService";
import { CreateEventQuoteRequest, SendEmailQuoteRequest } from "../../../types/event_public_liability/api/Quote";

export const createQuoteRequest = (data: liabilityFormState, region: string): CreateEventQuoteRequest => {
  return {
    productType: region === Region.HK ? "CLV" : "APL",
    branch: region === Region.HK ? "HK" : "SG",
    proposerName: data.proposerName,
    eventTitle: data.eventTitle,
    attendees: data.attendees,
    eventType: data.eventType,
    duration: data.duration,
    drone: data.drone,
    setupAndDismantling: data.setupAndDismantling,
    volunteers: data.volunteers,
    plans: data.plans,
  };
};

export const createQuote = (formState: liabilityFormState, region: string) => agentApiService.createQuote(formState, region);
export const updateQuotesGst = (quoteId: string) => agentApiService.updateQuotesGst(quoteId);
export const sendEmailPDF = (emailPayload: SendEmailQuoteRequest) => agentApiService.sendEmailPDF(emailPayload);
