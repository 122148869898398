import { useTranslation } from "react-i18next";
import { Dialog } from "../../../components/interactives";
import { DialogFooter } from "../../../components/interactives/Dialog";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import RawHTML from "../../../components/basics/RawHTML";

interface ReferralDialogProps {
  onClose: VoidFunction;
  onNext: VoidFunction;
}

const ReferralDialog: React.FC<ReferralDialogProps> = ({ onClose, onNext }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupPersonalAccident.newProduct.steps.plans.referral" });
  return (
    <Dialog onClose={onClose} title={t("title")}>
      <RawHTML content={t("content")} />
      <span className="flex justify-end">
        <DialogFooter>
          <Button type={ButtonType.PRIMARY} onClick={onNext}>
            {t("next")}
          </Button>
        </DialogFooter>
      </span>
    </Dialog>
  );
};

export default ReferralDialog;
