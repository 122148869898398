import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../../../components/basics";
import TextInput from "../../../../components/form-fields/TextInput";
import { Alert } from "../../../../components/attentions";
import classNamePresets from "../../../../utils/classNamePresets";
import IndemnityFormStepNavigation from "../../Form/IndemnityFormStepNavigation";
import { FormGroup } from "../../../../components/forms";
import { limitOfLiabilityValidations } from "../../../../helpers/professional_indemnity/validations";
import useForm from "../../../../hooks/useForm";
import { RenewalFormContext } from "../Renewal";
import LimitRadioGroup from "../../components/LimitRadioGroup";
import LimitOfLiabilityOptions from "./LimitOfLiabilityOptions.json";
import UserInputError from "../../components/UserInputError";
import clientApiService from "../../../../services/professional_indemnity/clientApiService";
import PremiumLabel from "../../../components/PremiumLabel";
import ServerError from "../../../errors/ServerError";
import Spinner from "../../../../components/icons/Spinner";
import { formatToTwoDecimals } from "../../../../helpers/shared/format";

type Props = {
  onNext: VoidFunction;
  onPrevious: VoidFunction | null;
};

const TotalPremiumLabel: FC<{ premium: number }> = ({ premium }) => (
  <PremiumLabel label="Total Premium (Incl. IA Levy)" value={formatToTwoDecimals(premium)} currency="HKD" />
);
const LimitRadioOptions: { value: number; label: string; rightLabel: JSX.Element }[] = [];
LimitOfLiabilityOptions.map(option => {
  LimitRadioOptions.push({
    value: option.value,
    label: option.label,
    rightLabel: <TotalPremiumLabel premium={option.total} />,
  });
});
const getPremium = (key: any, limit: any) => {
  const option = LimitOfLiabilityOptions.find(o => o.value === limit) as any;
  return option && option[key];
};
const userInputErrors = (showInvalidInput: boolean, onClose: any, t: any) => {
  return showInvalidInput && <UserInputError onClose={onClose} error={t} />;
};
const LimitOfLiability: FC<React.PropsWithChildren<Props>> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.renewal" });
  const { state, onChange, setFormState, clientId } = useContext(RenewalFormContext);
  const [showInvalidInput, setShowInvalidInput] = useState(false);
  const form = useForm(state, limitOfLiabilityValidations, onChange);
  const { limit, email, agentCode } = form.field;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverError, setServerError] = useState(false);

  const validateInsuredStatus = async () => {
    try {
      setIsSubmitting(true);
      const item = await clientApiService.renewalCheck(clientId, { email: state.email, agentCode: state.agentCode });
      item.result === "Success" ? setQuoteId(item.quoteId) : setShowInvalidInput(true);
    } catch (error) {
      console.error(error);
      setServerError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const setQuoteId = (quoteId: string) => {
    setFormState({
      ...state,
      quoteId: quoteId,
      gp: getPremium("gp", state.limit),
      levy: getPremium("levy", state.limit),
      total: getPremium("total", state.limit),
    });
    onNext();
  };

  const onClose = () => {
    setShowInvalidInput(false);
  };

  const handleNext = () => {
    form.validateForm(validateInsuredStatus);
  };

  return (
    <>
      <div className="container-flow flex-1">
        <H2 className={classNamePresets.mainHeading}>{t("steps.limitofliability")}</H2>
        <div className="flex justify-between flex-wrap mb-2 md:mb-8 gap-x-20">
          <div className="mt-8 flex-shrink max-w-[18rem]">
            <p className="font-bold text-lg">{t("limitOfLiability.insuredDetails.detailsOfInsured")}</p>
            <div className="text-lg">{t("limitOfLiability.insuredDetails.sub-title")}</div>
          </div>
          <div className="min-w-[20rem] flex-1">
            <FormGroup title="" fullWidth>
              <TextInput
                aria-label="agent-code"
                id={t("formLabels.agentCode")}
                label={t("formLabels.agentCode")}
                value={state.agentCode}
                {...agentCode}
              />
              <TextInput aria-label="email" id={t("formLabels.email")} label={t("formLabels.email")} value={state.email} {...email} />
            </FormGroup>
          </div>
        </div>
        <LimitRadioGroup
          mainClassName="flex justify-between flex-wrap mb-5 md:mb-12 gap-x-18"
          labelClassName="mt-8 flex-shrink max-w-[20rem]"
          fieldsClassName="min-w-[20rem] flex-1 ml-0.5"
          state={state}
          options={LimitRadioOptions}
          limit={limit}
          ariaLabel="limit of liability"
          limitTranslationObject={t}
        />
        <Alert type="warning" icon="icon-info">
          <p className="space-y-4">{t("limitOfLiability.totalAggregateLimit")}</p>
        </Alert>
      </div>
      {serverError && <ServerError supportEmailId={t("customerSupport.emailId")} displayType="modal" onClose={() => setServerError(false)} />}
      {isSubmitting ? (
        <div className="md:flex md:flex-row-reverse container-flow pt-4 pb-8 mt-10 md:mt-20">
          <span className="flex items-center justify-center -translate-x-4 md:-translate-x-5">
            <span className="mx-auto md:mx-0 h-6 inline-block">
              <Spinner width={`1.5rem`} height={`1.5rem`} color={"#3F9293"} />
            </span>
          </span>
        </div>
      ) : (
        <IndemnityFormStepNavigation onBack={onPrevious} onNext={handleNext} />
      )}
      {userInputErrors(showInvalidInput, onClose, t)}
    </>
  );
};

export default LimitOfLiability;
