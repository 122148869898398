import { FC, HTMLAttributes } from "react";
import cls from "../../utils/cls";

interface AlertProps extends HTMLAttributes<HTMLElement> {
  type: "ok" | "info" | "warning" | "error";
  icon?: string;
  className?: string;
}

const classesPerType = {
  ok: "icon-green border-green text-black bg-transparent",
  info: "icon-grey border-grey text-black bg-transparent",
  warning: "icon-yellow border-yellow text-black bg-yellow-light",
  error: "icon-red border-red text-black bg-red-light",
};

const Alert: FC<React.PropsWithChildren<AlertProps>> = ({ type, icon, className, children, ...props }) => (
  <div
    role="alert"
    className={cls(
      "block",
      "w-full py-4 pr-4",
      "border rounded-lg",
      "icon-6 icon-left-4 icon-top-4",
      { "pl-4": !icon },
      { "pl-13": !!icon },
      classesPerType[type],
      icon,
      className
    )}
    {...props}
  >
    {children}
  </div>
);

export default Alert;
