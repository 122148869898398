import { AnimatedSidebar } from "./../../../components/interactives";
import { Button } from "./../../../components/basics/Button";
import { ButtonType } from "./../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const EventTypeInfo = ({ show, onClose }: IProps) => {
  const label1 = "Meetings, Conferences and Seminars";
  const label2 = "Music Events (Blues, Classical, Folk, Jazz or Orchestra)";
  const label3 = "Trade Shows";
  const label4 = "Atrium Sales";
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">Types of Event Covered</h2>
        <h3 className="text-md font-bold mt-6">Static Events (Indoor and Outdoor Setting)</h3>
        <ul className="list-disc pl-4">
          <li>{label1}</li>
          <li>{label2}</li>
          <li>Corporate Events (Dinner and Dance, Training, Opening Ceremony, Award Ceremony, Product Launch)</li>
          <li>Networking Events</li>
          <li>Press Conferences</li>
          <li>Theatre Events (Musicals, Movie Premiere, Theatre Play)</li>
        </ul>
        <h3 className="text-md font-bold mt-6">Dynamic Events (Indoor Setting)</h3>
        <ul className="list-disc pl-4">
          <li>Exhibitions</li>
          <li>Team Building (with incidental outdoor activities)</li>
          <li>{label3}</li>
          <li>{label4}</li>
          <li>Roadshows</li>
        </ul>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
