export const errorMessage = (err: unknown) => {
  let message: string;
  if (err instanceof Error) message = err.message;
  else message = String(err);
  return message;
};

const isCognitoError = (err: unknown): err is { code: string } => typeof err === "object" && !!err && "code" in err;

export const isPendingLoginExpiredError = (err: unknown) => {
  return isCognitoError(err) && err.code === "NotAuthorizedException";
};
