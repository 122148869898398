import Header from "../auth/layout/Header";
import A11yH1 from "../../components/basics/A11YH1";
import briefcaseImage from "../../assets/icons/briefcase.svg";
import { useTranslation } from "react-i18next";
import { FC } from "react";

interface IProps {
  hideAccount?: boolean;
  home: string;
}

const ManulifeHeader: FC<React.PropsWithChildren<IProps>> = ({ home, hideAccount, ...props }) => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.header" });
  return (
    <Header home={home} hideAccount={hideAccount} className="gap-4" {...props}>
      <A11yH1>{t("title")}</A11yH1>
      <div className="my-auto text-xl font-serif md:text-3xl whitespace-nowrap hidden md:block">{t("title")}</div>
      <img src={briefcaseImage} alt="professional indemnity logo" className="ml-2 h-10 md:h-16" />
    </Header>
  );
};

export default ManulifeHeader;
