import { Navigate, Route, Routes } from "react-router-dom";
import CreateGroupTravel from "./CreateQuote";
import GroupTravelQuoteList from "./bind/quotes/QuoteList";
import GroupTravelBindQuote from "./BindQuote";
import React from "react";
import ProtectedAgentRoute from "../../components/authentication/ProtectedAgentRoute";
import { Region } from "../../enums/shared/forms/Region";
import NotFound from "../errors/NotFound";
import GroupTravelSuccess from "./Success";
import PolicyDocuments from "../components/PolicyDocuments";
import { ProductsName } from "../../enums/shared/api/Api";

const RoutesForTravel: React.FC = () => (
  <Routes>
    <Route element={<ProtectedAgentRoute redirectPath="/user/login" allowedRegion={Region.AU} />}>
      <Route path="" element={<Navigate to="form" />} />
      <Route path="form/*" element={<CreateGroupTravel />} />
      <Route path="retrieve-list" element={<GroupTravelQuoteList />} />
      <Route path="documents" element={<PolicyDocuments productName={ProductsName.ACTL} region={Region.AU} />} />
      <Route path="bind/*" element={<GroupTravelBindQuote />} />
    </Route>
    <Route path="payment/success" element={<GroupTravelSuccess />} />
    <Route path="*" element={<NotFound homePage="/" />} />
  </Routes>
);

export default RoutesForTravel;
