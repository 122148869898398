import { Branch, ProductType } from "../../../enums/shared/api/Api";
import { GroupPersonalAccidentFormState } from "../../../pages/group_personal_accident/Form/types";
import agentApiService from "../../../services/group_personal_accident/agentApiService";
import { PremiumQuote } from "../../../types/group_personal_accident/api/Quote";
import { formatToServerDate } from "../../../utils/date";
import { convertToBoolean, travelPlan } from "../../group_travel/api/quoteApi";

const establishTravelDetails = (details: GroupPersonalAccidentFormState): PremiumQuote["travelDetails"] => {
  return {
    domiciledState: details.regionCode,
    policyType: details.coverageType,
    insuredCount: details.totalNumbersOfInsured,
    startDate: formatToServerDate(details.coverageStartDate) || null,
    endDate: formatToServerDate(details.coverageEndDate) || null,
  };
};

export const createQuoteRequest = (data: GroupPersonalAccidentFormState): PremiumQuote => {
  return {
    productType: ProductType.APAG,
    proposerName: data.policyHoldersName,
    branch: Branch.AU,
    travelPlan: travelPlan(data),
    plans: data.allPlans && data.allPlans[data?.brokerageRate as number],
    travelDetails: establishTravelDetails(data),
    mandatoryClause: {
      auRegisteredBusinesss: convertToBoolean(data.familyTrust),
      riskyOccupation: convertToBoolean(data.occupationEligibility),
    },
    referalDetails: {
      // referral object
      undergroundOrAbove5mHeight: convertToBoolean(data.undergroundOrAbove5mHeight),
      nonScheduledFlightMoreThan10: convertToBoolean(data.nonScheduledFlightMoreThan10),
      over70YearsOld: convertToBoolean(data.over70YearsOld),
      average55YearsOld: convertToBoolean(data.average55YearsOld),
      previousIncomeProtectOrPAInsurance: convertToBoolean(data.previousIncomeProtectOrPAInsurance),
      moreThan25000Claims: convertToBoolean(data.moreThan25000Claims),
    },
  };
};

export const createQuote = async (state: GroupPersonalAccidentFormState) => {
  const data = await agentApiService.createQuote(state);
  return data;
};
