import { Auth } from "aws-amplify";
import React, { useEffect, useState, useCallback, FC, VFC } from "react";
import { ErrorMessage } from "../../../components/forms";
import { errorMessage, isPendingLoginExpiredError } from "../../../utils/error";
import { QRCodeSVG } from "qrcode.react";
import TextInput from "../../../components/form-fields/TextInput";
import SecondaryLayout from "../../../components/authentication/layout/SecondaryLayout";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import googleAuthImg from "../../../assets/images/google_auth.png";
import classNamePresets from "../../../utils/classNamePresets";
import cls from "../../../utils/cls";
import SessionExpiredDialog from "../../../components/authentication/SessionExpiredDialog";
import AuthenticationFlowActions from "../../../components/authentication/AuthenticationFlowActions";
import useWithAsyncLoadingIndicator from "../../../hooks/useWithAsyncLoadingIndicator";

interface IProps {
  onSuccess: () => void;
  cognitoUser: { username: string };
  onLoginReset: VoidFunction;
  isGuestTpa: boolean;
}

const CircleNumber: VFC<{ number: number }> = ({ number }) => (
  <div className="h-8 w-8 flex-shrink-0 bg-red border-[1px] border-red rounded-full font-sans text-lg text-white flex justify-center items-center">
    {number}
  </div>
);

const NumberedList: FC<React.PropsWithChildren<unknown>> = ({ children }) => <ol className="space-y-2">{children}</ol>;

const NumberedListItem: FC<React.PropsWithChildren<{ number: number; title: string }>> = ({ number, title, children }) => (
  <li>
    <div className="flex items-center">
      <CircleNumber number={number} />
      <p className="ml-2">{title}</p>
    </div>
    {/* ml-10 matches the 8 rem size and 2rem margin left of the paragraph */}
    {children && <div className="mt-2 ml-10">{children}</div>}
  </li>
);

type InstallAuthenticatorStepProps = {
  onSubmit: VoidFunction;
};

const InstallAuthenticatorStep: VFC<InstallAuthenticatorStepProps> = ({ onSubmit }) => {
  const { t } = useTranslation("pages", { keyPrefix: "auth.login.topSetupForm" });

  return (
    <div className="space-y-8">
      <NumberedList>
        <NumberedListItem number={1} title={t("steps.install-authenticator.step1")}>
          <img className="drop-shadow-subtle my-3" src={googleAuthImg} alt="Google authenticator" />
        </NumberedListItem>
        <NumberedListItem number={2} title={t("steps.install-authenticator.step2")} />
      </NumberedList>
      <p className={cls(classNamePresets.quaternary, "ml-10")}>{t("steps.install-authenticator.alternativeStep")}</p>
      <div className="ml-10">
        <Button type={ButtonType.PRIMARY} htmlType="button" onClick={onSubmit}>
          {t("steps.install-authenticator.confirm")}
        </Button>
      </div>
    </div>
  );
};

type ScanCodeStepProps = {
  error: string;
  isSubmitting: boolean;
  setError: (message: string) => void;
  onBackClick: VoidFunction;
  cognitoUser: { username: string };
  authCode: string;
  onSubmit: (code: string) => void;
};

const ScanCodeStep: VFC<ScanCodeStepProps> = ({ error, setError, isSubmitting, onBackClick, onSubmit, cognitoUser, authCode }) => {
  const [state, setState] = useState({ code: "" });
  const { t } = useTranslation("pages", { keyPrefix: "auth.login.topSetupForm" });

  const username = cognitoUser.username;
  const setupCode = `otpauth://totp/AWSCognito:${username}?secret=${authCode}&issuer=AWAC`;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (state.code === "") return setError(errorMessage(t("emptyError")));
    onSubmit(state.code);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-1">
      <NumberedList>
        <NumberedListItem number={1} title={t("steps.scan-code.step1")}>
          <div className="space-y-4 md:max-w-xs">
            {authCode && (
              <div className="mb-8">
                <QRCodeSVG size={192} value={setupCode} />
              </div>
            )}
          </div>
        </NumberedListItem>
        <NumberedListItem number={2} title={t("steps.scan-code.step2")}>
          <div className="w-60">
            <TextInput autoFocus label={t("steps.scan-code.label")} value={state.code} onValueChange={value => setState({ ...state, code: value })} />
          </div>
        </NumberedListItem>
      </NumberedList>

      <div className="ml-10">
        {error && <ErrorMessage error={error} />}
        <div className="mt-6">
          <AuthenticationFlowActions isSubmitting={isSubmitting} nextLabel={t("steps.scan-code.confirm")} onBack={onBackClick} />
        </div>
      </div>
    </form>
  );
};

type Step = "install-authenticator" | "scan-code";

type TotpFormProps = {
  error: string;
  step: Step;
  setStep: (step: Step) => void;
  setError: (message: string) => void;
  cognitoUser: { username: string };
  onSuccess: VoidFunction;
  errorHandler: (error: unknown) => void;
};

const TotpForm: VFC<TotpFormProps> = ({ error, setError, setStep, onSuccess, errorHandler, cognitoUser, step }) => {
  const [authCode, setAuthCode] = useState("test");

  useEffect(() => {
    Auth.setupTOTP(cognitoUser)
      .then(setAuthCode)
      .catch(err => setError(errorMessage(err)));
  }, [cognitoUser, setError]);

  const goToScanCode = useCallback(() => setStep("scan-code"), [setStep]);

  const handleSubmit = useCallback(
    async (code: string) => {
      try {
        await Auth.verifyTotpToken(cognitoUser, code);
        return onSuccess();
      } catch (err) {
        return errorHandler(err);
      }
    },
    [cognitoUser, onSuccess, errorHandler]
  );

  const { isLoading, action: onSubmit } = useWithAsyncLoadingIndicator(handleSubmit);

  return (
    <>
      {step === "install-authenticator" && <InstallAuthenticatorStep onSubmit={goToScanCode} />}
      {step === "scan-code" && (
        <ScanCodeStep
          authCode={authCode}
          cognitoUser={cognitoUser}
          error={error}
          setError={setError}
          onBackClick={() => setStep("install-authenticator")}
          isSubmitting={isLoading}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export const TotpSetupForm = ({ cognitoUser, onSuccess, onLoginReset, isGuestTpa }: IProps) => {
  const [step, setStep] = useState<Step>("install-authenticator");
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const { t } = useTranslation("pages", { keyPrefix: "auth.login.topSetupForm" });

  const [error, setError] = useState("");

  const goToLogin = useCallback(() => {
    setIsSessionExpired(false);
    onLoginReset();
  }, [onLoginReset, setIsSessionExpired]);

  const errorHandler = useCallback(
    (err: unknown) => {
      if (isPendingLoginExpiredError(err)) {
        setIsSessionExpired(true);
      } else {
        setError(errorMessage(err));
      }
    },
    [setError, setIsSessionExpired]
  );

  return (
    <SecondaryLayout title={t(`steps.${step}.title`)} addonBeforeTitle={t(`steps.${step}.titleBefore${isGuestTpa ? "Tpa" : ""}`)}>
      {isSessionExpired && <SessionExpiredDialog onClose={goToLogin} />}
      <TotpForm
        step={step}
        setStep={setStep}
        cognitoUser={cognitoUser}
        error={error}
        setError={setError}
        onSuccess={onSuccess}
        errorHandler={errorHandler}
      />
    </SecondaryLayout>
  );
};
