import { isDate } from "date-fns";
import { Validations, Errors } from "../types/shared/Validation";
import { FieldChange } from "../types/travel/Policy";
import useScrollToFirstError from "./useScrollToFirstError";
import { useValidation, Validator } from "./useValidation";

/**
 * Represents a field in the form with its associated handlers and its errors, if present
 */
export type FormField<T> = {
  name: string;
  onBlur: () => void;
  onValueChange: (value: T) => void;
  /**
   * String is a textual representation, true is an error without message (eg: blank)
   * @see Errors
   */
  error?: Errors["formField"];
};

type UnknownDataRecord = Record<string, unknown>;
/**
 * Based on the given record, completely created a mimicked structure, and replaces values with a FieldValidator signature
 * Supports nested signatures
 * Dev note: The root has to be a record, if otherwise extending TS does not understand the generic unpacking and makes incorrect unions
 */
type FormSchemaFor<T extends UnknownDataRecord> = {
  // For each key in the record, check whether the value is also a Record
  [key in keyof T]: T[key] extends UnknownDataRecord // If so, nest schema creation
    ? FormSchemaFor<T[key]> // Otherwise, check if the key is an array of Records
    : T[key] extends Array<UnknownDataRecord> // In that case, make an array of nested types
    ? FormSchemaFor<T[key][0]>[] // Otherwise, check if it's another type of array, make an array of FormFields in that case, otherwise a regular FormField
    : T[key] extends Array<infer U>
    ? FormField<U>[]
    : FormField<T[key]>;
};

const isObject = (val: unknown): val is Record<string, unknown> => {
  if (val === null) {
    return false;
  }
  return typeof val === "function" || typeof val === "object";
};

const createPath = (arr: unknown[]) => arr.join(".");

type ChangeHandler = (key: string, value: unknown) => void;
type ValidatorFunction = (name: string, value: unknown) => void;

/**
 * Returns a function that can be used to build a form Schema
 * uses the given validator and formErrors in its closure
 * @param {ValidatorFunction} validator Function to validate the (changed) value for validity
 * @param {Errors} formErrors object containing all errors of the form based on dot separated key
 */
const formSchemaBuilder = (validator: ValidatorFunction, formErrors: Errors) => {
  const createFormField = <T,>(key: string, value: T, path: string, onChangeHandler: (value: T) => void): FormField<T> => {
    return {
      name: key,
      onBlur: () => validator(path, value),
      onValueChange: onChangeHandler,
      error: formErrors[path],
    };
  };

  /**
   * Builds a representation of the form with associated handlers and errors, based on the given data-structure
   * @param {T} data The data to create a form schema for
   * @param {ChangeHandler} onChange Function to call when data changes
   * @param {string[]} path
   * @returns {ValidatorSchemaFor<T>} a validation schema for the given data structure
   */
  const buildFormSchema = <T extends Record<string, unknown>>(data: T, onChange: ChangeHandler, path: string[] = []): FormSchemaFor<T> => {
    const result: Record<string, unknown> = {};
    Object.entries(data).forEach(([key, value]) => {
      const fullPath = createPath([...path, key]);
      const isArrayOfObjects = Array.isArray(value) && value.every(isObject);

      if (isArrayOfObjects) {
        result[key] = value.map((v, index) => buildFormSchema(v, onChange, [...path, key, String(index)]));
      } else if (isObject(value) && !isDate(value)) {
        result[key] = buildFormSchema<typeof value>(value, onChange, [...path, key]);
      } else {
        result[key] = createFormField(key, value, fullPath, (updatedValue: unknown) => onChange(fullPath, updatedValue));
      }
    });
    return result as FormSchemaFor<T>;
  };
  return buildFormSchema;
};

type FormControls<T extends UnknownDataRecord> = Validator & {
  validateForm: (onNext: () => void) => void;
  errors: Errors;
  field: FormSchemaFor<T>;
  handleFieldChange: FieldChange<T>;
};

/**
 * Handles form complexity: Makes a representation of them form, with an onChange handler, blur handler, and access to the field's errors per field, keeping TS typing in mind
 * Keep in mind that this does not handle exceptional cases such as a transient input updating an entire object (eg: PlanPrice)
 * Exposes the root errors and handleFieldChange in case these are required for exceptional circumstances
 * @param {T} data The data structure used in the form
 * @param validations The validation to be applied over the structure
 * @param onFieldChange The root callback to call when updating a field
 * @returns {FormControls<T>} Form controls for use in forms
 * @see FormControls<T>
 */
const useForm = <T extends UnknownDataRecord>(data: T, validations: Validations<T>, onFieldChange: FieldChange<T>): FormControls<T> => {
  const [validator, errors] = useValidation(data, validations);
  const scrollToFirstError = useScrollToFirstError();

  const validateForm = (onNext: () => void) => {
    const valid = validator.validateAll();
    valid ? onNext() : scrollToFirstError();
  };

  const handleFieldChange: FieldChange<T> = (key, value) => {
    onFieldChange(key, value);
    errors[key as string] && validator.validateField(key as string, value);
  };

  const onChange = (key: string, value: unknown) => {
    handleFieldChange(key, value as T[string]);
  };

  const formSchema = formSchemaBuilder(validator.validateField, errors)(data, onChange);

  return {
    ...validator,
    validateForm,
    errors,
    field: formSchema,
    handleFieldChange,
  };
};

export default useForm;
