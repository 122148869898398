import { FC } from "react";
import xOctagon from "../../../assets/icons/x-octagon.svg";
import loader from "../../../assets/icons/loader.svg";
import checkCircle from "../../../assets/icons/check-circle-1.svg";
import { get } from "lodash";

interface StatusProps {
  status: string;
}

const Status: FC<StatusProps> = ({ status }) => {
  const statusDes = ["expired", "pending", "bound", "valid"];
  const imgUrl = [xOctagon, loader, checkCircle];
  const index = statusDes.indexOf(status);

  const statusColoring = {
    expired: "w-[5.25rem] h-6 px-2 py-1 flex flex-col justify-center items-center rounded bg-pink-light text-pink-darker",
    pending: "w-[5.25rem] h-6 px-2 py-1 flex flex-col justify-center items-center rounded bg-blue-lighter text-blue-darker",
    bound: "w-[5.25rem] h-6 px-2 py-1 flex flex-col justify-center items-center rounded  bg-green-light text-green-darkest",
    valid: "w-[5.25rem] h-6 px-2 py-1 flex flex-col justify-center items-center rounded  bg-green-light text-green-darkest",
  };

  return (
    <div className={get(statusColoring, status)}>
      {imgUrl[index] && (
        <div className="flex items-center">
          <img src={imgUrl[index]} alt={status} className="w-3.5 h-3.5" />
          <div className="text-xxs font-bold text-center ml-1">{status === "pending" ? `${status} $` : status}</div>
        </div>
      )}
      {!imgUrl[index] && <div className="text-xxs font-bold text-center">{status}</div>}
    </div>
  );
};

export default Status;
