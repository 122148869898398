import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/basics/Button";
import { Dialog } from "../../../components/interactives";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import { GroupPersonalAccidentFormState } from "../Form/types";
import { useNavigate } from "react-router-dom";

type Props = {
  onClose: VoidFunction;
  state: GroupPersonalAccidentFormState;
};

const BasicDetailsDialog: FC<Props> = ({ onClose, state }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupPersonalAccident.newProduct" });
  const navigate = useNavigate();
  const onConfirm = () => navigate("/products");

  return (
    <Dialog onClose={onClose} title={t("dialog.dialogTitle")}>
      <ul className={"list-disc pl-4"}>
        {state.familyTrust === "no" && <li>{t("basicDetailLabels.familyTrustContent")}</li>}
        {state.occupationEligibility === "no" && <li>{t("basicDetailLabels.occupationHazardContent")}</li>}
      </ul>
      <br></br>
      <p>
        {/* Customer support and contact info */}
        If you need assistance, please contact us at{" "}
        <a className="text-red hover:underline font-bold" href={`mailto:${t("dialog.email")}`} rel="noreferrer" target="_blank">
          {t("dialog.email")}
        </a>{" "}
        from Mondays to Fridays, 8.30am to 5.30pm.
      </p>
      <div className="flex flex-col-reverse md:flex-row-reverse justify-between gap-4 mt-4">
        <Button type={ButtonType.PRIMARY} onClick={onConfirm}>
          {t("dialog.proceed")}
        </Button>
      </div>
    </Dialog>
  );
};
export default BasicDetailsDialog;
