export const discountResponse = {
  type: "object",
  properties: {
    discount: {
      type: "number",
    },
    commission: {
      type: "number",
    },
  },
  required: ["discount"],
  additionalProperties: true,
};
