import { FC } from "react";
import { Carousel } from "../interactives";
import Table from "./Table";

interface PlanTableGroupProps {
  planTables: any[];
}

const PlanTableGroup: FC<PlanTableGroupProps> = ({ planTables }) => (
  <Carousel>
    {planTables.map(table => (
      <div role="listitem" key={table.headers[0].value} tabIndex={-1} className="focus:ring-0">
        <Table data={table} />
      </div>
    ))}
  </Carousel>
);

export default PlanTableGroup;
