import { formatToServerDate } from "../../../utils/date";
import {
  EligibilityState,
  IndemnityFormState,
  PersonalDetailsState,
  TermsConditionsState,
  CheckoutPersonalDetailsState,
  Pricing,
} from "../Form/types";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import { PersonalTitle } from "../../../enums/shared/forms/NewPolicy";
import { Territories } from "../../../enums/shared/forms/Territories";
import { Districts } from "../../../enums/shared/forms/Districts";
import { pick } from "lodash";
import { EMAIL_REGEX } from "../../../constants";

const EMAIL_REGEX_STRING = `${EMAIL_REGEX.source}${EMAIL_REGEX.flags}`;

const eligibilityKeys: (keyof EligibilityState)[] = [
  "hasBeenDeregistered",
  "hasClaims",
  "pendingClaims",
  "refusedRegularPaymentOrProposal",
  "subjectToDisciplinaryActions",
];

type OmitNulls<T extends Record<string, unknown>> = { [key in keyof T]: Omit<T[key], "null"> };

type PIdetails = {
  startDate: string;
  endDate: string;
  eligibility: OmitNulls<EligibilityState>;
  limit: {
    liabilityLimit: number;
  };
  terms: TermsConditionsState;
  pricing: Pricing;
};

export type ProfessionalIndemnity = PIdetails & {
  personalDetails: PersonalDetailsState;
};

export type CheckoutProfessionalIndemnity = PIdetails & {
  personalDetails: CheckoutPersonalDetailsState;
};

const yesNo = {
  type: "boolean",
};

const stringType = {
  type: "string",
};

const nonEmptyStringType = {
  ...stringType,
  minLength: 1,
};

const eligibilitySchema = {
  required: eligibilityKeys,
  type: "object",
  properties: {
    hasBeenDeregistered: yesNo,
    hasClaims: yesNo,
    pendingClaims: yesNo,
    refusedRegularPaymentOrProposal: yesNo,
    subjectToDisciplinaryActions: yesNo,
  },
};

const requiredPersonalDetailsKeys: (keyof PersonalDetailsState)[] = [
  "agentCode",
  "district",
  "districtCode",
  "districtName",
  "email",
  "givenName",
  "mobileNumber",
  "officeLocation",
  "salutation",
  "streetNumberAndName",
  "surname",
  "territory",
];

const personalDetailsSchema = {
  required: requiredPersonalDetailsKeys,
  type: "object",
  properties: {
    address: stringType,
    agentCode: { ...stringType, minLength: 6, maxLength: 6 },
    building: stringType,
    district: {
      ...stringType,
      enum: Object.values(Districts).filter(Boolean),
    },
    districtCode: { ...stringType, minLength: 5, maxLength: 5 },
    districtName: nonEmptyStringType,
    email: { ...stringType, pattern: EMAIL_REGEX_STRING },
    givenName: nonEmptyStringType,
    mobileNumber: { ...stringType, minLength: 8, maxLength: 8 },
    officeLocation: nonEmptyStringType,
    otherNames: stringType,
    salutation: {
      ...stringType,
      enum: Object.values(PersonalTitle).filter(Boolean),
    },
    streetNumberAndName: nonEmptyStringType,
    surname: nonEmptyStringType,
    territory: {
      ...stringType,
      enum: Object.values(Territories).filter(Boolean),
    },
  },
};

const schema = {
  required: ["endDate", "startDate", "eligibility", "limit"],
  type: "object",
  properties: {
    endDate: {
      type: "string",
      format: "date",
    },
    startDate: {
      type: "string",
      format: "date",
    },
    eligibility: eligibilitySchema,
    limit: {
      type: "object",
      required: ["liabilityLimit"],
      properties: {
        liabilityLimit: {
          type: "number",
          enum: [3_500_000, 7_500_000, 12_500_000],
        },
      },
    },
    personalDetails: personalDetailsSchema,
    terms: {
      type: "object",
      required: ["accepted", "promotionalServices"],
      properties: {
        accepted: {
          const: true,
        },
        promotionalServices: {
          type: "boolean",
        },
      },
    },
  },
};
const ajv = new Ajv();
addFormats(ajv);

function validatePayload(payload: Record<string, unknown>): asserts payload is ProfessionalIndemnity {
  const compiledSchema = ajv.compile(schema);
  ajv.validate(schema, payload);
  if (compiledSchema.errors) {
    console.error(compiledSchema.errors);
    throw new Error("Given payload was incorrect");
  }
}

// It's not allowed to proceed with a "yes" answer so that can't happen
const convertYesNo = (value: unknown) => (value === "no" ? false : null);

const yesNoToBoolean = (payload: Record<string, unknown>): Record<string, boolean> =>
  Object.entries(payload).reduce<Record<string, boolean>>((result, [key, value]) => Object.assign(result, { [key]: convertYesNo(value) }), {});

/**
 * Constructs a professional indemnity object from the given form state.
 * Validates the properties to see whether they satisfy the backend contract, throws an error otherwise
 * @param state The form state
 * @returns a professional indemnity object
 * @throws Error object
 */
const professionalIndemnity = (state: IndemnityFormState): ProfessionalIndemnity => {
  const indemnityPayload = {
    startDate: formatToServerDate(state.coverageStartDate),
    endDate: formatToServerDate(state.coverageEndDate),
    eligibility: yesNoToBoolean(pick(state, eligibilityKeys)),
    limit: {
      liabilityLimit: state.limit,
    },
    personalDetails: pick(state, [...requiredPersonalDetailsKeys, "address", "building", "otherNames"]),
    terms: {
      accepted: state.accepted,
      promotionalServices: state.promotionalServices,
    },
    pricing: {
      gp: state.gp,
      levy: state.levy,
      total: state.total,
    },
  };
  validatePayload(indemnityPayload);
  return indemnityPayload;
};

export default professionalIndemnity;
