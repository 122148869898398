import { PropsWithChildren } from "react";
import cls from "../../utils/cls";

const LiabilitySection = ({
  name,
  className,
  children,
  headClass,
  hasBorder = true,
}: PropsWithChildren<{ name: string; className: string; headClass?: string; hasBorder?: boolean }>) => {
  return (
    <div key={name}>
      <div className={cls(headClass, "flex justify-between mb-2")}>
        <p className={cls(className, "font-bold")}>{name}</p>
      </div>
      {hasBorder && <hr className="border-grey-light" />}
      {children}
    </div>
  );
};

export default LiabilitySection;
