import { FC, PropsWithChildren } from "react";
import { FormGroup } from "../../../components/forms";
import CustomRadioInput from "../../../components/interactives/CustomRadioInput";

type AdditionalDetailsOptionsProps = {
  value: "yes" | "no" | null;
  onValueChange: (value: "yes" | "no" | null) => void;
  formLabel: string;
  label: string;
  error?: string | boolean | null;
  onMoreInfo?: () => void;
  moreInfoStyle?: string;
};

const AdditionalDetailsOptions: FC<PropsWithChildren<AdditionalDetailsOptionsProps>> = ({
  value,
  error,
  onValueChange,
  formLabel,
  label,
  children,
  onMoreInfo,
  moreInfoStyle,
}) => (
  <div className="flex justify-between flex-wrap gap-x-16">
    <FormGroup title="" fullWidth onMoreInfo={onMoreInfo} className={`flex md:flex-col-reverse flex-shrink max-w-[34.5rem] ${moreInfoStyle}`}>
      <p className="font-normal md:text-xl mr-4">{label}</p>
      {children}
    </FormGroup>

    <div className="min-w-[12rem] md:max-w-[12rem] flex-1">
      <CustomRadioInput error={error} formLabel={formLabel} onValueChange={onValueChange} value={value} />
    </div>
  </div>
);

export default AdditionalDetailsOptions;
