import { useMatch } from "react-router-dom";
import clientApiService from "../services/travel/new-policy/clientApiService";
import { useEffect, useState } from "react";
import { get } from "lodash";

type ClientIdData = {
  isValidId: boolean;
  isLoading: boolean;
};

const useIdFromUrl = () => {
  return get(useMatch("/client/:id/*"), "params.id", "") as string;
};

const validateId = (id: string) => {
  return clientApiService.checkClientId(id);
};

export const useClientId = () => {
  return useIdFromUrl();
};

export const useValidClientId = () => {
  const [clientIdData, setClientIdData] = useState<ClientIdData>({ isValidId: false, isLoading: true });

  const id = useClientId();

  useEffect(() => {
    validateId(id)
      .then(result => {
        setClientIdData({
          isValidId: result,
          isLoading: false,
        });
      })
      .catch(() => {
        setClientIdData({
          isValidId: false,
          isLoading: false,
        });
      });
  }, [id]);

  if (clientIdData.isLoading) return null;
  return clientIdData.isValidId;
};
