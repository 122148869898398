export enum States {
  EMPTY = "",
  AUSTRALIAN_CAPITAL_TERRITORY = "Australian Capital Territory",
  NEW_SOUTH_WALES = "New South Wales",
  NORTHERN_TERRITORY = "Northern Territory",
  QUEENSLAND = "Queensland",
  SOUTHERN_AUSTRALIA = "Southern Australia",
  TASMANIA = "Tasmania",
  VICTORIA = "Victoria",
  WESTERN_AUSTRALIA = "Western Australia",
}
