import { NavigateFunction, useLocation, useMatch, useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createNavigationSteps, getFirstInvalidPageIndex } from "../../../helpers/shared/navigation";
import { ReferralDetails, NewTravelPolicyState, NewTravelPolicyStateSliceChange } from "../../../types/travel/Policy";
import { createInitialNewTravelPolicyState } from "../../../helpers/travel/newTravelPolicyInitialization";
import NewPolicyRoutes, { ROUTES } from "./NewPolicyRoutes";
import { NavigationIndex } from "../../../types/shared/Navigation";
import updateNewTravelPolicyState from "../../../helpers/travel/updateNewTravelPolicyState";
import TravelHeader from "../TravelHeader";
import ThankYouDialog from "./dialog/ThankYouDialog";
import { travelStepValidations } from "../../../helpers/travel/pageValidations";
import { clearSessionStorage } from "../../../helpers/shared/sessionStorage";
import FormStep from "../../../components/forms/FormStep";
import { useClientId } from "../../../hooks/useClientId";
import { parseState, serializeState } from "../../../helpers/shared/serialization";
import createReferral from "../../../helpers/travel/new-policy/api/createReferral";
import useSessionId from "../../../hooks/useSessionId";
import { CheckoutResponse } from "../../../types/travel/new-policy/api/Checkout";
import { createQuote } from "../../../helpers/travel/new-policy/api/quoteApi";
import { checkout } from "../../../helpers/travel/new-policy/api/checkoutApi";
import TravelFooter from "../TravelFooter";

const submit = async (flowState: NewTravelPolicyState, clientId?: string) => {
  try {
    const quote = await createQuote(flowState, clientId);
    const response = await checkout(quote, clientId);
    response.quote_id = quote.quoteId;
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const determineUserPath = (clientId: string) => {
  return clientId ? `client/${clientId}/travel/:step` : "travel/:step";
};

const createTravelQuoteKey = (sessionId: string): `travelQuote${string}` => `travelQuote${sessionId}`;

const getTravelLink = (clientId: any) => {
  return clientId ? `/client/${clientId}/travel` : "/";
};
const NewPolicy = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigationSteps = createNavigationSteps(ROUTES);
  const sessionId = useSessionId();
  const sessionStorageKey = createTravelQuoteKey(sessionId);
  const sessionFlowState = sessionStorage[sessionStorageKey];
  const [flowState, setFlowState] = useState<NewTravelPolicyState>(
    sessionFlowState ? (parseState(sessionFlowState) as NewTravelPolicyState) : createInitialNewTravelPolicyState()
  );
  const [stepIndex, setStepIndex] = useState(NavigationIndex.DETAILS);
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const clientId = useClientId();
  const travelLink = getTravelLink(clientId);
  const matchPattern = determineUserPath(clientId);
  const match = useMatch(matchPattern);
  const currentStep = navigationSteps[stepIndex].stepNumber;
  const totalSteps = 5;
  const resetState = useCallback(() => {
    clearSessionStorage(sessionStorageKey);
    setFlowState(createInitialNewTravelPolicyState());
  }, [setFlowState, sessionStorageKey]);

  useEffect(() => {
    if (match?.params.step) {
      if (!ROUTES.includes(match.params.step)) {
        setStepIndex(NavigationIndex.DETAILS);
        navigate(navigationSteps[NavigationIndex.DETAILS].route);
      }
      const currentNavigationStep = navigationSteps.find(step => step.route === match.params.step);
      setStepIndex(currentNavigationStep ? currentNavigationStep.stepIndex : NavigationIndex.DETAILS);
    }
  }, [location, navigate, navigationSteps, match]);

  useEffect(() => {
    const pageValidations = travelStepValidations(flowState);
    const firstInvalidStep = getFirstInvalidPageIndex(stepIndex, pageValidations);
    if (firstInvalidStep !== null && firstInvalidStep !== stepIndex) {
      setStepIndex(firstInvalidStep);
      navigate(navigationSteps[firstInvalidStep].route);
    }
  }, [stepIndex, flowState, navigate, navigationSteps]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  useEffect(() => {
    sessionStorage.setItem(sessionStorageKey, serializeState(flowState));
  }, [flowState, sessionStorageKey]);

  const onFieldChange: NewTravelPolicyStateSliceChange = (key, sliceKey, value) => {
    setFlowState(prevState => updateNewTravelPolicyState(prevState, key, sliceKey, value));
  };

  const navigateStep = (onNavigate: NavigateFunction, index: NavigationIndex) => () => {
    const step = navigationSteps[index];
    step && onNavigate(step.route);
  };

  const navigateToPayment = async (response: CheckoutResponse) => {
    if (clientId) {
      window.location.href = response.url;
    } else {
      navigate("payment-link-sent");
    }
  };

  const onBack = navigateStep(navigate, stepIndex - 1);
  const onNext = navigateStep(navigate, stepIndex + 1);
  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await submit(flowState, clientId);
      navigateToPayment(response);
      return response;
    } finally {
      setIsSubmitting(false);
    }
  };
  const onReferral = async (details: ReferralDetails) => {
    return createReferral(clientId, details, flowState).then(() => {
      setShowThankYouDialog(true);
      clearSessionStorage(sessionStorageKey);
    });
  };

  const onClose = () => {
    setShowThankYouDialog(false);
    navigate(travelLink);
  };

  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy" });

  return (
    <main>
      <form>
        <div className="flex flex-col min-h-screen gap-0">
          <TravelHeader />
          {currentStep <= totalSteps && <FormStep current={currentStep} total={totalSteps} />}
          <NewPolicyRoutes
            flowState={flowState}
            onFieldChange={onFieldChange}
            isSubmitting={isSubmitting}
            onNext={onNext}
            onBack={onBack}
            onSubmit={onSubmit}
            resetState={resetState}
            onReferral={onReferral}
          />
          {showThankYouDialog && <ThankYouDialog onClose={onClose} thankYou={t} />}
          <TravelFooter />
        </div>
      </form>
    </main>
  );
};

export default NewPolicy;
