import { AriaAttributes, FC, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import cls from "../../utils/cls";
import useDelayedUnmount from "../../hooks/useDelayedUnmount";
import FocusTrap from "focus-trap-react";

interface IProps {
  onClose: () => void;
  className?: string;
  overlayClassName?: string;
}

type AnimationInfoProps = {
  onAnimationEnd?: VoidFunction;
  isAnimating?: boolean;
};

interface IAnimatedProps extends IProps {
  show: boolean;
}

export const AnimatedSidebar: FC<React.PropsWithChildren<IAnimatedProps>> = ({ children, show, className, overlayClassName, ...props }) => {
  const sm = window.innerWidth < 768; // since sm is not listened to, you may see a jarring effect when switching to a higher width and then tabbing during the now shown animations
  const mount = useDelayedUnmount(show, sm ? 0 : 200);
  const [isAnimating, setIsAnimating] = useState(!sm);
  const signalAnimationEnded = useCallback(() => {
    setIsAnimating(false);
  }, [setIsAnimating]);

  useEffect(() => {
    if (!mount) {
      // Reset initial state
      setIsAnimating(!sm);
    }
  }, [mount, sm]);

  if (!mount) {
    return null;
  }

  return (
    <Sidebar
      overlayClassName={cls(overlayClassName, show ? "animate-duration-300 md:fade-in" : "animate-duration-200 md:fade-out")}
      className={cls(className, show ? "animate-duration-300 md:slide-from-right" : "animate-duration-200 md:slide-to-right")}
      isAnimating={isAnimating}
      onAnimationEnd={signalAnimationEnded}
      {...props}
    >
      {children}
    </Sidebar>
  );
};

export const Sidebar: FC<React.PropsWithChildren<IProps & AnimationInfoProps & AriaAttributes>> = ({
  children,
  className,
  overlayClassName,
  onClose,
  onAnimationEnd,
  isAnimating,
  ...rest
}) => {
  return createPortal(
    <FocusTrap active={!isAnimating} focusTrapOptions={{ initialFocus: false, returnFocusOnDeactivate: true, escapeDeactivates: false }}>
      <aside
        {...rest}
        data-animating={isAnimating}
        aria-label="sidebar"
        className={cls("fixed z-50 inset-0 bg-black bg-opacity-80 overflow-y-auto overflow-x-hidden h-full w-full m-auto", overlayClassName)}
        onClick={onClose}
        onAnimationEnd={onAnimationEnd}
      >
        <div
          className={cls("bg-white pt-16", "h-screen md:w-[30rem] md:m-0 md:absolute md:top-0 md:right-0", className)}
          onClick={event => event.stopPropagation()}
        >
          <button
            className="!absolute icon-6 icon-left-0 icon-top-0 top-8 right-8 icon-close icon-red cursor-pointer hover:icon-red-dark focus:icon-red-dark"
            onClick={onClose}
            aria-label="Close"
          />
          {children}
        </div>
      </aside>
    </FocusTrap>,
    document.body
  );
};
