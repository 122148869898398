import { liabilityFormState } from "./types";

/**
 * Loads the initial form state
 * Allows passing already known values to override values. This way we can load persisted values and add new properties without breaking persisted state
 */
const createInitialState = (persistedState: Partial<liabilityFormState> = {}): liabilityFormState => {
  return {
    proposerName: "",
    eventTitle: "",
    eventType: null,
    duration: 1,
    attendees: null,
    drone: null,
    setupAndDismantling: null,
    volunteers: null,
    plans: [],
    quoteId: "",
    quoteNo: "",
    validityDate: "",
    createdAt: "",
    status: "",
    agentEmail: "",
    accepted: false,
    marketingVoiceCall: false,
    marketingTextMessage: false,
    ...persistedState,
  };
};

export default createInitialState;
