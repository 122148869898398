import PrimaryLayout from "../../../components/authentication/layout/PrimaryLayout";
import AgentFormDialog from "../../travel/new-policy/dialog/AgentFormDialog";
import { AgentResetType } from "./ResetForm";
import ResetInputForm from "./ResetInputForm";

type ResetLayoutFormProps = {
  isLoading: boolean;
  hasErrors: boolean;
  errorMessageForReset?: string;
  errors: {
    resetEmail?: string;
  };
  resetFormState: AgentResetType;
  field: any;
  validateField: (name: string, value: unknown, newData?: Record<string, unknown>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  resetSuccess: boolean;
  onClose: () => void;
};

const ResetLayoutForm = (props: ResetLayoutFormProps) => {
  return (
    <PrimaryLayout title="Account Reactivation">
      <form onSubmit={props.onSubmit}>
        <ResetInputForm
          isLoading={props.isLoading}
          hasErrors={props.hasErrors}
          errorMessageForReset={props.errorMessageForReset}
          errors={props.errors}
          resetFormState={props.resetFormState}
          field={props.field}
          validateField={props.validateField}
          onClose={props.onClose}
        />
        {/* {sending all the props} */}
      </form>
      {props.resetSuccess && <AgentFormDialog onClose={props.onClose} message={"Account reset successful."} />}
    </PrimaryLayout>
  );
};

export default ResetLayoutForm;
