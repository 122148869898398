import { useTranslation } from "react-i18next";
import { Dialog } from "../../../components/interactives";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import { Button } from "../../../components/basics/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "../../../components/basics";

interface IProps {
  onCancel: () => void;
  showLink?: boolean;
}

const ReferralDialog = ({ onCancel, showLink }: IProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct" });
  const handlerHomeBtn = () => navigate("/products");

  const navigate = useNavigate();
  return (
    <Dialog onClose={onCancel} title={t("referral.dialogTitle")}>
      {/* {true && <ServerError supportEmailId={t("customerSupport.emailId")} />} */}
      <p>{t("referral.content")}</p>
      <div className="flex flex-col-reverse md:flex-row-reverse justify-between gap-4 mt-4">
        <Button type={ButtonType.PRIMARY} onClick={handlerHomeBtn} htmlType="button">
          {t("referral.home")}
        </Button>
        {showLink && (
          <Link to={"/au/group_travel/retrieve-list"} type={ButtonType.SECONDARY}>
            {t("referral.listing")}
          </Link>
        )}
      </div>
    </Dialog>
  );
};

export default ReferralDialog;
