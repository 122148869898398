import { useEffect, useRef } from "react";
import { Data, Validations } from "../types/shared/Validation";
import { validate } from "../helpers/shared/validation";
import { useNavigate } from "react-router-dom";

export const useStepNavigation = <T>(step: number, stepValidations: Validations<T>[], state: T, stepsURLS: string[]) => {
  const previousStep = useRef<number>(-1);
  const navigate = useNavigate();
  const findFirstInvalidPage = (validations: Validations<unknown>[], formState: T): number =>
    validations.findIndex(validationSchema => Object.keys(validate(formState as Data, validationSchema)).length > 0);
  useEffect(() => {
    if (previousStep.current === step) return;
    previousStep.current = step;

    const validationsToRun = stepValidations.slice(0, step + 1);
    const errorIndex = findFirstInvalidPage(validationsToRun, state);
    if (errorIndex > -1 && step !== errorIndex) {
      navigate(stepsURLS[errorIndex], { replace: true });
    }
  }, [step, state, navigate]);
};
