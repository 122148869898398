import { VFC } from "react";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import { Button, LoadingButton } from "../basics/Button";

type AuthenticationFlowActionProps = {
  isSubmitting?: boolean;
  nextLabel: string;
  onBack?: VoidFunction | null;
};

const AuthenticationFlowActions: VFC<AuthenticationFlowActionProps> = ({ isSubmitting, nextLabel, onBack }) => (
  <div className="flex flex-col">
    <LoadingButton isLoading={isSubmitting || false} loadingText="Logging in" type={ButtonType.PRIMARY}>
      {nextLabel}
    </LoadingButton>
    {onBack && (
      <Button htmlType="button" onClick={onBack} type={ButtonType.CENTERED_LINK}>
        Back
      </Button>
    )}
  </div>
);

export default AuthenticationFlowActions;
