import cls from "../../../utils/cls";
import logo from "../../../assets/images/logo.svg";
import logoLg from "../../../assets/images/logo-lg.svg";
import { FC } from "react";
import { useClientId } from "../../../hooks/useClientId";
import Navbar from "./header/Navbar";

interface IProps {
  home: string;
  hideAccount?: boolean;
  className?: string;
  logoLink?: string;
}

const Header: FC<React.PropsWithChildren<IProps>> = ({ home, hideAccount, className, children, logoLink, ...props }) => {
  const clientId = useClientId();
  const homeLink = logoLink || (clientId ? `/client/${clientId}/${home}` : `${home}`);

  return (
    <header className="flex justify-between container-lg bg-white shadow-2xl z-20">
      <a href={homeLink} className={`h-16 md:h-24 py-4 flex-shrink-0 mr-12 ${home ? "pointer-events-auto" : "pointer-events-none"}`}>
        <picture>
          <source media="(min-width:768px)" srcSet={logoLg} />
          <img src={logo} alt="Allied World logo" className="h-full" />
        </picture>
      </a>
      <div className={cls("flex h-16 md:h-24", className)} {...props}>
        {hideAccount && children}
        {!hideAccount && <Navbar />}
      </div>
    </header>
  );
};

export default Header;
