/**
 * Serializes state into a format readable by the browser storage
 * Next to default conversion, converts date objects to their json representation
 * @param {Record<string, unknown>} state The state to serialize
 * @returns The serialized state as string
 */
export const serializeState = (state: Record<string, unknown>): string => {
  return JSON.stringify(state);
};

const isoStringRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

/**
 * Parses a given serialized state string to javascript objects.
 * Next to default conversion, also converts JSON dates to Date objects
 * @param {string} state The state to parse
 * @returns The javascript object representation of the given serialized string
 */
export const parseState = (state: string): Record<string, unknown> => {
  return JSON.parse(state, (_, value) => (typeof value === "string" && value.match(isoStringRegex) ? new Date(value) : value));
};
