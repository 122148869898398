//The data is obtained from AW-Singapore-Business-Protector-Travel-Protector.pdf
import { getPlanLabel } from "./labels";
import { formatToTwoDecimals, formatToTwoDecimalsWithThousandSeparator } from "../shared/format";
import { Plans } from "../../types/group_travel/api/Pricing";

const groupTravelPriceTableLabels = [
  "Overseas Medical & Evacuation Expenses",
  "Loss Of Deposits",
  "Cancellation & Curtailment",
  "Loss of Luggage",
  "Portable Electronic Equipment",
  "Accidental Death",
  "Temporary Total Disablement",
  "Rental Vehicle",
  "Kidnap, Ransom & Extortion",
  "Political and Natural Disaster Evacuation",
  "Alternative Employee or Resumption of Journey",
];

const groupTravelPriceTableRows = [
  [
    // values for silver plans
    { value: `Unlimited \n (up to 24 months)` },
    { value: "AUD 30,000" },
    { value: "Unlimited" },
    { value: "AUD 20,000" },
    { value: "AUD 10,000" },
    { value: `AUD \n 250,000` },
    { value: "AUD 2,500" },
    { value: "AUD 10,000" },
    { value: "AUD 500,000" },
    { value: "AUD 50,000" },
    { value: "AUD 25,000" },
  ],
  [
    /*values for gold plans */
    { value: `Unlimited \n (up to 24 months)` },
    { value: "AUD 30,000" },
    { value: "Unlimited" },
    { value: "AUD 30,000" },
    { value: "AUD 15,000" },
    { value: `AUD \n 500,000` },
    { value: "AUD 3,500" },
    { value: "AUD 10,000" },
    { value: "AUD 500,000" },
    { value: "AUD 50,000" },
    { value: "AUD 25,000" },
  ],
  /*values for platinum plans */
  [
    { value: `Unlimited \n (up to 24 months)` },
    { value: "AUD 50,000" },
    { value: "Unlimited" },
    { value: "AUD 30,000" },
    { value: "AUD 15,000" },
    { value: `AUD \n 1,000,000` },
    { value: "AUD 4,500" },
    { value: "AUD 15,000" },
    { value: "AUD 500,000" },
    { value: "AUD 50,000" },
    { value: "AUD 25,000" },
  ],
];

const injuryOnlyPriceTableLabels = [
  "Weekly Injury Benefit",
  "Temporary Total Disablement",
  "Temporary Partial Disablement",
  "Percentage of Income",
  "Waiting Period",
  "Benefit Period",
];

const sicknessOnlyPriceTableLabels = [
  "Weekly Sickness Benefit",
  "Temporary Total Disablement",
  "Temporary Partial Disablement",
  "Percentage of Income",
  "Waiting Period",
  "Benefit Period",
];

const priceTableLabelsForInjury = ["Accidental Death & Disablement", ...injuryOnlyPriceTableLabels];

const priceTableLabelsForInjuryAndSickness = ["Accidental Death & Disablement", ...injuryOnlyPriceTableLabels, ...sicknessOnlyPriceTableLabels];

const injuryOnlyPriceRows1 = [
  { value: "" },
  { value: "AUD 2,000" },
  { value: "AUD 2,000" },
  { value: "90%" },
  { value: "7 days" },
  { value: "104 weeks" },
];

const injuryOnlyPriceRows2 = [
  { value: "" },
  { value: "AUD 2,500" },
  { value: "AUD 2,500" },
  { value: "90%" },
  { value: "7 days" },
  { value: "104 weeks" },
];

const injuryOnlyPriceRows3 = [
  { value: "" },
  { value: "AUD 3,000" },
  { value: "AUD 3,000" },
  { value: "90%" },
  { value: "7 days" },
  { value: "104 weeks" },
];

const sicknessOnlyPriceRows1 = [
  { value: "" },
  { value: "AUD 2,000" },
  { value: "AUD 2,000" },
  { value: "90%" },
  { value: "7 days" },
  { value: "104 weeks" },
];

const sicknessOnlyPriceRows2 = [
  { value: "" },
  { value: "AUD 2,500" },
  { value: "AUD 2,500" },
  { value: "90%" },
  { value: "7 days" },
  { value: "104 weeks" },
];

const sicknessOnlyPriceRows3 = [
  { value: "" },
  { value: "AUD 3,000" },
  { value: "AUD 3,000" },
  { value: "90%" },
  { value: "7 days" },
  { value: "104 weeks" },
];

const priceRowForInjury1 = [{ value: "AUD 100,000" }, ...injuryOnlyPriceRows1];
const priceRowForInjury2 = [{ value: "AUD 100,000" }, ...injuryOnlyPriceRows2];
const priceRowForInjury3 = [{ value: "AUD 100,000" }, ...injuryOnlyPriceRows3];

const priceRowsForInjury = [priceRowForInjury1, priceRowForInjury2, priceRowForInjury3];

const priceRowForInjuryAndSickness1 = [{ value: "AUD 100,000" }, ...injuryOnlyPriceRows1, ...sicknessOnlyPriceRows1];
const priceRowForInjuryAndSickness2 = [{ value: "AUD 100,000" }, ...injuryOnlyPriceRows2, ...sicknessOnlyPriceRows2];
const priceRowForInjuryAndSickness3 = [{ value: "AUD 100,000" }, ...injuryOnlyPriceRows3, ...sicknessOnlyPriceRows3];

const priceRowsForInjuryAndSickness = [priceRowForInjuryAndSickness1, priceRowForInjuryAndSickness2, priceRowForInjuryAndSickness3];

const getPriceTables = (tableRows: { value: string }[][]) => {
  return tableRows.map(tableRow => {
    const tableContent = {
      header: { label: "", value: "" },
      rows: tableRow,
    };
    return tableContent;
  });
};

const groupTravelPriceTables: Table[] = getPriceTables(groupTravelPriceTableRows);
const defaultPriceTablesForInjuryAndSickness: Table[] = getPriceTables(priceRowsForInjuryAndSickness);
const defaultPriceTablesForInjury: Table[] = getPriceTables(priceRowsForInjury);
export interface ITable {
  headers: ICell[];
  rows: IRows[];
}

interface IRows {
  cells: ICell[];
}

interface ICell {
  value: string;
}

interface Table {
  header: TableHeader;
  rows: TableRow[];
}

interface TableHeader {
  label: string;
  value: string;
}

interface TableRow {
  value: string;
}

export const formatValue = (value: number) => {
  const toDecimal = Number(formatToTwoDecimals(value));
  return formatToTwoDecimalsWithThousandSeparator(toDecimal);
};

const getTablesByCoverage = (coverageType?: "Injury Only" | "Injury and Sickness" | "groupTravel" | null) => {
  let priceTable = [] as Table[],
    priceTableLabel = [] as string[];
  if (coverageType === "groupTravel") {
    priceTable = groupTravelPriceTables;
    priceTableLabel = groupTravelPriceTableLabels;
  }
  if (coverageType === "Injury Only") {
    priceTable = defaultPriceTablesForInjury;
    priceTableLabel = priceTableLabelsForInjury;
  }
  if (coverageType === "Injury and Sickness") {
    priceTable = defaultPriceTablesForInjuryAndSickness;
    priceTableLabel = priceTableLabelsForInjuryAndSickness;
  }
  return { priceTable, priceTableLabel };
};
export const createTravelPlanPriceTable = (plans: Plans, coverageType: "Injury Only" | "Injury and Sickness" | "groupTravel" | null) => {
  const tables: ITable[] = [];
  const { priceTable, priceTableLabel } = getTablesByCoverage(coverageType);
  priceTable.forEach((priceRow, index) => {
    const rows = priceRow.rows.map((row, rowIndex) => ({
      cells: [{ value: priceTableLabel[rowIndex] }, { value: row.value }],
    }));

    tables.push({
      headers: [{ value: getPlanLabel(plans[index]?.id) }, { value: `AUD ${formatValue(plans[index]?.total)}` }],
      rows: rows,
    });
  });
  return tables;
};
