const QuoteListSchema = {
  type: "object",
  properties: {
    policyNumber: { type: "string" },
    email: { type: "string" },
    status: { type: "string" },
    mailStatus: { type: "string" },
    title: { type: "string" },
    firstName: { type: "string" },
    lastName: { type: "string" },
    productType: { type: "string" },
    agentCode: { type: "string" },
    quoteId: { type: "string" },
    agentEmail: { type: "string" },
    intermediaryName: { type: "string" },
    clientId: { type: "string" },
    branch: { type: "string" },
    updatedAt: { type: "string" },
  },
};

export const quoteListResponse = {
  type: "array",
  properties: {
    items: QuoteListSchema,
  },
  required: ["policyNumber", "status"],
  additionalProperties: true,
};
