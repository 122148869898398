import { Button } from "../../components/basics/Button";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import { useNavigate } from "react-router-dom";
import { LoginState, useAuth } from "../../hooks/useAuth";

interface SuccessBackButtonProps {
  backButtonText: string;
}

const SuccessBackButton: React.FC<SuccessBackButtonProps> = ({ backButtonText }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const hideButton = user === LoginState.LoggedOut || user === LoginState.Unknown;

  return !hideButton ? (
    <div className="justify-end container-flow md:py-8 md:mt-[2rem]">
      <div className="md:flex md:justify-end md:mb-0 mb-16">
        <Button type={ButtonType.PRIMARY} onClick={() => navigate("/products")}>
          {backButtonText}
        </Button>
      </div>
    </div>
  ) : (
    <div className="md:mt-[2rem]"></div>
  );
};

export default SuccessBackButton;
