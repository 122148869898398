import { Article } from "../../../components/groups";

const Extensions = () => (
  <Article>
    <p className="font-bold md:pt-8 pt-4 text-xl">Automatic Extensions</p>
    <p className="font-bold mt-6 mb-2">Extensions:</p>
    <ol className="list-decimal">
      <li>Sudden and Accidental Pollution (72 hours)</li>
      <li>
        Care, Custody and Control Extension (Sublimit: SGD 100,000 per occurrence and in the aggregate; Deductible: SGD 2,500 on each and every claim)
      </li>
      <li>Food Poisoning Clause (Sublimit: SGD 100,000 per occurrence and in the aggregate)</li>
      <li>Host Liquor Liability (Sublimit: SGD 100,000 per occurrence and in the aggregate)</li>
      <li>Guest Effects (Sublimit: SGD 1,500 per guest and SGD 150,000 in the aggregate)</li>
      <li>Premises Medical Payment (Sublimit: SGD 1,500 per person; SGD 150,000 in the aggregate)</li>
      <li>Loading and Unloading</li>
      <li className="md:pb-13 pb-5">Non-Owned &amp; Hired Automobile Liability (Sublimit: SGD 1,000,000 per occurrence and in the aggregate)</li>
    </ol>
  </Article>
);

export default Extensions;
