import { ProductType } from "../../../enums/shared/api/Api";
import agentApiService from "./../../../services/event_public_liability/agentApiService";

export const fetchQuoteList = async (payload: { productType: string }) => {
  const data = await agentApiService.getQuoteList(payload);
  return data;
};

export const getUenInfo = async (payload: { uenNumber: string; productType: ProductType.CLV | ProductType.APL }) => {
  const data = await agentApiService.getUenInfo(payload);
  return data;
};
