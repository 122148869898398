import { Dispatch, FC, SetStateAction, useState } from "react";
import Status from "./status";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CreateEventQuoteResponse } from "../../../types/event_public_liability/api/Quote";
import { formatToDisplayQuoteDate } from "../../../utils/date";
import { Region } from "../../../enums/shared/forms/Region";
import Spinner from "../../../components/icons/Spinner";
import { updateQuotesGst } from "../../../helpers/event_public_liability/api/quoteApi";
import { textEllipsis } from "../../../helpers/shared/textEllipsis";
import { ArrowCell } from "../../../components/basics/ArrowCell";
import { ProductType } from "../../../enums/shared/api/Api";

interface TableProps {
  rows: CreateEventQuoteResponse[] | [];
  region: string;
}

const boundStatus = (status: string) => {
  return status === "paid" || status === "processed" || status === "bound";
};

const getRowNavigationLink = (region: string) => {
  return region === Region.HK ? "/event_public_liability/hk/bind/quoteDetails" : "/event_public_liability/bind/quoteDetails";
};

const documentUrl: Partial<Record<ProductType, string>> = {
  [ProductType.CLV]: `/event_public_liability/hk/documents`,
  [ProductType.APL]: `/event_public_liability/documents`,
};
const handleRowClick = async (
  row: CreateEventQuoteResponse,
  setLoader: Dispatch<SetStateAction<boolean>>,
  navigate: NavigateFunction,
  region: string
) => {
  if (row.status === "valid") {
    setLoader(true);
    const data = row.productType === ProductType.APL ? await updateQuotesGst(row.quoteId) : row;
    setLoader(false);
    navigate(getRowNavigationLink(region), { state: data });
  }
  if (boundStatus(row.status)) {
    navigate(`${documentUrl[row.productType as ProductType]}`, { state: { policyNumber: row.policyNumber, productCode: row.productType } });
  }
};

const QuoteTable: FC<React.PropsWithChildren<TableProps>> = ({ rows, region }) => {
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();

  return (
    <span>
      {isLoader ? (
        <div className="py-32 flex justify-around">
          <Spinner width="100" height="100" color="#3F9293"></Spinner>
        </div>
      ) : (
        <table className="flex flex-col table-shadow overflow-hidden mt-8">
          <thead className="px-8">
            <tr className="flex justify-between pt-2 pb-2 text-left md:text-[0.625rem] text-[0.7rem] th-text-color leading-3">
              <th className="w-[5.125rem] md:w-60 shrink-1">CLIENT NAME</th>
              <th className="w-[5.125rem] md:w-60 shrink-1 hidden md:block">EVENT NAME </th>
              <th className="w-[9rem] hidden md:block shrink-0">QUOTE/POLICY NO.</th>
              <th className="w-[5.315rem] md:hidden shrink-0">QUOTE/POLI..</th>
              <th className="w-[5.25rem] hidden md:block shrink-0">VALIDITY DATE</th>
              <th className="w-[5.25rem] shrink-0">STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.length <= 0 ? (
              <tr className="flex justify-between px-8 py-2 text-sm bg-white border-b border-color">
                <td className="font-bold md:text-left">No Record Found!</td>
              </tr>
            ) : (
              rows.map((row, index) => (
                <tr
                  key={index}
                  className={`flex justify-between  px-8 py-2 text-sm bg-white border-b border-color ${
                    row.status === "valid" || boundStatus(row.status) ? "hover:shadow-hover cursor-pointer" : ""
                  }`}
                  onClick={() => handleRowClick(row, setIsLoader, navigate, region)}
                >
                  <td className="w-[5.125rem] md:w-60 shrink-1 md:text-left whitespace-nowrap">
                    <span className="hidden md:block">{textEllipsis(row.proposerName, 28, 2)}</span>
                    <span className="md:hidden">{textEllipsis(row.proposerName, 9, 2)}</span>
                  </td>

                  <td className="w-[5.125rem] md:w-60 shrink-1 hidden md:block">{textEllipsis(row.eventTitle, 24, 2)}</td>

                  <td className="w-[5.315rem] md:w-[9rem] shrink-0">
                    <span className="hidden md:block">{boundStatus(row.status) ? row.policyNumber : row.quoteNo}</span>
                    <span className="md:hidden">
                      {boundStatus(row.status) ? ".." + row.policyNumber?.substring(row.policyNumber.length - 9) : row.quoteNo}
                    </span>
                  </td>
                  <td className="w-[5.25rem] hidden md:block shrink-0">
                    {boundStatus(row.status) ? "-" : formatToDisplayQuoteDate(new Date(row.validityDate))}
                  </td>
                  <td className="w-[5.25rem] uppercase shrink-0">
                    <Status status={boundStatus(row.status) ? "bound" : row.status} />
                  </td>
                  <ArrowCell isClickable={row.status === "valid" || boundStatus(row.status)} />
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
    </span>
  );
};

export default QuoteTable;
