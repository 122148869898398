import { Branch, ProductType } from "../../../enums/shared/api/Api";
import { formatToServerDate } from "../../../utils/date";
import { GroupPersonalAccidentFormState } from "../../../pages/group_personal_accident/Form/types";
import agentApiService from "../../../services/group_personal_accident/agentApiService";
import { PricingRequest } from "../../../types/group_personal_accident/api/Pricing";

export const createPricingRequest = (state: GroupPersonalAccidentFormState): PricingRequest => ({
  productType: ProductType.APAG,
  branch: Branch.AU,
  insuredCount: state.totalNumbersOfInsured,
  stampDutyState: state.regionCode,
  policyType: state.coverageType,
  startDate: formatToServerDate(state.coverageStartDate) || null,
  endDate: formatToServerDate(state.coverageEndDate) || null,
});

export const fetchPlans = async (state: GroupPersonalAccidentFormState) => {
  const plans = await agentApiService.getPlans(state);
  return plans;
};
