import React, { FC } from "react";
import eventPl from "../../../assets/icons/eventpl.svg";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import { useNavigate } from "react-router-dom";
import { Article } from "../../../components/groups";

type Props = {
  t: any;
};

interface HTMLUnderlineProps {
  text: string;
}
const HTMLUnderline: React.FC<HTMLUnderlineProps> = ({ text }) => {
  return <span className="text-xl underline-offset-4" dangerouslySetInnerHTML={{ __html: text }}></span>;
};

const PreUnderwrittenCriteria: FC<Props> = ({ t }) => {
  const navigate = useNavigate();
  return (
    <div className="container-flow flex-1">
      <div className="flex items-center justify-between md:text-4xl text-3xl font-serif font-bold text-black pt-8 md:pt-16 md:pb-8 pb-4">
        <span>{t("header.title")} </span>
        <div className="hidden md:block">
          <img src={eventPl} alt="Minus Icon" />
        </div>
      </div>
      <Article>
        <div className="font-bold text-xl text-black ml-2">{t("formLabels.criteria")}</div>
        <ol className="text-xl text-black list-decimal">
          <li>
            {t("formLabels.events")}

            <ol className="list-[lower-alpha]">
              <li>
                <span className="font-bold">{t("formLabels.staicevent")}</span>
                <br />
                {t("formLabels.staticparagraph")}
              </li>
              <li>
                <span className="font-bold">{t("formLabels.dynamicevent")}</span>
                <br />
                {t("formLabels.dynamicparagraph")}
              </li>
            </ol>
          </li>
          <li>
            <HTMLUnderline text={t("formLabels.paragraph")} />
          </li>
          <li>
            <HTMLUnderline text={t("formLabels.paragraph1")} />
          </li>
          <li>
            <HTMLUnderline text={t("formLabels.paragraph2")} />
          </li>
          <li className="mb-4">
            <HTMLUnderline text={t("formLabels.paragraph3")} />
          </li>
        </ol>
        <div className="font-bold text-xl text-black ml-2 mt-8">{t("formLabels.declaration")}</div>
        <ol className="text-xl text-black list-decimal">
          <li>
            <HTMLUnderline text={t("formLabels.paragraph4")} />
          </li>
          <li>
            <span>{t("formLabels.paragraph7")}</span>
          </li>
          <li>
            <span>{t("formLabels.paragraph8")}</span>
          </li>
        </ol>
      </Article>
      <div className=" md:flex items-center justify-between pb-8 ">
        <div>
          <p className="md:ml-[40px] text-xl font-normal md:pt-4">{t("formLabels.paragraph5")} </p>
        </div>
        <div className="xs:w-[329px] md:w-[280px] mt-4">
          <Button onClick={() => navigate("form/details")} type={ButtonType.PRIMARY}>
            {t("navigation.button.getquote")}
          </Button>
        </div>
      </div>
      <div className="text-xl flex justify-end md:pb-24 pb-16">
        {" "}
        {t("formLabels.paragraph6")}{" "}
        <a href={`mailto:${t("optOutEmailAddress")}`} className="text-red font-bold underline underline-offset-4 ml-1">
          {t("optOutEmailAddress")}
        </a>
      </div>
    </div>
  );
};
export default PreUnderwrittenCriteria;
