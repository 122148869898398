import { FC } from "react";
import { CheckInputSimple } from "../../../../../components/form-fields";
import { FieldChange, TermsConditions } from "../../../../../types/travel/Policy";
import { useTranslation } from "react-i18next";
import RawHTML from "../../../../../components/basics/RawHTML";

export interface PersonalDataProps {
  flowStateSlice: TermsConditions;
  onFieldChange: FieldChange<TermsConditions>;
}

const PersonalDataTerms: FC<React.PropsWithChildren<PersonalDataProps>> = ({ flowStateSlice, onFieldChange }) => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.terms.personalDataTerms" });

  return (
    <>
      <p>{t("consent")}</p>
      <p>{t("contact.title")}</p>
      <p>
        <CheckInputSimple
          label={t("contact.phone")}
          name="marketingVoiceCall"
          id="marketing-voice-call"
          checked={flowStateSlice.marketingVoiceCall}
          onValueChange={v => onFieldChange("marketingVoiceCall", v)}
        />
      </p>
      <p>
        <CheckInputSimple
          label={t("contact.message")}
          name="marketingTextMessage"
          id="marketing-text-message"
          checked={flowStateSlice.marketingTextMessage}
          onValueChange={v => onFieldChange("marketingTextMessage", v)}
        />
      </p>
      <RawHTML content={t("notice")} />
    </>
  );
};

export default PersonalDataTerms;
