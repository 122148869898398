import { Dialog } from "../../../../components/interactives";
import { Button } from "../../../../components/basics/Button";
import { DialogFooter } from "../../../../components/interactives/Dialog";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";

interface IProps {
  onClose: () => void;
  thankYou: any;
}

const ThankYouDialog = ({ onClose, thankYou }: IProps) => {
  return (
    <Dialog onClose={onClose} title={thankYou("dialog.thankYou.title")}>
      <DialogFooter>
        <Button className="ml-auto" type={ButtonType.PRIMARY} onClick={onClose}>
          {thankYou("dialog.thankYou.button.back")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default ThankYouDialog;
