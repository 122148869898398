import { Dialog } from "../../../../components/interactives";
import { useState } from "react";
import { useValidation } from "../../../../hooks/useValidation";
import { feedbackValidations } from "../../../../helpers/travel/validations";
import { FeedbackDetails } from "../../../../types/travel/Policy";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "../../../../components/basics/Button";
import { DialogFooter } from "../../../../components/interactives/Dialog";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";
import { FormGroup } from "../../../../components/forms";
import { RadioInput } from "../../../../components/form-fields";
import TextAreaInput from "../../../../components/form-fields/TextAreaInput";
import { IOption } from "../../../../components/form-fields/RadioInput";
import ServerError from "../../../errors/ServerError";
import { removeExtraSpaces } from "../../../../helpers/shared/formHandlers";

interface IProps {
  onConfirm: (details: FeedbackDetails) => void;
  onCancel: () => void;
  serverError: string;
  isLoading: boolean;
  isSubmitted: boolean;
  customerSupportMail: string;
}

interface IPortalProps {
  ariaLabel: string;
  name: string;
  props: any;
  options: any;
}

const PortalOption = ({ ariaLabel, name, props, options }: IPortalProps) => (
  <div className="flex md:flex-col w-full">
    <RadioInput aria-label={ariaLabel} name={name} className="md:grid-flow-col gap-2 pt-2" {...props} options={options} />
  </div>
);

const validateErrors = (value: string | null, errors: any) => {
  return value !== null ? null : errors;
};

const FeedbackDialog = ({ onConfirm, onCancel, serverError, isLoading, isSubmitted, customerSupportMail }: IProps) => {
  const [feedbackDetails, setFeedbackDetails] = useState<FeedbackDetails>({
    isEasy: null,
    isUseful: null,
    scale: null,
    comments: "",
  });
  const [{ validateField, validateAll }, errors] = useValidation(feedbackDetails, feedbackValidations);
  const feedbackYesNoOptions: IOption<"yes" | "no">[] = [
    { value: "yes", label: "Yes", disabled: isSubmitted },
    { value: "no", label: "No", disabled: isSubmitted },
  ];
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy" });
  const handleFeedbackConfirm = () => {
    const valid = validateAll();
    valid && onConfirm(feedbackDetails);
  };

  const updateFeedbackDetails = (key: keyof FeedbackDetails, value: string) => {
    setFeedbackDetails(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fieldProps = <K extends keyof FeedbackDetails>(key: K) => {
    return {
      value: feedbackDetails[key] as string,
      onValueChange: (v: string) => updateFeedbackDetails(key, v),
      onBlur: (event: React.FocusEvent<HTMLInputElement>) => removeExtraSpaces(key, setFeedbackDetails, validateField)(event),
      error: validateErrors(feedbackDetails[key], errors[key]),
    };
  };

  const feedbackLableStyles = "flex items-center relative w-full md:w-9 h-11 md:h-8 ";
  const feedbackSpanStyles = "h-full flex items-center flex-1 inset-0 text-base justify-center ";
  const disbledSpanStyles = "text-black peer-checked:border-black peer-checked:bg-grey-light peer-checked:border-2 peer-checked:font-bold";

  let poorFeedbackSpanStyles = "";
  let avgFeedbackSpanStyles = "";
  let excelFeedbackSpanStyles = "";

  let poorFeedbackLabelStyles = "";
  let avgFeedbackLabelStyles = "";
  let excelFeedbackLabelStyles = "";

  if (!isSubmitted) {
    poorFeedbackLabelStyles =
      feedbackLableStyles +
      "cursor-pointer bg-pink-light hover:border-red hover:font-bold transition-transform  hover:scale-110  hover:z-10 hover:!border-2 hover:rounded";
    avgFeedbackLabelStyles =
      feedbackLableStyles +
      "cursor-pointer bg-yellow-lightest hover:border-grey-dark hover:font-bold transition-transform  hover:scale-110  hover:z-10 hover:!border-2 hover:rounded";
    excelFeedbackLabelStyles =
      feedbackLableStyles +
      "cursor-pointer bg-green-light hover:border-green-dark hover:font-bold transition-transform  hover:scale-110  hover:z-10 hover:!border-2 hover:rounded";
    poorFeedbackSpanStyles =
      feedbackSpanStyles + "text-red peer-checked:border-red peer-checked:bg-pink-dark peer-checked:border-2 peer-checked:font-bold";
    avgFeedbackSpanStyles =
      feedbackSpanStyles + "text-grey-dark peer-checked:border-grey-dark peer-checked:bg-yellow-dark peer-checked:border-2 peer-checked:font-bold";
    excelFeedbackSpanStyles =
      feedbackSpanStyles +
      "text-green-dark peer-checked:border-green-dark peer-checked:bg-green-highlight peer-checked:border-2 peer-checked:font-bold";
  } else {
    poorFeedbackLabelStyles = feedbackLableStyles;
    avgFeedbackLabelStyles = feedbackLableStyles;
    excelFeedbackLabelStyles = feedbackLableStyles;
    poorFeedbackSpanStyles = feedbackSpanStyles + disbledSpanStyles;
    avgFeedbackSpanStyles = feedbackSpanStyles + disbledSpanStyles;
    excelFeedbackSpanStyles = feedbackSpanStyles + disbledSpanStyles;
  }

  return (
    <Dialog onClose={onCancel} classNames="fixed container-xs z-50 inset-0 bg-black bg-opacity-80 overflow-y-auto h-full w-full m-auto">
      {serverError === "true" && <ServerError supportEmailId={customerSupportMail} />}
      <p className="font-bold text-xl md:text-2xl">{t("dialog.feedback.title")}</p>
      <p className="font-normal">{t("dialog.feedback.content")}</p>

      <FormGroup subtitle={t("dialog.feedback.form.label.isEasy")} className="md:flex-col" margin="mt-4">
        <PortalOption ariaLabel="portal easy" name="isEasy" props={fieldProps("isEasy")} options={feedbackYesNoOptions} />
      </FormGroup>
      <FormGroup subtitle={t("dialog.feedback.form.label.isUseful")} className="md:flex-col" margin="mt-4">
        <PortalOption ariaLabel="portal useful" name="isUseful" props={fieldProps("isUseful")} options={feedbackYesNoOptions} />
      </FormGroup>
      <FormGroup className="md:flex-col md:w-100" margin="mt-4">
        <>
          <div>{t("dialog.feedback.form.label.scale")}</div>
          <div className="flex md:flex-col pt-2 w-full md:w-[99%]">
            <RadioInput
              aria-label="feedback scale"
              name="scale"
              className="grid-flow-col grid-rows-1 gap-0"
              {...fieldProps("scale")}
              options={[
                {
                  value: "0",
                  label: t("dialog.feedback.form.label.zero"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: poorFeedbackLabelStyles,
                  spanClassName: poorFeedbackSpanStyles,
                },
                {
                  value: "1",
                  label: t("dialog.feedback.form.label.one"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: poorFeedbackLabelStyles,
                  spanClassName: poorFeedbackSpanStyles,
                },
                {
                  value: "2",
                  label: t("dialog.feedback.form.label.two"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: poorFeedbackLabelStyles,
                  spanClassName: poorFeedbackSpanStyles,
                },
                {
                  value: "3",
                  label: t("dialog.feedback.form.label.three"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: poorFeedbackLabelStyles,
                  spanClassName: poorFeedbackSpanStyles,
                },
                {
                  value: "4",
                  label: t("dialog.feedback.form.label.four"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: poorFeedbackLabelStyles,
                  spanClassName: poorFeedbackSpanStyles,
                },
                {
                  value: "5",
                  label: t("dialog.feedback.form.label.five"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: poorFeedbackLabelStyles,
                  spanClassName: poorFeedbackSpanStyles,
                },
                {
                  value: "6",
                  label: t("dialog.feedback.form.label.six"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: poorFeedbackLabelStyles,
                  spanClassName: poorFeedbackSpanStyles,
                },
                {
                  value: "7",
                  label: t("dialog.feedback.form.label.seven"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: avgFeedbackLabelStyles,
                  spanClassName: avgFeedbackSpanStyles,
                },
                {
                  value: "8",
                  label: t("dialog.feedback.form.label.eight"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: avgFeedbackLabelStyles,
                  spanClassName: avgFeedbackSpanStyles,
                },
                {
                  value: "9",
                  label: t("dialog.feedback.form.label.nine"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: excelFeedbackLabelStyles,
                  spanClassName: excelFeedbackSpanStyles,
                },
                {
                  value: "10",
                  label: t("dialog.feedback.form.label.ten"),
                  disabled: isSubmitted,
                  hidden: true,
                  labelClassName: excelFeedbackLabelStyles,
                  spanClassName: excelFeedbackSpanStyles,
                },
              ]}
            />
          </div>
        </>
      </FormGroup>
      <FormGroup subtitle={t("dialog.feedback.form.label.comments")} className="md:flex-col" margin="mt-4">
        <div className="flex md:flex-col w-full pt-2">
          <TextAreaInput
            label={t("dialog.feedback.form.label.commentsDesc")}
            span={"pt-2 pb-0 placeholder-shown:py"}
            {...fieldProps("comments")}
            disabled={isSubmitted}
            maxLength={1700}
          />
        </div>
      </FormGroup>
      <DialogFooter>
        {!isSubmitted ? (
          <div className="flex items-center justify-center w-full md:w-100">
            <LoadingButton
              type={ButtonType.PRIMARY}
              onClick={handleFeedbackConfirm}
              isLoading={isLoading}
              loadingText={"Submitting"}
              className="md:w-full"
            >
              {t("dialog.feedback.button.submit")}
            </LoadingButton>
          </div>
        ) : (
          <span className="flex items-center justify-center gap-2 w-full md:w-100">
            <span className="icon-check-circle icon-6 icon-bottom-0 icon-green"></span>
            <span className="text-green-dark font-bold text-lg">{t("dialog.feedback.form.label.thankyou")}</span>
          </span>
        )}
      </DialogFooter>
    </Dialog>
  );
};

export default FeedbackDialog;
