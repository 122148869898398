import agentApiService from "../../services/user/agentApiServices";
import { AgentResetType } from "../../pages/auth/reset/ResetForm";
import { AgentResetRequest } from "../../types/user/api/User";

export const createAgentResetRequest = (agentResetDetails: AgentResetType): AgentResetRequest => {
  return {
    email: agentResetDetails.resetEmail,
  };
};

export const resetAgent = async (agentResetDetails: AgentResetType) => {
  const response = await agentApiService.resetAgent(agentResetDetails);
  return response;
};
