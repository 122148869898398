import { NewTravelPolicyState } from "../../types/travel/Policy";
import { detailsValidations, personalDetailsValidations, planValidations, termsValidations } from "./validations";

export const travelStepValidations = (flowState: NewTravelPolicyState) => [
  { data: flowState.travelDetails, validations: detailsValidations },
  { data: flowState.travelPlan, validations: planValidations },
  { data: flowState.personalDetails, validations: personalDetailsValidations },
  { data: {}, validations: {} },
  { data: flowState.termsConditions, validations: termsValidations },
];
