import { Outlet } from "react-router-dom";
import Spinner from "../icons/Spinner";
import { useValidClientId } from "../../hooks/useClientId";
import { navigateToExternalPage } from "../../helpers/shared/navigation";
import { useAuth } from "../../hooks/useAuth";
import NotFound from "../../pages/errors/NotFound";

const AW_INSURANCE_URL = "https://alliedworldinsurance.com/products/personal-lines-singapore/";

const ProtectedClientRoute = () => {
  const { isSignedIn: isAgentSignedIn } = useAuth();
  const isValidClientId = useValidClientId();

  if (isAgentSignedIn()) {
    console.warn("Attempted to visit a client url as agent.");
    return <NotFound homePage="/" />;
  }

  if (isValidClientId === null) {
    return (
      <div role="progressbar" className="flex justify-center mt-10">
        <Spinner width="100" height="100" color="#3F9293" />
      </div>
    );
  }

  if (!isValidClientId) {
    navigateToExternalPage(AW_INSURANCE_URL);
    return null;
  }
  return <Outlet />;
};

export default ProtectedClientRoute;
