import { useNavigate } from "react-router-dom";
import SecondaryLayout from "../../../components/authentication/layout/SecondaryLayout";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";

export const Success = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("pages", { keyPrefix: "auth.forgot.success" });

  return (
    <SecondaryLayout title="Password Reset Successfully">
      <p>{t("content")}</p>
      <Button className="mt-6" type={ButtonType.PRIMARY} onClick={() => navigate("/user/login")}>
        {t("button.login")}
      </Button>
    </SecondaryLayout>
  );
};
