import { FC } from "react";
import { useTranslation } from "react-i18next";
import Overview from "../components/Overview";

const RenewalOverview: FC = () => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.renewal.overview" });
  return <Overview content={t} flow="renewal" />;
};

export default RenewalOverview;
