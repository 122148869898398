import { FC, useContext } from "react";
import GroupTravelHeading from "../../../../group_travel/components/Heading";
import AuGroupBusinessFormStepNavigation from "../../../../group_travel/Form/CreateQuoteStepNavigation";
import { useTranslation } from "react-i18next";
import NonReferral, { PlanProps } from "./NonReferral";
import { CreateQuoteContext } from "../../../CreateQuote";
import useForm from "../../../../../hooks/useForm";
import { planValidations } from "../../../../../helpers/group_personal_accident/validations";

const Plan: FC<React.PropsWithChildren<PlanProps>> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupPersonalAccident.newProduct" });
  const { state, onChange: onFieldChange, setFormState } = useContext(CreateQuoteContext);
  const { field, validateForm } = useForm(state, planValidations, onFieldChange);
  const handleNext = () => validateForm(() => onNext?.());

  return (
    <>
      <div className="container-flow flex-grow">
        <GroupTravelHeading title={t("steps.plans.plan.planTitle")} alt="GroupPersonalAccident" />
        <NonReferral state={state} setFormState={setFormState} field={field} />
      </div>
      <AuGroupBusinessFormStepNavigation onBack={onPrevious} onNext={handleNext} />
    </>
  );
};

export default Plan;
