const ProductInformationTerms = () => (
  <ol>
    <li>
      Please refer to our{" "}
      <a href="/documents/Allied%20World%20-%20Travel%20Protector.pdf" target="_blank" rel="noreferrer">
        Product Brochure
      </a>{" "}
      for a summary of the policy benefits and major exclusions. Please read the Policy Wording for the full terms, conditions and exclusions.
    </li>
    <li>
      The Policy is protected under the Policy Owners’ Protection Scheme, which is administered by the Singapore Deposit Insurance Corporation (SDIC).
      Coverage for your Policy is automatic and no further action is required from you. For more information on the types of benefits that are covered
      under the scheme as well as the limits of coverage, where applicable, please contact your insurer or visit the GIA / LIA or SDIC websites (
      <a href="https://www.gia.org.sg" target="_blank" rel="noreferrer">
        www.gia.org.sg
      </a>{" "}
      or{" "}
      <a href="https://www.lia.org.sg" target="_blank" rel="noreferrer">
        www.lia.org.sg
      </a>{" "}
      or{" "}
      <a href="https://www.sdic.org.sg" target="_blank" rel="noreferrer">
        www.sdic.org.sg
      </a>
      ).
    </li>
  </ol>
);

export default ProductInformationTerms;
