const planLabels = {
  "plan-1": "Plan 1",
  "plan-2": "Plan 2",
  "plan-3": "Plan 3",
};

export const getPlanLabel = (value: string | null) => {
  const plan = Object.entries(planLabels).find(array => {
    return array[0] === value;
  });
  return plan?.[1] || "";
};
