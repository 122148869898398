import { Branch, ProductType } from "../../../enums/shared/api/Api";
import agentApiService from "../../../services/group_travel/agentApiService";
import { GroupTravelFormState } from "../../../pages/group_travel/Form/types";
import { PricingRequest } from "../../../types/group_travel/api/Pricing";
import { GroupTravelBindState } from "../../../pages/group_travel/bind/types";
import { formatToServerDate } from "../../../utils/date";

export const convertToBoolean = (valueToConvert: string | null) => (valueToConvert === "no" || null ? false : true);

export const createTravelPricingRequest = (state: GroupTravelFormState): PricingRequest => ({
  productType: ProductType.ACTL,
  domesticTravelDays: state.domesticTravelDays,
  domesticAvgTripDuration: state.domesticAvgTripDuration,
  numberOfDomesticTrip: state.numberOfDomesticTrip,
  internationalTravelDays: state.internationalTravelDays,
  internationalAvgTripDuration: state.internationalAvgTripDuration,
  numberOfInternationalTrip: state.numberOfInternationalTrip,
  exemptedStampDuty: convertToBoolean(state.exemptedStampDuty),
  branch: Branch.AU,
  stampDutyState: state.regionCode,
  startDate: formatToServerDate(state.coverageStartDate) || null,
  endDate: formatToServerDate(state.coverageEndDate) || null,
});

export const fetchTravelPlans = async (state: GroupTravelFormState) => {
  const plans = await agentApiService.getTravelPricing(state);
  return plans;
};

export const createQuote = async (state: GroupTravelFormState) => {
  const data = await agentApiService.createQuote(state);
  return data;
};

export const checkout = async (state: GroupTravelBindState) => {
  const data = agentApiService.checkout(state);
  return data;
};
