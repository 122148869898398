import { format, startOfWeek } from "date-fns";
import { useMemo, useState } from "react";
import { BarChart, Bar, CartesianGrid, Tooltip, XAxis, YAxis, LineChart, Line, Legend } from "recharts";
import { Table } from "../../components/basics";
import { ITable } from "../../helpers/travel/planTables";
import { axisColorMapping, quoteStatuses } from "./constants";
import DashboardFilters from "./components/DashboardFilters";
import { useData } from "./hooks";
import PlotContainer from "./components/PlotContainer";
import { toStatusOverviewBarData, toQuoteOverTimeData, toTableRowPerAgent } from "./plotData";
import { Filters } from "./types";
import { Page } from "./components/Page";

const isInfinity = (value: unknown) => value === -Infinity || value === Infinity;
// These are exported for testing, as we can't test plots in a sane way in jest
export const tickFormatter: React.ComponentProps<typeof YAxis>["tickFormatter"] = timeStamp =>
  !isInfinity(timeStamp) ? format(new Date(timeStamp), "dd-MM-yy") : timeStamp;
export const tooltipFormatter = (timeStamp: string) => format(new Date(timeStamp), "dd-MM-yy");

// The approach is to fetch the data, apply filters. and then do the transformations. This means that each plot data transformer doesn't have to know about how we filter or that we even do filtering.
const Dashboard = () => {
  const [filters, setFilters] = useState<Filters>({
    startDate: startOfWeek(new Date()),
    endDate: new Date(),
    quoteStatuses,
  });
  const { data: quoteData, status } = useData(filters);
  const barData = useMemo(() => toStatusOverviewBarData(quoteData), [quoteData]);
  const quoteOverTimeData = useMemo(() => toQuoteOverTimeData(quoteData), [quoteData]);
  const tableRows: ITable["rows"] = useMemo(() => toTableRowPerAgent(quoteData), [quoteData]);
  const barAxes = axisColorMapping.filter(item => filters.quoteStatuses.includes(item[0]));

  return (
    <Page>
      <div className="container-lg pt-4">
        <DashboardFilters filters={filters} onChange={setFilters} />
        <div className="flex justify-center flex-wrap">
          {status === "loading" || status === "not-loaded" ? (
            <div>Loading</div>
          ) : status === "error" ? (
            <div>Something went wrong. Please try again or contact an administrator</div>
          ) : (
            <>
              <PlotContainer title="Quotes per status">
                <BarChart width={500} height={500} data={[barData]}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis allowDecimals={false} />
                  <Legend />
                  {barAxes.map(([quoteStatus, color]) => (
                    <Bar key={quoteStatus} dataKey={quoteStatus} fill={color} />
                  ))}
                </BarChart>
              </PlotContainer>

              <PlotContainer title="Quote count over time">
                <LineChart width={500} height={500} data={quoteOverTimeData}>
                  <Tooltip labelFormatter={tooltipFormatter} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis dataKey="count" name="Amount" />
                  <XAxis
                    dataKey="date"
                    domain={["auto", "auto"]}
                    name="Time"
                    type="number"
                    // When no axes are present, this library seems to return 1 -Infinity item or Infinity
                    tickFormatter={tickFormatter}
                  />
                  <Line dataKey="count" fill="blue" />
                </LineChart>
              </PlotContainer>

              <PlotContainer title="Relative division of quotes over agents">
                <Table
                  data={{
                    headers: [{ value: "Agent" }, { value: "Quote amount (and %)" }],
                    rows: tableRows,
                  }}
                />
              </PlotContainer>
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
