import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ServerError from "../../../errors/ServerError";
import Spinner from "../../../../components/icons/Spinner";
import { fetchQuoteList } from "../../../../helpers/event_public_liability/api/retriveList";
import Header from "../../../auth/layout/Header";
import { useFetch } from "../../../../hooks/useFetch";
import QuotationTable from "./components/QuotationTable";
import GroupTravelFooter from "../../Footer";
import FilterPanel, { FilterPanelState } from "../../../../components/interactives/FilterPanel";
import { Branch, ProductType } from "../../../../enums/shared/api/Api";

const productTypeOptions = {
  title: "Product Type",
  options: [
    { label: "Group Business Travel", value: ProductType.ACTL, filterGroup: "productType" },
    { label: "Group Personal Accident", value: ProductType.APAG, filterGroup: "productType" },
  ],
  positionClass: "inset-0 md:-left-[7.5rem] md:-top-0 top-4",
};
export const statusOptions = {
  title: "Status",
  options: [
    { label: "Valid", value: "valid", filterGroup: "status" },
    { label: "Bound", value: "bound", filterGroup: "status" },
    { label: "Expired", value: "expired", filterGroup: "status" },
  ],
  positionClass: "inset-0 md:-left-0 md:-top-0 top-4",
};

const selectButtons = [productTypeOptions, statusOptions];

export const filterByGroup = (data: any[], filterState: FilterPanelState) => {
  if (filterState.length === 0 || data.length === 0) {
    return data;
  }
  return data.filter(obj => filterState.every(({ filterGroup, value }) => obj[filterGroup] === value));
};

const QuotationList: FC = () => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct" });
  const [filterState, setFilterState] = useState<FilterPanelState>([] as FilterPanelState);
  const { data, loadingError, isLoading } = useFetch(fetchQuoteList, { productType: Branch.AU });
  const updatedState = filterByGroup(data, filterState);
  const showFilterPanel = data.length > 0;

  return (
    <main>
      <div className="flex flex-col min-h-screen gap-0">
        <Header home="/" hideAccount={false} />
        <div className="container-lg flex-grow md:pt-8 pt-4 md:pb-20 pb-8">
          {loadingError && <ServerError contactDetailsWithMailId={t("serverError.contactDetailsWithMailId")} />}
          {showFilterPanel && <FilterPanel panelButtons={selectButtons} filterState={filterState} setFilterState={setFilterState} />}
          {isLoading ? (
            <div className="py-32 flex justify-around mt-8">
              <Spinner width="100" height="100" color="#3F9293"></Spinner>
            </div>
          ) : (
            <QuotationTable state={updatedState} filterState={filterState} />
          )}
        </div>
        <GroupTravelFooter />
      </div>
    </main>
  );
};

export default QuotationList;
