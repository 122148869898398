import { Dispatch, SetStateAction } from "react";
import ActionMenu from "./ActionMenu";
import { textEllipsis } from "../../helpers/shared/textEllipsis";
import caretDown from "../../assets/icons/caret-down.svg";
import closeIcon from "../../assets/icons/close.svg";

export type Option = { label: string; value: string; filterGroup: string };
export type FilterPanelState = Option[];
type PanelButton = {
  title: string;
  options: Option[];
  positionClass: string;
};

interface FilterPanelProps {
  title?: string;
  panelButtons: PanelButton[];
  filterState: FilterPanelState;
  setFilterState: Dispatch<SetStateAction<FilterPanelState>>;
}

const getHeading = (showText: boolean, title: string) => (
  <div className="flex items-center justify-between md:text-3xl text-xl font-bold text-black mb-4 ">
    <span>{title}</span>
    {showText && <span className="uppercase block md:hidden text-xs font-bold text-disabled">SHOWING ALL</span>}
  </div>
);

const FilterPanel: React.FC<FilterPanelProps> = ({ panelButtons, filterState, setFilterState, title = "Listing" }) => {
  const clearHandler = (selectedFilterGroup: Option) => {
    const updatedFilter = filterState.filter(ele => selectedFilterGroup.value !== ele.value);
    setFilterState(updatedFilter);
  };

  const updateHandler = (option: Option) => {
    const copiedState = [...filterState] as Option[];
    const index = filterState?.findIndex(ele => ele.filterGroup === option.filterGroup);
    if (index === -1) {
      copiedState?.push(option);
    } else {
      copiedState.splice(index as number, 1, option);
    }
    setFilterState(copiedState);
  };

  const isFilterNotApplied = filterState.length === 0;
  const activeFilters = filterState.map(ele => ele.value);

  return (
    <>
      {getHeading(!!filterState.length, title)}
      <fieldset className="flex flex-col md:flex-row md:gap-8">
        <div className="md:md:flex md:gap-4 md:justify-between md:items-center">
          <div className="flex flex-col md:flex-row gap-2 md:gap-4" aria-label="filter-dropdowns">
            {panelButtons.map(ele => (
              <ActionMenu
                key={ele.title}
                options={ele.options}
                optionBoxPosition={`${ele.positionClass}`}
                classNames="relative w-full md:w-auto shrink-0 flex items-center justify-center font-sans text-center hover:cursor-pointer"
                clickEnabled={true}
                updateHandler={updateHandler}
                activeOptions={activeFilters}
              >
                <div className="flex bg-white  rounded gap-2 font-sans py-4 md:py-2 px-4 justify-between items-center w-full md:w-auto" role="menu">
                  <span className=" capitalize font-bold"> {ele.title} </span>
                  <img src={caretDown} className="w-[1.25rem] h-[0.625rem] md:w-[0.875rem] md:h-2" alt={"caret-down"} role="button" />
                </div>
              </ActionMenu>
            ))}
          </div>
          {isFilterNotApplied && <div className="md:block hidden text-disabled text-xs font-bold uppercase">SHOWING ALL</div>}
        </div>

        <div className={`md:flex gap grid ${filterState.length === 1 ? "grid-cols-1" : "grid-cols-2"} md:my-1`} aria-label="filters-selected">
          {filterState.map(filterElement => (
            <div
              key={filterElement.value}
              className="flex bg-[#b31b341a] gap-2 font-sans rounded-full py-1 md:py-1 px-4  items-center  h-12 md:h-auto justify-between md:mt-0 mt-4"
            >
              <span className="capitalize items-center hidden md:flex"> {filterElement.label} </span>
              <span className="capitalize flex items-center md:hidden ">
                {" "}
                {filterState.length === 1 ? filterElement.label : textEllipsis(filterElement.label, 10, 2)}{" "}
              </span>
              <img
                src={closeIcon}
                className={`w-4 h-4 md:w-2 md:h-2 cursor-pointer grayscale`}
                onClick={() => clearHandler(filterElement)}
                aria-label={`${filterElement.label}-close`}
                role="button"
              />
            </div>
          ))}
        </div>
      </fieldset>
    </>
  );
};

export default FilterPanel;
