import Header from "./auth/layout/Header";
import ContentContainer from "../components/basics/ContentContainer";
import Overview from "./travel/Overview";
import { OverviewLinks } from "./travel/OverviewLinks";
import Navbar from "./auth/layout/header/Navbar";
import { useClientId } from "../hooks/useClientId";
import TravelFooter from "./travel/TravelFooter";

const Home = () => {
  const clientId = useClientId();
  return (
    <div className="flex flex-col justify-start bg-white min-h-[inherit]">
      <Header home="/" hideAccount>
        <Navbar />
      </Header>
      <main className="drop-shadow-landing">
        <Overview />
        <div className="md:hidden">
          <ContentContainer>
            <OverviewLinks />
          </ContentContainer>
        </div>
      </main>
      {clientId && <TravelFooter />}
    </div>
  );
};

export default Home;
