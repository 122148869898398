import axios from "axios";
import { PricingRequest, PricingResponse } from "../../../types/travel/new-policy/api/Pricing";

import { createTravelPricingRequest } from "../../../helpers/travel/new-policy/api/pricingApi";
import {
  validateCheckoutResponse,
  validateCreateQuoteResponse,
  validateDiscountResponse,
  validatePricingResponse,
} from "../../../helpers/travel/new-policy/api/validators";
import { DiscountRequest, DiscountResponse } from "../../../types/travel/new-policy/api/Discount";
import { discountRequest } from "../../../helpers/travel/new-policy/api/discountApi";
import { validateStatusCode } from "../../../helpers/travel/new-policy/api/http";
import { clientPost } from "../../../helpers/travel/new-policy/api/client";
import { validateBlacklistCheckResponse, validateCreateReferralResponse } from "../../../helpers/travel/new-policy/api/validators";
import { BlacklistCheckRequest, BlacklistCheckResponse } from "../../../types/travel/new-policy/api/BlacklistCheck";
import { createBlacklistCheckRequest } from "../../../helpers/travel/new-policy/api/blacklistCheckApi";
import { CreateReferralRequest } from "../../../types/travel/new-policy/api/Quote";
import { createReferralRequest } from "../../../helpers/travel/new-policy/api/referralApi";
import { CreateQuoteResponse, PremiumQuote } from "../../../types/travel/new-policy/api/Quote";
import { createQuoteRequest } from "../../../helpers/travel/new-policy/api/quoteApi";
import { CheckoutRequest, CheckoutResponse } from "../../../types/travel/new-policy/api/Checkout";
import { checkoutRequest } from "../../../helpers/travel/new-policy/api/checkoutApi";
import { NewTravelPolicyState, ReferralDetails, TravelDetails } from "../../../types/travel/Policy";

const CLIENT_ID_CHECK = "v1/client/check/";
const CLIENT_PRICING = "v1/client/pricing";
const CLIENT_RATES = "v1/client/rates";
const CLIENT_CREATE_QUOTE = "v1/client/submit";
const CLIENT_CHECKOUT = "v1/client/payment/checkout";
const CHECK_BLACKLIST = "v1/client/blacklist/check";
const CREATE_REFERRAL = "v1/client/referral";

const clientApiService = {
  checkClientId: async (clientId: string): Promise<boolean> => {
    const result = await axios.get(`${process.env.REACT_APP_BACKEND_API}${CLIENT_ID_CHECK}${clientId}`, {
      validateStatus: validateStatusCode,
    });
    return result.status === 204;
  },
  getTravelPricing: (clientId: string, travelDetails: TravelDetails): Promise<PricingResponse> =>
    clientPost<PricingRequest, PricingResponse>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CLIENT_PRICING,
      createTravelPricingRequest(travelDetails),
      (response: unknown) => validatePricingResponse(response)
    ),
  getDiscount: (clientId: string, travelDetails: TravelDetails): Promise<DiscountResponse> =>
    clientPost<DiscountRequest, DiscountResponse>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CLIENT_RATES,
      discountRequest(travelDetails),
      (response: unknown) => validateDiscountResponse(response)
    ),
  createQuote: (clientId: string, newTravelPolicyState: NewTravelPolicyState): Promise<CreateQuoteResponse> =>
    clientPost<PremiumQuote, CreateQuoteResponse>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CLIENT_CREATE_QUOTE,
      createQuoteRequest(newTravelPolicyState),
      (response: unknown) => validateCreateQuoteResponse(response)
    ),
  checkout: (clientId: string, quoteId: string): Promise<CheckoutResponse> =>
    clientPost<CheckoutRequest, CheckoutResponse>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CLIENT_CHECKOUT,
      checkoutRequest(quoteId),
      (data: unknown) => {
        return validateCheckoutResponse(data);
      }
    ),
  validateIdentificationNumber: (clientId: string, identificationNumber: string): Promise<BlacklistCheckResponse> =>
    clientPost<BlacklistCheckRequest, BlacklistCheckResponse>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CHECK_BLACKLIST,
      createBlacklistCheckRequest(identificationNumber),
      (data: unknown) => validateBlacklistCheckResponse(data)
    ),
  createReferral: (clientId: string, referralDetails: ReferralDetails, flowState: NewTravelPolicyState) =>
    clientPost<CreateReferralRequest, unknown>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CREATE_REFERRAL,
      createReferralRequest(referralDetails, flowState),
      (data: unknown) => validateCreateReferralResponse(data)
    ),
};
export default clientApiService;
