import { FC } from "react";

/**
 * Represents a line of Skeleton content used for texts. Defaults to one line height and 32 pixels width, but that is configurable
 */
const SkeletonText: FC<{ height?: `${string}rem`; width?: `${string}rem` }> = ({ height = "1rem", width = "8rem" }) => (
  <div className="skeleton-content" style={{ height, width }}>
    <div className="h-4 w-32 bg-grey-light opacity-30 rounded" />
  </div>
);

export default SkeletonText;
