import { useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAuth } from "../../../../hooks/useAuth";
import { ConfirmLogoutDialog } from "./ConfirmLogoutDialog";
import { getRole } from "../../../../helpers/shared/getRole";
import { Role } from "../../../../enums/shared/forms/Role";
import { getRegion } from "../../../../helpers/shared/getRegion";
import { Region } from "../../../../enums/shared/forms/Region";

const isUserAgent = (role: string) => role !== Role.Unknown && (role === Role.Agent || role === Role.AdminAgent);
export const isUserAdmin = (role: string) => role !== Role.Unknown && (role === Role.Admin || role === Role.AdminAgent);

const getTextStyle = (isActive: boolean) => (isActive ? "font-sans text-base font-bold text-black" : "font-sans text-base text-black-DEFAULT");
const NavbarMobile = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const role = getRole(user);
  const { t } = useTranslation("pages", { keyPrefix: "travel.overview" });
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const region = getRegion(user);

  const handleLogout = () => {
    signOut();
    navigate("/user/login");
  };

  const links = (
    <ul>
      {isUserAdmin(role) && (
        <>
          <li className="pb-8">
            <NavLink to="/user/reset" className={({ isActive }) => getTextStyle(isActive)}>
              {t("header.button.resetAccount")}
            </NavLink>
          </li>
          <li className="pb-8">
            <NavLink to="/user/signup" className={({ isActive }) => getTextStyle(isActive)}>
              {t("header.button.createAccount")}
            </NavLink>
          </li>
          {/* <li className="pb-8">
            <NavLink to="/campaign/create" className={({ isActive }) => getTextStyle(isActive)}>
              {t("header.button.createTravelCampaign")}
            </NavLink>
          </li>
          <li className="pb-8">
            <NavLink to="/campaign/list" className={({ isActive }) => getTextStyle(isActive)}>
              {t("header.button.listTravelCampaigns")}
            </NavLink>
          </li> */}
        </>
      )}
      {isUserAgent(role) && (
        <li className="pb-8">
          <NavLink to="/products" className={({ isActive }) => getTextStyle(isActive)}>
            {t("header.button.products")}
          </NavLink>
        </li>
      )}

      <li className="pb-8">
        <NavLink to="/downloads" className={({ isActive }) => getTextStyle(isActive)}>
          {t("header.button.downloads")}
        </NavLink>
      </li>

      {isUserAgent(role) && region !== Region.AU && (
        <li className="pb-8">
          <NavLink
            to={`//claims.alliedworldinsurance.io/${region}/personal/travel`}
            className={({ isActive }) => getTextStyle(isActive)}
            target="_blank"
          >
            {t("header.button.travelClaim")}
          </NavLink>
        </li>
      )}

      <li className="font-sans text-3xl pt-8">
        <a
          onClick={() => {
            setShowLogoutDialog(true);
          }}
        >
          <span className="flex font-bold underline text-red text-base">{t("header.button.logout")}</span>
        </a>
      </li>
    </ul>
  );

  return (
    <>
      <div className="flex w-full items-center px-5 py-2" onClick={() => setIsNavOpen(prev => !prev)}>
        <div className="cursor-pointer space-y-2">
          <span className="block h-0.5 w-8 bg-black"></span>
          <span className="block h-0.5 w-8 bg-black"></span>
          <span className="block h-0.5 w-8 bg-black"></span>
        </div>
        <div className="cursor-pointer pl-5 text-xl">Menu</div>
      </div>
      {isNavOpen && (
        <aside className="fixed z-50 flex flex-col w-full h-full bg-black bg-opacity-30 overflow-y-auto overflow-x-hidden block md:hidden left-0">
          <div className="absolute flex flex-col w-full h-full bg-white justify-start items-start block md:hidden left-32 pl-8 pt-8 pr-0">
            {links}
          </div>
        </aside>
      )}
      {showLogoutDialog && <ConfirmLogoutDialog onCancel={() => setShowLogoutDialog(false)} onConfirm={handleLogout} />}
    </>
  );
};

export default NavbarMobile;
