import { createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { windowScroll } from "../../helpers/shared/useNavigationEffects";
import FormStep from "../../components/forms/FormStep";
import useEvent from "../../hooks/useEvent";
import { parseState } from "../../helpers/shared/serialization";
import { GroupPersonalAccidentBindState } from "./bind/types";
import createInitialState from "./bind/state";
import BindRoutes from "./bind/routes";
import cls from "../../utils/cls";
import Header from "../auth/layout/Header";
import Footer from "../group_travel/Footer";
import { clearPISessionStorage as clearSession } from "../../helpers/shared/sessionStorage";
import { useStepNavigation } from "../../hooks/useStepNavigation";
import { policyHolderDetailsValidations } from "../../helpers/group_travel/validations";
import { checkout } from "../../helpers/group_personal_accident/api/checkout";

type ChangeHandler = <T extends keyof GroupPersonalAccidentBindState>(key: T, value: GroupPersonalAccidentBindState[T]) => void;
type test = (t: GroupPersonalAccidentBindState) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};

export const BindContext = createContext<{
  state: GroupPersonalAccidentBindState;
  isSubmitting: boolean;
  serverError: boolean;
  onChange: ChangeHandler;
  setFormState: test;
  setServerError: Dispatch<SetStateAction<boolean>>;
}>({
  serverError: false,
  isSubmitting: false,
  setServerError: noop,
  onChange: noop,
  state: createInitialState(),
  setFormState: noop,
});

enum Steps {
  "RetrieveList",
  "QuoteSummary",
  "ProposerDetails",
  "ProposalSummary",
}
const getURLForStep = (step: string) => `/au/group_personal_accident/bind/${step}`;
const stepsURLS = [
  "/au/group_personal_accident/retrieve-list",
  getURLForStep("quote-summary"),
  getURLForStep("proposer-details"),
  getURLForStep("proposer-summary"),
  getURLForStep("terms"),
];
const stepValidations = [{}, {}, policyHolderDetailsValidations];
const steps: Steps[] = [Steps.RetrieveList, Steps.QuoteSummary, Steps.ProposerDetails, Steps.ProposalSummary];
const amountOfSteps = steps.length;

const storageKey = "au/group_personal_accident/bind";
const getStorageState = () => sessionStorage.getItem(storageKey);
export const getInitialBindState = () => {
  try {
    const sessionState = getStorageState();
    //setting initial bind state
    return createInitialState(sessionState ? (parseState(sessionState) as GroupPersonalAccidentBindState) : {});
  } catch (e) {
    //catch errors if any
    console.error("Session storage could not be parsed", e);
    return createInitialState();
  }
};
const BindQuoteForm: FC = () => {
  const [serverError, setServerError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const location = useLocation();
  const quoteData = location.state as any;
  const [formState, setFormState] = useState<GroupPersonalAccidentBindState>(getInitialBindState());
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const navigate = useNavigate();
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const persistState = useEvent(() => sessionStorage.setItem(storageKey, JSON.stringify(formState)));
  useStepNavigation<GroupPersonalAccidentBindState>(step, stepValidations, formState, stepsURLS);
  const successUrl = "/au/group_personal_accident/payment/success";
  useEffect(() => {
    clearSession("au/group_personal_accident/form");
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);
  useEffect(() => {
    setFormState({
      ...formState,
      ...quoteData,
    });
  }, [quoteData]);

  useEffect(() => {
    windowScroll();
  }, [location]);

  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const submitHandler = async () => {
    try {
      setIsSubmitting(true);
      await checkout(formState);
      setIsSubmitting(false);
      navigate(successUrl);
    } catch {
      setServerError(true);
      setIsSubmitting(false);
    }
  };
  return (
    <BindContext.Provider
      value={{
        isSubmitting,
        serverError,
        setServerError,
        setFormState: setFormState,
        onChange: onFieldChange,
        state: formState,
      }}
    >
      <main>
        <form>
          <div className={cls("flex flex-col min-h-screen gap-0")}>
            <Header home={"/"} hideAccount={false} />
            {step > 1 && <FormStep current={step - 1} total={amountOfSteps - 1} />}
            <BindRoutes onPrevious={goToPreviousStep} onNext={goToNextStep} onSubmit={submitHandler} />
            <Footer />
          </div>
        </form>
      </main>
    </BindContext.Provider>
  );
};

const BindQuote: FC = () => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <BindQuoteForm />
    </QueryClientProvider>
  );
};

export default BindQuote;
