import { IUser, useAuth } from "./useAuth";
import { useClientId } from "./useClientId";

/**
 * Grabs the unique session id for the current user.
 * This is the cognito id in case of cognito users, and the clientId in case of b2b2bc clients
 * @returns {string} sessionId
 */
const useSessionId = () => {
  const { isSignedIn: isAgentSignedIn, user: agent } = useAuth();
  const clientId = useClientId();

  if (isAgentSignedIn()) {
    return (agent as IUser).id;
  }
  return clientId;
};

export default useSessionId;
