import { FC } from "react";
import { createLink } from "../../helpers/shared/links";
import { useClientId } from "../../hooks/useClientId";
import NotFound from "./NotFound";

const ClientNotFound: FC = () => {
  const clientId = useClientId();
  const homePage = createLink("/travel", clientId);

  return <NotFound homePage={homePage} />;
};

export default ClientNotFound;
