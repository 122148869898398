import { FC } from "react";
import LiabilitySection from "../../../components/forms/LiabilitySection";
import { CommonTable, ContentCell, LabelCell, NormalRow } from "../components/CommonTable";
import OptionalCover from "../components/OptionalCovers";
import { proposerEventDetailsState } from "../bind/types";

type Props = {
  state: proposerEventDetailsState;
  t: any;
  renderExclusion: JSX.Element;
};
const DetailsLabels: any = {
  staticOutdoor: "Static Event (Outdoor Setting)",
  staticIndoor: "Static Event (Indoor Setting)",
  dynamicIndoor: "Dynamic Event (Indoor Setting)",
};

const SummaryLiabilitySection: FC<Props> = ({ state, renderExclusion, t }) => {
  return (
    <LiabilitySection name={""} headClass="mb-0 mt-2" className="text-xl" hasBorder={false}>
      <CommonTable className="w-full table-fixed">
        <NormalRow>
          <ContentCell>{t("PricingLabels.eventType")}</ContentCell>
          <LabelCell>{state.eventType && DetailsLabels[state.eventType]}</LabelCell>
        </NormalRow>
        <NormalRow>
          <ContentCell>{t("PricingLabels.eventDuration")}</ContentCell>
          <LabelCell>
            {state?.duration} {state.duration === 1 ? "Day" : "Days"}
          </LabelCell>
        </NormalRow>
        <NormalRow>
          <ContentCell>{t("PricingLabels.noOfAttendees")}</ContentCell>
          <LabelCell>{state?.attendees}</LabelCell>
        </NormalRow>
        <NormalRow>
          <ContentCell>{t("PricingLabels.optionalCover")}</ContentCell>
          <LabelCell>
            <OptionalCover state={state} />
          </LabelCell>
        </NormalRow>
      </CommonTable>
      {renderExclusion}
    </LiabilitySection>
  );
};

export default SummaryLiabilitySection;
