import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { ErrorMessage, FormGroup } from "../../../components/forms";
import PrimaryLayout from "../../../components/authentication/layout/PrimaryLayout";
import { OnLoginResponse } from "../Login";
import { useTranslation } from "react-i18next";
import { Button, LoadingButton } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import useWithAsyncLoadingIndicator from "../../../hooks/useWithAsyncLoadingIndicator";
import { authenticationValidations } from "../../../helpers/authentication/validations";
import useForm from "../../../hooks/useForm";
import TextInput from "../../../components/form-fields/TextInput";

interface IProps {
  onLoginResponse: OnLoginResponse;
}

export const LoginForm = ({ onLoginResponse }: IProps) => {
  const [state, setState] = useState({ email: "", password: "" });
  const onFieldChange = useCallback(
    (key: "email" | "password", value: string) => setState(prevState => ({ ...prevState, [key]: value })),
    [setState]
  );
  const { errors, field, validateAll, validateField } = useForm(state, authenticationValidations, onFieldChange);
  const hasErrors = Object.values(errors).filter(Boolean).length > 0;
  const [cognitoError, setCognitoError] = useState("");
  const navigate = useNavigate();

  const { t } = useTranslation("pages", { keyPrefix: "auth.login.loginForm" });

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();

        if (!validateAll()) {
          return;
        }
        const loginResponse = await Auth.signIn(state.email, state.password);
        onLoginResponse(loginResponse);
      } catch (err) {
        setCognitoError(t("error.loginError"));
      }
    },
    [state, setCognitoError, onLoginResponse, validateAll]
  );

  const { isLoading, action: onSubmit } = useWithAsyncLoadingIndicator(handleSubmit);

  return (
    <PrimaryLayout title={t("title")}>
      <form onSubmit={onSubmit}>
        <div className="text-xl md:text-2xl text-black font-semibold mb-6" role="heading" aria-level={2}>
          {t("form.title")}
        </div>
        <FormGroup className={"mb-6"}>
          <TextInput
            error={errors.email}
            label={t("form.email")}
            backgroundColor="bg-grey-lighter"
            autoComplete="username"
            value={state.email}
            onBlur={field.email.onBlur}
            onValueChange={value => {
              const trimmedEmail = value.trim();
              field.email.onValueChange(trimmedEmail);
              errors.email && validateField("email", trimmedEmail);
            }}
          />
        </FormGroup>
        <FormGroup className={"mt-0"}>
          <TextInput
            error={errors.password}
            type="password"
            label={t("form.password")}
            backgroundColor="bg-grey-lighter"
            autoComplete="current-password"
            value={state.password}
            onBlur={field.password.onBlur}
            onValueChange={field.password.onValueChange}
          />
          <ErrorMessage error={cognitoError} />
        </FormGroup>

        <LoadingButton disabled={hasErrors} isLoading={isLoading} className="mt-6 md:!w-full" type={ButtonType.PRIMARY} loadingText={"Loading"}>
          {t("form.button.login")}
        </LoadingButton>
      </form>
      <div className="flex justify-around">
        <Button className="m-auto mt-3 text-center" type={ButtonType.LINK} onClick={() => navigate("/user/password/reset")}>
          {t("button.forgot")}
        </Button>
      </div>
    </PrimaryLayout>
  );
};
