import { ReferralDetails, NewTravelPolicyState } from "../../../../types/travel/Policy";
import { CreateReferralRequest } from "../../../../types/travel/new-policy/api/Quote";
import { createQuoteRequest } from "./quoteApi";

export const createReferralRequest = (referralDetails: ReferralDetails, flowState: NewTravelPolicyState): CreateReferralRequest => {
  return {
    ...referralDetails,
    ...createQuoteRequest(flowState),
  };
};
