import { FC, HTMLAttributes, useState } from "react";
import cls from "../../utils/cls";
import { Article, Group } from "../groups";

type AccordionProps = HTMLAttributes<HTMLDivElement> & {
  omitMarginTop?: boolean;
};

interface AccordionSectionProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  initialState?: "expanded" | "collapsed";
}

const Accordion = ({ className, children, ...props }: AccordionProps) => (
  <Group className={cls("my", className)} {...props}>
    {children}
  </Group>
);

const AccordionSection: FC<React.PropsWithChildren<AccordionSectionProps>> = ({
  id,
  label,
  className,
  children,
  initialState = "collapsed",
  ...props
}) => {
  const [expanded, setExpanded] = useState(initialState === "expanded");

  return (
    <div className={cls("flex flex-col relative", "bg-white w-full", "shadow-soft rounded-lg", className)}>
      <button
        type="button"
        id={id}
        aria-expanded={expanded}
        className={cls("ringed rounded-md text-left text-base text-black", "w-full", "focus:rounded-lg")}
        onClick={() => setExpanded(!expanded)}
      >
        {label && (
          <span
            className={cls(
              "flex items-center w-full pl-4 pr-12 h-15 text-base font-bold text-black cursor-pointer",
              "icon-expand icon-red icon-4 icon-right-4",
              "hover:icon-red-dark",
              { "icon-collapse icon-4": expanded }
            )}
          >
            {label}
          </span>
        )}
      </button>
      {children && expanded && (
        <div
          role="region"
          aria-labelledby={id}
          className={cls("px-4 w-full overflow-y-auto h-auto", "cursor-default", { "h-0 pb-0": !expanded }, { "h-full pb-4": expanded })}
          {...props}
        >
          <Article>{children}</Article>
        </div>
      )}
    </div>
  );
};

export { Accordion, AccordionSection };
