import { TextInputProps, InputElement } from "./text-input/InputElement";

const TextInput = ({ value, className, label, type, onValueChange, error, children, disabled, backgroundColor, ...props }: TextInputProps) => {
  return (
    <InputElement
      value={value}
      className={className}
      label={label}
      type={type}
      onValueChange={onValueChange}
      error={error}
      disabled={disabled}
      backgroundColor={backgroundColor}
      {...props}
    >
      {children}
    </InputElement>
  );
};

export default TextInput;
