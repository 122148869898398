import { FC } from "react";
import { Link } from "../../../components/basics";
import ContentContainer from "../../../components/basics/ContentContainer";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import Header from "../../auth/layout/Header";
import ManulifeFooter from "./ManulifeFooter";
import { getCurrentPolicyPeriod, formatDate, getRenewalPolicyPeriod } from "../policyPeriod";

type Props = {
  content: any;
  flow: string;
};

const Overview: FC<React.PropsWithChildren<Props>> = ({ content, flow }) => {
  const { startDate, endDate } = flow === "renewal" ? getRenewalPolicyPeriod() : getCurrentPolicyPeriod();
  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <Header home={content("homeUrl")} hideAccount />
      <main>
        <div className="bg-curved-red mb-6">
          <ContentContainer flex>
            <div className="pt-4 xxl:pt-16">
              <h1 className="font-serif text-white text-4xl font-semibold md:text-5xl">{content("title")}</h1>
              <h2 className="text-white text-sm pt-2 pr-16 md:pr-0 md:text-2xl">
                {content("subtitle", { coverageStartDate: formatDate(startDate), coverageEndDate: formatDate(endDate) })}
              </h2>
              <div className="flex flex-col gap-4 text-lg w-full pb-0 xxs:pt-20 md:pt-8 xxl:pt-20 md:mt-0 md:pb-8 xxl:pb-12 md:w-2/3"></div>
            </div>
          </ContentContainer>
        </div>
        {flow === "renewal" && process.env.REACT_APP_FEATURE_FLAG_MPI_RENEWAL === "false" ? (
          <ContentContainer>
            <div className="space-y-8 pb-[291px] pt-[28px]">
              <p className="font-bold font-sans text-3xl text-black">{content("inActiveRenewalTitle")}</p>
              <p className="font-bold font-sans text-3xl text-black">
                {content("inActiveRenewalContent1")}
                <a className="text-red font-bold underline" href={content("inActiveRenewalLink")} rel="noreferrer">
                  {content("inActiveRenewalContent2")}
                </a>
                .
              </p>
            </div>
          </ContentContainer>
        ) : (
          <ContentContainer>
            <p className="font-bold text-3xl">{content("leadingParagraph")}</p>
            <div className="space-y-8 mt-4 mb-8">
              {content("paragraphs", { returnObjects: true }).map((label: string) => (
                <p key={label}>{label}</p>
              ))}
              <p className="font-bold">{content("lastParagraph")}</p>
            </div>
            <p className="underline">{content("moreInformation")}</p>
            <div>{content("agencyName")}</div>
            <div>{content("contactNo")}</div>
            <div>{content("contactPersons.person1")}</div>
            <div> {content("contactPersons.person2")}</div>
            <div> {content("contactPersons.person3")}</div>
            <a className="text-red font-bold hover:underline" href={`mailto:${content("email")}`} target="_blank" rel="noreferrer">
              {content("email")}
            </a>
            <div className="my-16">
              <Link type={ButtonType.PRIMARY} to="./form">
                {content("next")}
              </Link>
            </div>
          </ContentContainer>
        )}
      </main>
      <ManulifeFooter />
    </div>
  );
};

export default Overview;
