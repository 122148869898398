export const formatToTwoDecimals = (value: number) => {
  return value.toFixed(2);
};

export const formatToTwoDecimalsWithThousandSeparator = (value: number) => {
  return value?.toLocaleString("en-US", { minimumFractionDigits: 2 });
};

export const formatWithThousandSeparator = (value: number) => {
  return value?.toLocaleString("en-US");
};
export const formatToPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length === 8) {
    return `+65${phoneNumber}`;
  } else if (phoneNumber.charAt(0) !== "+") {
    return `+${phoneNumber}`;
  }

  return phoneNumber;
};
