import { createCheckoutRequest } from "../../helpers/group_personal_accident/api/checkout";
import { createPricingRequest } from "../../helpers/group_personal_accident/api/pricingApi";
import { createQuoteRequest } from "../../helpers/group_personal_accident/api/quoteApi";
import { validateCheckoutResponse, validateCreateQuoteResponse, validatePricingResponse } from "../../helpers/group_personal_accident/api/validators";
import { agentPost } from "../../helpers/travel/new-policy/api/agent";
import { GroupPersonalAccidentFormState } from "../../pages/group_personal_accident/Form/types";
import { GroupPersonalAccidentBindState } from "../../pages/group_personal_accident/bind/types";
import { PricingRequest } from "../../types/group_personal_accident/api/Pricing";
import { CheckoutRequest, CheckoutResponse, CreateQuoteResponse, PremiumQuote } from "../../types/group_personal_accident/api/Quote";
import { PricingResponse } from "../../types/group_travel/api/Pricing";

const CREATE_QUOTE = "v1/agent/submit";
const CHECKOUT = "v1/agent/payment/checkout";
const PRICING = "v1/agent/pricing";

const agentApiService = {
  createQuote: (data: GroupPersonalAccidentFormState): Promise<CreateQuoteResponse> =>
    agentPost<PremiumQuote, CreateQuoteResponse>(process.env.REACT_APP_BACKEND_API, CREATE_QUOTE, createQuoteRequest(data), (response: unknown) => {
      return validateCreateQuoteResponse(response);
    }),

  checkout: (data: GroupPersonalAccidentBindState): Promise<CheckoutResponse> =>
    agentPost<CheckoutRequest, CheckoutResponse>(process.env.REACT_APP_BACKEND_API, CHECKOUT, createCheckoutRequest(data), (response: unknown) => {
      return validateCheckoutResponse(response);
    }),

  getPlans: (data: GroupPersonalAccidentFormState): Promise<PricingResponse> =>
    agentPost<PricingRequest, PricingResponse>(process.env.REACT_APP_BACKEND_API, PRICING, createPricingRequest(data), (response: unknown) =>
      validatePricingResponse(response)
    ),
};

export default agentApiService;
