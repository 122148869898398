import { FC } from "react";
import { RadioInput } from "../form-fields";
import { IOption } from "../form-fields/RadioInput";
import FormGroup from "../forms/FormGroup";

interface CustomRadioInputProps {
  value: "yes" | "no" | null;
  onValueChange: (value: "yes" | "no" | null) => void;
  formLabel: string;
  error?: string | boolean | null;
}
const yesNoOptions: IOption<"yes" | "no">[] = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
const CustomRadioInput: FC<CustomRadioInputProps> = ({ error, formLabel, onValueChange, value }) => (
  <FormGroup title="" fullWidth>
    <RadioInput error={error} name={formLabel} onValueChange={onValueChange} value={value} options={yesNoOptions} aria-label={formLabel} />
  </FormGroup>
);

export default CustomRadioInput;
