import { Region } from "../enums/shared/forms/Region";
import Footer from "./auth/layout/Footer";
import { KeyPrefix, useTranslation } from "react-i18next";

interface ProductsFooterProps {
  region: string;
}
const footerPrefix: Record<Region, string> = {
  [Region.AU]: "travel.au.footer",
  [Region.HK]: "eventplHk.footer",
  [Region.SG]: "travel.footer",
};
const ProductsFooter: React.FC<ProductsFooterProps> = ({ region }) => {
  const abnUrlOfAu = region === Region.AU ? "https://abr.business.gov.au/ABN/View?id=54163304907" : "";
  const { t } = useTranslation("pages", { keyPrefix: footerPrefix[region as Region] as KeyPrefix<"pages"> });
  return <Footer footer={t} abnUrlOfAu={abnUrlOfAu} />;
};

export default ProductsFooter;
