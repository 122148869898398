// Uses tailwind colors if known, otherwise grabs a material color
export const axisColorMapping = [
  ["pending", "#512da8"],
  ["success", "#3F9293"],
  ["processed", "#448aff"],
  ["failed", "#FDBC32"],
  ["failureRateExceeded", "#B31B34"],
];

export const quoteStatuses = axisColorMapping.map(item => item[0]);
