import { InputHTMLAttributes } from "react";
import { camelCase, kebabCase } from "lodash";
import cls from "../../../utils/cls";
import { ErrorMessage } from "../../forms";

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  value?: string;
  backgroundColor?: string;
  className?: string;
  label?: string;
  onValueChange?: (value: string) => void;
  error?: string | boolean | null;
  span?: string;
}

export const InputElement = ({
  value,
  className,
  label,
  type,
  onValueChange,
  error,
  children,
  disabled,
  backgroundColor,
  span,
  ...props
}: TextInputProps) => {
  const name = props.name || props.id || camelCase(label);
  const id = props.id || kebabCase(name);
  const TextInputElement = type === "textarea" ? "textarea" : "input";

  return (
    <div className="flex w-full flex-wrap">
      <label htmlFor={id} className={cls(className, "relative block", "w-full", { "text-red border-red border rounded-md": !!error })}>
        <TextInputElement
          type={type || "text"}
          value={value}
          aria-invalid={!!error}
          onChange={event => onValueChange && onValueChange(event.currentTarget.value)}
          className={cls(
            "textinput-autofill",
            "peer",
            { "h-15 whitespace-nowrap": type !== "textarea" },
            { "h-32 whitespace-normal resize-none": type === "textarea" },
            "block px-4 border w-full rounded-md",
            "text-base text-black border-grey-light",
            "inset-0",
            { "placeholder:opacity-0": !span },
            { ringed: !disabled },
            { "bg-grey-light cursor-not-allowed": !!disabled },
            { [backgroundColor || ""]: !!backgroundColor },
            { "pt-5 pb-0 placeholder-shown:py focus:pt-5 focus:pb-0 hover:pt-5 hover:pb-0": !!label && !span },
            span
          )}
          placeholder={label}
          disabled={disabled}
          {...props}
        />
        {!span && label && (
          <span
            className={cls(
              "absolute px-4 z-10",
              "transition-all duration-200",
              "text-grey-dark text-xs top-2 pointer-events-none",
              { "peer-placeholder-shown:text-black peer-placeholder-shown:text-base peer-placeholder-shown:top-4": !disabled },
              { "peer-placeholder-shown:text-red": !!error },
              "peer-focus:text-grey-dark peer-focus:text-xs peer-focus:top-2",
              "peer-hover:text-grey-dark peer-hover:text-xs peer-hover:top-2"
            )}
          >
            {label}
          </span>
        )}
        {children}
      </label>
      <ErrorMessage error={error} />
    </div>
  );
};

export default InputElement;
