export enum Districts {
  EMPTY = "",
  SOUTHERN = "Southern",
  SAI_KUNG = "Sai Kung",
  ISLAND = "Island",
  CENTRAL_WESTERN = "Central & Western",
  WAN_CHAI = "Wan Chai",
  EASTERN = "Eastern",
  YAU_TSIM_MONG = "Yau Tsim Mong",
  SHAM_SHUI_PO = "Sham Shui Po",
  KOWLOON_CITY = "Kowloon City",
  WON_TAI_SIN = "Wong Tai Sin",
  KWUN_TONG = "Kwun Tong",
  TSUEN_WAN = "Tsuen Wan",
  KWAI_CHING = "Kwai Ching",
  TAI_PO = "Tai Po",
  SHATIN = "Shatin",
  TUEN_MUN = "Tuen Mun",
  YUEN_LONG = "Yuen Long",
  NORTH = "North",
}
