import { getDomiciledState } from "./labels";

export const formatToCreateQuoteState = (quoteData: any) => {
  return {
    quoteNo: quoteData.quoteNo,
    quoteId: quoteData.quoteId,
    planName: quoteData.travelPlan.id,
    status: quoteData.status,
    policyHoldersName: quoteData.proposerName,

    validityDate: new Date(quoteData.validityDate),

    basePrice: quoteData.travelPlan.price,
    totalPrice: quoteData.travelPlan.total,
    brokerageRate: quoteData.travelPlan.brokerage.percent,
    brokerageAmount: quoteData.travelPlan.brokerage.amount,
    gstRate: quoteData.travelPlan.gst.percent,
    gstAmount: quoteData.travelPlan.gst.amount,
    stampDutyRate: quoteData.travelPlan.stampDuty.percent,
    stampDutyAmount: quoteData.travelPlan.stampDuty.amount,

    exemptedStampDuty: quoteData.travelDetails.stampDuty,
    regionCode: quoteData.travelDetails.domiciledState,
    domicileRegion: getDomiciledState(quoteData.travelDetails.domiciledState),
    internationalTravelDays: quoteData.travelDetails.internationalTravelDays,
    domesticTravelDays: quoteData.travelDetails.domesticTravelDays,
    coverageStartDate: new Date(quoteData.travelDetails.startDate),
    coverageEndDate: new Date(quoteData.travelDetails.endDate),

    agentMail: quoteData.agentEmail,

    familyTrust: quoteData.mandatoryClause.auRegisteredBusinesss,
    annualRenewableBusiness: quoteData.mandatoryClause.annuallyRenewableBTP,
    donNotTravelAdvice: quoteData.mandatoryClause.doNotTravelLocation,

    excessClaimsCover: quoteData.mandatoryClause.greaterThan$15000,
    exceedDuration: quoteData.mandatoryClause.greaterThan180days,
    morePeopleInTrip: quoteData.mandatoryClause.moreThan10people,
    engagedActivities: quoteData.mandatoryClause.travelActivity,
    privateTravel: quoteData.mandatoryClause.privateTravel50percent,
    collectiveFlights: quoteData.mandatoryClause.collective10Flights,
  };
};
