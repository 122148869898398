import { HEADER_CONTENT_TYPE, post, get } from "./http";
import { addAuthorizationHeader } from "../../../shared/authorization";

const agentHeaders = async () => addAuthorizationHeader(HEADER_CONTENT_TYPE);

export const agentPost = async <TBody, TResponse>(
  url: string | undefined,
  endpoint: string,
  body: TBody,
  responseMatchesSchema: (data: unknown) => boolean
) => {
  const headers = await agentHeaders();
  return post<TBody, TResponse>(url, endpoint, headers, body, responseMatchesSchema);
};

export const agentGet = async <TResponse>(url: string | undefined, endpoint: string, responseMatchesSchema: (data: unknown) => boolean) => {
  const headers = await agentHeaders();
  return get<TResponse>(url, endpoint, headers, responseMatchesSchema);
};
