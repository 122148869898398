import { TravelDetails } from "../../../../../types/travel/Policy";
import { ContentCell, LabelCell, NormalRow, SummaryTable } from "./SummaryTable";
import { calculateAnnualEndDate, formatToDisplayDate } from "../../../../../utils/date";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";

const travelLabels = {
  policyTypes: {
    "single-trip": "Single Trip",
    "annual-trip": "Annual",
  },
  locations: {
    asean: "ASEAN",
    "asia-pacific": "Asia Pacific",
    worldwide: "Worldwide",
  },
};

const TravelDetailsSummary = ({ policyType, groupType, adultCount, childrenCount, location, endDate, startDate }: TravelDetails) => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.summary.summarySection.policyDetails" });

  return (
    <SummaryTable className="w-full md:mt-4 table-fixed">
      <NormalRow>
        <LabelCell>{t("coverage.label")}</LabelCell>
        <ContentCell>{`${policyType && travelLabels.policyTypes[policyType]} (${capitalize(groupType)})`}</ContentCell>
      </NormalRow>
      <NormalRow>
        <LabelCell>{t("travelDates.label")}</LabelCell>
        <ContentCell>
          {t("travelDates.content", {
            startDate: formatToDisplayDate(startDate),
            endDate: formatToDisplayDate(policyType === "annual-trip" ? startDate && calculateAnnualEndDate(startDate) : endDate),
          })}
        </ContentCell>
      </NormalRow>
      <NormalRow>
        <LabelCell>{t("destination.label")}</LabelCell>
        <ContentCell>{location && travelLabels.locations[location]}</ContentCell>
      </NormalRow>
      <NormalRow>
        <LabelCell>{t("insuredCount.label")}</LabelCell>
        <ContentCell>{`${t("insuredCount.content.adults", { adultCount: adultCount })}${
          childrenCount > 0
            ? t("insuredCount.content.children", {
                childrenCount: childrenCount,
              })
            : ""
        }`}</ContentCell>
      </NormalRow>
    </SummaryTable>
  );
};

export default TravelDetailsSummary;
