import { useTranslation } from "react-i18next";
import photosStacked from "../../assets/images/photos-stacked.png";
import { LoginState, useAuth } from "../../hooks/useAuth";
import { useClientId } from "../../hooks/useClientId";
import { OverviewLinks } from "./OverviewLinks";
import ContentContainer from "../../components/basics/ContentContainer";
import { Alert } from "../../components/attentions";
import RawHTML from "../../components/basics/RawHTML";

const Overview = () => {
  const { user } = useAuth();
  const clientId = useClientId();
  const isLoggedOut = user === LoginState.LoggedOut || user === LoginState.Unknown;
  const { t } = useTranslation("pages", { keyPrefix: "travel.overview" });
  const userType = clientId ? "client" : "agent";
  return (
    <>
      <div className={`md:bg-curved-white md:mb-6 md:pb-28 ${isLoggedOut && !clientId && "md:mb-60"}`}>
        <div className="p-3 md:p-0">
          <ContentContainer flex>
            <div className="md:w-3/5">
              <div className={isLoggedOut ? " md:pt-8 pt-4 xxl:pt-16 pb-8 xxl:pb-12" : "md:pt-24 xxl:pt-48 pb-8 xxl:pb-48 mb-6"}>
                <h1 className="font-serif text-black text-4xl font-semibold md:text-5xl">{t(`header.title.${userType}`)}</h1>
                <h2 className="text-black text-sm pt-4 pr-8 md:pr-0 md:text-2xl md:mb-0 mb-5">{t(`header.subtitle.${userType}`)}</h2>
                {isLoggedOut && (
                  <div className="hidden md:block">
                    <OverviewLinks />
                  </div>
                )}
              </div>
            </div>
            <div className="hidden absolute md:block pt-9 w-2/5 -right-24">
              <img src={photosStacked} className="md:min-w-[19.5rem] md:max-w-[25rem] xxl:min-w-[28rem] xxl:max-w-[28rem]" alt="photos-stacked" />
            </div>
          </ContentContainer>
        </div>
      </div>
      {userType === "client" && (
        <div className="container-lg md:my-12">
          <Alert type="warning" icon="icon-info">
            <div className="space-y-4">
              <RawHTML boldedLinks content={t("agree.giaNotice")} />
            </div>
          </Alert>
        </div>
      )}
    </>
  );
};

export default Overview;
