import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../event_public_liability/EventHeader";
import Footer from "../../event_public_liability/EventFooter";
import { QueryClient, QueryClientProvider } from "react-query";
import Cls from "../../../utils/cls";
import { liabilityBindState } from "../bind/types";
import { Validations } from "../../../types/shared/Validation";
import { parseState } from "../../../helpers/shared/serialization";
import { checkout } from "../../../helpers/event_public_liability/api/checkoutApi";
import { proposerEventDetailsValidations } from "../../../helpers/event_public_liability/HkValidations";
import useEvent from "../../../hooks/useEvent";
import { useAuth } from "../../../hooks/useAuth";
import { getRegion } from "../../../helpers/shared/getRegion";
import { windowScroll } from "../../../helpers/shared/useNavigationEffects";
import FormStep from "../../../components/forms/FormStep";
import HkLiabilityBindRoutes from "./bind/routes";
import createInitialState from "../bind/state";
import { validate } from "../../../helpers/shared/validation";
import { Region } from "../../../enums/shared/forms/Region";

type ChangeHandler = <T extends keyof liabilityBindState>(key: T, value: liabilityBindState[T]) => void;
type test = (t: liabilityBindState) => void;
type submitErr = (t: boolean) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};

export const HkLiabilityBindContext = createContext<{
  // bind context for Hk event Pl
  state: liabilityBindState;
  onChange: ChangeHandler;
  setFormState: test;
  setSubmitError: submitErr;
  isSubmitting: boolean;
  submitError: unknown;
}>({
  onChange: noop,
  state: createInitialState(),
  setFormState: noop,
  setSubmitError: noop,
  isSubmitting: false,
  submitError: null,
});

enum Steps {
  "QuoteList",
  "QuoteDetails",
  "ProposerDetails",
  "ProposerAddress",
  "EventDetails",
  "Summary",
  "Terms",
}
const getURLForStep = (step: string) => `/event_public_liability/hk${step}`;
const stepsURLS = [
  getURLForStep("/retrieveList"),
  getURLForStep("/bind/quoteDetails"),
  getURLForStep("/bind/proposerDetails"),
  getURLForStep("/bind/proposerAddress"),
  getURLForStep("/bind/eventDetails"),
  getURLForStep("/bind/summary"),
  getURLForStep("/bind/terms"),
];
const steps: Steps[] = [Steps.ProposerDetails, Steps.ProposerAddress, Steps.EventDetails, Steps.Summary, Steps.Terms];
const amountOfSteps = steps.length;

const findInvalidPage = (validations: Validations<unknown>[], state: liabilityBindState): number =>
  validations.findIndex(validationSchema => Object.keys(validate(state, validationSchema)).length > 0);

const storageKey = "/event_public_liability/hk/retrieveList";
const getInitialBindState = () => {
  //  session storage for HK event pl
  try {
    const sessionStorageState = sessionStorage.getItem(storageKey);
    const initialState = sessionStorageState ? (parseState(sessionStorageState) as liabilityBindState) : {};
    return createInitialState(initialState);
  } catch (e) {
    console.error("Session storage could not be parsed", e);
    return createInitialState();
  }
};
const submit = async (formState: liabilityBindState, paymentMethod: string, region: string) => {
  try {
    const response = await checkout(formState, paymentMethod, region);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const BindQuoteForm: FC = () => {
  const location = useLocation();
  const quoteRow = location.state as any;
  const [formState, setFormState] = useState<liabilityBindState>(getInitialBindState());
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const navigate = useNavigate();
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const stepValidations = [proposerEventDetailsValidations];
  const persistState = useEvent(() => sessionStorage.setItem(storageKey, JSON.stringify(formState)));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const { user } = useAuth();
  const region = getRegion(user);
  const successUrl = "/event_public_liability/hk/payment/success";

  useEffect(() => {
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);
  useEffect(() => {
    setFormState({
      ...formState,
      ...quoteRow,
      ...(quoteRow?.quoteNo && { uenNumber: quoteRow?.quoteNo }),
    });
  }, [quoteRow?.quoteNo]);

  useEffect(() => {
    const validationsToRun = stepValidations.slice(0);
    findInvalidPage(validationsToRun, formState);
  }, [formState]);

  useEffect(() => {
    windowScroll();
  }, [location]);
  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const shouldRenderFormStep = step !== 1;

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await submit(formState, "credit", region);
      setSubmitError(false);
      navigate(successUrl);
    } catch {
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <HkLiabilityBindContext.Provider
      value={{
        setFormState: setFormState,
        onChange: onFieldChange,
        state: formState,
        isSubmitting: isSubmitting,
        submitError: submitError,
        setSubmitError: setSubmitError,
      }}
    >
      <main>
        <form>
          <div className={Cls("flex flex-col min-h-screen gap-0")}>
            <Header hideAccount={false} />
            {shouldRenderFormStep && <FormStep current={step - 1} total={amountOfSteps} />}
            <HkLiabilityBindRoutes onPrevious={goToPreviousStep} onNext={goToNextStep} creditTerms={onSubmit} region={Region.HK} />
            <Footer />
          </div>
        </form>
      </main>
    </HkLiabilityBindContext.Provider>
  );
};

const BindQuote: FC = () => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <BindQuoteForm />
    </QueryClientProvider>
  );
};

export default BindQuote;
