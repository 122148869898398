import React, { FC, useCallback, useState } from "react";
import useForm from "../../../hooks/useForm";
import { authenticationValidationsForSignup } from "../../../helpers/authentication/validations";
import useWithAsyncLoadingIndicator from "../../../hooks/useWithAsyncLoadingIndicator";
import { createAgent } from "../../../helpers/signup/signupApi";
import SignupLayoutForm from "./SignupLayoutForm";

export type AgentDetailsType = {
  email: string;
  givenName: string;
  surName: string;
  agentCode: string;
  agentName: string;
  region?: string;
  homeBranch?: string;
  afslNumber?: string;
  isNotListed?: boolean;
};
const agentInitialState = {
  email: "",
  givenName: "",
  surName: "",
  agentCode: "",
  agentName: "",
};
const SignupForm: FC = () => {
  const [state, setState] = useState<AgentDetailsType>(agentInitialState);
  const onFieldChange = useCallback(
    (key: "email" | "givenName" | "surName" | "agentCode" | "agentName", value: string | number) => {
      setState(prevState => ({ ...prevState, [key]: value }));
    },
    [setState]
  );
  const { errors, validateField, field, validateAll } = useForm(state, authenticationValidationsForSignup, onFieldChange);
  const hasErrors = Object.values(errors).filter(Boolean).length > 0;
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onClose = () => {
    setSignUpSuccess(false);
    setState(agentInitialState);
    setErrorMessage("");
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();
        //to prevent form from reload when clicking on submit button
        if (!validateAll()) {
          return;
        }
        await createAgent(state);
        setSignUpSuccess(true);
      } catch (error) {
        setErrorMessage("Something went wrong. Please try again later.");
        setSignUpSuccess(false);
      }
    },
    [state, validateAll, setSignUpSuccess]
  );
  const { isLoading, action: onSubmit } = useWithAsyncLoadingIndicator(handleSubmit);
  return (
    <SignupLayoutForm
      hasErrors={hasErrors}
      errorMessage={errorMessage}
      signUpSuccess={signUpSuccess}
      onSubmit={onSubmit}
      isLoading={isLoading}
      errors={errors}
      validateField={validateField}
      state={state}
      field={field}
      onClose={onClose}
      setState={setState}
    />
  );
};
export default SignupForm;
