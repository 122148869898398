import { Article } from "../../../components/groups";
import { Link } from "../../../components/basics";
import { ButtonType, createTextClasses } from "../../../helpers/shared/buttonStyling";
import { FC } from "react";
type Props = {
  t: any;
};

const Cancel: FC<React.PropsWithChildren<Props>> = ({ t }) => {
  return (
    <div className="container-flow flex-grow mt-6 lg:mt-20">
      <div className="flex gap-10">
        <div className="w-full">
          <h2 className="font-serif font-bold text-4xl mb">{t("payment.cancelled.title")}</h2>
          <Article>
            <p className="mb-8">{t("payment.cancelled.content")}</p>
            <Link to={t("header.home")} type={ButtonType.PRIMARY}>
              <span className={createTextClasses(ButtonType.PRIMARY)}>{t("payment.cancelled.button.back")}</span>
            </Link>
          </Article>
        </div>
      </div>
    </div>
  );
};
export default Cancel;
