import { CognitoUser } from "@aws-amplify/auth";
import { Hub, HubCallback } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

export interface IUser {
  id: string;
  email: string;
  region: string;
  branch: string;
  fullName: string;
  role: string[];
}

export enum LoginState {
  LoggedOut = "LoggedOut",
  Unknown = "Unknown",
}
export type UserState = LoginState | IUser;

export interface IAuthContext {
  user: UserState;
  signOut: () => void;
  isSignedIn: () => boolean;
  clearAuthState: () => void;
}

interface CognitoUserWithAttributes extends CognitoUser {
  attributes: Record<string, string>;
  signInUserSession: Record<string, any>;
}

interface IProps {
  children?: React.ReactNode;
}

export const AuthContext = createContext<IAuthContext>({
  user: LoginState.Unknown,
  signOut: () => undefined,
  isSignedIn: () => false,
  clearAuthState: () => undefined,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: IProps) => {
  const auth = useCognito();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.error(error);
  }
};

const isValidUser = (value: unknown): value is CognitoUserWithAttributes => {
  return !!value && typeof value === "object" && "attributes" in value;
};

const ensureValidUser = async (user: unknown) => {
  if (isValidUser(user)) return user;

  const currentUser = await Auth.currentAuthenticatedUser();
  if (isValidUser(currentUser)) return currentUser;

  throw new Error("Invalid user");
};

const loggedOutStates: unknown[] = [LoginState.LoggedOut, LoginState.Unknown];
const isLoggedIn = (value: LoginState | IUser): value is IUser => !loggedOutStates.includes(value);

const getUserObject = (attributes: Record<string, string>, signInUserSession: Record<string, any>) => {
  return {
    id: attributes.sub,
    email: attributes.email,
    region: attributes["custom:region"],
    branch: attributes["custom:home_branch"],
    fullName: [attributes.given_name, attributes.family_name].filter(Boolean).join(" "),
    role: signInUserSession.accessToken.payload["cognito:groups"],
  };
};

export const useCognito = () => {
  const [user, setUser] = useState<UserState>(LoginState.Unknown);
  const clearAuthState = () => setUser(LoginState.Unknown);

  const isSignedIn = useCallback(() => isLoggedIn(user), [user]);

  const setOrFetchUser = async (data: unknown) => {
    try {
      const { attributes, signInUserSession } = await ensureValidUser(data);
      setUser(getUserObject(attributes, signInUserSession));
    } catch (err) {
      console.error(err);
    }
  };

  const authListener: HubCallback = useCallback(({ payload: { event, data } }) => {
    switch (event) {
      case "signIn":
        setOrFetchUser(data);
        break;
      case "signOut":
        setUser(LoginState.LoggedOut);
        break;
    }
  }, []);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(({ attributes, signInUserSession }) => {
        setUser(getUserObject(attributes, signInUserSession));
      })
      .catch(() => {
        setUser(LoginState.LoggedOut);
      });
  }, []);

  useEffect(() => {
    Hub.listen("auth", authListener);
    return () => Hub.remove("auth", authListener);
  }, [authListener]);

  return { user, signOut, clearAuthState, isSignedIn };
};
