import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import { Button } from "../basics/Button";
import { Dialog } from "../interactives";
import { DialogFooter } from "../interactives/Dialog";

const SessionExpiredDialog: VFC<{ onClose: VoidFunction }> = props => {
  const { t } = useTranslation("pages", { keyPrefix: "auth.login.common.pendingLoginSessionExpired" });
  return (
    <Dialog onClose={props.onClose} title={t("title")}>
      <p>{t("text")}</p>
      <DialogFooter>
        <Button className="whitespace-nowrap ml-auto" type={ButtonType.PRIMARY} onClick={props.onClose}>
          {t("close")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default SessionExpiredDialog;
