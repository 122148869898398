import { clearSessionStorage } from "../../helpers/shared/sessionStorage";
import { Button } from "../../components/basics/Button";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useClientId } from "../../hooks/useClientId";

export const OverviewLinks = () => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.overview" });
  const clientId = useClientId();
  const location = useLocation();
  const navigate = useNavigate();

  if (clientId) {
    return (
      <div className="flex-col gap-4 text-lg w-full pb-4 md:pb-0 md:pt-8 xxl:pt-20 md:mt-0 md:w-2/3 flex">
        <Button
          className="h-10"
          onClick={() => {
            clearSessionStorage("travelQuote");
            navigate("details");
          }}
          type={ButtonType.PRIMARY}
        >
          {t("header.button.getQuote")}
        </Button>
        <Button
          className="h-10"
          onClick={() => window.open("//claims.alliedworldinsurance.io/sg/personal/travel", "_blank", "noreferrer")}
          type={ButtonType.SECONDARY}
        >
          {t("header.button.travelClaim")}
        </Button>
      </div>
    );
  }

  return (
    //login button
    <div className="flex-col gap-4 text-lg w-full pb-4 md:pb-0 md:pt-8 xxl:pt-20 md:mt-0 md:w-2/3 flex">
      <Button className="h-10" onClick={() => navigate("/user/login", { replace: true, state: { from: location } })} type={ButtonType.PRIMARY}>
        {t("header.button.login")}
      </Button>
      <p className="font-sans">
        For Australian users only:{" "}
        <span
          className={`text-red hover:text-red-dark hover:cursor-pointer underline font-bold`}
          role="button"
          onClick={() => navigate("/user/signup")}
        >
          Sign up
        </span>
      </p>
    </div>
  );
};
