import { FC } from "react";
import { FormGroup } from "../../../components/forms";
import TextInput from "../../../components/form-fields/TextInput";
import SelectInput from "../../../components/form-fields/SelectInput";
import { Territories } from "../../../enums/shared/forms/Territories";
import { Districts } from "../../../enums/shared/forms/Districts";

type AddressFormGroupProps = {
  title: string;
  ariaLabel: string;
  houseNumberValue: string;
  houseNumberProps: any;
  streetNameValue: string;
  streetNameProps: any;
  districtValue: string;
  districtProps: any;
  territoryValue: string;
  territoryProps: any;
  buildingValue: string;
  buildingProps: any;
  disableForm?: boolean;
  labelForBlockHouseNo: string;
  labelForBuilding: string;
  t: any;
};

const AddressFormGroup: FC<AddressFormGroupProps> = ({
  title,
  ariaLabel,
  buildingValue,
  buildingProps,
  houseNumberValue,
  houseNumberProps,
  streetNameValue,
  streetNameProps,
  districtValue,
  districtProps,
  territoryProps,
  territoryValue,
  labelForBlockHouseNo,
  labelForBuilding,
  t,
  disableForm,
  // hk epl address form
}) => {
  return (
    <FormGroup title={title}>
      <TextInput
        disabled={disableForm}
        aria-label={`${ariaLabel}-blockHouseNo`}
        value={houseNumberValue}
        {...houseNumberProps}
        label={labelForBlockHouseNo}
      />
      <TextInput disabled={disableForm} aria-label={`${ariaLabel}-buildingNo`} value={buildingValue} {...buildingProps} label={labelForBuilding} />
      <TextInput
        disabled={disableForm}
        aria-label={`${ariaLabel}-street`}
        value={streetNameValue}
        {...streetNameProps}
        label={t("streetNumberAndName")}
      />
      <SelectInput
        aria-label={`${ariaLabel}-district`}
        label="District"
        value={districtValue}
        options={Object.values(Districts).sort()}
        disabled={disableForm}
        {...districtProps}
      />
      <SelectInput
        aria-label={`${ariaLabel}-territory`}
        label="Territory"
        value={territoryValue}
        options={Object.values(Territories).sort()}
        disabled={disableForm}
        {...territoryProps}
      />
    </FormGroup>
  );
};
export default AddressFormGroup;
