import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import Dialog from "../../../components/interactives/Dialog";
import { useTranslation } from "react-i18next";

interface IProps {
  onClose: () => void;
  error: any;
}

const UserInputError = ({ onClose, error }: IProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.newProduct" });
  return (
    <Dialog onClose={onClose} title={error("dialog.error.title")}>
      <div className="pb-[20px]"> {error("dialog.error.subTitle")}</div>
      <p>
        {error("dialog.error.subTitle1")}{" "}
        <a className="text-red font-bold hover:underline" href={`mailto:${t("customerSupport.emailId")}`} target="_blank" rel="noreferrer">
          {t("customerSupport.emailId")}
        </a>
        {"."}
      </p>
      <div className="flex-col-reverse md:flex-row justify-between gap-4 mt-4">
        <Button className="ml-auto" type={ButtonType.PRIMARY} onClick={onClose}>
          {error("dialog.error.button.back")}
        </Button>
      </div>
    </Dialog>
  );
};

export default UserInputError;
