import { AnimatedSidebar } from "./../../../components/interactives";
import { Button } from "./../../../components/basics/Button";
import { ButtonType } from "./../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
  t: any;
}

export const AdditionalInsuredInfo = ({ t, show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className={"text-xl font-bold mb-4"}>{t("additionalInsuredCover.title")}</h2>
        <p>{t("additionalInsuredCover.description")}</p>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
