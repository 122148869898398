import { FormGroup } from "../../../../components/forms";
import { DateInput, RadioInput, NumberInput } from "../../../../components/form-fields";
import { FC, useState } from "react";
import { FieldChange, TravelDetails } from "../../../../types/travel/Policy";
import { H2 } from "../../../../components/basics";
import { detailsValidations } from "../../../../helpers/travel/validations";
import { TripTypeInfo } from "../more-info/TripTypeInfo";
import { TravellersInfo } from "../more-info/TravellersInfo";
import { TravelRegionInfo } from "../more-info/TravelRegionInfo";
import useForm from "../../../../hooks/useForm";
import { useTranslation } from "react-i18next";
import classNamePresets from "../../../../utils/classNamePresets";
import PolicyFormStepNavigation from "../PolicyFormStepNavigation";

export interface DetailsProps {
  flowStateSlice: TravelDetails;
  onFieldChange: FieldChange<TravelDetails>;
  onNext: () => void;
}

const Details: FC<React.PropsWithChildren<DetailsProps>> = ({ flowStateSlice, onFieldChange, onNext }) => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.details" });

  const isAnnual = flowStateSlice.policyType === "annual-trip";

  const { validateField, validateForm, field, handleFieldChange } = useForm(flowStateSlice, detailsValidations, onFieldChange);
  const handleNext = () => validateForm(onNext);
  const [moreInfo, setMoreInfo] = useState<string | null>(null);

  const handleDateChange: FieldChange<TravelDetails> = (key, value) => {
    handleFieldChange(key, value);
    validateField(key, value);
  };

  return (
    <>
      <div className="container-flow flex-grow">
        <H2 className={classNamePresets.mainHeading}>{t("title")}</H2>
        <FormGroup
          title={t("form.groupTitle.policyType")}
          subtitle={t("form.groupTitle.subTitle.covidCoverage")}
          onMoreInfo={() => setMoreInfo("trip-type")}
        >
          <RadioInput
            aria-label="policy type"
            {...field.policyType}
            value={flowStateSlice.policyType}
            options={[
              { value: "single-trip", label: t("form.label.singleTrip") },
              { value: "annual-trip", label: t("form.label.annualTrip") },
            ]}
          />
        </FormGroup>
        <FormGroup title={t("form.groupTitle.travellers")} onMoreInfo={() => setMoreInfo("travellers")}>
          <NumberInput {...field.adultCount} value={flowStateSlice.adultCount} label={t("form.label.adults")} min={1} max={2} />
          <NumberInput {...field.childrenCount} value={flowStateSlice.childrenCount} label={t("form.label.children")} min={0} max={10} />
        </FormGroup>
        <FormGroup title={t("form.groupTitle.region")} onMoreInfo={() => setMoreInfo("travel-region")}>
          <RadioInput
            aria-label="location"
            {...field.location}
            value={flowStateSlice.location}
            options={[
              { value: "asean", label: t("form.label.asean"), visible: !isAnnual },
              { value: "asia-pacific", label: t("form.label.asiaPacific") },
              { value: "worldwide", label: t("form.label.worldwide") },
            ]}
          />
        </FormGroup>
        <FormGroup title={t("form.groupTitle.startDate")} hasVerticalFieldsOrientation>
          <DateInput
            {...field.startDate}
            value={flowStateSlice.startDate}
            label={isAnnual ? t("form.label.startDate.annual") : t("form.label.startDate.single")}
            onValueChange={v => {
              handleDateChange("startDate", v);
              field.startDate.onValueChange(v);

              // trigger end date validation
              if (flowStateSlice.endDate) {
                validateField("endDate", flowStateSlice.endDate, { startDate: v });
              }
            }}
          />
        </FormGroup>
        {!isAnnual && (
          <FormGroup title={t("form.groupTitle.endDate")} hasVerticalFieldsOrientation={true}>
            <DateInput
              {...field.endDate}
              value={flowStateSlice.endDate}
              onValueChange={value => {
                handleDateChange("endDate", value);
                field.endDate.onValueChange(value);

                validateField("endDate", value, { startDate: flowStateSlice.startDate });
              }}
              label={t("form.label.endDate.single")}
            />
          </FormGroup>
        )}
      </div>

      <TripTypeInfo show={moreInfo === "trip-type"} onClose={() => setMoreInfo(null)} />
      <TravellersInfo show={moreInfo === "travellers"} onClose={() => setMoreInfo(null)} />
      <TravelRegionInfo show={moreInfo === "travel-region"} onClose={() => setMoreInfo(null)} />

      <PolicyFormStepNavigation onNext={handleNext} />
    </>
  );
};

export default Details;
