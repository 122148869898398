import { Link } from "react-router-dom";
import { FC } from "react";
import ContentContainer from "../../../components/basics/ContentContainer";
import { sanitizeHtml } from "../../../components/basics/RawHTML";

type FooterProps = {
  footer: any;
  abnUrlOfAu?: string;
};

const Footer: FC<React.PropsWithChildren<FooterProps>> = ({ footer, abnUrlOfAu }) => {
  return (
    <footer className="mt-auto">
      <div className="pt-4 pb-4 md:pb-4 xxl:pb-4 bg-white">
        <ContentContainer omitTopPadding>
          <p
            className="text-black text-sm hover:prose-a:font-bold prose max-w-none"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(footer("note", { abnUrlOfAu })) }}
          ></p>
        </ContentContainer>
      </div>
      <div className="py-1 pb-4 bg-white">
        <ContentContainer omitTopPadding>
          <div className="flex flex-col md:flex-row">
            <p className="text-black text-sm">{footer("copyright")}</p>
            <div className="flex md:justify-between text-black text-sm pt-4 md:pt-0">
              <p className="px-2 hidden md:block">|</p>
              <Link to={footer("privacyPolicyUrl")} className="hover:text-red" target="_blank">
                {footer("button.privacyPolicy")}
              </Link>
              <p className="px-2">|</p>
              <Link to={footer("termsConditionsUrl")} className="hover:text-red" target="_blank">
                {footer("button.termsConditions")}
              </Link>
            </div>
          </div>
        </ContentContainer>
      </div>
    </footer>
  );
};

export default Footer;
