import { FC } from "react";
import LiabilitySection from "../../../components/forms/LiabilitySection";
import ServerError from "../../errors/ServerError";
import Spinner from "../../../components/icons/Spinner";
import { CommonTable, ContentCell, HeadingRow } from "./CommonTable";
import { Pricing } from "./Pricing";
import PricingLabels from "./PricingLabels";

type Props = {
  t: any;
  serverError: boolean;
  arePricesLoaded: any;
  pricingState: any;
};
const PricingDetail: FC<Props> = ({ serverError, arePricesLoaded, pricingState, t }) => {
  return (
    <div className="container-flow flex-1">
      <div className="mx-[-0.5rem] md:px-0 md:mx-0 md:w-full">
        <div className="bg-white px-4 md:px-16 mt-8 md:mt-16 rounded-lg shadow-xl">
          <div className="font-semibold md:text-base text-xs opacity-30 pt-8">{t("header.title1")}</div>
          <LiabilitySection name={t("PricingLabels.plan")} className="md:text-6xl text-2xl font-serif font-semibold">
            {serverError && <ServerError supportEmailId={t("customerSupport.emailId")} />}
            {!arePricesLoaded ? (
              <div className="py-32 flex justify-around">
                <Spinner width="100" height="100" color="#3F9293"></Spinner>
              </div>
            ) : (
              <>
                <CommonTable className="w-full table-fixed">
                  <HeadingRow>
                    <ContentCell>{t("PricingLabels.Heading1")}</ContentCell>
                    <ContentCell>{t("PricingLabels.Heading2")}</ContentCell>
                  </HeadingRow>
                  <Pricing plans={pricingState} />
                </CommonTable>
                <PricingLabels t={t} />
              </>
            )}
          </LiabilitySection>
        </div>
      </div>
    </div>
  );
};

export default PricingDetail;
