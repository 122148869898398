import { FC, PropsWithChildren } from "react";

type TableProps = PropsWithChildren<{
  className: string;
}>;
type RowColumnProps = PropsWithChildren<unknown>;

const Cell: FC<PropsWithChildren<{ className: string }>> = ({ children, className }) => <td className={className}>{children}</td>;
const Row: FC<PropsWithChildren<{ className: string }>> = ({ children, className }) => <tr className={className}>{children}</tr>;

export const LabelCell: FC<RowColumnProps> = ({ children }) => <Cell className="md:align-baseline pt-2 md:py-2 md:w-1/3">{children}</Cell>;
export const ContentCell: FC<RowColumnProps> = ({ children }) => <Cell className="font-bold pb-2 md:py-2">{children}</Cell>;
export const ContentPriceCell: FC<RowColumnProps> = ({ children }) => (
  <Cell className="flex justify-between pb-2 md:py-2 font-bold md:w-2/3">{children}</Cell>
);

export const NormalRow: FC<RowColumnProps> = ({ children }) => <Row className="flex flex-col md:flex-row">{children}</Row>;

export const SummaryTable: FC<TableProps> = ({ className, children }) => {
  return (
    <table className={className}>
      <tbody>{children}</tbody>
    </table>
  );
};
