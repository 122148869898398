import Spinner from "../../../components/icons/Spinner";
import { DocumentListResponse } from "../../../services/tpa/tpaApiServices";
import vector from "../../../assets/icons/vector.svg";

interface DocumentsProps {
  policyDetails: DocumentListResponse;
  loading: boolean;
  isTpa?: boolean;
}

const LoadingSpinner = () => (
  <div className="flex justify-around mt-16">
    <Spinner width="166" height="166" color="#3F9293"></Spinner>
  </div>
);

const Documents: React.FC<DocumentsProps> = ({ loading, policyDetails, isTpa = false }) => {
  const hasNoDocForPolicy = policyDetails.length === 0;
  const camelCaseToSpaced = (fileName: string) => fileName.replace(/([A-Z])/g, " $1").trim();
  const POLICY_SCHEDULE = "Policy Schedule";

  if (loading) return <LoadingSpinner />;
  return (
    <section>
      {hasNoDocForPolicy ? (
        <span className="flex justify-center text-disabled mt-8">No records found.</span>
      ) : (
        <div>
          {policyDetails?.map(policy => (
            <tr
              key={policy.id}
              aria-label={policy.filename}
              className="h-12 flex justify-between items-center px-8 text-sm bg-white border-b font-bold border-color hover:shadow-hover cursor-pointer"
              onClick={() => window.open(policy.url, "_blank", "noreferrer")}
            >
              <td className="flex items-center text-sm bg-white cursor-default hover:shadow-hover capitalize">
                {isTpa
                  ? policy.filename.substring(0, 4) === "END_"
                    ? "Endorsement/Cancellation " + policy.filename.substring(policy.filename.length - 1, policy.filename.length)
                    : POLICY_SCHEDULE
                  : camelCaseToSpaced(policy.filename)
                      .replace(/\.[^/.]+$/, "")
                      .replace(/^schedule$/i, POLICY_SCHEDULE)
                      .split("_")
                      .join(" ")}
              </td>
              <button type="button" data-testid="btn-click" aria-label="arrow-btn" name="arrow-btn">
                <img className="w-[0.375rem]" src={vector} alt="arrow-btn" />
              </button>
            </tr>
          ))}
        </div>
      )}
    </section>
  );
};

export default Documents;
