import React, { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useOutsideClick from "../../helpers/shared/useOutsideClick";

interface DropdownOption {
  label: string;
  to?: string;
  value?: string;
  filterGroup?: string;
}

interface ActionMenuProps {
  title?: string;
  options: DropdownOption[];
  classNames?: string;
  optionBoxPosition?: string;
  hoverEnabled?: boolean;
  clickEnabled?: boolean;
  updateHandler?: (option: any) => void;
  activeOptions?: string[];
}

const ActionMenu: FC<React.PropsWithChildren<ActionMenuProps>> = ({
  title,
  children,
  options,
  classNames,
  optionBoxPosition = "top-[3.25rem] -left-[9rem]",
  hoverEnabled,
  clickEnabled,
  updateHandler,
  activeOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickRef = useRef<HTMLDivElement | null>(null);
  const toggleHover = () => hoverEnabled && setIsOpen(!isOpen);
  const handleClick = () => clickEnabled && setIsOpen(!isOpen);
  useOutsideClick(onClickRef, () => {
    setIsOpen(false);
  });

  return (
    <div tabIndex={0} className={classNames} onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={handleClick} ref={onClickRef}>
      {children || <div aria-expanded={isOpen}>{title}</div>}

      {isOpen && (
        <div role="menu" className={`absolute z-20 ${optionBoxPosition}`}>
          <div className="m-2 h-8"></div>
          <ul className='w-full md:w-fit flex text-black flex-col justify-center items-start gap-4 p-8 border-0 rounded bg-white shadow-lg drop-shadow-md"'>
            {options.map((option, index) => (
              <li role="menuitem" key={index} className="inset-0">
                {option.to ? (
                  <Link to={option?.to}>
                    <button
                      aria-label={`option-${option.label}`}
                      className={`py-2 px-4 font-sans text-md font-normal whitespace-nowrap overflow-hidden hover:bg-[#b31b341a] hover:border-0 rounded cursor-pointer`}
                    >
                      {option.label}
                    </button>
                  </Link>
                ) : (
                  <button
                    aria-label={`option-${option.filterGroup}-${option.value}`}
                    className={`py-2 px-4 font-sans text-md  whitespace-nowrap overflow-hidden hover:bg-[#b31b341a] hover:border-0 rounded cursor-pointer ${
                      activeOptions?.includes(option.value as string) ? "text-red font-bold" : ""
                    }`}
                    onClick={() => updateHandler && updateHandler(option)}
                  >
                    {option.label}
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
