import { AnimatedSidebar } from "../../../components/interactives";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

const DronCoverInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose} aria-hidden={!show}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">Drones Third Party Liability Cover</h2>
        <p>
          Cover against third party bodily injury or property damage caused by Unmanned Aircraft Systems (Drones) used during the event at the event
          venue, provided that all relevant permit and approval by respective authorities are in place before the event.
        </p>
        <h3 className="text-md font-bold mt-6">Sublimit:</h3>
        <p>SGD 150,000 per occurrence and in the aggregate</p>
        <h3 className="text-md font-bold mt-6">Deductible:</h3>
        <p>SGD 10,000 on each and every claim </p>
        <h3 className="text-md font-bold mt-6">Exclusions:</h3>
        <ul className="list-disc pl-4">
          <li>Exclude any loss or damage to the drones</li>
          <li>Exclude non-compliance with the aviation authority regulations</li>
        </ul>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};

export default DronCoverInfo;
