import SignupResetForm from "./SignupResetForm";
import ResetForm from "./reset/ResetForm";

const Reset: React.FC = () => {
  return (
    <SignupResetForm title={"Reset form Agent"}>
      <ResetForm />
    </SignupResetForm>
  );
};

export default Reset;
