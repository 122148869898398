import { GroupPersonalAccidentBindState } from "../../../pages/group_personal_accident/bind/types";
import agentApiService from "../../../services/group_personal_accident/agentApiService";
import { CheckoutRequest } from "../../../types/group_personal_accident/api/Quote";
import { getProposerDetails, getTerms } from "../../group_travel/api/checkout";
import { createQuoteRequest } from "./quoteApi";

export const createCheckoutRequest = (state: GroupPersonalAccidentBindState): CheckoutRequest => ({
  ...createQuoteRequest(state),
  quoteId: state.quoteId,
  proposerDetails: getProposerDetails(state),
  termsConditions: getTerms(state),
  quoteNo: state.quoteNo,
  paymentDetails: {
    method: "credit",
  },
});

export const checkout = async (state: GroupPersonalAccidentBindState) => {
  const data = agentApiService.checkout(state);
  return data;
};
