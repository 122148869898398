import { Dispatch, FC, SetStateAction } from "react";
import { DateInput, RadioInput } from "../../../components/form-fields";
import { FormGroup } from "../../../components/forms";
import { FieldChange, liabilityBindState } from "../bind/types";
import LimitPerOccurrenceRadioGroup from "../bind/LimitPerOccurrenceRadioGroup";
import AdditionalInsuredField from "./AdditionalInsuredField";
import { addDays } from "date-fns";
import { removeExtraSpaces } from "../../../helpers/shared/formHandlers";

type Props = {
  state: liabilityBindState;
  setMoreInfo?: Dispatch<SetStateAction<string | null>>;
  t: any;
  field: any;
  handleFieldChange: FieldChange<liabilityBindState>;
  validateField: (name: string, value: unknown, newData?: Record<string, unknown>) => void;
  setFormState: any;
};

const ProposerDetailsMoreInfo: FC<Props> = ({ state, setMoreInfo, field, handleFieldChange, validateField, t, setFormState }) => {
  const handleDateChange: FieldChange<liabilityBindState> = (key, value) => {
    handleFieldChange(key, value);
    validateField(key, value);
  };

  return (
    <>
      <LimitPerOccurrenceRadioGroup
        mainClassName="flex justify-between flex-wrap mb-5 md:mb-12 gap-x-18"
        labelClassName="mt-8 flex-shrink max-w-[20rem]"
        fieldsClassName="min-w-[20rem] flex-1 ml-0.5"
        state={state}
        options={state.plans}
        limit={field.limit}
        ariaLabel="limit of liability"
        limitTranslationObject={t}
      />
      <FormGroup title={t("startdateOfCoverage")}>
        <DateInput
          {...field.coverageStartDate}
          value={state.coverageStartDate}
          minDate={addDays(new Date(), 1)}
          label={t("startdateOfCoverage")}
          onValueChange={v => {
            handleDateChange("coverageStartDate", v);
            field.coverageStartDate.onValueChange(v);
          }}
        />
      </FormGroup>
      <FormGroup title={t("additionalInsured")} onMoreInfo={setMoreInfo ? () => setMoreInfo("additionalInsured-cover") : undefined}>
        <RadioInput
          aria-label="additionalInsured"
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
          ]}
          {...field.additionalInsured}
          value={state.additionalInsured}
        />
      </FormGroup>
      {state.additionalInsured === "yes" ? (
        <>
          <AdditionalInsuredField
            fieldProps={{ ...field.landlord, onBlur: removeExtraSpaces("landlord", setFormState, validateField) }}
            label={t("landlord")}
            subtitle={t("subTitleLandlordandPrincipal")}
            value={state.landlord}
          />
          <AdditionalInsuredField
            fieldProps={{ ...field.principal, onBlur: removeExtraSpaces("principal", setFormState, validateField) }}
            label={t("pricipal")}
            subtitle={t("subTitleLandlordandPrincipal")}
            value={state.principal}
          />
        </>
      ) : null}
    </>
  );
};

export default ProposerDetailsMoreInfo;
