import { Section } from "../components/interactives";
import cls from "../utils/cls";

export type ProductType = {
  type?: string;
  img: string;
  text: string;
  topLinks: any;
  download: boolean;
  className: string;
  contentDirectionClass?: string;
  isActive?: boolean;
  alt?: any;
};

const Product = ({ alt, img, text, topLinks, download, className, contentDirectionClass = "md:flex-row gap-16 md:pl-[102px]" }: ProductType) => {
  return (
    <div>
      <Section className={cls(className, "md:shadow-lg drop-shadow-md")}>
        <div className="flex justify-start md:container-lg bg-white gap-8">
          <img src={img} alt={alt} className="h-10 md:pl-4 md:h-20 md:w-20 w-10 md:my-2 mt-4 mb-8" />
          <div className="my-auto text-xl font-sans text-black font-bold md:text-2xl block md:hidden pt-4 pb-2 mt-2 mb-6">{text}</div>
          <div className="my-auto text-xl font-sans text-black font-bold md:text-2xl hidden md:block pt-2">{text}</div>
        </div>
        {download ? (
          <div className="flex flex-col md:flex-row container-lg bg-white md:pt-0 xs:pt-4 md:pb-0 pb-4">
            <div className={`flex ${contentDirectionClass}`}>{topLinks}</div>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row justify-center container-lg bg-white gap-4 md:mt-8 mb-4 md:mb-0">{topLinks}</div>
        )}
      </Section>
    </div>
  );
};

export default Product;
