import { HEADER_CONTENT_TYPE, post } from "./http";

const clientHeaders = (clientId: string) => {
  return {
    ...HEADER_CONTENT_TYPE,
    "X-Client-Id": clientId,
  };
};

export const clientPost = async <TBody, TResponse>(
  clientId: string,
  url: string | undefined,
  endpoint: string,
  body: TBody,
  responseMatchesSchema: (data: unknown) => boolean
) => {
  const headers = clientHeaders(clientId);
  return post<TBody, TResponse>(url, endpoint, headers, body, responseMatchesSchema);
};
