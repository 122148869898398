import agentApiService from "../../services/user/agentApiServices";
import { AgentDetailsType } from "../../pages/auth/signup/SignupForm";
import { AgentRequest } from "../../types/user/api/User";

export const createAgentRequest = (agentDetails: AgentDetailsType): AgentRequest => {
  return {
    email: agentDetails.email,
    given_name: agentDetails.givenName,
    family_name: agentDetails.surName,
    billing_code: agentDetails.agentCode,
    agency_name: agentDetails.agentName,
    region: agentDetails.region,
    home_branch: agentDetails.homeBranch,
    afslNumber: agentDetails.afslNumber,
    isNotListed: agentDetails.isNotListed,
  };
};

export const createAgent = async (agentDetails: AgentDetailsType) => {
  const response = await agentApiService.createAgent(agentDetails);
  return response;
};

export const createBrokerUser = async (agentDetails: AgentDetailsType) => {
  const response = await agentApiService.createBroker(agentDetails);
  return response;
};

export const getBrokerageInfo = async (payload: { afslNumber: string }) => {
  const data = await agentApiService.getBrokerageInfo(payload);
  return data;
};
