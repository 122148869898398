import { useTranslation } from "react-i18next";
import { clearEventPLSessionStorage } from "../../helpers/shared/sessionStorage";
import ThankYouMessage from "../components/ThankYouMessage";
import useOnComponentDidMount from "../../hooks/useOnComponentDidMount";
import Header from "../auth/layout/Header";
import SuccessBackButton from "../event_public_liability/SuccessBackButton";
import Footer from "./Footer";

const Success = () => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct.payment" });
  useOnComponentDidMount(() => clearEventPLSessionStorage("au/group_travel/bind"));
  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <Header hideAccount={false} home={"/"} />
      <ThankYouMessage t={t} additionalcontent={true} />
      <SuccessBackButton backButtonText={t("button.back")} />
      <Footer />
    </div>
  );
};
export default Success;
