import Ajv from "ajv";

export type PricePayload = {
  productType: "MPI";
  coverageStartDate: string;
  coverageEndDate: string;
  limit: number;
};

export type MPIPriceResponse = {
  gp: number;
  levy: number;
  total: number;
};

export const pricingResponseSchema = {
  type: "object",
  properties: {
    gp: {
      type: "number",
    },
    levy: {
      type: "number",
    },
    total: {
      type: "number",
    },
  },
  required: ["gp", "levy", "total"],
  additionalProperties: true,
};

export const validatePricingResponse = new Ajv().compile(pricingResponseSchema);
