import { LoginState, useAuth } from "../../hooks/useAuth";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getRole } from "../../helpers/shared/getRole";
import { Region } from "../../enums/shared/forms/Region";
import { Role } from "../../enums/shared/forms/Role";
import NotFound from "../../pages/errors/NotFound";

interface IProps {
  redirectPath: string;
  allowedRegion: Region | "All";
}

export const isTPARole = (role: string) => role !== Role.Unknown && role === Role.TPA;
const ProtectedTPARoute: FC<React.PropsWithChildren<IProps>> = ({ redirectPath }) => {
  const { user } = useAuth();
  const role = getRole(user);

  if (user === LoginState.LoggedOut) return <Navigate to={redirectPath} replace />;
  if (user === LoginState.Unknown) return null;
  if (!isTPARole(role)) return <NotFound homePage="/" />;

  return <Outlet />;
};

export default ProtectedTPARoute;
