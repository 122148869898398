import {
  Address,
  TermsConditions,
  TravelDetails,
  NewTravelPolicyState,
  TravelPlan,
  PersonalDetails,
  Requester,
  Traveller,
  Identity,
} from "../../types/travel/Policy";
import { PersonalTitle } from "../../enums/shared/forms/NewPolicy";

const defaultTravelDetails: TravelDetails = {
  policyType: null,
  location: null,
  adultCount: 1,
  childrenCount: 0,
  groupType: "individual",
  startDate: null,
  endDate: null,
};

export const defaultTravelPlan: TravelPlan = {
  planName: null,
  planPrice: null,
  planCurrency: null,
};

export const defaultAddress: Address = {
  postalCode: "",
  number: "",
  streetName: "",
  floorNumber: "",
  unitNumber: "",
};

export const defaultIdentity: Identity = {
  identificationNumber: "",
  identificationNumberStatus: "unknown",
  dateOfBirth: null,
};

export const defaultRequester: Requester = {
  title: PersonalTitle.EMPTY,
  givenName: "",
  familyName: "",
  phoneNumber: "",
  email: "",
  ...defaultIdentity,
};

export const defaultTraveller: Traveller = {
  fullName: "",
  ...defaultIdentity,
};

const defaultPersonalDetails: PersonalDetails = {
  requester: defaultRequester,
  address: defaultAddress,
  requestingPersonIsTravelingPerson: true,
  travellers: [],
};

const defaultTermsConditions: TermsConditions = {
  accepted: false,
  marketingVoiceCall: false,
  marketingTextMessage: false,
};

export const createInitialNewTravelPolicyState = (): NewTravelPolicyState => {
  return {
    travelDetails: defaultTravelDetails,
    travelPlan: defaultTravelPlan,
    personalDetails: defaultPersonalDetails,
    termsConditions: defaultTermsConditions,
  };
};
