export const pricingResponseSchema = {
  type: "object",
  properties: {
    plans: {
      type: "object",
      patternProperties: {
        "^[a-zA-Z0-9]*$": {
          type: "array",
          items: {
            type: "object",
            properties: {
              id: { type: "string" },
              price: { type: "number" },
              currency: { type: "string" },
              total: { type: "number" },
              gst: {
                type: "object",
                properties: {
                  percent: { type: "number" },
                  amount: { type: "number" },
                },
              },
              stampDuty: {
                type: "object",
                properties: {
                  percent: { type: "number" },
                  amount: { type: "number" },
                },
              },
              brokerage: {
                type: "object",
                properties: {
                  percent: { type: "number" },
                  amount: { type: "number" },
                },
              },
            },
          },
        },
      },
    },
  },
  required: ["plans"],
  additionalProperties: true,
};
export const getQuoteResponseSchema = {
  type: "object",
  properties: {
    quoteId: {
      type: "string",
    },
  },
  required: ["quoteId"],
  additionalProperties: true,
};

export const getCheckoutResponseSchema = {
  ...getQuoteResponseSchema,
  additionalProperties: false,
};

export const sendPDFResponseSchema = {
  type: "object",
  properties: {
    message: { type: "string" },
  },
};
