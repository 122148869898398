import Ajv from "ajv";
import { EligibilityState } from "../../pages/professional_indemnity/Form/types";

const ajv = new Ajv();

export type ReferralRequest = {
  productType: "MPI";
  name: string;
  email: string;
  eligibility: EligibilityState;
};

export type ReferralResponse = Record<string, never>;

export const referralResponseSchema = {};

export const validateReferralResponse = ajv.compile(referralResponseSchema);

/**
 * Although the checkout response contains the entire backend quote model, we don't use any of that right now. We only verify for quoteId so we knw it's a quote, and the associated checkout url!
 */
const checkoutSchema = {
  type: "object",
  properties: {
    quoteId: { type: "string" },
    checkoutUrl: { type: "string" },
  },
  required: ["quoteId", "checkoutUrl"],
  additionalProperties: true,
};

export const validateCheckoutResponse = ajv.compile(checkoutSchema);
