import TextAreaInput from "../../../components/form-fields/TextAreaInput";
import FormGroup from "../../../components/forms/FormGroup";

interface Props {
  label: string;
  subtitle: string;
  value: string;
  fieldProps: any;
}

const AdditionalInsuredField: React.FC<Props> = ({ label, subtitle, value, fieldProps }) => {
  return (
    <FormGroup title={label} subtitle={subtitle}>
      <TextAreaInput span={"pt-2 pb-0 placeholder-shown:py"} label={label} value={value} {...fieldProps} />
    </FormGroup>
  );
};

export default AdditionalInsuredField;
