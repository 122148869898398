import { FC } from "react";
import SummaryLiabilitySection from "./SummaryLiabiltySection";
import SummarySections from "./SummarySections";
import { proposerEventDetailsState } from "../bind/types";
import { Region } from "../../../enums/shared/forms/Region";
import HkSummarySection from "./HkSummarySection";
import { useAuth } from "../../../hooks/useAuth";
import { getRegion } from "../../../helpers/shared/getRegion";

interface Props {
  state: proposerEventDetailsState;
  editProposalDetailsLink: string;
  t: any;
  renderExclusion: JSX.Element;
}
const SummaryMain: FC<Props> = ({ state, renderExclusion, t, editProposalDetailsLink }) => {
  const { user } = useAuth();
  const region = getRegion(user);
  return (
    <div className="container-flow flex-1">
      <div className="md:px-0 md:mx-0 md:w-full">
        <div className="bg-white px-4 mt-4 md:mt-8 md:pl-[4.375rem] md:pr-[6.438rem] rounded-t-lg shadow-xl md:pb-6 pb-4">
          <div className="font-semibold md:text-base text-xs opacity-30 md:pt-8 pt-4">{t("header.title1")}</div>
          <SummaryLiabilitySection state={state} t={t} renderExclusion={renderExclusion} />
          {region === Region.HK ? (
            <HkSummarySection state={state} t={t} editProposalDetailsLink={editProposalDetailsLink} />
          ) : (
            <SummarySections state={state} t={t} editProposalDetailsLink={editProposalDetailsLink} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SummaryMain;
