import Header from "../../auth/layout/Header";
import ServerError from "../../errors/ServerError";
import Spinner from "../../../components/icons/Spinner";
import QuoteTable from "./QuoteTable";
import TravelFooter from "../TravelFooter";
import { useFetch } from "../../../hooks/useFetch";
import { ProductType, ProductsName } from "../../../enums/shared/api/Api";
import { fetchQuoteList } from "../../../helpers/travel/listing/api/quoteList";

const QuoteList: React.FC = () => {
  const { data, loadingError, isLoading } = useFetch(fetchQuoteList, { productType: ProductType.ATR });
  return (
    <main>
      <div className="flex flex-col min-h-screen gap-0">
        <Header home="/" />
        <div className={`container-flow flex-grow md:pt-8 pt-4 md:pb-20 pb-8`}>
          <h2 className="font-bold text-2xl md:text-3xl">{ProductsName.ATR}</h2>
          {loadingError && <ServerError supportEmailId="axy.com" />}
          {isLoading ? (
            <div className="py-32 flex justify-around">
              <Spinner width="100" height="100" color="#3F9293"></Spinner>
            </div>
          ) : (
            <QuoteTable rows={data} />
          )}
        </div>
        <TravelFooter />
      </div>
    </main>
  );
};

export default QuoteList;
