import Ajv from "ajv";
import { pricingResponseSchema } from "../../../../schemas/travel/new-policy/PricingResponse";
import { blacklistCheckResponse } from "../../../../schemas/travel/new-policy/BlacklistCheckResponse";
import { createQuoteResponse } from "../../../../schemas/travel/new-policy/QuoteResponse";
import { checkoutResponse } from "../../../../schemas/travel/new-policy/CheckoutResponse";
import { createReferralResponse } from "../../../../schemas/travel/new-policy/ReferralResponse";
import { discountResponse } from "../../../../schemas/travel/new-policy/DiscountResponse";
import { feedbackResponse } from "../../../../schemas/travel/new-policy/FeedbackResponse";

const ajv = new Ajv();

export const validatePricingResponse = ajv.compile(pricingResponseSchema);
export const validateBlacklistCheckResponse = ajv.compile(blacklistCheckResponse);
export const validateCheckoutResponse = ajv.compile(checkoutResponse);
export const validateCreateQuoteResponse = ajv.compile(createQuoteResponse);
export const validateCreateReferralResponse = ajv.compile(createReferralResponse);
export const validateDiscountResponse = ajv.compile(discountResponse);
export const validateCreateFeedbackResponse = ajv.compile(feedbackResponse);
