import { useNavigate } from "react-router-dom";
import { Article } from "../../components/groups";
import { Button } from "../../components/basics/Button";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import FeedbackDialog from "../travel/new-policy/dialog/FeedbackDialog";
import { useEffect, useState } from "react";
import { UseMutationResult, useMutation } from "react-query";
import { FeedbackDetails } from "../../types/travel/Policy";
import { useTranslation } from "react-i18next";

interface PaymentSent {
  onFeedback: (details: FeedbackDetails) => Promise<void>;
}

const PaymentLinkSent: React.FC<PaymentSent> = ({ onFeedback }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product" });
  const navigate = useNavigate();
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => setShowFeedback(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  const handleCloseFeedback = () => setShowFeedback(false);

  const {
    isLoading,
    isError,
    isSuccess,
    mutate: handleFeedback,
  }: UseMutationResult<any, unknown, FeedbackDetails> = useMutation(`sg-epl-feedback`, feedbackDetails => onFeedback(feedbackDetails));
  return (
    <>
      <div className="container-flow flex-grow w-3/4 mt-6 lg:mt-32">
        <h2 className="font-serif font-bold md:text-4xl text-3xl mb-8 icon-check-circle md:icon-12 icon-8 icon-green icon-bottom-1 after:ml-5">
          Payment Link Sent
        </h2>
        <Article>
          <p className="pb-1">A payment link has been sent to the Policyholder at the email provided. This link is valid for 24 hours.</p>
          <p className="pb-1">Once payment has been made, both the Policyholder and you will receive an email notification.</p>
          <p className="pb-1">
            Please note that the policy will not become effective until the application has been accepted by Allied World Assurance Company, Ltd
            (Singapore Branch) and payment in full has been successfully made.
          </p>
        </Article>
      </div>
      <div className="justify-end container-flow py-4 mt-2 md:mt-[334px] ">
        <div className="md:flex md:justify-end md:mb-4 mb-16">
          <Button type={ButtonType.PRIMARY} onClick={() => navigate("/products")}>
            Back to Homepage
          </Button>
        </div>
      </div>
      {showFeedback && (
        <FeedbackDialog
          onCancel={handleCloseFeedback}
          onConfirm={handleFeedback}
          serverError={isError.toString()}
          isLoading={isLoading}
          isSubmitted={isSuccess}
          customerSupportMail={t("payment.customerSupport.emailId")}
        />
      )}
    </>
  );
};

export default PaymentLinkSent;
