import { FC } from "react";
import Header from "./EventHeader";
import Footer from "./EventFooter";

const QuoteDetails: FC = () => {
  return (
    <main>
      <div className="flex flex-col min-h-screen gap-0">
        <Header hideAccount={false} />
        <div className="container-flow flex-grow mx-[-0.5rem] pt-8 md:pb-20">
          <div className="flex items-center justify-between md:text-4xl text-3xl font-serif font-bold text-black pb-8">
            <span>Quotation Details</span>
          </div>
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default QuoteDetails;
