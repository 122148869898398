import { Children, FC } from "react";
import Slider from "react-slick";

type IProps = {
  className?: string;
};

const Carousel: FC<React.PropsWithChildren<IProps>> = ({ children, className }) => {
  const slideCount: number = Children.count(children);

  return (
    <Slider
      dots={false}
      arrows={false}
      speed={200}
      slidesToShow={slideCount}
      slidesToScroll={slideCount}
      className={className}
      responsive={[
        { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1, dots: true, infinite: true, centerMode: true, centerPadding: "20px" } },
      ]}
    >
      {children}
    </Slider>
  );
};

export default Carousel;
