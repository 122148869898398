import { FC, PropsWithChildren } from "react";
import cls from "../../utils/cls";

/**
 * Wraps the given content with a container that adds padding as specified for mobile/desktop. Allows specifying flex or omitting its usual top padding
 */
const ContentContainer: FC<PropsWithChildren<{ flex?: boolean; omitTopPadding?: boolean }>> = ({
  flex = false,
  omitTopPadding = false,
  children,
}) => <div className={cls("px-4 md:px-0 md:container-lg", { flex, "pt-3": !omitTopPadding })}>{children}</div>;

export default ContentContainer;
