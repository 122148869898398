import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import LiabilityFormStepNavigation from "../../LiabilityFormStepNavigation";
import { fetchEPLPrices } from "../../../../helpers/event_public_liability/api/pricingApi";
import { PricingPlan } from "../../../../types/event_public_liability/api/Pricing";
import useOnComponentDidMount from "../../../../hooks/useOnComponentDidMount";
import ServerError from "../../../errors/ServerError";
import { HkLiabilityFormContext } from "../CreateQuote";
import PricingDetail from "../../components/PricingDetails";
import { useAuth } from "../../../../hooks/useAuth";
import { getRegion } from "../../../../helpers/shared/getRegion";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};
const PricingDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product" });
  const { state, setFormState, submitError, setSubmitError, isSubmitting } = useContext(HkLiabilityFormContext);
  const [pricingState, setPricingState] = useState<PricingPlan[]>([]);
  const [serverError, setServerError] = useState(false);
  const { user } = useAuth();
  const region = getRegion(user);
  useOnComponentDidMount(() => {
    const setPrices = async () => {
      // setting price for HK event pl
      try {
        const { plans: PricingResponse } = await fetchEPLPrices(state, region);

        setPricingState(PricingResponse);
        setFormState({
          ...state,
          plans: PricingResponse,
        });
        setServerError(false);
      } catch (error) {
        console.error(error);
        setServerError(true);
      }
    };

    setPrices();
  });
  const arePricesLoaded = pricingState.length > 0;

  return (
    <>
      <PricingDetail t={t} serverError={serverError} arePricesLoaded={arePricesLoaded} pricingState={pricingState} />
      {submitError && <ServerError supportEmailId={t("customerSupport.emailId")} displayType="modal" onClose={() => setSubmitError(false)} />}
      <LiabilityFormStepNavigation isLoading={isSubmitting} onBack={onPrevious} onSubmit={!arePricesLoaded ? undefined : onNext} />
    </>
  );
};

export default PricingDetails;
