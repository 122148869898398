const personalInformationCollectionStatementUrl = "//assets.alliedworld.cloud/awac.com/marketing/asiapacretail/hk/pics.pdf";
const DeclarationTerms = () => (
  <ol>
    <li> By renewing the policy you warrant that: </li>
    <ol type="a">
      <li>all material facts affecting the assessment of the risk have been disclosed to Allied World;</li>
      <li>
        there has been no change in the information provided to Allied World in connection with your initial application for the policy and all such
        information remain true, complete and accurate;
      </li>
      <li>
        without limiting the foregoing, the matters specified in renewal conditions 1. to 5. of the conditions of renewal above are true, correct and
        accurate.
      </li>
    </ol>
    <li>
      You agree that should any of the information given by you alter between the date of your renewal application and the inception date of the
      insurance to which your renewal relates, you will give Allied World immediate notice thereof. You agree that all information provided by you
      shall be the basis of the renewed contract of insurance policy between Allied World Assurance Company, Ltd (Hong Kong Branch) (“Allied World”)
      and you, and shall be deemed to be incorporated in such policy, subject to the terms and conditions of the policy. You agree to accept a renewed
      policy in the Allied World’s usual form for this class of insurance. No policy will be in force until your renewal application has been accepted
      by Allied World, and the premium fully paid.
    </li>
    <li>
      You confirm that you have read, understood and that you agree to the{" "}
      <a href={personalInformationCollectionStatementUrl} rel="noreferrer" target="_blank">
        Personal Information Collection Statement{" "}
      </a>{" "}
      attached to this renewal invitation. If you have provided or will provide information to Allied World about any other individuals, you confirm
      that you are authorised to disclose their personal data and also give this agreement on both my/our and their behalf.
    </li>
  </ol>
);

export default DeclarationTerms;
