import { FeedbackDetails } from "../../../../types/travel/Policy";
import { CreateFeedbackRequest } from "../../../../types/travel/new-policy/api/Quote";
import { ProductType } from "../../../../enums/shared/api/Api";

export const createFeedbackRequest = (feedbackDetails: FeedbackDetails, quoteId: string, productType: ProductType): CreateFeedbackRequest => {
  return {
    ...feedbackDetails,
    quoteId: quoteId,
    productType,
  };
};
