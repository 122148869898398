import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import useForm from "../../../../hooks/useForm";
import Heading from "../../components/Heading";
import ProposerAddressForm from "../../components/HkCommonAddressInput";
import LiabilityBindStepNavigation from "../../bind/LiabilityBindStepNavigation";
import { HkLiabilityBindContext } from "../BindQuote";
import { proposerAddressValidations } from "../../../../helpers/event_public_liability/HkValidations";
import { removeExtraSpaces } from "../../../../helpers/shared/formHandlers";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const ProposerAddress: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product.proposer" });
  const { state, setFormState, onChange } = useContext(HkLiabilityBindContext);
  const { field, validateField, validateForm } = useForm(state, proposerAddressValidations, onChange);
  const handleNext = () => {
    onNext && validateForm(onNext);
  };
  return (
    <>
      <div className="container-flow flex-grow">
        <Heading headingTitle={t("step2Title")} />
        <ProposerAddressForm
          disableForm={false}
          title={t("correspondenceAddress")}
          ariaLabel="correspondence"
          buildingValue={state.building}
          buildingProps={{ ...field.building, onBlur: removeExtraSpaces("building", setFormState, validateField) }}
          houseNumberValue={state.houseNumber}
          houseNumberProps={{ ...field.houseNumber, onBlur: removeExtraSpaces("houseNumber", setFormState, validateField) }}
          streetNameValue={state.streetName}
          streetNameProps={{ ...field.streetName, onBlur: removeExtraSpaces("streetName", setFormState, validateField) }}
          districtValue={state.district}
          districtProps={field.district}
          territoryValue={state.territory}
          territoryProps={field.territory}
          labelForBlockHouseNo={t("RoomFloorBlock")}
          labelForBuilding={t("Building")}
          t={t}
        />
      </div>
      <LiabilityBindStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
    </>
  );
};

export default ProposerAddress;
