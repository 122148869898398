import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateQuoteFormRoutes from "./Form/routes";
import { additionalDetailsValidations, basicDetailsValidations, planValidations } from "../../helpers/group_personal_accident/validations";
import { GroupPersonalAccidentFormState } from "./Form/types";
import createInitialState from "./Form/state";
import { QueryClient, QueryClientProvider } from "react-query";
import FormStep from "../../components/forms/FormStep";
import { parseState } from "../../helpers/shared/serialization";
import Footer from "../group_travel/Footer";
import Header from "../../pages/event_public_liability/EventHeader";
import { useStepNavigation } from "../../hooks/useStepNavigation";
import useEvent from "../../hooks/useEvent";
import { createQuote } from "../../helpers/group_personal_accident/api/quoteApi";

type ChangeHandler = <T extends keyof GroupPersonalAccidentFormState>(key: T, value: GroupPersonalAccidentFormState[T]) => void;
export type test = (t: GroupPersonalAccidentFormState) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};

type SetterFn<T> = (t: T) => void;
const contextObj = {
  onChange: noop,
  state: createInitialState(),
  setFormState: noop,
  isSubmitting: false,
  serverError: false,
  setServerError: noop,
  setShowReferralDialog: noop,
  showReferralDialog: false,
};
export const CreateQuoteContext = createContext<{
  // context for GPA
  state: GroupPersonalAccidentFormState;
  onChange: ChangeHandler;
  setFormState: test;
  isSubmitting: boolean;
  serverError: boolean;
  setServerError: SetterFn<boolean>;
  setShowReferralDialog: SetterFn<boolean>;
  showReferralDialog: boolean;
}>(contextObj);

enum Steps {
  "BasicDetails",
  "Plans",
  "AdditionalDetails",
  "Summary",
}

const getURLForStep = (step: string) => `/au/group_personal_accident/form/${step}`;

// Tracks the url instead of step since it allows to respond to browser location seamlessly
const stepsURLS = [getURLForStep("basic-details"), getURLForStep("plans"), getURLForStep("additional-details"), getURLForStep("summary")];
const stepValidations = [basicDetailsValidations, planValidations, additionalDetailsValidations];
const steps: Steps[] = [Steps.BasicDetails, Steps.Plans, Steps.AdditionalDetails];
const amountOfSteps = steps.length;

const storageKey = "au/group_personal_accident/form";

const getInitialState = () => {
  //Create session storage and create Initial state.
  const sessionStorageState = sessionStorage.getItem(storageKey);
  try {
    return createInitialState(sessionStorageState ? parseState(sessionStorageState) : {});
  } catch (e) {
    console.error("Session storage could not be parsed", e);
    return createInitialState();
  }
};

type ReferralField = {
  field: keyof GroupPersonalAccidentFormState;
  value: "yes" | "no";
};

const additionalDetailsReferralValues: ReferralField[] = [
  { field: "undergroundOrAbove5mHeight", value: "yes" },
  { field: "nonScheduledFlightMoreThan10", value: "yes" },
  { field: "over70YearsOld", value: "yes" },
  { field: "average55YearsOld", value: "yes" },
  { field: "previousIncomeProtectOrPAInsurance", value: "no" },
  { field: "moreThan25000Claims", value: "yes" },
];
const isReferralCase = (state: GroupPersonalAccidentFormState) =>
  state.totalNumbersOfInsured > 100 || additionalDetailsReferralValues.some(({ field, value }) => state[field] === value);

const referralOrSaveQuote = async (
  setShowReferralDialog: SetterFn<boolean>,
  response: any,
  setFormState: SetterFn<GroupPersonalAccidentFormState>,
  formState: GroupPersonalAccidentFormState,
  goToNextStep: VoidFunction | null
) => {
  if (isReferralCase(formState)) {
    setShowReferralDialog(true);
  } else {
    setFormState({
      ...formState,
      quoteId: response?.quoteId,
      quoteNo: response?.quoteNo,
      validityDate: new Date(response?.validityDate),
      status: response?.status,
      agentMail: response?.agentEmail,
    });
    goToNextStep?.();
  }
};

const CreateQuoteForm: FC = () => {
  const [formState, setFormState] = useState<GroupPersonalAccidentFormState>(getInitialState());
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const { totalNumbersOfInsured } = formState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [showReferralDialog, setShowReferralDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const persistState = useEvent(() => sessionStorage.setItem(storageKey, JSON.stringify(formState)));
  const isAccountHidden = false;
  useStepNavigation<GroupPersonalAccidentFormState>(step, stepValidations, formState, stepsURLS);
  const isReferralInStep1 = formState.totalNumbersOfInsured > 100;
  const calculateStepFactor = (stepToTarget: number) => (step === stepToTarget && isReferralInStep1 ? 2 : 1);

  useEffect(() => {
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - calculateStepFactor(2)];
    newURL && navigate(newURL);
  }, [step, navigate, totalNumbersOfInsured]);

  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + calculateStepFactor(0)];
    newURL && navigate(newURL);
  }, [step, navigate, totalNumbersOfInsured]);
  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await createQuote(formState);
      referralOrSaveQuote(setShowReferralDialog, response, setFormState, formState, goToNextStep);
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setServerError(true);
    }
  };
  const stepCount = step + 1;
  return (
    <CreateQuoteContext.Provider
      value={{
        setFormState: setFormState,
        onChange: onFieldChange,
        state: formState,
        isSubmitting,
        setServerError,
        serverError,
        setShowReferralDialog,
        showReferralDialog,
      }}
    >
      <main>
        <form>
          <div className="flex flex-col min-h-screen gap-0">
            <Header hideAccount={isAccountHidden} />
            {stepCount < 4 && <FormStep current={stepCount} total={amountOfSteps} />}
            <CreateQuoteFormRoutes onPrevious={goToPreviousStep} onNext={goToNextStep} onSubmit={onSubmit} accessPlans={!isReferralInStep1} />
            <Footer />
          </div>
        </form>
      </main>
    </CreateQuoteContext.Provider>
  );
};

const CreateQuote: FC = () => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <CreateQuoteForm />
    </QueryClientProvider>
  );
};

export default CreateQuote;
