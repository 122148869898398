import { LoginState, useAuth } from "../../hooks/useAuth";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getRole } from "../../helpers/shared/getRole";
import { Role } from "../../enums/shared/forms/Role";
import { getRegion } from "../../helpers/shared/getRegion";
import NotFound from "../../pages/errors/NotFound";
import { Region } from "../../enums/shared/forms/Region";

interface IProps {
  redirectPath: string;
  allowedRegion: Region | "All";
}

export const isAdminOnlyRole = (role: string) => role !== Role.Unknown && role === Role.Admin && Role;
export const isTPARole = (role: string) => role !== Role.Unknown && role === Role.TPA;

const isPageNotFound = (allowedRegion: Region | "All", region: string, role: string) => {
  return (allowedRegion !== "All" && region !== allowedRegion) || isTPARole(role);
};

const getNotFundUrl = (role: string) => (isTPARole(role) ? "/tpa" : "/");
const ProtectedAgentRoute: FC<React.PropsWithChildren<IProps>> = ({ redirectPath, allowedRegion }) => {
  const { user } = useAuth();
  const role = getRole(user);
  const region = getRegion(user);
  const redirectTo = user === LoginState.LoggedOut ? redirectPath : "/products";

  if (user === LoginState.LoggedOut || isAdminOnlyRole(role)) return <Navigate to={redirectTo} replace />;
  if (user === LoginState.Unknown) return null;

  if (isPageNotFound(allowedRegion, region, role)) return <NotFound homePage={getNotFundUrl(role)} />;

  return <Outlet />;
};

export default ProtectedAgentRoute;
