import { FC } from "react";
import { CommonTable, ContentCell, LabelCell, NormalRow } from "../components/CommonTable";
import SummarySection from "../../../components/forms/SummarySection";
import { formatToDisplayQuoteReadableDate } from "../../../utils/date";
import CommonRow from "../../components/CommonRow";
import { proposerEventDetailsState } from "../bind/types";

type Props = {
  state: proposerEventDetailsState;
  editProposalDetailsLink: string;
  t: any;
};
const getCoverageDate = (date: Date | null) => {
  return date && formatToDisplayQuoteReadableDate(new Date(date));
};
const SummarySections: FC<Props> = ({ state, t, editProposalDetailsLink }) => {
  return (
    <>
      <SummarySection name={t("SummaryLabels.proposerDetails")} link={editProposalDetailsLink}>
        <CommonTable className="w-full mt-2 table-fixed">
          <NormalRow>
            <ContentCell>{t("SummaryLabels.companyName")}</ContentCell>
            <LabelCell>{state.proposerName}</LabelCell>
          </NormalRow>
          <NormalRow>
            <ContentCell>{t("SummaryLabels.uenNo")}</ContentCell>
            <LabelCell>{state.uenNumber}</LabelCell>
          </NormalRow>
          <NormalRow>
            <ContentCell>{t("SummaryLabels.mobileNo")}</ContentCell>
            <LabelCell>{state.mobileNumber}</LabelCell>
          </NormalRow>
          <NormalRow>
            <ContentCell>{t("SummaryLabels.email")}</ContentCell>
            <LabelCell>{state.email}</LabelCell>
          </NormalRow>
          <NormalRow>
            <ContentCell>{t("SummaryLabels.correspondenceAddress")}</ContentCell>
            <LabelCell>
              <p>
                {state.houseNumber} {state.streetName},
              </p>
              <p>
                {" "}
                {state.floorNumber} {state.unitNumber} {state?.district}
                {state?.territory} {t("countryName")} {state.postalCode}{" "}
              </p>
            </LabelCell>
          </NormalRow>
          <NormalRow>
            <ContentCell>{t("SummaryLabels.eventName")}</ContentCell>
            <LabelCell>{state.eventTitle}</LabelCell>
          </NormalRow>
          <NormalRow>
            <ContentCell>{t("SummaryLabels.eventAddress")}</ContentCell>
            <LabelCell>
              <p>
                {state.eventHouseNumber} {state.eventStreetName},
              </p>
              <p>
                {state.eventfloorNumber} {state.eventunitNumber} {state?.eventDistrict} {state?.eventTerritory} {t("countryName")}
                {state.eventPostalCode}{" "}
              </p>
            </LabelCell>
          </NormalRow>
        </CommonTable>
      </SummarySection>
      <SummarySection name={t("SummaryLabels.coverage")} link={editProposalDetailsLink}>
        <CommonTable className="w-full mt-2 table-fixed">
          <NormalRow>
            <ContentCell>{t("SummaryLabels.limitperOccurrence")}</ContentCell>
            <LabelCell>
              {t("SummaryLabels.currency")} {state.limit}
            </LabelCell>
          </NormalRow>
          <NormalRow>
            <ContentCell>{t("SummaryLabels.periodofCoverage")}</ContentCell>
            <LabelCell>
              {getCoverageDate(state.coverageStartDate)} - {getCoverageDate(state.coverageEndDate)} {t("SummaryLabels.bothDaysInclusive")}
            </LabelCell>
          </NormalRow>
          {state.additionalInsured === "yes" ? (
            <>
              <CommonRow label={t("SummaryLabels.additionalInsuredLandlord")} value={state.landlord} />
              <CommonRow label={t("SummaryLabels.additionalInsuredPrincipal")} value={state.principal} />
            </>
          ) : (
            <NormalRow>
              <ContentCell>{t("SummaryLabels.additionalInsured")}</ContentCell>
              <LabelCell>{"-"}</LabelCell>
            </NormalRow>
          )}
        </CommonTable>
      </SummarySection>
    </>
  );
};

export default SummarySections;
