const stampDutyStateValues: Record<string, { name: string; percentage: string }> = {
  "New South Wales": { name: "NSW", percentage: "5" },
  "Australian Capital Territory": { name: "ACT", percentage: "0" },
  "Western Australia": { name: "WA", percentage: "10" },
  "Northern Territory": { name: "NT", percentage: "10" },
  Tasmania: { name: "TAS", percentage: "10" },
  Queensland: { name: "QLD", percentage: "9" },
  Victoria: { name: "VIC", percentage: "10" },
  "Southern Australia": { name: "SA", percentage: "11" },
};

export const getDomiciledState = (value: string) => {
  for (const state in stampDutyStateValues) {
    if (stampDutyStateValues[state].name === value) {
      return state;
    }
  }
};
export const getStampDutyPercentage = (value: string | null) => {
  const stampDuty = stampDutyStateValues[value || ""] || null;
  return stampDuty ? { name: stampDuty.name, percentage: stampDuty.percentage } : null;
};

const planLabels = {
  "plan-1": "Silver",
  "plan-2": "Gold",
  "plan-3": "Platinum",
};
export const getPlanLabel = (value: string) => {
  const plan = Object.entries(planLabels).find(array => {
    return array[0] === value;
  });
  return plan?.[1] as string;
};
