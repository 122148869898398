import { useContext } from "react";
import { CreateQuoteContext } from "../../CreateQuote";
import SummaryDetail from "../../../group_travel/components/SummaryDetail";
import { useNavigate } from "react-router-dom";
import { clearPISessionStorage } from "../../../../helpers/shared/sessionStorage";

const clearFormSession = () => clearPISessionStorage("au/group_personal_accident/form/");

const Summary: React.FC = () => {
  const { state } = useContext(CreateQuoteContext);
  const navigate = useNavigate();

  const goToBind = () => {
    clearFormSession();
    navigate("/au/group_personal_accident/bind/proposer-details", { state });
  };
  const goToListingUrl = () => {
    clearFormSession();
    navigate("/au/group_personal_accident/retrieve-list");
  };
  return <SummaryDetail state={state} goToBind={goToBind} goToListing={goToListingUrl} />;
};

export default Summary;
