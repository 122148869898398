import { Auth } from "aws-amplify";
import { useState } from "react";
import TextInput from "../../../components/form-fields/TextInput";
import { ErrorMessage } from "../../../components/forms";
import SecondaryLayout from "../../../components/authentication/layout/SecondaryLayout";
import { errorMessage } from "../../../utils/error";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";

interface IProps {
  onSubmit: (email: string) => void;
}

const externalHandleSubmit = async (
  e: React.FormEvent<HTMLFormElement>,
  email: string,
  setError: (error: string) => void,
  onSubmit: (email: string) => void
) => {
  e.preventDefault();
  if (email.length === 0) {
    setError("Username cannot be empty");
    return;
  }
  try {
    await Auth.forgotPassword(email);
    onSubmit(email);
  } catch (err) {
    if ((err as any).code === "UserNotFoundException") {
      onSubmit(email);
    } else {
      setError(errorMessage(err));
    }
  }
};
export const ForgotPasswordForm = ({ onSubmit }: IProps) => {
  const [state, setState] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const { t } = useTranslation("pages", { keyPrefix: "auth.forgot.forgotPasswordForm" });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    externalHandleSubmit(e, state.email, setError, onSubmit);
  };

  return (
    <SecondaryLayout title={t("title")}>
      <form onSubmit={handleSubmit} className="space-y-8">
        <p>{t("content")}</p>
        <div className="space-y-4 md:max-w-xs">
          <TextInput
            value={state.email}
            error={!!error}
            autoComplete="username"
            label={t("form.email")}
            onValueChange={value => setState({ ...state, email: value })}
          />
          <ErrorMessage error={error} />
        </div>
        <Button type={ButtonType.PRIMARY}>{t("button.send")}</Button>
      </form>
    </SecondaryLayout>
  );
};
