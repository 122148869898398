import { LoginState, useAuth } from "../../hooks/useAuth";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getRole } from "../../helpers/shared/getRole";
import { Role } from "../../enums/shared/forms/Role";
import NotFound from "../../pages/errors/NotFound";

interface IProps {
  redirectPath: string;
}
export const isAgentOnlyRole = (role: string) => role !== Role.Unknown && role === Role.Agent;
export const isTPARole = (role: string) => role !== Role.Unknown && role === Role.TPA;

const ProtectedAminRoute: FC<React.PropsWithChildren<IProps>> = ({ redirectPath }) => {
  const { user } = useAuth();
  const role = getRole(user);
  const redirectTo = user === LoginState.LoggedOut ? redirectPath : "/products";

  if (user === LoginState.Unknown) return null;
  if (user === LoginState.LoggedOut || isAgentOnlyRole(role)) return <Navigate to={redirectTo} replace />;
  if (isTPARole(role)) return <NotFound homePage="/tpa" />;

  return <Outlet />;
};

export default ProtectedAminRoute;
