import { Branch, ProductType } from "../../../../enums/shared/api/Api";
import agentApiService from "../../../../services/travel/new-policy/agentApiService";
import clientApiService from "../../../../services/travel/new-policy/clientApiService";
import { PremiumQuote } from "../../../../types/travel/new-policy/api/Quote";
import { NewTravelPolicyState } from "../../../../types/travel/Policy";
import { formatToServerDate } from "../../../../utils/date";

const establishTravelDetails = (details: NewTravelPolicyState["travelDetails"]): PremiumQuote["travelDetails"] => {
  if (details.policyType === "annual-trip") {
    return {
      policyType: details.policyType,
      location: details.location,
      adultCount: details.adultCount,
      childrenCount: details.childrenCount,
      startDate: formatToServerDate(details.startDate) || null,
      endDate: null,
    };
  }
  return {
    policyType: details.policyType,
    location: details.location,
    adultCount: details.adultCount,
    childrenCount: details.childrenCount,
    startDate: formatToServerDate(details.startDate) || null,
    endDate: formatToServerDate(details.endDate) || null,
  };
};

export const createQuoteRequest = (data: NewTravelPolicyState): PremiumQuote => {
  return {
    productType: ProductType.ATR,
    branch: Branch.SG,
    travelDetails: establishTravelDetails(data.travelDetails),
    travelPlan: {
      id: data.travelPlan.planName,
      price: data.travelPlan.planPrice?.price || null,
    },
    personalDetails: {
      title: data.personalDetails.requester.title,
      firstName: data.personalDetails.requester.givenName,
      lastName: data.personalDetails.requester.familyName,
      phoneNumber: data.personalDetails.requester.phoneNumber,
      email: data.personalDetails.requester.email,
      identificationNumber: data.personalDetails.requester.identificationNumber,
      dateOfBirth: formatToServerDate(data.personalDetails.requester.dateOfBirth),
      address: {
        postalCode: data.personalDetails.address.postalCode,
        number: data.personalDetails.address.number,
        streetName: data.personalDetails.address.streetName,
        floorNumber: data.personalDetails.address.floorNumber,
        unitNumber: data.personalDetails.address.unitNumber,
      },
      requesterIsTravelling: data.personalDetails.requestingPersonIsTravelingPerson,
      travellers: data.personalDetails.travellers.map(traveller => ({
        fullName: traveller.fullName,
        identificationNumber: traveller.identificationNumber,
        dateOfBirth: formatToServerDate(traveller.dateOfBirth),
      })),
    },
    termsConditions: {
      acceptedAt: new Date(),
      marketingVoiceCall: data.termsConditions.marketingVoiceCall,
      marketingTextMessage: data.termsConditions.marketingTextMessage,
    },
    paymentDetails: {
      method: "stripe",
    },
  };
};

export const createQuote = (flowState: NewTravelPolicyState, clientId?: string) =>
  clientId ? clientApiService.createQuote(clientId, flowState) : agentApiService.createQuote(flowState);
