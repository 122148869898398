import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormStepNavigation, { PropsWithoutLabels } from "../../../components/forms/FormStepNavigation";

const LiabilityBindStepNavigation: FC<PropsWithoutLabels> = props => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product.bind.navigation.button" });
  const nextButtonLabel = props.onNext ? t("next") : t("bind");
  const backButtonLabel = t("back");
  return <FormStepNavigation backButtonLabel={backButtonLabel} nextButtonLabel={nextButtonLabel} {...props} />;
};

export default LiabilityBindStepNavigation;
