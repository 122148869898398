const policyDocuments = {
  type: "object",
  properties: {
    filename: { type: "string" },
    url: { type: "string" },
  },
  required: ["filename", "url"],
  additionalProperties: true,
};

export const policyDocumentsResponseSchema = {
  type: "array",
  properties: {
    items: policyDocuments,
  },
};
