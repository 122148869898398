import { AxiosRequestHeaders } from "axios";
import { Auth } from "aws-amplify";

export const addAuthorizationHeader = async (headers: AxiosRequestHeaders) => {
  return Auth.currentSession()
    .then(session => {
      const token = session.getIdToken().getJwtToken();
      return { ...headers, Authorization: `Bearer ${token}` };
    })
    .catch(_error => headers);
};
