import { PricingRequest, PricingResponse } from "./../../types/event_public_liability/api/Pricing";
import { createPricingRequest } from "./../../helpers/event_public_liability/api/pricingApi";
import { agentPost } from "./../../helpers/travel/new-policy/api/agent";
import { DetailsState, liabilityFormState } from "../../pages/event_public_liability/form/types";
import { pricingResponseSchema } from "../../schemas/event_public_liability/PricingResponse";
import Ajv from "ajv";
import {
  CreateEventQuoteRequest,
  CreateEventQuoteResponse,
  SendEmailQuoteRequest,
  premiumQuoteRequest,
  premiumQuoteResponse,
} from "../../types/event_public_liability/api/Quote";
import { QuoteListResponse } from "../../types/event_public_liability/api/Quote";
import { quoteListResponseSchema } from "../../schemas/event_public_liability/EventQuote";
import { createQuoteRequest } from "../../helpers/event_public_liability/api/quoteApi";
import { createQuoteResponseSchema } from "../../schemas/event_public_liability/CreateQuoteResponse";
import { liabilityBindState } from "../../pages/event_public_liability/bind/types";
import { createBindQuoteRequest } from "../../helpers/event_public_liability/api/checkoutApi";
import { UenInfoRequest, UenInfoResponse } from "../../types/event_public_liability/UenInfo";

const BASEURL = process.env.REACT_APP_BACKEND_API;
const PRICING = "v1/agent/pricing";
const UEN = "v1/agent/uenInfo";
const sendEmail = "v1/agent/quotationMail";
const CREATE_QUOTE = "v1/agent/submit";
const QUOTELIST = "v1/agent/quoteslist";
const CHECKOUT = "v1/agent/payment/checkout";
const UPDATE_QUOTE_GST = "v1/agent/updateQuotesGst";
const ajv = new Ajv();

export const sendPDFResponseSchema = {
  type: "object",
  properties: {
    message: { type: "string" },
  },
};
export const premiumQuoteResponseSchema = {
  type: "object",
  properties: {
    quoteId: { type: "string" },
  },
};
export const validateUenIfoResponseSchema = {
  type: "object",
  properties: {
    message: { type: "string" },
  },
};

export const validatePricingResponse = ajv.compile(pricingResponseSchema);
export const validateSendEmailResponse = ajv.compile(sendPDFResponseSchema);
export const validateCreateQuoteResponse = ajv.compile(createQuoteResponseSchema);
export const validateQuoteListResponse = ajv.compile(quoteListResponseSchema);
export const validateCheckoutResponse = ajv.compile(premiumQuoteResponseSchema);
export const validateUenIfoResponse = ajv.compile(validateUenIfoResponseSchema);
const agentApiService = {
  getPricing: (data: DetailsState, region: string): Promise<PricingResponse> =>
    agentPost<PricingRequest, PricingResponse>(BASEURL, PRICING, createPricingRequest(data, region), (response: unknown) =>
      validatePricingResponse(response)
    ),
  sendEmailPDF: (emailPayload: SendEmailQuoteRequest): Promise<string> =>
    agentPost<SendEmailQuoteRequest, string>(BASEURL, sendEmail, emailPayload, (response: unknown) => validateSendEmailResponse(response)),
  createQuote: (data: liabilityFormState, region: string): Promise<CreateEventQuoteResponse> =>
    agentPost<CreateEventQuoteRequest, CreateEventQuoteResponse>(BASEURL, CREATE_QUOTE, createQuoteRequest(data, region), (response: unknown) => {
      return validateCreateQuoteResponse(response);
    }),
  getQuoteList: (data: { productType: string }): Promise<QuoteListResponse> =>
    agentPost<{ productType: string }, QuoteListResponse>(BASEURL, QUOTELIST, data, (response: unknown) => validateQuoteListResponse(response)),
  checkout: (data: liabilityBindState, paymentMethod: string, region: string): Promise<premiumQuoteResponse> =>
    agentPost<premiumQuoteRequest, premiumQuoteResponse>(
      BASEURL,
      CHECKOUT,
      createBindQuoteRequest(data, paymentMethod, region),
      (response: unknown) => validateCheckoutResponse(response)
    ),
  getUenInfo: (data: UenInfoRequest): Promise<UenInfoResponse> =>
    agentPost<UenInfoRequest, UenInfoResponse>(BASEURL, UEN, data, (response: unknown) => validateUenIfoResponse(response)),
  updateQuotesGst: (quoteId: string): Promise<CreateEventQuoteResponse> =>
    agentPost<{ productType: string; quoteId: string }, CreateEventQuoteResponse>(
      BASEURL,
      UPDATE_QUOTE_GST,
      { productType: "APL", quoteId },
      (response: unknown) => {
        return validateCreateQuoteResponse(response);
      }
    ),
};

export default agentApiService;
