import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProfessionalIndemnityOverview from "./professional_indemnity/Overview";
import RenewalOverview from "./professional_indemnity/renewal/Overview";
import ProtectedAgentRoute from "../components/authentication/ProtectedAgentRoute";
import ProtectedClientRoute from "../components/authentication/ProtectedClientRoute";
import TravelSuccess from "./travel/payment/Success";
import TravelCancelled from "./travel/payment/Cancelled";
import NotFound from "./errors/NotFound";
import Error from "./errors/Error";
import NewPolicy from "./travel/new-policy/NewPolicy";
import Dashboard from "./dashboard";
import NewProduct from "./professional_indemnity/NewProduct";
import ClientNotFound from "./errors/ClientNotFound";
import PISuccess from "./professional_indemnity/Success";
import PICancelled from "./professional_indemnity/Cancelled";
import Renewal from "./professional_indemnity/renewal/Renewal";
import Success from "./professional_indemnity/renewal/Success";
import Cancelled from "./professional_indemnity/renewal/Cancelled";
import Overviews from "./event_public_liability/Overview";
import CreateQuote from "./event_public_liability/CreateQuote";
import HkCreateQuote from "./event_public_liability/hk/CreateQuote";
import Downloads from "./Downloads";
import AuthRoute from "../components/authentication/AuthRoute";
import Home from "./Home";
import QuoteList from "./event_public_liability/QuoteList";
import TravelQuoteList from "./travel/listing/QuoteList";
import QuoteDetails from "./event_public_liability/QuoteDetails";
import BindQuote from "./event_public_liability/BindQuote";
import EventPLSuccess from "./event_public_liability/Success";
import HkEventPLSuccess from "./event_public_liability/HkSuccess";
import { Region } from "../enums/shared/forms/Region";
import HkBindQuote from "./event_public_liability/hk/BindQuote";
import Overview from "./event_public_liability/hk/Overview";
import ProtectedAdminRoute from "../components/authentication/ProtectedAdminRoute";
import TPAHome from "./tpa/Home";
import ProtectedTPARoute from "../components/authentication/ProtectedTPARoute";
import ProtectedRoute from "../components/authentication/ProtectedRoute";
import RoutesForTravel from "./group_travel/routes";
import RoutesForGPA from "./group_personal_accident/routes";
import { Login } from "./auth/Login";
import { ForgotPassword } from "./auth/ForgotPassword";
import Signup from "./auth/Signup";
import Reset from "./auth/Reset";
import PolicyDocuments from "./components/PolicyDocuments";
import { ProductsName } from "../enums/shared/api/Api";
import CreateContractor from "./contractor/CreateContractor";
import FWBLandingPage from "./contractor/Main";
import { isFwbEnabled, isRenewalPolicyEnabled } from "../helpers/shared/featureFlags";
import PaymentAlreadyMade from "./components/pay_now/PaymentAlreadyMade";
import PageNotFound from "./components/pay_now/PageNotFound";
import RenewalPolicy from "./components/pay_now/RenewalPolicy";
import RenewalSuccess from "./components/pay_now/Success";
import RenewalPolicyDetails from "./components/pay_now/sg_nb/RenewalPolicyDetails";
import HkSuccess from "./components/pay_now/hk/HkSuccess";
import HKRenewalPolicyDetails from "./components/pay_now/hk/RenewalPolicyDetail";
import SGRenewalPolicyDetails from "./components/pay_now/RenewalPolicyDetails";
import PayNowCancelled from "./components/pay_now/Cancelled";
import RenewalLinkExpire from "./components/pay_now/RenewalLinkExpire";
import AuSignupRoutes from "./auth/signup/AuSignupRoutes";

const AppRoutes: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Routes>
      {/*agent routes*/}

      <Route path="/" element={<AuthRoute component={<Home />} routePath="/" />} />
      <Route path="/products" element={<AuthRoute component={<Home />} routePath="/products" />} />

      <Route element={<ProtectedRoute redirectPath="/user/login" />}>
        <Route path="/downloads" element={<Downloads />} />
      </Route>

      <Route element={<ProtectedAgentRoute redirectPath="/user/login" allowedRegion={"All"} />}>
        {/* All region routes are common to all agents */}
        <Route path="" element={<Navigate to="products" />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>

      <Route element={<ProtectedTPARoute redirectPath="/user/login" allowedRegion={Region.AU} />}>
        <Route path="/tpa" element={<TPAHome />} />
        <Route path="" element={<Navigate to="/tpa" />} />
      </Route>

      <Route element={<ProtectedAgentRoute redirectPath="/user/login" allowedRegion={Region.SG} />}>
        {/* Travel routes is only for sg */}
        <Route path="/travel/retrieveList" element={<TravelQuoteList />} />
        <Route path="/travel/documents" element={<PolicyDocuments productName={ProductsName.ATR} region={Region.SG} />} />
        <Route path="/travel/*" element={<NewPolicy />} />
      </Route>

      <Route path="/user/login" element={<Login />} />
      <Route path="/user/password/*" element={<ForgotPassword />} />
      <Route path="/payment/success" element={<TravelSuccess />} />
      <Route path="/payment/cancelled" element={<TravelCancelled />} />
      <Route path="/user/signup" element={<Signup />} />
      <Route path="/user/*" element={<AuSignupRoutes />} />

      <Route element={<ProtectedAdminRoute redirectPath="/user/login" />}>
        <Route path="/user/reset" element={<Reset />} />
      </Route>

      {/*client routes*/}
      <Route path="client/:id" element={<ProtectedClientRoute />}>
        <Route path="" element={<Navigate to="travel" />} />
        <Route path="travel" element={<Home />} />
        <Route path="travel/*" element={<NewPolicy />} />
        <Route path="payment/success" element={<TravelSuccess />} />
        <Route path="payment/cancelled" element={<TravelCancelled />} />
        <Route path="*" element={<ClientNotFound />} />
      </Route>

      {/*Custom product - Professional Indemnity */}
      <Route path="/professional_indemnity/manulife">
        <Route path="" element={<ProfessionalIndemnityOverview />} />
        <Route path="form/*" element={<NewProduct />} />
        <Route path="*" element={<NotFound homePage="/professional_indemnity/manulife" />} />
        <Route path="payment/success" element={<PISuccess />} />
        <Route path="payment/cancelled" element={<PICancelled />} />
      </Route>

      <Route path="/professional_indemnity/manulife/renewal">
        {/*Professional Indemnity - Renewal routes */}
        <Route path="" element={<RenewalOverview />} />
        <Route path="form/*" element={<Renewal />} />
        <Route path="payment/success" element={<Success />} />
        <Route path="payment/cancelled" element={<Cancelled />} />
      </Route>

      {/*Event public liability routes */}
      <Route path="/event_public_liability">
        <Route element={<ProtectedAgentRoute redirectPath="/user/login" allowedRegion={Region.SG} />}>
          <Route path="" element={<Overviews />} />
          <Route path="form/*" element={<CreateQuote />} />
          <Route path="bind/*" element={<BindQuote />} />
          <Route path={"retrieveList"} element={<QuoteList />} />
          <Route path="documents" element={<PolicyDocuments productName={ProductsName.APL} region={Region.SG} />} />
          <Route path="quoteDetails" element={<QuoteDetails />} />
        </Route>
        <Route path="payment/success" element={<EventPLSuccess />} />
      </Route>

      <Route path="/event_public_liability/hk">
        <Route element={<ProtectedAgentRoute redirectPath="/user/login" allowedRegion={Region.HK} />}>
          <Route path="" element={<Overview />} />
          <Route path="form/*" element={<HkCreateQuote />} />
          <Route path={"retrieveList"} element={<QuoteList />} />
          <Route path="documents" element={<PolicyDocuments productName={ProductsName.CLV} region={Region.HK} />} />
          <Route path="bind/*" element={<HkBindQuote />} />
        </Route>
        <Route path="payment/success" element={<HkEventPLSuccess />} />
      </Route>

      <Route path="/au/group_travel/*" element={<RoutesForTravel />} />
      <Route path="/au/group_personal_accident/*" element={<RoutesForGPA />} />

      {isFwbEnabled() && (
        <Route path="/contractor">
          <Route element={<ProtectedAgentRoute redirectPath="/user/login" allowedRegion={Region.SG} />}>
            <Route path="" element={<FWBLandingPage />} />
            <Route path="new/*" element={<CreateContractor />} />
          </Route>
        </Route>
      )}

      {/*other routes*/}
      {isRenewalPolicyEnabled() && (
        <>
          <Route path="/policy-details/:renewalId" element={<RenewalPolicy />} />
          <Route path="/payment-already-done" element={<PaymentAlreadyMade />} />
          <Route path="/page-not-found" element={<PageNotFound />} />
          <Route path="/policy-details" element={<RenewalPolicyDetails />} />
          <Route path="/sg/payment/success" element={<RenewalSuccess />} />
          <Route path="/hk/payment/success" element={<HkSuccess />} />
          <Route path="/hk/policy-details" element={<HKRenewalPolicyDetails />} />
          <Route path="/sg/policy-details" element={<SGRenewalPolicyDetails />} />
          <Route path="/sg/payment/cancelled" element={<PayNowCancelled />} />
          <Route path="/hk/payment/cancelled" element={<PayNowCancelled footerType />} />
          <Route path="/renewal-link-expired" element={<RenewalLinkExpire />} />
        </>
      )}
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<NotFound homePage="/" />} />
    </Routes>
  );
};

export default AppRoutes;
