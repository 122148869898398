import { getDaysBetweenDates, isDateBefore } from "../../utils/date";

export const validateStartCoverageDate = (value: Date) => {
  //Check if the input date of coverage start date is more than 60 days.
  const futureDate = new Date();
  const currentDate = new Date();
  futureDate.setDate(currentDate.getDate() + 59);
  if (value > futureDate) {
    return `Must be within 60 days from today`;
  }
  return null;
};

export const checkLeapYear = (year: number) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const checkDatesBetweenLeapYear = (startDate: Date, endDate: Date): boolean => {
  for (let year = startDate.getFullYear(); year <= endDate.getFullYear(); year++) {
    if (checkLeapYear(year) && isLeapDayInRange(year, startDate, endDate)) {
      return true;
    }
  }
  return false;
};

const validateReturnDateAfterStartDate = (startDate: Date, endDate: Date) => {
  if (isDateBefore(endDate, startDate)) {
    return "Must be after start date";
  }

  return null;
};

export const validateTravelDaysNotZero = (currentTravelDays: number, OtherTravelDays: number, travelType: "domestic" | "international") => {
  if (currentTravelDays === 0 && OtherTravelDays === 0) {
    return "Both fields cannot be 0";
  }
  if (travelType === "international" && currentTravelDays > 2500) {
    return "Must be less than 2,501 days";
  }
  if (travelType === "domestic" && currentTravelDays > 5000) {
    return "Must be less than 5,001 days";
  }
  return null;
};
export const validateDuration = (startDateString: Date, endDateString: Date, minDays: number, maxDays: number) => {
  const checkRev = validateReturnDateAfterStartDate(startDateString, endDateString);
  if (checkRev !== null) {
    return checkRev;
  }

  const days = getDaysBetweenDates(startDateString, endDateString);
  const isLeapYearDateIncluded = checkDatesBetweenLeapYear(startDateString, endDateString);
  if (isLeapYearDateIncluded) {
    minDays++;
    maxDays++;
  }

  if (days !== null && days < minDays) {
    return "Policy period must be at least 6 months";
  } else if (days !== null && days > maxDays) {
    return "Policy period can only be up to 18 months";
  }
  return null;
};

const isLeapDayInRange = (year: number, startDate: Date, endDate: Date) => {
  const leapDay = new Date(year, 1, 29);
  return leapDay >= startDate && leapDay <= endDate;
};
