import { NewTravelPolicyState, UpdateState } from "../../types/travel/Policy";
import { updateTravellersOnAdultCount, updateTravellersOnRequesterChange } from "./travellers";
import { set } from "lodash/fp";
import { defaultTravelPlan } from "./newTravelPolicyInitialization";

const getFamilyCount = (flowState: NewTravelPolicyState) => {
  return flowState.travelDetails.adultCount + flowState.travelDetails.childrenCount;
};

const resetPlanAndAddOn: UpdateState = (prevFlowState, key) => {
  if (key === "travelDetails") {
    return {
      ...prevFlowState,
      travelPlan: defaultTravelPlan,
    };
  }
  return prevFlowState;
};

const setGroupType: UpdateState = (prevFlowState, key, sliceKey) => {
  if (key === "travelDetails" && (sliceKey === "adultCount" || sliceKey === "childrenCount")) {
    return {
      ...prevFlowState,
      travelDetails: {
        ...prevFlowState["travelDetails"],
        groupType: getFamilyCount(prevFlowState) > 1 ? "family" : "individual",
      },
    };
  }
  return prevFlowState;
};

const setTravellers: UpdateState = (prevFlowState, key, sliceKey) => {
  if (key === "travelDetails" && sliceKey === "adultCount") {
    return {
      ...prevFlowState,
      personalDetails: {
        ...prevFlowState["personalDetails"],
        travellers: updateTravellersOnAdultCount(
          prevFlowState.travelDetails.adultCount,
          prevFlowState.personalDetails.requestingPersonIsTravelingPerson,
          prevFlowState.personalDetails.travellers
        ),
      },
    };
  }

  if (key === "personalDetails" && sliceKey === "requestingPersonIsTravelingPerson") {
    return {
      ...prevFlowState,
      personalDetails: {
        ...prevFlowState["personalDetails"],
        travellers: updateTravellersOnRequesterChange(
          prevFlowState.personalDetails.requestingPersonIsTravelingPerson,
          prevFlowState.personalDetails.travellers
        ),
      },
    };
  }
  return prevFlowState;
};

const resetDateAndLocation: UpdateState = (prevFlowState, key, sliceKey) => {
  if (key === "travelDetails" && sliceKey === "policyType" && prevFlowState.travelDetails.policyType === "annual-trip") {
    //reset end date and location
    return {
      ...prevFlowState,
      travelDetails: {
        ...prevFlowState["travelDetails"],
        endDate: null,
        location: null,
      },
    };
  }
  return prevFlowState;
};

const updateNewTravelPolicyState: UpdateState = (prevFlowState, key, sliceKey, value): NewTravelPolicyState => {
  const path = String(sliceKey).split(".");
  let newFlowState = {
    ...prevFlowState,
    [key]: {
      ...prevFlowState[key],
    },
  };
  // In my ideal vision we can fully rely on callback updates but that needs a bit more work
  newFlowState = set([key, ...path], value, newFlowState);
  newFlowState = resetPlanAndAddOn(newFlowState, key);
  newFlowState = setGroupType(newFlowState, key, sliceKey);
  newFlowState = setTravellers(newFlowState, key, sliceKey);
  newFlowState = resetDateAndLocation(newFlowState, key, sliceKey);

  return newFlowState;
};

export default updateNewTravelPolicyState;
