const PlanSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    price: { type: "number" },
    currency: { type: "string" },
  },
  required: ["id", "price", "currency"],
  additionalProperties: true,
};

export const pricingResponseSchema = {
  type: "object",
  properties: {
    plans: {
      type: "array",
      items: PlanSchema,
      minItems: 3,
    },
  },
  required: ["plans"],
  additionalProperties: true,
};
