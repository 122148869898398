import { Auth } from "aws-amplify";
import { Dispatch, SetStateAction, useState } from "react";
import TextInput from "../../../components/form-fields/TextInput";
import { ErrorMessage } from "../../../components/forms";
import SecondaryLayout from "../../../components/authentication/layout/SecondaryLayout";
import { errorMessage } from "../../../utils/error";
import { ChallengeResponse, OnLoginResponse, getRegionWithFallback } from "../Login";
import { formatToPhoneNumber } from "../../../helpers/shared/format";
import { useTranslation } from "react-i18next";
import AuthenticationFlowActions from "../../../components/authentication/AuthenticationFlowActions";
import { CheckInputBlock } from "../../../components/form-fields";
import RawHTML from "../../../components/basics/RawHTML";
import { Region } from "../../../enums/shared/forms/Region";

interface IProps {
  onLoginResponse: OnLoginResponse;
  cognitoUser: ChallengeResponse;
  onBack: VoidFunction;
  isGuestTpa: boolean;
  cognitoUserRegion: Region | string;
}
interface TermsProps {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  error: string;
  t: any;
}

const checkPhoneNumberNeeded = (cognitoUser: ChallengeResponse) => {
  const required = cognitoUser?.challengeParam?.requiredAttributes || [];
  return required.includes("phone_number");
};

const getAdditionalAttributes = (phoneNumberNeeded: boolean, phoneNumber: string) => {
  return phoneNumberNeeded ? { phone_number: formatToPhoneNumber(phoneNumber) } : {};
};

const handleFormValidationAndSetError = (state: any, setError: Dispatch<SetStateAction<string>>, t: any, region: string, isGuestTpa: boolean) => {
  const { password, passwordConfirmation, termsAccepted, phoneNumber } = state;

  if (password !== passwordConfirmation) {
    setError(t("matchError"));
    return true; // Indicates validation failure
  }

  if (region === Region.HK && password && passwordConfirmation && !termsAccepted) {
    setError(t("termsError"));
    return true; // Indicates validation failure
  }
  if (region === Region.AU && !isGuestTpa && phoneNumber.length !== 10) {
    setError(t("phoneNumberAUError"));
    return true;
  }
  return false; // Indicates validation success
};

const Terms: React.FC<TermsProps> = ({ state, setState, error, t }) => {
  const hkTermsOfUseDocUrl = "/documents/AW Portal HK_Terms.pdf";
  return (
    <>
      <hr className="border-grey-light !my-8" />
      <div className="space-y-4 md:max-w-[25rem]">
        <h2 className="font-serif font-bold   md:font-semibold text-3xl md:text-4xl max-w-lg">{"Agree to Terms of Use"}</h2>
        <CheckInputBlock
          error={error.includes("term")}
          name="checked"
          onValueChange={value => setState({ ...state, termsAccepted: value })}
          id="accepted"
          checked={state.termsAccepted}
          className={error.includes("term") ? "text-red border-red border rounded-md" : "".toString()}
        >
          <RawHTML boldedLinks content={t(`terms`, { hkTermsOfUseDocUrl })} />
        </CheckInputBlock>
      </div>
    </>
  );
};
const getDefaultPhoneNumber = (isGuestTpa: boolean) => (isGuestTpa ? "+61280152500" : "");
const showPhoneNumber = (phoneNumberNeeded: boolean, isGuestTpa: boolean) => phoneNumberNeeded && !isGuestTpa;
const displayBackButton = (onBack: VoidFunction, isGuestTpa: boolean) => (isGuestTpa ? null : onBack);
export const NewPasswordForm = ({ cognitoUser, onLoginResponse, onBack, isGuestTpa, cognitoUserRegion }: IProps) => {
  const region = getRegionWithFallback(cognitoUserRegion) as Region;

  const phoneNumberNeeded = checkPhoneNumberNeeded(cognitoUser);
  const [state, setState] = useState({
    password: "",
    passwordConfirmation: "",
    phoneNumber: getDefaultPhoneNumber(isGuestTpa),
    termsAccepted: false,
  });
  const [error, setError] = useState("");
  const { t } = useTranslation("pages", { keyPrefix: "auth.login.newPasswordForm" });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (handleFormValidationAndSetError(state, setError, t, region, isGuestTpa)) {
      return;
    }
    const additional = getAdditionalAttributes(phoneNumberNeeded, state.phoneNumber);

    try {
      const response = await Auth.completeNewPassword(cognitoUser, state.password, additional);
      onLoginResponse(response);
    } catch (err) {
      setError(errorMessage(err));
    }
  };

  return (
    <SecondaryLayout addonBeforeTitle={t(`step${isGuestTpa ? "Tpa" : ""}`)}>
      <form onSubmit={handleSubmit} className="mb-10  space-y-4">
        <div>
          <h2 className="font-serif font-bold md:font-semibold text-3xl md:text-4xl mb-4">{t("form.title")}</h2>
          <p className="md:text-lg font-normal mb-4">{t("form.content")}</p>
          <div className="md:max-w-[25rem] space-y-2">
            <TextInput
              type="password"
              label={t("form.newPassword")}
              value={state.password}
              onValueChange={value => setState({ ...state, password: value })}
              error={error.toLowerCase().includes("password")}
            />
            <TextInput
              type="password"
              value={state.passwordConfirmation}
              label={t("form.confirmPassword")}
              onValueChange={value => setState({ ...state, passwordConfirmation: value })}
              error={error.toLowerCase().includes("password")}
            />
          </div>
        </div>
        {showPhoneNumber(phoneNumberNeeded, isGuestTpa) && (
          <>
            <hr className="border-grey-light !my-8" />
            <div>
              <h2 className="font-serif font-bold md:font-semibold text-3xl md:text-4xl">{t("form.phoneRequirement.title")}</h2>
              <div className="pt-4">
                <div className="space-y-4 md:max-w-[25rem]">
                  <TextInput
                    label={t(`form.phoneRequirement.form.${region}.mobile`)}
                    value={state.phoneNumber}
                    onValueChange={value => setState({ ...state, phoneNumber: value })}
                    error={error.includes("phone")}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {region === Region.HK && <Terms state={state} setState={setState} error={error} t={t} />}

        <ErrorMessage error={error} />
        <div className="!mt-8">
          <AuthenticationFlowActions nextLabel={t("form.button.next")} onBack={displayBackButton(onBack, isGuestTpa)} />
        </div>
      </form>
    </SecondaryLayout>
  );
};
