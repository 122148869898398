import { pricingResponseSchema } from "./PricingResponse";

export const createQuoteResponseSchema = {
  type: "object",
  properties: {
    productType: { type: "string" },
    branch: { type: "string" },
    proposerName: { type: "string" },
    eventTitle: { type: "string" },
    eventType: { type: "string" },
    duration: { type: "number" },
    attendees: { type: "string" },
    drone: { type: "string" },
    setupAndDismantling: { type: "string" },
    volunteers: { type: "string" },
    validityDate: { type: "string" },
    quoteId: { type: "string" },
    quoteNo: { type: "string" },
    agentEmail: { type: "string" },
    intermediaryName: { type: "string" },
    accountHandlerName: { type: "string" },
    agentCode: { type: "string" },
    status: { type: "string" },
    pricingResponseSchema,
  },
  required: ["quoteId", "quoteNo", "validityDate", "status"],
  additionalProperties: true,
};
