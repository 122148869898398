import { useTranslation } from "react-i18next";

const ImportantNoticesTerms = () => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.renewal" });
  return (
    <ol>
      <li>
        Coverage is subject to the specific terms, conditions and exclusions of the actual policy issued. You may obtain a copy of your policy wording
        from your insurance representative.
      </li>
      <li>
        {t("terms.importantNoticeTerms")}
        <ol type="a">
          <li>that diminishes the risk to be undertaken by Allied World;</li>
          <li>that is common knowledge;</li>
          <li>that Allied World knows or, in the ordinary course of its business, ought to know;</li>
          <li> as to which compliance with your duty is waived by Allied World.</li>
        </ol>
        You have the same duty to disclose those matters to Allied World before you renew, extend, vary, or reinstate a contract of insurance.
      </li>
      <li>
        All information provided by you in support of your renewal application for insurance must be correct, as you will be bound by the answers and
        by the information you have provided. If you do not comply with your duty of disclosure or make a misrepresentation, Allied World may be
        entitled to reduce our liability under the policy in respect of a claim or may cancel or avoid the policy from its inception. If the
        non-disclosure or misrepresentation is fraudulent, Allied World may also have the additional option of avoiding the policy from its inception
        and retaining the premiums paid.
      </li>
    </ol>
  );
};

export default ImportantNoticesTerms;
