import { createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./EventHeader";
import Footer from "./EventFooter";
import { QueryClient, QueryClientProvider } from "react-query";
import { windowScroll } from "../../helpers/shared/useNavigationEffects";
import LiabilityBindRoutes from "./bind/routes";
import Cls from "../../utils/cls";
import { liabilityBindState } from "./bind/types";
import createInitialState from "./bind/state";
import { Validations } from "../../types/shared/Validation";
import { validate } from "../../helpers/shared/validation";
import FormStep from "../../components/forms/FormStep";
import useEvent from "../../hooks/useEvent";
import { proposerEventDetailsValidations } from "../../helpers/event_public_liability/validations";
import { parseState } from "../../helpers/shared/serialization";
import { checkout } from "../../helpers/event_public_liability/api/checkoutApi";
import { Region } from "../../enums/shared/forms/Region";
import { FeedbackDetails } from "../../types/travel/Policy";
import createFeedback from "../../helpers/travel/new-policy/api/createFeedback";
import { ProductType } from "../../enums/shared/api/Api";

type ChangeHandler = <T extends keyof liabilityBindState>(key: T, value: liabilityBindState[T]) => void;
type test = (t: liabilityBindState) => void;
type submitErr = (t: boolean) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};

export const LiabilityBindContext = createContext<{
  state: liabilityBindState;
  onChange: ChangeHandler;
  setFormState: test;
  setSubmitError: submitErr;
  isSubmitting: boolean;
  submitError: unknown;
}>({
  onChange: noop,
  state: createInitialState(),
  setFormState: noop,
  setSubmitError: noop,
  isSubmitting: false,
  submitError: null,
});

enum Steps {
  "QuoteList",
  "QuoteDetails",
  "ProposerDetails",
  "Summary",
  "Terms",
}
const getURLForStep = (step: string) => `/event_public_liability${step}`;
const stepsURLS = [
  getURLForStep("/retrieveList"),
  getURLForStep("/bind/quoteDetails"),
  getURLForStep("/bind/proposerDetails"),
  getURLForStep("/bind/summary"),
  getURLForStep("/bind/terms"),
  getURLForStep("/bind/payment-link-sent"),
];
const steps: Steps[] = [Steps.ProposerDetails, Steps.Summary, Steps.Terms];
const amountOfSteps = steps.length;

const findInvalidPage = (validations: Validations<unknown>[], state: liabilityBindState): number =>
  validations.findIndex(validationSchema => Object.keys(validate(state, validationSchema)).length > 0);

const storageKey = "/event_public_liability/retrieveList";
export const getInitialBindState = () => {
  try {
    const sessionStorageState = sessionStorage.getItem(storageKey);
    const initialState = sessionStorageState ? (parseState(sessionStorageState) as liabilityBindState) : {};
    return createInitialState(initialState);
  } catch (e) {
    console.error("Session storage could not be parsed", e);
    return createInitialState();
  }
};
const submit = async (formtate: liabilityBindState, paymentMethod: string, setQuoteId: Dispatch<SetStateAction<string>>) => {
  try {
    const response = await checkout(formtate, paymentMethod, Region.SG);
    setQuoteId(response.quoteId);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const BindQuoteForm: FC = () => {
  const location = useLocation();
  const quoteRow = location.state as any;
  const [quoteId, setQuoteId] = useState("");
  const [formState, setFormState] = useState<liabilityBindState>(getInitialBindState());
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const navigate = useNavigate();
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const stepValidations = [proposerEventDetailsValidations];
  const persistState = useEvent(() => sessionStorage.setItem(storageKey, JSON.stringify(formState)));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const onFeedback = async (details: FeedbackDetails) => createFeedback(details, quoteId, ProductType.APL).then(() => {});

  useEffect(() => {
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);
  useEffect(() => {
    setFormState({ ...formState, ...quoteRow });
  }, [quoteRow]);

  useEffect(() => {
    const validationsToRun = stepValidations.slice(0);
    findInvalidPage(validationsToRun, formState);
  }, [formState]);

  useEffect(() => {
    windowScroll();
  }, [location]);
  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const shouldRenderFormStep = step !== 0 && step !== 1 && step !== 5;

  const sendPaymentLink = () => {
    const url = "/event_public_liability/bind/payment-link-sent";
    onSubmit(url, "stripe");
  };
  const creditTerms = async () => {
    const url = "/event_public_liability/payment/success";
    onSubmit(url, "credit");
  };
  const onSubmit = async (url: string, paymentMethod: string) => {
    try {
      setIsSubmitting(true);
      await submit(formState, paymentMethod, setQuoteId);
      setSubmitError(false);
      navigate(url, { state: { quoteId: formState.quoteId } });
    } catch {
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <LiabilityBindContext.Provider
      value={{
        setFormState: setFormState,
        onChange: onFieldChange,
        state: formState,
        isSubmitting: isSubmitting,
        submitError: submitError,
        setSubmitError: setSubmitError,
      }}
    >
      <main>
        <form>
          <div className={Cls("flex flex-col min-h-screen gap-0")}>
            <Header hideAccount={false} />
            {shouldRenderFormStep && <FormStep current={step - 1} total={amountOfSteps} />}
            <LiabilityBindRoutes
              onPrevious={goToPreviousStep}
              onNext={goToNextStep}
              sendPaymentLink={sendPaymentLink}
              creditTerms={creditTerms}
              region={Region.SG}
              onFeedback={onFeedback}
            />
            <Footer />
          </div>
        </form>
      </main>
    </LiabilityBindContext.Provider>
  );
};

const BindQuote: FC = () => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <BindQuoteForm />
    </QueryClientProvider>
  );
};

export default BindQuote;
