import { FC } from "react";
import { textEllipsis } from "../../../../../helpers/shared/textEllipsis";
import { formatToDisplayQuoteDate } from "../../../../../utils/date";
import Status from "../../../../event_public_liability/components/status";
import { CreateQuoteResponse } from "../../../../../types/group_travel/api/Quote";
import { useNavigate } from "react-router";
import { formatToCreateQuoteState } from "../../../../../helpers/group_travel/quoteState";
import { clearPISessionStorage as clearSession } from "../../../../../helpers/shared/sessionStorage";
import { ProductType, ProductsName } from "../../../../../enums/shared/api/Api";
import { formatToAPAGCreateQuoteState } from "../../../../../helpers/group_personal_accident/quoteState";
import { ArrowCell } from "../../../../../components/basics/ArrowCell";

interface TableCellProps {
  row: Row;
}

interface Row extends CreateQuoteResponse {
  policyNumber: string;
}

const boundStatus = (status: string) => {
  return status === "paid" || status === "processed" || status === "bound";
};

export const ProposerNameCell: FC<{ name: string }> = ({ name }) => (
  <td className="w-[5rem] md:w-60 md:text-left whitespace-nowrap">
    <span className="font-bold md:hidden">{textEllipsis(name, 9, 2)}</span>
    <span className="font-bold hidden md:block">{textEllipsis(name, 28, 2)}</span>
  </td>
);

export const PolicyNumberCell: FC<{ row: any }> = ({ row }) => (
  <td className="w-[5rem] md:w-[9rem]">
    {" "}
    <span className="hidden md:block">{boundStatus(row.status) ? row.policyNumber : row.quoteNo}</span>
    <span className="md:hidden">{boundStatus(row.status) ? ".." + row.policyNumber?.substring(row.policyNumber.length - 9) : row.quoteNo}</span>
  </td>
);

export const ValidityDateCell: FC<{ row: CreateQuoteResponse }> = ({ row }) => (
  <td className="w-[5rem] hidden md:block">{boundStatus(row.status) ? "-" : formatToDisplayQuoteDate(new Date(row.validityDate))}</td>
);

export const ProductTypeName: FC<{ row: CreateQuoteResponse }> = ({ row }) => (
  <td className="w-[10rem] hidden md:block">{ProductsName[row.productType as keyof typeof ProductsName]}</td>
);

export const StatusCell: FC<{ row: CreateQuoteResponse }> = ({ row }) => (
  <td className="w-[5rem] uppercase">
    <Status status={boundStatus(row.status) ? "bound" : row.status} />
  </td>
);

type AUProductType = ProductType.APAG | ProductType.ACTL;

export const TableCell: FC<TableCellProps> = ({ row }) => {
  const quoteData = {
    [ProductType.APAG]: formatToAPAGCreateQuoteState(row),
    [ProductType.ACTL]: formatToCreateQuoteState(row),
  } as any;
  const navigate = useNavigate();
  const navLink: Record<AUProductType, string> = {
    [ProductType.APAG]: "au/group_personal_accident/bind",
    [ProductType.ACTL]: "au/group_travel/bind",
  } as any;

  const documentLink: Record<AUProductType, string> = {
    [ProductType.APAG]: "au/group_personal_accident/documents",
    [ProductType.ACTL]: "au/group_travel/documents",
  };
  const goToBind = () => {
    clearSession(navLink[row.productType as AUProductType]);
    navigate(`/${navLink[row.productType as AUProductType]}/quote-summary`, { state: quoteData[row.productType] });
  };
  const goToDocuments = () => {
    navigate(`/${documentLink[row.productType as AUProductType]}`, { state: { policyNumber: row.policyNumber, productCode: row.productType } });
  };
  return (
    <tr
      className={`flex justify-between pl-4 md:pl-[1.875rem] pr-[1.125rem] py-2 text-sm bg-white border-b border-color ${
        row.status !== "expired" ? "hover:shadow-hover cursor-pointer" : ""
      }`}
      onClick={row.status !== "expired" ? (row.status === "valid" ? goToBind : goToDocuments) : undefined}
    >
      <ProposerNameCell name={row.proposerName} />
      <PolicyNumberCell row={row} />
      <ProductTypeName row={row} />
      <ValidityDateCell row={row} />
      <StatusCell row={row} />
      <ArrowCell isClickable={row.status !== "expired"} />
    </tr>
  );
};

export default TableCell;
