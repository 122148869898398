import { FC, useCallback, useState } from "react";
import TextInput from "../../../components/form-fields/TextInput";
import { LoadingButton } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import RawHTML from "../../../components/basics/RawHTML";
import SecondaryLayout from "../../../components/authentication/layout/SecondaryLayout";
import { useTranslation } from "react-i18next";
import SelectInput from "../../../components/form-fields/SelectInput";
import useForm from "../../../hooks/useForm";
import { validationsForAuSignupForm } from "../../../helpers/authentication/validations";
import { removeExtraSpaces } from "../../../helpers/shared/formHandlers";
import { createBrokerUser, getBrokerageInfo } from "../../../helpers/signup/signupApi";
import { BrokerageInfoResponse, BrokeragesList } from "../../../types/user/api/User";
import { ErrorMessage } from "../../../components/forms";
import useWithAsyncLoadingIndicator from "../../../hooks/useWithAsyncLoadingIndicator";
import Spinner from "../../../components/icons/Spinner";
import cls from "../../../utils/cls";
import { useNavigate } from "react-router-dom";

export type BrokerDetailsType = {
  email: string;
  firstName: string;
  lastName: string;
  afslNumber: string;
  brokerageName: string;
  brokerCode: string;
  region: string;
  homeBranch: string;
  isNotListed: boolean;
};

const brokerInitialState: BrokerDetailsType = {
  email: "",
  firstName: "",
  lastName: "",
  afslNumber: "",
  brokerageName: "",
  brokerCode: "",
  region: "AU",
  homeBranch: "SY",
  isNotListed: false,
};

const prepareBrokeragesList = (brokerListData: string[]): string[] => ["", ...brokerListData, "Not Listed"];

const fetchBrokerages = async (
  afslNumber: string,
  setBrokeragesList: React.Dispatch<React.SetStateAction<BrokeragesList[] | undefined>>,
  setBrokerages: React.Dispatch<React.SetStateAction<string[]>>,
  setAfslNumberStatus: React.Dispatch<React.SetStateAction<string>>,
  setState: React.Dispatch<React.SetStateAction<BrokerDetailsType>>,
  setCognitoError: React.Dispatch<React.SetStateAction<string>>
) => {
  setAfslNumberStatus("checking");
  try {
    const response: BrokerageInfoResponse = await getBrokerageInfo({ afslNumber });
    if (response?.result && response?.result.length !== 0) {
      const brokeragesData = response.result as BrokeragesList[];
      setBrokeragesList(brokeragesData);
      setBrokerages(brokeragesData.map(item => item.abbreviation).sort());
      setAfslNumberStatus("done");
      setState(prevState => ({ ...prevState, isNotListed: false }));
    } else {
      setBrokerages([]);
      setBrokeragesList([]);
      setAfslNumberStatus("");
      setState(prevState => ({ ...prevState, isNotListed: true, brokerageName: "Not Listed" }));
    }
  } catch (error: any) {
    setState(prevState => ({ ...prevState, isNotListed: true, brokerageName: "Not Listed" }));
    setAfslNumberStatus("");
    console.error(error);
    setCognitoError(error?.response?.data?.errorMessage);
  }
};

const handleSubmit = async (
  e: React.FormEvent<HTMLFormElement>,
  validateAll: () => boolean,
  state: BrokerDetailsType,
  setCognitoError: React.Dispatch<React.SetStateAction<string>>,
  navigate: (path: string) => void
) => {
  try {
    e.preventDefault();
    if (!validateAll()) {
      return;
    }
    const { brokerageName, brokerCode, email, lastName, firstName, region, homeBranch, afslNumber, isNotListed } = state;
    await createBrokerUser({
      agentName: brokerageName,
      agentCode: brokerCode,
      email: email,
      surName: lastName,
      givenName: firstName,
      region: region,
      homeBranch: homeBranch,
      afslNumber: afslNumber,
      isNotListed: isNotListed,
    });
    navigate(state.brokerageName === "Not Listed" ? "/user/request" : "/user/success");
  } catch (error: any) {
    setCognitoError(error?.response?.data?.errorMessage);
  }
};

const handleBrokerageName = (
  selectedValue: string,
  brokeragesList: BrokeragesList[] | undefined,
  setState: React.Dispatch<React.SetStateAction<BrokerDetailsType>>
) => {
  const selectedBrokerDetail = brokeragesList?.find(item => item.abbreviation === selectedValue);
  if (selectedBrokerDetail) {
    setState(prevState => ({
      ...prevState,
      brokerageName: selectedValue,
      brokerCode: selectedBrokerDetail?.brokerageCode,
      region: selectedBrokerDetail?.region,
      homeBranch: selectedBrokerDetail?.homeBranch,
    }));
  } else {
    setState(prevState => ({
      ...prevState,
      brokerageName: selectedValue,
      brokerCode: "",
    }));
  }
};

const AuSignupForm: FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<BrokerDetailsType>(brokerInitialState);
  const onFieldChange = useCallback(
    (key: "email" | "firstName" | "lastName" | "afslNumber" | "brokerageName", value: string | number) => {
      setState(prevState => ({ ...prevState, [key]: value }));
    },
    [setState]
  );
  const { errors, validateField, field, validateAll } = useForm(state, validationsForAuSignupForm, onFieldChange);
  const [cognitoError, setCognitoError] = useState("");
  const [afslNumberStatus, setAfslNumberStatus] = useState("");
  const [brokerages, setBrokerages] = useState<string[]>([]);
  const [brokeragesList, setBrokeragesList] = useState<BrokeragesList[]>();

  const { t } = useTranslation("pages", { keyPrefix: "auth.login.confirmAgentForm" });

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    removeExtraSpaces("afslNumber", setState, validateField)(event);

    if (state.afslNumber) {
      fetchBrokerages(state.afslNumber, setBrokeragesList, setBrokerages, setAfslNumberStatus, setState, setCognitoError);
    } else {
      setState(prevState => ({ ...prevState, brokerageName: "" }));
      setBrokerages([]);
    }
  };

  const { isLoading, action: onSubmit } = useWithAsyncLoadingIndicator(async (e: React.FormEvent<HTMLFormElement>) =>
    handleSubmit(e, validateAll, state, setCognitoError, navigate)
  );

  return (
    <SecondaryLayout title={"Sign up"}>
      <form noValidate={true} onSubmit={onSubmit} className="space-y-8 mb-10">
        <RawHTML boldedLinks={true} content={t(`form.au.auSignupContent`)} />
        <div className="space-y-3 md:max-w-xs">
          <TextInput
            error={errors.firstName}
            label={"First Name"}
            value={state.firstName}
            onBlur={removeExtraSpaces("firstName", setState, validateField)}
            onValueChange={field.firstName.onValueChange}
          />
          <TextInput
            error={errors.lastName}
            label={"Last Name"}
            value={state.lastName}
            onBlur={removeExtraSpaces("lastName", setState, validateField)}
            onValueChange={field.lastName.onValueChange}
          />
          <TextInput
            label="Email"
            aria-label="email"
            error={errors.email}
            autoComplete="username"
            value={state.email}
            onBlur={field.email.onBlur}
            onValueChange={value => {
              const trimmedEmail = value.trim();
              field.email.onValueChange(trimmedEmail);
              errors.email && validateField("email", trimmedEmail);
            }}
          />

          <TextInput
            error={errors.afslNumber}
            label="AFSL No."
            value={state.afslNumber}
            onBlur={onBlur}
            onValueChange={field.afslNumber.onValueChange}
          >
            {afslNumberStatus === "checking" && (
              <div
                role="status"
                className={cls(
                  "!absolute flex gap-1 font-light text-xs right-4 top-6 pointer-events-none icon-3 icon-left icon-top-0.5 pl-7",
                  "text-green-dark delayed-show animate-duration-1000"
                )}
              >
                <div className="m-auto w-3 h-3">
                  <Spinner width="0.75rem" height="0.75rem" color="#3F9293" />
                </div>
              </div>
            )}
          </TextInput>

          <SelectInput
            aria-label="brokerage name"
            label={"Brokerage Name"}
            value={state.brokerageName}
            options={prepareBrokeragesList(brokerages)}
            {...field.brokerageName}
            onChange={e => handleBrokerageName(e.target.value, brokeragesList, setState)}
          />
        </div>
        <ErrorMessage error={cognitoError} />
        <LoadingButton isLoading={isLoading} className="mt-6" type={ButtonType.PRIMARY} loadingText={"Loading"}>
          Sign up
        </LoadingButton>
      </form>
    </SecondaryLayout>
  );
};

export default AuSignupForm;
