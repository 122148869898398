import { AnimatedSidebar } from "../../../components/interactives";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const DomesticTravelInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      {/* Body text  */}
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">Domestic Travel Days</h2>
        <p>
          This includes interstate and intrastate business travel, associated leisure travel and private travel days outside of a 50km radius from the
          Insured Person’s place of residence or place of work.
        </p>
        <p> 1 day = 1 travel day, per person.</p>
        <Button className="mt-10" type={ButtonType.PRIMARY} htmlType="button" onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
