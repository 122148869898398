import { ContractorType } from "./type";

export const createInitialState = (persistedState: Partial<ContractorType> = {}): ContractorType => {
  return {
    companyName: "",
    uenNumber: "",
    number: "",
    streetName: "",
    floorNumber: "",
    unitNumber: "",
    postalCode: "",
    directors: [{ name: "", NRIC: "" }],
    guarantors: [{ name: "", NRIC: "" }],
    sumInsured: 100000,
    agents: [{ agentCode: "", agentCommision: 15, basePremiumCP14: 50, basePremiumNCP26: 50, businessType: "fwb" }],
    ...persistedState,
  };
};

export default createInitialState;
