import { getCurrentPolicyPeriod } from "../policyPeriod";
import { IndemnityFormState } from "./types";

/**
 * Loads the initial form state
 * Allows passing already known values to override values. This way we can load persisted values and add new properties without breaking persisted state
 */
const createInitialState = (persistedState: Partial<IndemnityFormState> = {}): IndemnityFormState => {
  const { startDate, endDate } = getCurrentPolicyPeriod();
  return {
    coverageStartDate: startDate,
    coverageEndDate: endDate,
    hasBeenDeregistered: null,
    hasClaims: null,
    pendingClaims: null,
    refusedRegularPaymentOrProposal: null,
    subjectToDisciplinaryActions: null,
    limit: null,
    address: "",
    agentCode: "",
    building: "",
    district: "",
    districtCode: "",
    districtName: "",
    email: "",
    givenName: "",
    mobileNumber: "",
    officeLocation: "",
    otherNames: "",
    salutation: "",
    streetNumberAndName: "",
    surname: "",
    territory: "",
    accepted: false,
    promotionalServices: true,
    gp: 0,
    levy: 0,
    total: 0,
    ...persistedState,
  };
};

export default createInitialState;
