import { FC } from "react";
import RawHTML from "../../../components/basics/RawHTML";
import CheckBox from "../components/CheckBox";

export interface PersonalDataProps {
  t: any;
  state: any;
  marketingVoiceCall: any;
  marketingTextMessage: any;
}

const PersonalDataTerms: FC<React.PropsWithChildren<PersonalDataProps>> = ({ t, state, marketingVoiceCall, marketingTextMessage }) => {
  return (
    <>
      <p>{t("personalDataTerms.consent")}</p>
      <p>{t("personalDataTerms.contact.title")}</p>
      <p>
        <CheckBox
          t={t}
          label="personalDataTerms.contact.phone"
          name="marketingVoiceCall"
          id="marketing-voice-call"
          checked={state.marketingVoiceCall}
          onValueChange={marketingVoiceCall.onValueChange}
        />
      </p>
      <p>
        <CheckBox
          t={t}
          label="personalDataTerms.contact.message"
          name="marketingTextMessage"
          id="marketing-text-message"
          checked={state.marketingTextMessage}
          onValueChange={marketingTextMessage.onValueChange}
        />
      </p>
      <RawHTML content={t("personalDataTerms.notice")} />
    </>
  );
};

export default PersonalDataTerms;
