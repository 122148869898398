import { AnimatedSidebar } from "../../../../components/interactives";
import { Button } from "../../../../components/basics/Button";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const TripTypeInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">Coverage</h2>
        <h3 className="text-md font-bold mt-6">Single Trip</h3>
        <p>
          Choose “Single Trip” if you only want coverage for a single specified Trip. The Period of Insurance for a Single Trip begins from the
          “Starting from” date and ends on the “Ending on” indicated.
        </p>
        <h3 className="text-md font-bold mt-6">Annual</h3>
        <p>
          Choose “Annual” if you want to be covered for multiple Trips (subject always to the terms, conditions and exclusions of the policy issued)
          during the Period of Insurance. The Period of Insurance is for a 12-month period beginning from the “Starting from” date indicated.
        </p>

        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
