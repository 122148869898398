import { useNavigate } from "react-router-dom";
import { FC, PropsWithChildren, useContext, useState } from "react";
import { RadioInput } from "../../../../components/form-fields";
import { IOption } from "../../../../components/form-fields/RadioInput";
import { FormGroup } from "../../../../components/forms";
import { basicDetailsValidations } from "../../../../helpers/group_travel/validations";
import useForm from "../../../../hooks/useForm";
import { BasicDetailsState } from "../types";
import { CreateQuoteContext } from "../../CreateQuote";
import { useTranslation } from "react-i18next";
import AuGroupBusinessFormStepNavigation from "../CreateQuoteStepNavigation";
import EligibilityDialog from "../../dialog/EligibilityDialog";
import GroupTravelHeading from "../../components/Heading";

const yesNoOptions: IOption<"yes" | "no">[] = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

type BasicDetailsOptionProps = {
  value: "yes" | "no" | null;
  onValueChange: (value: "yes" | "no" | null) => void;
  formLabel: string;
  label: string;
  error?: string | boolean | null;
  hyperLinkText?: string;
  onHyperLink?: () => void;
};

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const BasicDetailsOption: FC<PropsWithChildren<BasicDetailsOptionProps>> = ({
  value,
  error,
  onValueChange,
  formLabel,
  label,
  onHyperLink,
  hyperLinkText,
}) => (
  <FormGroup
    margin="mt-4 md:mt-8"
    subtitle={label}
    subTitleFontSize="mb-4 md:mb-0 text-xl md:min-w-[34rem]"
    fullWidth
    onHyperLink={onHyperLink}
    hyperLinkText={hyperLinkText}
    justifyEnd={true}
  >
    <RadioInput
      error={error}
      name={formLabel}
      onValueChange={onValueChange}
      value={value}
      options={yesNoOptions}
      aria-label={formLabel}
      inputWidth="md:w-48 w-full"
    />
    {/* List of Radio buttons for the group travel eligibility options */}
  </FormGroup>
);

const areAllAnswerApproved = (state: BasicDetailsState) => {
  return state.familyTrust === "yes" && state.annualRenewableBusiness === "yes" && state.donNotTravelAdvice === "no";
};

const BasicDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct" });
  const [showEligibilityDialog, setShowEligibilityDialog] = useState(false);
  const navigate = useNavigate();
  const { state, onChange } = useContext(CreateQuoteContext);
  const form = useForm(state, basicDetailsValidations, onChange);
  const {
    field: { familyTrust, annualRenewableBusiness, donNotTravelAdvice },
  } = form;
  const redirectToTravelAdviceLink = () => window.open("https://www.smartraveller.gov.au/", "_blank", "noreferrer");

  const handleProceed = () => {
    if (areAllAnswerApproved(state)) {
      onNext?.();
    } else {
      setShowEligibilityDialog(true);
    }
  };

  const handleNext = () => form.validateForm(handleProceed);

  onPrevious = () => {
    navigate("/products");
  };

  return (
    <>
      <div className="container-flow">
        <GroupTravelHeading title={t("steps.basicDetails")} alt="GroupTravel" />
        <BasicDetailsOption formLabel="claims-made" label={t("basicDetailLabels.familyTrust")} value={state.familyTrust} {...familyTrust} />
        <BasicDetailsOption
          formLabel="subject-to-disciplinary-action"
          label={t("basicDetailLabels.annualRenewableBusiness")}
          value={state.annualRenewableBusiness}
          {...annualRenewableBusiness}
        />
        <BasicDetailsOption
          formLabel="deregistered-as-agent"
          label={t("basicDetailLabels.donNotTravelAdvice")}
          value={state.donNotTravelAdvice}
          {...donNotTravelAdvice}
          onHyperLink={() => redirectToTravelAdviceLink()}
          hyperLinkText={t("basicDetailLabels.travelLink")}
        />
      </div>

      <AuGroupBusinessFormStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
      {showEligibilityDialog && <EligibilityDialog onClose={() => setShowEligibilityDialog(false)} state={state} />}
    </>
  );
};

export default BasicDetails;
