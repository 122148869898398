import RawHTML from "../../components/basics/RawHTML";
interface PolicyWordingsProps {
  t: any;
  policyWordingUrl: string;
}

const PolicyWordings: React.FC<PolicyWordingsProps> = ({ t, policyWordingUrl }) => {
  return (
    <div className="space-y-4">
      <RawHTML boldedLinks content={t("keyExclusions.policyWording", { policyWordingUrl })} />
    </div>
  );
};

export default PolicyWordings;
