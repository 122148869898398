import { DiscountRequest } from "../../../../types/travel/new-policy/api/Discount";
import { TravelDetails } from "../../../../types/travel/Policy";
import { ProductType } from "../../../../enums/shared/api/Api";
import { formatToServerDate } from "../../../../utils/date";

export const discountRequest = (data: TravelDetails): DiscountRequest => {
  return {
    productCode: ProductType.ATR,
    coverageType: data.policyType || "single-trip",
    start_date: formatToServerDate(data.startDate),
  };
};
