import { FC, useState, useCallback } from "react";
import { H2 } from "../../../../components/basics";
import { CheckInputBlock } from "../../../../components/form-fields";
import { Article } from "../../../../components/groups";
import { Accordion, AccordionSection, Section } from "../../../../components/interactives";
import { termsValidations } from "../../../../helpers/travel/validations";
import useForm from "../../../../hooks/useForm";
import { FieldChange, TermsConditions } from "../../../../types/travel/Policy";
import ServerError from "../../../errors/ServerError";
import PersonalDataTerms from "./terms/PersonalDataTerms";
import ProductInformationTerms from "./terms/ProductInformationTerms";
import { useTranslation } from "react-i18next";
import classNamePresets from "../../../../utils/classNamePresets";
import { CheckoutResponse } from "../../../../types/travel/new-policy/api/Checkout";
import { get } from "lodash/fp";
import { ServerApiError } from "../../../../types/shared/ServerApiError";
import PolicyFormStepNavigation from "../PolicyFormStepNavigation";
import ImportantNoticesTerms from "../../../../components/terms/ImportantNoticesTerms";
import DeclarationTerms from "../../../../components/terms/DeclarationTerms";
import AgreementAlert from "../../../../components/terms/AgreeAlert";

export interface TermsConditionsProps {
  flowStateSlice: TermsConditions;
  isSubmitting: boolean;
  onFieldChange: FieldChange<TermsConditions>;
  onBack: () => void;
  onSubmit: () => Promise<CheckoutResponse>;
}

const isServerApiError = (error: unknown): error is ServerApiError => !!error && typeof error == "object" && "details" in error;

const extractServerError = (error: unknown): ServerApiError => {
  const extractedError = get("response.data", error);
  if (isServerApiError(extractedError) && extractedError.details?.length) {
    return extractedError;
  }
  return { errorMessage: "An unknown error occurred" };
};

const Terms: FC<React.PropsWithChildren<TermsConditionsProps>> = ({
  flowStateSlice,
  onFieldChange,
  isSubmitting,
  onBack,
  onSubmit,
}: TermsConditionsProps) => {
  const { validateForm, field } = useForm(flowStateSlice, termsValidations, onFieldChange);
  const [serverError, setServerError] = useState<ServerApiError | null>(null);
  const clearServerError = useCallback(() => setServerError(null), [setServerError]);

  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.terms" });
  const { t: tSupportMailId } = useTranslation("pages", { keyPrefix: "travel.newPolicy.customerSupport" });

  const handleSubmit = async () => {
    validateForm(async () => {
      try {
        await onSubmit();
      } catch (error) {
        setServerError(extractServerError(error));
      }
    });
  };

  return (
    <>
      <div className="container-flow flex-grow">
        <H2 className={classNamePresets.mainHeading}>{t("title")}</H2>
        {serverError && <ServerError supportEmailId={tSupportMailId("emailId")} displayType="modal" error={serverError} onClose={clearServerError} />}
        <Article>
          <ProductInformationTerms />
        </Article>

        <Accordion id="terms-info">
          <AccordionSection id="important-notices" label="Important Notices">
            <ImportantNoticesTerms />
          </AccordionSection>
          <AccordionSection id="declaration" label="Declaration">
            <DeclarationTerms />
          </AccordionSection>
        </Accordion>

        <div className="grid gap-5">
          <h3 className="col-span-full font-bold md:text-xl mt-6">{t("personalDataTerms.title")}</h3>
          <Section>
            <PersonalDataTerms flowStateSlice={flowStateSlice} onFieldChange={onFieldChange} />
          </Section>

          <h3 className="col-span-full font-bold md:text-xl mt-6">{t("agree.title")}</h3>
          <CheckInputBlock {...field.accepted} id="accepted" checked={flowStateSlice.accepted}>
            {t("agree.content")}
          </CheckInputBlock>
          <AgreementAlert t={t} />
        </div>
      </div>
      <PolicyFormStepNavigation onBack={onBack} onSubmit={handleSubmit} isLoading={isSubmitting} />
    </>
  );
};

export default Terms;
