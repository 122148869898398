import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FormStep from "../../components/forms/FormStep";
import CreateQuoteFormRoutes from "./Form/routes";
import {
  additionalDetailsValidations,
  basicDetailsValidations,
  planValidations,
  policyDetailsValidation,
} from "../../helpers/group_travel/validations";
import { GroupTravelFormState } from "./Form/types";
import createInitialState from "./Form/state";
import useEvent from "../../hooks/useEvent";
import { useStepNavigation } from "../../hooks/useStepNavigation";
import Header from "../event_public_liability/EventHeader";
import Footer from "./Footer";
import { QueryClient, QueryClientProvider } from "react-query";
import { parseState } from "../../helpers/shared/serialization";
import { createQuote } from "../../helpers/group_travel/api/pricingApi";
import { CreateQuoteResponse } from "../../types/group_travel/api/Quote";
import { clearPISessionStorage as clearSession } from "../../helpers/shared/sessionStorage";

type ChangeHandler = <T extends keyof GroupTravelFormState>(key: T, value: GroupTravelFormState[T]) => void;
export type test = (t: GroupTravelFormState) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};
type SetterFn<T> = (t: T) => void;
export const CreateQuoteContext = createContext<{
  state: GroupTravelFormState;
  onChange: ChangeHandler;
  setFormState: test;
  isSubmitting: boolean;
  serverError: boolean;
  setServerError: SetterFn<boolean>;
  setShowReferralDialog: SetterFn<boolean>;
  showReferralDialog: boolean;
}>({
  onChange: noop,
  state: createInitialState(),
  setFormState: noop,
  isSubmitting: false,
  serverError: false,
  setServerError: noop,
  setShowReferralDialog: noop,
  showReferralDialog: false,
});

enum Steps {
  "BasicDetails",
  "PolicyDetails",
  "Plan",
  "AdditionalDetails",
  "Summary",
}

const getURLForStep = (step: string) => `/au/group_travel/form/${step}`;

// Tracks the url instead of step since it allows to respond to browser location seamlessly
const stepsURLS = [
  getURLForStep("basic-details"),
  getURLForStep("policy-details"),
  getURLForStep("plan"),
  getURLForStep("additional-details"),
  getURLForStep("summary"),
];
const stepValidations = [basicDetailsValidations, policyDetailsValidation, planValidations, additionalDetailsValidations, {}];
const steps: Steps[] = [Steps.BasicDetails, Steps.PolicyDetails, Steps.Plan, Steps.AdditionalDetails, Steps.Summary];
const amountOfSteps = steps.length - 1;

const storageKey = "au/group_travel/form";

const getInitialState = () => {
  const sessionStorageState = sessionStorage.getItem(storageKey);
  try {
    return createInitialState(sessionStorageState ? parseState(sessionStorageState) : {});
  } catch (e) {
    console.error("Session storage could not be parsed", e);
    return createInitialState();
  }
};
const referralFields: (keyof GroupTravelFormState)[] = [
  "excessClaimsCover",
  "exceedDuration",
  "morePeopleInTrip",
  "engagedActivities",
  "privateTravel",
  "collectiveFlights",
];

const isReferralCase = (state: GroupTravelFormState) => {
  return !referralFields.every(field => state[field] === "no");
};

const saveQuote = (
  // eslint-disable-next-line @typescript-eslint/no-shadow
  response: CreateQuoteResponse,
  setFormState: SetterFn<GroupTravelFormState>,
  formState: GroupTravelFormState,
  goToNextStep: VoidFunction | null
) => {
  setFormState({
    ...formState,
    quoteId: response?.quoteId,
    quoteNo: response?.quoteNo,
    validityDate: new Date(response?.validityDate),
    status: response?.status,
    agentMail: response?.agentEmail,
  });
  goToNextStep?.();
};
const referralOrSaveQuote = async (
  isReferral: boolean,
  setShowReferralDialog: SetterFn<boolean>,
  responseData: CreateQuoteResponse,
  setFormState: SetterFn<GroupTravelFormState>,
  formState: GroupTravelFormState,
  goToNextStep: VoidFunction | null
) => (isReferral ? setShowReferralDialog(true) : saveQuote(responseData, setFormState, formState, goToNextStep));

const CreateQuoteForm: FC = () => {
  const [formState, setFormState] = useState<GroupTravelFormState>(getInitialState());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [showReferralDialog, setShowReferralDialog] = useState(false);
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const navigate = useNavigate();
  const location = useLocation();
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const persistState = useEvent(() => sessionStorage.setItem(storageKey, JSON.stringify(formState)));
  const isAccountHidden = false;
  useStepNavigation<GroupTravelFormState>(step, stepValidations, formState, stepsURLS);

  useEffect(() => {
    clearSession("au/group_travel/bind");
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const data = await createQuote(formState);
      referralOrSaveQuote(isReferralCase(formState), setShowReferralDialog, data, setFormState, formState, goToNextStep);
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setServerError(true);
    }
  };

  return (
    <CreateQuoteContext.Provider
      value={{
        setFormState: setFormState,
        onChange: onFieldChange,
        state: formState,
        isSubmitting,
        setServerError,
        serverError,
        setShowReferralDialog,
        showReferralDialog,
      }}
    >
      <main>
        <form>
          <div className="flex flex-col min-h-screen gap-0">
            <Header hideAccount={isAccountHidden} />
            {step + 1 < 5 && <FormStep current={step + 1} total={amountOfSteps} />}
            <CreateQuoteFormRoutes onPrevious={goToPreviousStep} onNext={goToNextStep} onSubmit={onSubmit} />
            <Footer />
          </div>
        </form>
      </main>
    </CreateQuoteContext.Provider>
  );
};

const CreateQuote: FC = () => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <CreateQuoteForm />
    </QueryClientProvider>
  );
};

export default CreateQuote;
