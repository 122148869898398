import { Article } from "../../../components/groups";
import photosImg from "../../../assets/images/photos-thank-you.png";
import TravelHeader from "../TravelHeader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import TravelFooter from "../TravelFooter";

const Error = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.payment.cancelled" });

  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <TravelHeader hideAccount />
      <div className="container-flow flex-grow mt-6 lg:mt-20">
        <div className="flex gap-10">
          <div className="w-full lg:w-3/5">
            <h2 className="font-serif font-bold text-4xl mb">{t("title")}</h2>
            <Article>
              <p>{t("content")}</p>
              <Button className="mt-8" type={ButtonType.PRIMARY} onClick={() => navigate("/")}>
                {t("button.back")}
              </Button>
            </Article>
          </div>
          <div className="w-2/5 hidden lg:block">
            <img src={photosImg} alt="" />
          </div>
        </div>
      </div>
      <TravelFooter />
    </div>
  );
};

export default Error;
