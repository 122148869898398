import { FC, PropsWithChildren } from "react";
import LiabilitySection from "../../../components/forms/LiabilitySection";
import { liabilityFormState } from "../form/types";
import CommonRow from "../../components/CommonRow";
import { CommonTable, ContentCell, HeadingRow } from "../components/CommonTable";
import PricingLabels from "../components/PricingLabels";
import { Pricing } from "../components/Pricing";
type Props = PropsWithChildren<{
  state: liabilityFormState;
  t: any;
}>;
const QuotationLiabilitySection: FC<Props> = ({ state, t }) => {
  return (
    <>
      <LiabilitySection name={t("PricingLabels.proposerDetails")} headClass="mt-8" className="text-xl">
        <CommonTable className="w-full mt-2 table-fixed">
          <CommonRow label={t("PricingLabels.companyName")} value={state.proposerName} />
          <CommonRow label={t("PricingLabels.eventName")} value={state.eventTitle} />
        </CommonTable>
      </LiabilitySection>
      <LiabilitySection name={t("PricingLabels.plan")} headClass="mt-8" className="text-xl">
        <CommonTable className="w-full table-fixed">
          <HeadingRow>
            <ContentCell>{t("PricingLabels.Heading1")}</ContentCell>
            <ContentCell>{t("PricingLabels.Heading2")}</ContentCell>
          </HeadingRow>
          <Pricing plans={state.plans} />
        </CommonTable>
        <PricingLabels t={t} />
      </LiabilitySection>
    </>
  );
};

export default QuotationLiabilitySection;
