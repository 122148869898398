import { RenewalFormState, TermsConditionsState, Pricing } from "../Form/types";
import Ajv from "ajv";
import addFormats from "ajv-formats";

type RenewalPIdetails = {
  limit: {
    liabilityLimit: number;
  };
  terms: TermsConditionsState;
  pricing: Pricing;
  quoteId: string;
};

export type RenewalProfessionalIndemnity = RenewalPIdetails;

export type CheckoutrenewalProfessionalIndemnity = RenewalPIdetails;

const schema = {
  type: "object",
  properties: {
    limit: {
      type: "object",
      required: ["liabilityLimit"],
      properties: {
        liabilityLimit: {
          type: "number",
          enum: [3_500_000, 7_500_000, 12_500_000],
        },
      },
    },
    terms: {
      type: "object",
      required: ["accepted", "promotionalServices"],
      properties: {
        accepted: {
          type: "boolean",
        },
        promotionalServices: {
          type: "boolean",
        },
      },
    },
    pricing: {
      type: "object",
      required: ["total"],
      properties: {
        total: {
          type: "number",
        },
      },
    },
    quoteId: {
      type: "string",
    },
  },
  required: ["limit"],
  additionalProperties: true,
};
const ajv = new Ajv();
addFormats(ajv);

function validatePayload(payload: Record<string, unknown>): asserts payload is RenewalProfessionalIndemnity {
  const compiledSchema = ajv.compile(schema);
  ajv.validate(schema, payload);
  if (compiledSchema.errors) {
    throw new Error("Given payload was incorrect");
  }
}
const renewalProfessionalIndemnity = (state: RenewalFormState): RenewalProfessionalIndemnity => {
  const renewalCheckoutPayload = {
    limit: {
      liabilityLimit: state.limit,
    },
    terms: {
      accepted: state.accepted,
      promotionalServices: state.promotionalServices,
    },
    pricing: {
      gp: state.gp,
      levy: state.levy,
      total: state.total,
    },
    quoteId: state.quoteId,
  };
  validatePayload(renewalCheckoutPayload);
  return renewalCheckoutPayload;
};

export default renewalProfessionalIndemnity;
