import React, { FC, HTMLAttributes } from "react";
import classNamePresets from "../../utils/classNamePresets";

type IProps = HTMLAttributes<HTMLHeadingElement>;
type HeadingComponent = FC<React.PropsWithChildren<IProps>>;

const H1: HeadingComponent = ({ className, children, ...props }) => (
  <h1 className={className || classNamePresets.mainHeading} {...props}>
    {children}
  </h1>
);
const H2: HeadingComponent = ({ className, children, ...props }) => (
  <h2 className={className || classNamePresets.secondaryHeading} {...props}>
    {children}
  </h2>
);
const H3: HeadingComponent = ({ className, children, ...props }) => (
  <h3 className={className || "text-xl md:text-3xl"} {...props}>
    {children}
  </h3>
);
const H4: HeadingComponent = ({ className, children, ...props }) => (
  <h4 className={className || classNamePresets.quaternary} {...props}>
    {children}
  </h4>
);

export { H1, H2, H3, H4 };
