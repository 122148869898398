import PrimaryLayout from "../../../components/authentication/layout/PrimaryLayout";
import AgentFormDialog from "../../travel/new-policy/dialog/AgentFormDialog";
import { AgentDetailsType } from "./SignupForm";
import SignupInputForm from "./SignupInputForm";

type SignupLayoutFormProps = {
  isLoading: boolean;
  hasErrors: boolean;
  errorMessage?: string;
  errors: {
    email?: string;
    givenName?: string;
    surName?: string;
    agentCode?: string;
    agentName?: string;
  };
  state: AgentDetailsType;
  field: any;
  validateField: (name: string, value: unknown, newData?: Record<string, unknown>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  signUpSuccess: boolean;
  onClose: () => void;
  setState: (t: any) => void;
};

const SignupLayoutForm = (props: SignupLayoutFormProps) => {
  return (
    <PrimaryLayout title="Sign Up">
      {/* {sending all the props} */}
      <form onSubmit={props.onSubmit}>
        <SignupInputForm {...props} />
      </form>
      {props.signUpSuccess && <AgentFormDialog onClose={props.onClose} message={"Account created successfully."} />}
    </PrimaryLayout>
  );
};

export default SignupLayoutForm;
