import { useTranslation } from "react-i18next";
import RawHTML from "../../../components/basics/RawHTML";

const ImportantNoticesTerms = () => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct.bind.terms" });
  const InsuranceAttachment = "//alliedworldinsurance.com/australia/";
  return (
    <ol>
      <li>
        <div className="font-bold">Your Duty of Disclosure</div>
        It is important that you read and understand your Duty to Take Reasonable Care not to Make a Misrepresentation as outlined here and in the PDS
        in relation to the information you have provided to us to ensure that our records are correct.
      </li>

      <li>
        <div className="font-bold">Privacy Notice</div>
        Allied World Company, Ltd (Australia Branch) (ABN 54 163 304 907, AFSL 548668) is committed to comply with the Australian Privacy Principles
        under the Privacy Act 1988 (Cth). Allied World may collect, use and disclose personal information for the purposes of conducting its insurance
        business and to provide you and manage insurance products and services, including to:
        <ol type="a">
          <li>identify you when you do business with us;</li>
          <li>establishing your requirements and providing the appropriate product or service;</li>
          <li>consider and process your application;</li>
          <li>vary, cancel and renew your insurance;</li>
          <li>deal with and/or process any claims under your policy, including settlement and to conduct necessary investigations;</li>
          <li>
            complete due diligence and background checks that are either required by law or regulation or have been put in place by Allied World;
          </li>
          <li>respond to your queries and administer your policy, including correspondence with you;</li>
          <li>investigate fraud, misconduct or any unlawful act or omission in relation to your policy;</li>
          <li>enable us to comply with our legal obligations;</li>
          <li>
            for research and statistical purposes, including data analysis, improving our financial products and services, and training and developing
            our staff and representatives;
          </li>
          <li>for marketing (subject to applicable law) of other services provided by us; and</li>
          <li>any purpose directly related to the above.</li>
        </ol>
      </li>
      <li>
        Personal information may be obtained by Allied World directly from you or from a third party such as an insurance intermediary. We request for
        and rely on information that is relevant. If this information is not provided to Allied World, we may not be able to provide insurance
        services to you.
      </li>
      <li>
        Allied World's Australia Personal Data Privacy Statement contains information on the kinds of personal information we collect and hold, how we
        do so and the purposes for which we collect, hold and disclose personal information. It also contains information on how Allied World can
        access the personal information of an insured or other individuals (each a “personal information subject”), how a personal information subject
        can seek correction of such information or make a privacy related complaint and when Allied World is likely to disclose personal information
        to third parties, including other insurers; reinsurers; intermediaries; insurance associations, federations or similar organisations; related
        companies; our advisers, service providers and agents; external claims data collectors and verifiers; parties that have an insurance scheme in
        place under which you purchased your policy; parties involved in claims investigation and management; government and statutory agencies; and
        as otherwise required or allowed by law, in each case both within and outside Australia. The countries in which these third parties may be
        located will vary from time to time, but may include Bermuda, the United States of America, the United Kingdom, Ireland, Singapore and Hong
        Kong.
      </li>
      <li>
        <RawHTML boldedLinks content={t("importantNotice", { InsuranceAttachment })} />
      </li>
      <li>
        By providing Allied World with your personal information, you consent to its collection, use and disclosure as outlined above and in Allied
        World’s Australia Personal Data Privacy Statement. By providing Allied World with another individual’s personal information, you confirm that
        you are authorized to disclose their personal information and to consent to the collection, use and disclosure of such individual’s personal
        information on their behalf.
      </li>
    </ol>
  );
};

export default ImportantNoticesTerms;
