import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormStepNavigation, { PropsWithoutLabels } from "../../../components/forms/FormStepNavigation";

const PolicyFormStepNavigation: FC<PropsWithoutLabels> = props => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.navigation.button" });
  const nextButtonLabel = props.onNext ? t("next") : t("submit");

  return <FormStepNavigation {...props} backButtonLabel={t("back")} nextButtonLabel={nextButtonLabel} />;
};

export default PolicyFormStepNavigation;
