import { FC, useState, useCallback } from "react";
import { FieldChange, PersonalDetails, ReferralDetails } from "../../../../types/travel/Policy";
import TextInput from "../../../../components/form-fields/TextInput";
import { FormGroup } from "../../../../components/forms";
import { H2 } from "../../../../components/basics";
import { DateInput } from "../../../../components/form-fields";
import SelectInput from "../../../../components/form-fields/SelectInput";
import CheckInput from "../../../../components/form-fields/CheckInput";
import { PersonalTitle } from "../../../../enums/shared/forms/NewPolicy";
import { personalDetailsValidations } from "../../../../helpers/travel/validations";
import IdentificationNumberTextInput from "../../../../components/form-fields/IdentificationNumberTextInput";
import ReferralDialog from "../dialog/ReferralDialog";
import { hasTravellersGroupInvalidId } from "../../../../helpers/travel/referral";
import ServerError from "../../../errors/ServerError";
import useForm from "../../../../hooks/useForm";
import { useTranslation } from "react-i18next";
import AdditionalTraveller from "../more-info/AdditionalTraveller";
import { getSpouseFromTravellers } from "../../../../helpers/travel/travellers";
import classNamePresets from "../../../../utils/classNamePresets";
import PolicyFormStepNavigation from "../PolicyFormStepNavigation";
import { trimAndNormalizeSpaces } from "../../../../helpers/shared/formHandlers";

export interface PersonalDetailsProps {
  flowStateSlice: PersonalDetails;
  onFieldChange: FieldChange<PersonalDetails>;
  onBack: () => void;
  onNext: () => void;
  onReferral: (details: ReferralDetails) => Promise<void>;
}

const trimAndReplaceSpace = (
  field: any,
  value: any,
  onFieldChange: any,
  validateField: (name: string, value: unknown, newData?: Record<string, unknown>) => void
) => {
  const trimmedValue = trimAndNormalizeSpaces(value);
  onFieldChange(field, trimmedValue);
  validateField(field, trimmedValue, value);
};

const Personal: FC<React.PropsWithChildren<PersonalDetailsProps>> = ({ flowStateSlice, onFieldChange, onBack, onNext, onReferral }) => {
  const [showReferral, setShowReferral] = useState(false);
  const { validateField, validateForm, errors, field } = useForm(flowStateSlice, personalDetailsValidations, onFieldChange);
  const [serverError, setServerError] = useState(false);
  const [showMoreTravellerInfo, setShowMoreTravellerInfo] = useState(false);
  const toggleShowTravellerInfo = useCallback(() => setShowMoreTravellerInfo(v => !v), [setShowMoreTravellerInfo]);
  const [isHandlingReferralConfirm, setIsHandlingReferralConfirm] = useState(false);

  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.personal" });
  const { t: tRequester } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.personal.form.label.requester" });
  const { t: tAddress } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.personal.form.label.address" });
  const { t: tTraveller } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.personal.form.label.extraTraveller" });
  const { t: tSupportMailId } = useTranslation("pages", { keyPrefix: "travel.newPolicy.customerSupport" });

  const handleNext = () => {
    hasTravellersGroupInvalidId(flowStateSlice) && setShowReferral(true);
    validateForm(onNext);
  };

  const handleReferralCancel = () => {
    setServerError(false);
    setShowReferral(false);
  };
  const handleReferralConfirm = (details: ReferralDetails) => {
    setIsHandlingReferralConfirm(true);
    setServerError(false);
    onReferral(details)
      .then(() => {
        setIsHandlingReferralConfirm(false);
        setShowReferral(false);
      })
      .catch(() => {
        setIsHandlingReferralConfirm(false);
        setServerError(true);
      });
  };

  const { requester, address, travellers } = flowStateSlice;
  return (
    <>
      <div className="container-flow flex-grow">
        <H2 className={classNamePresets.mainHeading}>{t("title")}</H2>
        {serverError && <ServerError supportEmailId={tSupportMailId("emailId")} />}
        <FormGroup title={t("form.groupTitle.requester")} hasVerticalFieldsOrientation={true}>
          <SelectInput
            aria-label="requester-title"
            label={tRequester("salutation")}
            value={requester.title}
            options={[PersonalTitle.EMPTY, PersonalTitle.DR, PersonalTitle.MR, PersonalTitle.MRS, PersonalTitle.MS]}
            {...field.requester.title}
          />
          <TextInput
            label={tRequester("givenName")}
            value={requester.givenName}
            {...field.requester.givenName}
            onBlur={event => trimAndReplaceSpace("requester.givenName", event.target.value, onFieldChange, validateField)}
          />
          <TextInput
            label={tRequester("familyName")}
            value={requester.familyName}
            {...field.requester.familyName}
            onBlur={event => trimAndReplaceSpace("requester.familyName", event.target.value, onFieldChange, validateField)}
          />
          <TextInput label={tRequester("phoneNumber")} value={requester.phoneNumber} {...field.requester.phoneNumber} />
          <TextInput label={tRequester("email")} value={requester.email} {...field.requester.email} />
          <IdentificationNumberTextInput
            label={tRequester("identificationNumber")}
            identificationNumber={requester.identificationNumber}
            identificationNumberStatus={requester.identificationNumberStatus}
            onValueChange={field.requester.identificationNumber.onValueChange}
            setIdentificationNumberStatus={field.requester.identificationNumberStatus.onValueChange}
            validate={v => validateField(`requester.identificationNumber`, v)}
            error={field.requester.identificationNumber.error}
            isReferral={() => setShowReferral(true)}
            setServerError={setServerError}
          />
          <DateInput
            label={tRequester("dateOfBirth")}
            name="date-of-birth"
            value={requester.dateOfBirth}
            onValueChange={v => {
              field.requester.dateOfBirth.onValueChange(v);
              validateField(`requester.${String("dateOfBirth")}`, v);
            }}
            onBlur={() => validateField("requester.dateOfBirth", requester.dateOfBirth)}
            error={errors[`requester.dateOfBirth`]}
          />
        </FormGroup>
        <FormGroup title={t("form.groupTitle.address")} hasVerticalFieldsOrientation={true}>
          <TextInput
            label={tAddress("postalCode")}
            {...field.address.postalCode}
            value={address.postalCode}
            onBlur={event => trimAndReplaceSpace("address.postalCode", event.target.value, onFieldChange, validateField)}
          />
          <TextInput label={tAddress("blockNumber")} {...field.address.number} value={address.number} />
          <TextInput
            label={tAddress("streetName")}
            {...field.address.streetName}
            value={address.streetName}
            onBlur={event => trimAndReplaceSpace("address.streetName", event.target.value, onFieldChange, validateField)}
          />
          <TextInput
            label={tAddress("floorNumber")}
            {...field.address.floorNumber}
            value={address.floorNumber}
            onBlur={event => trimAndReplaceSpace("address.floorNumber", event.target.value, onFieldChange, validateField)}
          />
          <TextInput
            label={tAddress("unitNumber")}
            {...field.address.unitNumber}
            value={address.unitNumber}
            onBlur={event => trimAndReplaceSpace("address.unitNumber", event.target.value, onFieldChange, validateField)}
          />
        </FormGroup>
        <FormGroup title={t("form.groupTitle.requesterIsTravelling")} hasVerticalFieldsOrientation={true}>
          <CheckInput
            checked={flowStateSlice.requestingPersonIsTravelingPerson}
            onValueChange={v => onFieldChange("requestingPersonIsTravelingPerson", v)}
            label="Yes"
            name="requestingPersonIsTravelingPerson"
          />
        </FormGroup>
        {travellers.length > 0 &&
          travellers.map((traveller, index) => {
            const shouldTravellerBeSpouse = traveller === getSpouseFromTravellers(travellers, flowStateSlice.requestingPersonIsTravelingPerson);
            return (
              <FormGroup
                key={`${index}-${travellers.length}`}
                title={t("form.groupTitle.extraTraveller", { number: flowStateSlice.requestingPersonIsTravelingPerson ? index + 2 : index + 1 })}
                hasVerticalFieldsOrientation={true}
                onMoreInfo={shouldTravellerBeSpouse ? toggleShowTravellerInfo : undefined}
              >
                <TextInput
                  label={tTraveller("fullName")}
                  {...field.travellers[index].fullName}
                  value={traveller.fullName}
                  onBlur={event => trimAndReplaceSpace(`travellers.${index}.fullName`, event.target.value, onFieldChange, validateField)}
                />
                <IdentificationNumberTextInput
                  label={tTraveller("identificationNumber")}
                  isReferral={() => setShowReferral(true)}
                  identificationNumber={traveller.identificationNumber}
                  identificationNumberStatus={traveller.identificationNumberStatus}
                  onValueChange={field.travellers[index].identificationNumber.onValueChange}
                  setIdentificationNumberStatus={field.travellers[index].identificationNumberStatus.onValueChange}
                  validate={v => validateField(`travellers.${index}.identificationNumber`, v)}
                  setServerError={setServerError}
                  error={field.travellers[index].identificationNumber.error}
                />
                <DateInput
                  label={tTraveller("dateOfBirth")}
                  value={flowStateSlice.travellers[index].dateOfBirth}
                  {...field.travellers[index].dateOfBirth}
                  name="date-of-birth"
                  onValueChange={v => {
                    field.travellers[index].dateOfBirth.onValueChange(v);
                    validateField(`travellers.${index}.dateOfBirth`, v);
                  }}
                  error={errors[`travellers.${index}.dateOfBirth`]}
                />
              </FormGroup>
            );
          })}
      </div>

      {showReferral && (
        <ReferralDialog
          onConfirm={details => handleReferralConfirm(details)}
          onCancel={handleReferralCancel}
          serverError={serverError}
          isLoading={isHandlingReferralConfirm}
        />
      )}
      <AdditionalTraveller show={showMoreTravellerInfo} onClose={toggleShowTravellerInfo} />
      <PolicyFormStepNavigation onBack={onBack} onNext={handleNext} />
    </>
  );
};

export default Personal;
