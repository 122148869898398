import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FormStep from "../../../components/forms/FormStep";
import ManulifeFooter from "../components/ManulifeFooter";
import ManulifeHeader from "../ManulifeHeader";
import RenewalFormRoutes from "./Form/routes";
import createRenewalInitialState from "./Form/state";
import { RenewalFormState } from "./Form/types";
import useEvent from "../../../hooks/useEvent";
import { QueryClient, QueryClientProvider, useMutation } from "react-query";
import { windowScroll, persistSessionState } from "../../../helpers/shared/useNavigationEffects";
import clientApiService from "../../../services/professional_indemnity/clientApiService";
import renewalProfessionalIndemnity from "./models/renewalProfessionalIndemnity";

type ChangeHandler = <T extends keyof RenewalFormState>(key: T, value: RenewalFormState[T]) => void;
type test = (t: RenewalFormState) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};

export const RenewalFormContext = createContext<{
  state: RenewalFormState;
  clientId: string;
  onChange: ChangeHandler;
  isSubmitting: boolean;
  setFormState: test;
}>({
  onChange: noop,
  clientId: "",
  isSubmitting: false,
  state: createRenewalInitialState(),
  setFormState: noop,
});

enum Steps {
  "LimitOfLiability",
  "TermsAndConditions",
}

const useSubmit = (state: RenewalFormState, clientId: string) => {
  return useMutation(() => clientApiService.renewalCheckout(clientId, renewalProfessionalIndemnity(state)), {
    onSuccess: ({ checkoutUrl }) => {
      window.location.href = checkoutUrl;
    },
  });
};
const getURLForStep = (step: string) => `/professional_indemnity/manulife/renewal/form/${step}`;
const stepsURLS = [getURLForStep("limit"), getURLForStep("terms")];
const steps: Steps[] = [Steps.LimitOfLiability, Steps.TermsAndConditions];
const amountOfSteps = steps.length;
const storageKey = "professional_indemnity/manulife/renewal";
const getRenewalInitialState = () => {
  return createRenewalInitialState();
};
const RenewalForm: FC = () => {
  const [formState, setFormState] = useState<RenewalFormState>(getRenewalInitialState());
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = "manulife-renewal";
  const onSubmit = useSubmit(formState, clientId);
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const persistState = useEvent(() => persistSessionState(storageKey, formState));

  useEffect(() => {
    windowScroll();
  }, [location]);

  useEffect(() => {
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);

  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  return (
    <RenewalFormContext.Provider
      value={{
        onChange: onFieldChange,
        clientId,
        state: formState,
        setFormState: setFormState,
        isSubmitting: onSubmit.isLoading,
      }}
    >
      <main>
        <form>
          <div className="flex flex-col min-h-screen gap-0">
            <ManulifeHeader hideAccount home="/professional_indemnity/manulife/renewal" />
            <FormStep current={step + 1} total={amountOfSteps} />
            <RenewalFormRoutes onPrevious={step > 0 ? goToPreviousStep : null} onNext={step === steps.length - 1 ? onSubmit.mutate : goToNextStep} />
            <ManulifeFooter />
          </div>
        </form>
      </main>
    </RenewalFormContext.Provider>
  );
};

const Renewal: FC = () => {
  const queryClient = useRef(new QueryClient());
  return (
    <QueryClientProvider client={queryClient.current}>
      <RenewalForm />
    </QueryClientProvider>
  );
};

export default Renewal;
