import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/form-fields/TextInput";
import { FormGroup } from "../../../components/forms";
import { proposerEventDetailsValidations } from "../../../helpers/event_public_liability/validations";
import useForm from "../../../hooks/useForm";
import LiabilityBindStepNavigation from "./LiabilityBindStepNavigation";
import { CorrespondenceAddress, ProposerDetails } from "./types";
import AddressFormGroup from "../components/CommonAddressInput";
import Heading from "../components/Heading";
import { AdditionalInsuredInfo } from "../more-info/AdditionalInsuredInfo";
import useOnComponentDidMount from "../../../hooks/useOnComponentDidMount";
import { LiabilityBindContext } from "../BindQuote";
import ProposerCompanyDetails from "../components/ProposerCompanyDetails";
import ProposerDetailsMoreInfo from "../components/ProposerDetailsMoreInfo";
import { removeExtraSpaces } from "../../../helpers/shared/formHandlers";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

export const createCorrespondenceAddress = (correspondenceAddress: CorrespondenceAddress) => {
  const { postalCode, houseNumber, streetName, floorNumber, unitNumber, building, district, territory } = correspondenceAddress;
  return {
    postalCode,
    houseNumber,
    streetName,
    floorNumber,
    unitNumber,
    building,
    district,
    territory,
  };
};

export const getInitialCorrespondenceAddress = (): CorrespondenceAddress => {
  return {
    postalCode: "",
    houseNumber: "",
    streetName: "",
    floorNumber: "",
    unitNumber: "",
    district: "",
    territory: "",
    building: "",
  };
};
export const isUenNumberStatusVerified = (uenNumberStatus: ProposerDetails["uenNumberStatus"]) => uenNumberStatus === "verified";

const SgProposerDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const [moreInfo, setMoreInfo] = useState<string | null>(null);
  const [correspondenceAddress, setCorrespondenceAddress] = useState<CorrespondenceAddress>(getInitialCorrespondenceAddress());
  const [disabledAddress, setDisableAddress] = useState(true);
  const [corrAddressFetchedByUen, setCorrAddressFetchedByUen] = useState<boolean>(false);
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product.proposer" });
  const { state, setFormState, onChange } = useContext(LiabilityBindContext);
  const { validateField, field, validateForm, handleFieldChange } = useForm(state, proposerEventDetailsValidations, onChange);

  useEffect(() => {
    corrAddressFetchedByUen &&
      setFormState({
        ...state,
        ...createCorrespondenceAddress(correspondenceAddress),
      });
    setCorrAddressFetchedByUen(false);
    isUenNumberStatusVerified(state?.uenNumberStatus) && setDisableAddress(true);
  }, [correspondenceAddress]);

  useOnComponentDidMount(() => {
    !isUenNumberStatusVerified(state?.uenNumberStatus) && setDisableAddress(false);
  });

  const handleNext = () => {
    onNext && validateForm(onNext);
  };
  return (
    <>
      <div className="container-flow flex-grow">
        <Heading headingTitle={t("title")} />
        <ProposerCompanyDetails
          state={state}
          field={field}
          setDisableAddress={setDisableAddress}
          validateField={validateField}
          setCorrAddressFetchedByUen={setCorrAddressFetchedByUen}
          setCorrespondenceAddress={setCorrespondenceAddress}
          corrAddressFetchedByUen={corrAddressFetchedByUen}
          t={t}
          setFormState={setFormState}
        />
        <AddressFormGroup
          streetNameProps={{ ...field.streetName, onBlur: removeExtraSpaces("streetName", setFormState, validateField) }}
          floorNumberValue={state.floorNumber}
          floorNumberProps={{ ...field.floorNumber, onBlur: removeExtraSpaces("floorNumber", setFormState, validateField) }}
          unitNumberValue={state.unitNumber}
          unitNumberProps={{ ...field.unitNumber, onBlur: removeExtraSpaces("unitNumber", setFormState, validateField) }}
          // Correspondence street name is optional
          labelForStreetName={t("corrStreetName")}
          houseNumberValue={state.houseNumber}
          houseNumberProps={{ ...field.houseNumber, onBlur: removeExtraSpaces("houseNumber", setFormState, validateField) }}
          streetNameValue={state.streetName}
          disableForm={disabledAddress}
          title={t("correspondenceAddress")}
          ariaLabel="correspondence"
          postalCodeValue={state.postalCode}
          postalCodeProps={{ ...field.postalCode, onBlur: removeExtraSpaces("postalCode", setFormState, validateField) }}
          t={t}
        />
        <FormGroup title={t("eventName")}>
          <TextInput
            aria-label="proposer-eventName"
            value={state.eventTitle}
            {...field.eventTitle}
            label={t("eventName")}
            onBlur={removeExtraSpaces("eventTitle", setFormState, validateField)}
          />
        </FormGroup>
        <AddressFormGroup
          ariaLabel="event"
          streetNameValue={state.eventStreetName}
          streetNameProps={{
            ...field.eventStreetName,
            onBlur: removeExtraSpaces("eventStreetName", setFormState, validateField),
          }}
          floorNumberValue={state.eventfloorNumber}
          floorNumberProps={{
            ...field.eventfloorNumber,
            onBlur: removeExtraSpaces("eventfloorNumber", setFormState, validateField),
          }}
          unitNumberValue={state.eventunitNumber}
          unitNumberProps={{
            ...field.eventunitNumber,
            onBlur: removeExtraSpaces("eventunitNumber", setFormState, validateField),
          }}
          postalCodeValue={state.eventPostalCode}
          postalCodeProps={{
            ...field.eventPostalCode,
            onBlur: removeExtraSpaces("eventPostalCode", setFormState, validateField),
          }}
          houseNumberValue={state.eventHouseNumber}
          houseNumberProps={{
            ...field.eventHouseNumber,
            onBlur: removeExtraSpaces("eventHouseNumber", setFormState, validateField),
          }}
          title={t("eventAddress")}
          labelForStreetName={t("streetName")}
          t={t}
        />
        <ProposerDetailsMoreInfo
          setMoreInfo={setMoreInfo}
          t={t}
          state={state}
          handleFieldChange={handleFieldChange}
          validateField={validateField}
          field={field}
          setFormState={setFormState}
        />
      </div>
      <LiabilityBindStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
      <AdditionalInsuredInfo t={t} show={moreInfo === "additionalInsured-cover"} onClose={() => setMoreInfo(null)} />
    </>
  );
};

export default SgProposerDetails;
