import { FC, HTMLAttributes } from "react";
import cls from "../../utils/cls";
import { Article } from "./index";

type SectionProps = HTMLAttributes<HTMLDivElement>;

const Section: FC<React.PropsWithChildren<SectionProps>> = ({ className, children, ...props }) => {
  return (
    <div className={cls("flex flex-col relative", "bg-white w-full", "shadow-soft rounded-lg", className)}>
      {children && (
        <div role="region" className="p-4 w-full h-full" {...props}>
          <Article>{children}</Article>
        </div>
      )}
    </div>
  );
};

export default Section;
