import Header from "../auth/layout/Header";
import errorImg from "../../assets/images/error.svg";
import { useTranslation } from "react-i18next";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import { Link } from "../../components/basics";
import { FC } from "react";
import { getRole } from "../../helpers/shared/getRole";
import { useAuth } from "../../hooks/useAuth";
import { Role } from "../../enums/shared/forms/Role";

type Props = {
  homePage: string;
  renderFooter?: () => JSX.Element;
};

const NotFound: FC<Props> = ({ homePage, renderFooter }) => {
  const { t } = useTranslation("pages", { keyPrefix: "errors.notfound" });
  const { user } = useAuth();
  const role = getRole(user);
  const isTPARole = role === Role.TPA;

  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <Header home={isTPARole ? "/tpa" : "/"} className="gap-4" />
      <div className="container-flow flex-grow  mt-6 lg:mt-40">
        <div className="flex">
          <div className="w-full lg:w-3/5 lg:pr-12">
            <h1 className="font-serif font-bold text-3xl md:text-4xl mb-3">{t("title")}</h1>
            <p className="mt-6 mb-8">{t("content")}</p>
            <Link type={ButtonType.PRIMARY} to={homePage}>
              {t("button.back")}
            </Link>
          </div>
          <div className="w-2/5 hidden lg:block">
            <img src={errorImg} alt="" />
          </div>
        </div>
      </div>
      {renderFooter?.()}
    </div>
  );
};

export default NotFound;
