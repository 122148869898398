import { ProductType } from "../../../enums/shared/api/Api";
import { GroupTravelFormState } from "./types";

export const createInitialState = (persistedState: Partial<GroupTravelFormState> = {}): GroupTravelFormState => {
  return {
    productType: ProductType.ACTL,
    familyTrust: null,
    annualRenewableBusiness: null,
    donNotTravelAdvice: null,
    planName: null,
    basePrice: null,
    totalPrice: null,
    planCurrency: null,
    brokerageRate: 20,
    brokerageAmount: null,
    gstRate: null,
    gstAmount: null,
    stampDutyRate: null,
    stampDutyAmount: null,
    exemptedStampDuty: null,
    policyHoldersName: "",
    excessClaimsCover: null,
    exceedDuration: null,
    morePeopleInTrip: null,
    engagedActivities: null,
    privateTravel: null,
    collectiveFlights: null,
    validityDate: null,
    status: null,
    quoteNo: "",
    quoteId: "",
    domicileRegion: "",
    regionCode: "",
    internationalTravelDays: 0,
    internationalAvgTripDuration: 0,
    numberOfInternationalTrip: 0,
    domesticTravelDays: 0,
    domesticAvgTripDuration: 0,
    numberOfDomesticTrip: 0,
    coverageStartDate: null,
    coverageEndDate: null,
    agentMail: "",
    createdAt: null,
    ...persistedState,
  };
};

export default createInitialState;
