const ConditionOfRenewal = () => (
  <ol>
    <li>
      Renewal is subject to the following conditions of renewal. You confirm and represent to Allied World Assurance Company Limited ("Allied World")
      on your own behalf and on behalf of the any other Insured(s) that:
    </li>
    <ol type="a">
      <li>
        You are not, and have never been, the subject of any claim, inquiry, investigation, disciplinary action (by a professional body or otherwise),
        civil or criminal law suit, whether against you personally or which relates to your involvement in any way, nor are you aware of any
        circumstances which may give rise to any such claim, inquiry, disciplinary action, or civil or criminal law suit;
      </li>
      <li>
        You are not, and have never been aware of any claims or complaints alleging fraud or dishonesty, whether successful or not, against you
        personally or which relates to your involvement in any way;
      </li>
      <li>
        You are not aware of any pending claims and/or circumstances existing which may give rise to a claim against you personally or involving you
        in any way;
      </li>
      <li>You have never had any licence, permit or approval you require to act as an insurance agent suspended, revoked or withdrawn;</li>
      <li>You have never had any insurance policy cancelled or an application for insurance rejected.</li>
    </ol>
    <li>
      If any of the matters specified in a. to e. above is not true or inaccurate, you cannot renew your policy. In such event, you should notify
      Allied World immediately and provide Allied World with all information necessary for Allied World to reconsider whether to invite renewal and if
      so, the terms of any such renewal invitation.
    </li>
    <li>
      If any claim or circumstances arises before 1 September 2022 which may give rise to a Claim under the terms and conditions of the renewed policy
      or your existing policy, Allied World reserves the right to withdraw this renewal invitation, or if our renewal terms have been accepted, to
      amend the terms and/or cancel your cover from inception.
    </li>
    <li>
      Insured as defined in the Policy, including the natural person specified in the certificate of insurance to be renewed, their employee and any
      spouse.
    </li>
  </ol>
);

export default ConditionOfRenewal;
