import { FC } from "react";
import { ContentCell, LabelCell, NormalRow } from "../event_public_liability/components/CommonTable";

interface CommonRowProps {
  label: string;
  value: string;
}

const CommonRow: FC<CommonRowProps> = ({ label, value }) => (
  <NormalRow>
    <ContentCell>{label}</ContentCell>
    <LabelCell>{value}</LabelCell>
  </NormalRow>
);

export default CommonRow;
