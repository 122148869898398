import { useTranslation } from "react-i18next";

import SummarySection from "./Summary";
import { Button } from "../../../components/basics/Button";
import cls from "../../../utils/cls";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
interface SummarySectionProps {
  state: Record<string, any>;
  goToListing?: VoidFunction;
  goToBind?: VoidFunction;
}
const SummaryDetail: React.FC<SummarySectionProps> = ({ state, goToListing, goToBind }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct" });

  return (
    <>
      <div className="container-flow flex md:pt-8 pt-4 font-bold mr-4 md:mx-0 gap-1 md:items-center items-start">
        <i className="icon icon-check-circle icon-8 icon-green md:icon-right-4 icon-right-2 md:-mt-2 mt-1"></i>
        <div aria-label="Quote Saved" aria-level={2} role="heading" className="md:-ml-2">
          {t("summaryLabels.quotationMessage")}
        </div>
      </div>
      <div className="container-flow">
        <SummarySection state={state} />
        <div className={cls("flex flex-col-reverse justify-between items-center md:flex-row pt-4 pb-8 mt-10", "md:mt-20")}>
          <Button onClick={goToListing} htmlType="button" className="px-0 md:!px-4 md:w-auto md:text-left text-center" type={ButtonType.LINK}>
            {t("bind.navigation.button.goToListing")}
          </Button>
          <Button onClick={goToBind} htmlType="button" className="px-0  md:w-auto flex-end" type={ButtonType.PRIMARY}>
            {t("bind.navigation.button.continueToBind")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SummaryDetail;
