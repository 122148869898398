import { FC } from "react";
import { Alert } from "../../../components/attentions";
import RawHTML from "../../../components/basics/RawHTML";
import { CheckInputBlock } from "../../../components/form-fields";
import CheckInputField from "../../../components/form-fields/CheckInputField";
import { AccordionSection } from "../../../components/interactives";

type Props = {
  isAccordionExpanded: boolean;
  t: any;
  state: any;
  accepted: any;
  promotionalServices: any;
  policyWordingUrl: string;
};
const personalInformationCollectionStatementUrl = "//assets.alliedworld.cloud/awac.com/marketing/asiapacretail/hk/pics.pdf";
const privacySecurityPolicyUrl = "//alliedworldinsurance.com/hong-kong-privacy-and-security-policy/";
const optOutEmailAddress = "hkcompliance@awac.com";

const TermsConditions: FC<React.PropsWithChildren<Props>> = ({ isAccordionExpanded, state, t, accepted, promotionalServices, policyWordingUrl }) => {
  return (
    <>
      <AccordionSection
        initialState={isAccordionExpanded ? "expanded" : "collapsed"}
        id="personal-information-collection-statement"
        label={t("terms.personalInfoColStatement")}
      >
        <p>
          {t("terms.personalInfoConsent")}{" "}
          <a href={personalInformationCollectionStatementUrl} rel="noreferrer" target="_blank">
            {t("terms.personalInfoColStatement")}
          </a>
          .
        </p>
        <CheckInputField
          inline
          {...promotionalServices}
          id="promotional-services"
          checked={!state.promotionalServices}
          onValueChange={(value: any) => promotionalServices.onValueChange(!value)}
        >
          {t("terms.personalInfoConsent1")}
        </CheckInputField>
        {t("terms.personalInfoConsent2")} <a href={`mailto:${optOutEmailAddress}`}>{optOutEmailAddress}</a> {t("terms.personalInfoConsent3")}{" "}
        <a href={privacySecurityPolicyUrl} rel="noreferrer" target="_blank">
          here
        </a>
        .
      </AccordionSection>
      <div className="grid gap-5">
        <h3 className="col-span-full font-bold md:text-xl mt-6">Agree</h3>
        <CheckInputBlock {...accepted} id="accepted" checked={state.accepted}>
          {t("terms.personalInfoConsent4")}
        </CheckInputBlock>

        <Alert type="warning" icon="icon-info">
          <div className="space-y-4">
            <RawHTML boldedLinks content={t("terms.fullDetails", { policyWordingUrl, personalInformationCollectionStatementUrl })} />
          </div>
        </Alert>
      </div>
    </>
  );
};
export default TermsConditions;
