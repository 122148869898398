import { Button } from "../basics/Button";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import classNamePresets from "../../utils/classNamePresets";
import cls from "../../utils/cls";
import { Dialog } from "../interactives";
import { DialogFooter } from "../interactives/Dialog";

interface IProps {
  t: any;
  onClose: () => void;
  onHome: () => void;
  onRetrieve: () => void;
  error: any;
}

const QuotationSentDialog = ({ onClose, onHome, onRetrieve, error, t }: IProps) => {
  return (
    <Dialog onClose={onClose}>
      <div className="container-flow flex md:pt-4  pt-2 font-bold md:mx-0 gap-12 items-start">
        <i className="icon-check-circle md:icon-14 icon-12 icon-green icon-top-4 after:-translate-y-1/4"></i>
        <div className="flex flex-col">
          <h2 className={cls(classNamePresets.dialogTitle, "font-normal")}>{t("dialog.success.title")}</h2>
          <h2 className={cls(classNamePresets.dialogTitle, "font-normal", "break-all")}>{error}</h2>
        </div>
      </div>
      <div>
        <p className="mt-6 mb-6">{t("dialog.success.subtitle1")}</p>
        <p className="mt-4">{t("dialog.success.subtitle2")}</p>
      </div>
      <DialogFooter>
        {/* Dialog footer for Quotation send Dailog box */}
        <Button type={ButtonType.SECONDARY} onClick={onRetrieve} className="md:!w-[308px] md:mr-28 mb-4">
          {t("dialog.success.button.quote")}
        </Button>

        <Button type={ButtonType.PRIMARY} onClick={onHome} className="md:!w-[308px]">
          {t("dialog.success.button.back")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default QuotationSentDialog;
