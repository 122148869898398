import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/icons/Spinner";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";

type Props = {
  sendPaymentLink?: VoidFunction | null;
  handleSendPaymentLink?: VoidFunction;
  creditTerms?: VoidFunction | null;
  handleCreditTerms?: VoidFunction;
  isSubmitting: boolean;
  navigateBackUrl: string;
  creditTermLabel: string;
  t: any;
};
const getButtonTypeForCreditTerms = (creditTerms: VoidFunction | null, sendPaymentLink: VoidFunction | null | undefined) =>
  creditTerms && sendPaymentLink ? ButtonType.SECONDARY : ButtonType.PRIMARY;

const TermButtons: FC<Props> = ({
  isSubmitting,
  sendPaymentLink,
  handleSendPaymentLink,
  creditTerms,
  handleCreditTerms,
  navigateBackUrl,
  creditTermLabel,
  t,
}) => {
  const navigate = useNavigate();

  return (
    <div className=" md:flex md:justify-between md:flex-row-reverse md:pt-28 md:pb-8">
      {isSubmitting ? (
        <span className="flex items-center justify-center -translate-x-4 md:-translate-x-5">
          <span className="mx-auto md:mx-0 h-6 inline-block">
            <Spinner width={`1.5rem`} height={`1.5rem`} color={"#3F9293"} />
          </span>
        </span>
      ) : (
        <>
          {sendPaymentLink && (
            <Button htmlType="button" onClick={handleSendPaymentLink} type={ButtonType.PRIMARY} className="md:mt-0 mt-8">
              {t("navigation.button.sendPaymentLink")}
            </Button>
          )}
          {creditTerms && (
            <Button
              htmlType="button"
              onClick={handleCreditTerms}
              type={getButtonTypeForCreditTerms(creditTerms, sendPaymentLink)}
              className="md:mr-8 md:mt-0 mt-4"
            >
              {creditTermLabel}
            </Button>
          )}
        </>
      )}
      <Button
        onClick={() => navigate(navigateBackUrl)}
        htmlType="button"
        type={ButtonType.LINK}
        className="md:my-0 mt-4 mb-16 md:pl-0 flex justify-center md:block text-red"
      >
        {t("navigation.button.back")}
      </Button>
    </div>
  );
};

export default TermButtons;
