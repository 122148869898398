import { Link } from "react-router-dom";
import travelImg from "../assets/images/travel.svg";
import gpaImg from "../assets/images/gpa.svg";
import eventImg from "../assets/images/event.svg";
import Product, { ProductType } from "./Product";
import { Region } from "../enums/shared/forms/Region";
import downloadLinkArray from "./downloads.json";

interface IProps {
  t: any;
  region: string;
}

type DownloadContentsType = Record<Region, ProductType[]>;

type LinkInfo = {
  url: string;
  title: string;
};

type LinkArrayType = LinkInfo[];
export const DownloadDocuments = ({ t, region }: IProps) => {
  const { traveLinksArray, sgEventPLLinksArray, hkEventPLLinksArray, groupTravelsArray, groupPersonalAccidentArray } = downloadLinkArray;
  const getDownloadContentsLink = (contentArr: LinkArrayType) => (
    <>
      {contentArr.map(({ url, title }) => (
        <Link key={title} target="_blank" rel="noreferrer" to={url} className={`font-sans font-bold text-red hover:cursor-pointer underline`}>
          {title}
        </Link>
      ))}
    </>
  );
  const downloadContents: DownloadContentsType = {
    [Region.SG]: [
      {
        type: "travel",
        img: travelImg,
        text: t("header.button.travelProtector"),
        topLinks: getDownloadContentsLink(traveLinksArray),
        download: true,
        className: "md:h-max md:pb-8",
        alt: "travel logo",
      },
      {
        type: "eventPL",
        img: eventImg,
        text: t("header.button.eventPublic"),
        topLinks: getDownloadContentsLink(sgEventPLLinksArray),
        download: true,
        className: "md:h-max md:pb-8",
        alt: "event logo",
      },
    ],
    [Region.HK]: [
      {
        type: "eventPL",
        img: eventImg,
        text: t("header.button.eventPublic"),
        topLinks: getDownloadContentsLink(hkEventPLLinksArray),
        download: true,
        className: "md:h-max md:pb-8",
        alt: "event logo",
      },
    ],
    [Region.AU]: [
      {
        text: t("header.button.groupTravel"),
        download: true,
        className: "md:h-max md:pb-8",
        type: "auTravel",
        img: travelImg,
        topLinks: getDownloadContentsLink(groupTravelsArray),
        contentDirectionClass: "flex flex-col gap-5 items-start pl-16 md:pl-[120px]",
        alt: "travel logo",
      },
      {
        text: t("header.button.groupPersonalAccident"),
        download: true,
        className: "md:h-max md:pb-8",
        type: "auGpa",
        img: gpaImg,
        topLinks: getDownloadContentsLink(groupPersonalAccidentArray),
        contentDirectionClass: "flex flex-col gap-5 items-start pl-16 md:pl-[120px]",
        alt: "group personal accident logo",
      },
    ],
  };
  return (
    <div className="flex flex-col gap-4 md:flex-row md:mb-[354px] mb-20 md:w-full">
      {downloadContents[region as Region]?.map(downloadItem => (
        <div className="md:w-3/6 md:pt-8 md:pr-4 md:whitespace-nowrap" key={downloadItem.text}>
          <Product {...downloadItem} />
        </div>
      ))}
    </div>
  );
};
