import { useNavigate } from "react-router-dom";
import { Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from "react";
import { IOption } from "../../../../components/form-fields/RadioInput";
import { FormGroup } from "../../../../components/forms";
import { basicDetailsValidations } from "../../../../helpers/group_personal_accident/validations";
import { BasicDetailsState, GroupPersonalAccidentFormState } from "../types";
import { CreateQuoteContext } from "../../CreateQuote";
import { useTranslation } from "react-i18next";
import AuGroupBusinessFormStepNavigation from "../../../group_travel/Form/CreateQuoteStepNavigation";
import GroupPersonalAccidentHeading from "../../../group_travel/components/Heading";
import { addDays, addYears } from "date-fns";
import SelectInput from "../../../../components/form-fields/SelectInput";
import { RadioInput, NumberInput, DateInput } from "../../../../components/form-fields";
import { States } from "../../../group_travel/enums/forms/DomicileRegion";
import useForm from "../../../../hooks/useForm";
import BasicDetailsDialog from "../../dialog/BasicDetailsDialog";
import CustomRadioInput from "../../../../components/interactives/CustomRadioInput";
import { CompanyDomicileInfo } from "../../../group_travel/more-info/CompanyDomicileInfo";
import { getStampDutyPercentage } from "../../../../helpers/group_travel/labels";
import useWithAsyncLoadingIndicator from "../../../../hooks/useWithAsyncLoadingIndicator";
import ServerError from "../../../errors/ServerError";
import ReferralDialog from "../../dialog/ReferralDialog";
import { fetchPlans } from "../../../../helpers/group_personal_accident/api/pricingApi";
import { FieldChange } from "../../../group_travel/Form/steps/PolicyDetails";

const coverageTypeOptions: IOption<"Injury Only" | "Injury and Sickness">[] = [
  { value: "Injury Only", label: "Injury Only" },
  { value: "Injury and Sickness", label: "Injury and Sickness" },
];

const yesNoOptions: IOption<"yes" | "no">[] = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

type BasicDetailsOptionsProps = {
  formLabel: string;
  value: "yes" | "no" | null;
  label: string;
  onValueChange: (value: "yes" | "no" | null) => void;
  moreInfoStyle?: string;
  onMoreInfo?: () => void;
  error?: string | boolean | null;
};
const BasicDetailsOptions: FC<PropsWithChildren<BasicDetailsOptionsProps>> = ({
  formLabel,
  label,
  value,
  children,
  onMoreInfo,
  moreInfoStyle,
  onValueChange,
  error,
}) => (
  <div className="flex justify-between flex-wrap gap-x-16">
    <FormGroup title="" className={`flex md:flex-col-reverse flex-shrink max-w-[34.5rem] ${moreInfoStyle}`} fullWidth onMoreInfo={onMoreInfo}>
      <p className="text-xl mr-4 font-normal">{label}</p>
      {children}
    </FormGroup>

    <div className="flex-1 min-w-[12rem] md:max-w-[12rem]">
      <CustomRadioInput formLabel={formLabel} onValueChange={onValueChange} value={value} error={error} />
    </div>
  </div>
);
const areEligibilityAnswerApproved = (state: BasicDetailsState) => {
  return state.familyTrust === "yes" && state.occupationEligibility === "yes";
};
const handleProceed = async (
  validateForm: any,
  priceFn: VoidFunction,
  setShowBasicDetailsDialog: Dispatch<SetStateAction<boolean>>,
  state: GroupPersonalAccidentFormState
) => {
  validateForm(async () => {
    if (areEligibilityAnswerApproved(state)) {
      await priceFn();
    } else {
      setShowBasicDetailsDialog(true);
    }
  });
};

const BasicDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupPersonalAccident.newProduct" });
  const [showBasicDetailsDialog, setShowBasicDetailsDialog] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);
  const [showReferralDialog, setShowReferralDialog] = useState<boolean>(false);
  const [moreInfo, setMoreInfo] = useState<string | null>(null);
  const { action: getPlans } = useWithAsyncLoadingIndicator(fetchPlans, { defaultLoadingState: true });
  const navigate = useNavigate();
  const { state, onChange, setFormState } = useContext(CreateQuoteContext);

  const { totalNumbersOfInsured } = state;
  const isReferralCase = totalNumbersOfInsured > 100;
  const { validateField, validateForm, field, handleFieldChange }: { validateField: any; validateForm: any; field: any; handleFieldChange: any } =
    useForm(state, basicDetailsValidations, onChange);
  const [initialRenderforDates, setinitialRenderforDates] = useState(false);

  const setPrices = async () => {
    try {
      setIsFetching(true);
      const response = await getPlans(state);
      setFormState({ ...state, allPlans: response.plans, selectedPlan: null, planName: null, brokerageRate: 20 });
      isReferralCase ? setShowReferralDialog(true) : onNext?.();
      setIsFetching(false);
    } catch (error) {
      console.error(error);
      setServerError(true);
      setIsFetching(false);
    }
  };

  const handleNext = () => handleProceed(validateForm, setPrices, setShowBasicDetailsDialog, state);
  onPrevious = () => navigate("/products");
  const referralHandler = () => {
    setShowReferralDialog(false);
    onNext?.();
  };

  useEffect(() => {
    const stampDuty = getStampDutyPercentage(state.domicileRegion);
    if (stampDuty !== null) {
      onChange("stampDutyRate", Number(stampDuty.percentage));
      onChange("regionCode", stampDuty.name);
    }
  }, [state.domicileRegion]);

  useEffect(() => {
    if (initialRenderforDates) {
      validateField("coverageStartDate", state.coverageStartDate);
      validateField("coverageEndDate", state.coverageEndDate);
    }
    setinitialRenderforDates(true);
  }, [state.coverageStartDate, state.coverageEndDate]);

  useEffect(() => {
    if (initialRenderforDates) {
      const endDate = addYears(new Date(state.coverageStartDate as Date), 1);
      onChange("coverageEndDate", endDate);
      validateField("coverageStartDate", state.coverageStartDate);
    }
  }, [state.coverageStartDate]);

  const handleDateChange: FieldChange<GroupPersonalAccidentFormState> = (key, value) => {
    handleFieldChange(key, value);
    validateField(key, value);
  };

  return (
    <>
      <div className="container-flow flex-1">
        <GroupPersonalAccidentHeading title={t("steps.basicDetails")} alt="GroupPersonalAccident" />
        <FormGroup
          margin="mt-4 md:mt-8"
          fullWidth={true}
          justifyEnd={true}
          subtitle={t("basicDetailLabels.familyTrust")}
          subTitleFontSize="mb-4 md:mb-0 text-xl md:min-w-[34rem]"
        >
          <RadioInput aria-label="familyTrust" options={yesNoOptions} {...field.familyTrust} value={state.familyTrust} inputWidth="md:w-48 w-full" />
        </FormGroup>

        <BasicDetailsOptions
          formLabel="occupationHazard"
          label={t("basicDetailLabels.paOccuptaionContent")}
          value={state.occupationEligibility}
          {...field.occupationEligibility}
          moreInfoStyle=" !justify-end"
        >
          <ul className=" list-[lower-alpha] ml-[2em] text-xl">
            {t("paOccuptaionLabels.occupationist", { returnObjects: true }).map(label => (
              <li key={label}>{label}</li>
            ))}
          </ul>
        </BasicDetailsOptions>
        <FormGroup hasVerticalFieldsOrientation title={t("basicDetailLabels.companyDomicile")} onMoreInfo={() => setMoreInfo("domicle-cover")}>
          <SelectInput
            aria-label="title"
            label={t("basicDetailLabels.formProperties.selectState")}
            value={state.domicileRegion}
            options={Object.values(States)}
            disabled={false}
            {...field.domicileRegion}
          />
        </FormGroup>
        <FormGroup title={t("basicDetailLabels.totalNumberOfInsured")} className="!mt-4">
          <NumberInput
            {...field.totalNumbersOfInsured}
            value={state.totalNumbersOfInsured}
            min={0}
            max={300}
            onValueChange={v => {
              field.totalNumbersOfInsured.onValueChange(v);
              validateField("totalNumbersOfInsured", v, { totalNumbersOfInsured: state.totalNumbersOfInsured });
            }}
          />
        </FormGroup>
        <FormGroup title={t("basicDetailLabels.startdateOfCoverage")}>
          <DateInput
            value={state.coverageStartDate}
            label={t("basicDetailLabels.startdateOfCoverage")}
            minDate={addDays(new Date(), 0)}
            {...field.coverageStartDate}
            onValueChange={v => {
              handleDateChange("coverageStartDate", v);
              field.coverageStartDate.onValueChange(v);
            }}
          />
        </FormGroup>
        <FormGroup title={t("basicDetailLabels.enddateOfCoverage")}>
          <DateInput
            label={t("basicDetailLabels.enddateOfCoverage")}
            {...field.coverageEndDate}
            minDate={addDays(new Date(), 0)}
            value={state.coverageEndDate}
            onValueChange={v => {
              field.coverageEndDate.onValueChange(v);
              validateField("coverageEndDate", v, { coverageStartDate: state.coverageStartDate });
            }}
          />
        </FormGroup>
        <FormGroup title={t("basicDetailLabels.coverageType")}>
          <RadioInput aria-label="coverageType" options={coverageTypeOptions} {...field.coverageType} value={state.coverageType} />
        </FormGroup>
        {serverError && (
          <ServerError
            displayType="modal"
            onClose={() => setServerError(false)}
            contactDetailsWithMailId={t("serverError.contactDetailsWithMailId")}
          />
        )}
      </div>
      <AuGroupBusinessFormStepNavigation isLoading={isFetching} loadingText="Loading" onBack={onPrevious} onNext={handleNext} />
      <CompanyDomicileInfo show={moreInfo === "domicle-cover"} onClose={() => setMoreInfo(null)} />
      {showBasicDetailsDialog && <BasicDetailsDialog onClose={() => setShowBasicDetailsDialog(false)} state={state} />}
      {showReferralDialog && <ReferralDialog onNext={referralHandler} onClose={() => setShowReferralDialog(false)} />}
    </>
  );
};

export default BasicDetails;
