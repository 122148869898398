import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "../../../components/basics/Button";
import { Dialog } from "../../../components/interactives";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import { GroupTravelFormState } from "../Form/types";
import { useNavigate } from "react-router-dom";
import RawHTML from "../../../components/basics/RawHTML";

type Props = {
  onClose: VoidFunction;
  state: GroupTravelFormState;
};

const EligibilityDialog: FC<Props> = ({ onClose, state }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct" });
  const [loading] = useState(false);
  const onConfirm = () => {
    navigate("/products");
  };

  return (
    <Dialog onClose={onClose} title={t("dialog.dialogTitle")}>
      <ul className={"list-disc pl-4"}>
        {state.familyTrust === "no" && <li>{t("basicDetailLabels.familyTrustContent")}</li>}
        {state.annualRenewableBusiness === "no" && <li>{t("basicDetailLabels.annualRenewableBusinessContent")}</li>}
        {state.donNotTravelAdvice === "yes" && <li>{t("basicDetailLabels.donNotTravelAdviceContent")}</li>}
      </ul>
      <br></br>
      <RawHTML content={t("dialog.customerSupport")} />
      <div className="flex flex-col-reverse md:flex-row-reverse justify-between gap-4 mt-4">
        <LoadingButton type={ButtonType.PRIMARY} onClick={onConfirm} isLoading={loading} loadingText={t("dialog.loadingButtonText")}>
          {t("dialog.proceed")}
        </LoadingButton>
      </div>
    </Dialog>
  );
};
export default EligibilityDialog;
