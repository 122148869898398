import Ajv from "ajv";
import { createAgentRequest } from "../../helpers/signup/signupApi";
import { AgentDetailsType } from "../../pages/auth/signup/SignupForm";
import { createAgentResetRequest } from "../../helpers/reset/resetApi";
import { AgentResetType } from "../../pages/auth/reset/ResetForm";
import { agentPost } from "../../helpers/travel/new-policy/api/agent";
import {
  AgentRequest,
  AgentResetRequest,
  AgentResetResponse,
  AgentResponse,
  BrokerageInfoRequest,
  BrokerageInfoResponse,
} from "../../types/user/api/User";

const BASEURL = process.env.REACT_APP_BACKEND_API;
const CREATE_AGENT = "v1/agent/createUser";
const CREATE_BROKER = "v1/agent/signup";
const RESET_AGENT = "v1/agent/resetexpired";
const BROKERAGE_INFO = "v1/agent/brokerageInfo";
const ajv = new Ajv();

export const CreateAgentResponseSchema = {
  type: "object",
  properties: {
    message: { type: "string" },
  },
};

export const ResetAgentResponseSchema = {
  type: "object",
  properties: {
    message: { type: "string" },
  },
};

export const BrokerageInfoResponseSchema = {
  type: "object",
  properties: {
    message: { type: "string" },
  },
};

export const validateCreateAgentResponse = ajv.compile(CreateAgentResponseSchema);
export const validateResetAgentResponse = ajv.compile(ResetAgentResponseSchema);
export const validateBrokerageInfoResponse = ajv.compile(BrokerageInfoResponseSchema);

const agentApiService = {
  createAgent: (data: AgentDetailsType): Promise<AgentResponse> =>
    agentPost<AgentRequest, AgentResponse>(BASEURL, CREATE_AGENT, createAgentRequest(data), (response: unknown) =>
      validateCreateAgentResponse(response)
    ),
  resetAgent: (data: AgentResetType): Promise<AgentResetResponse> =>
    agentPost<AgentResetRequest, AgentResetResponse>(BASEURL, RESET_AGENT, createAgentResetRequest(data), (response: unknown) =>
      validateResetAgentResponse(response)
    ),
  getBrokerageInfo: (data: BrokerageInfoRequest): Promise<BrokerageInfoResponse> =>
    agentPost<BrokerageInfoRequest, BrokerageInfoResponse>(BASEURL, BROKERAGE_INFO, data, (response: unknown) =>
      validateBrokerageInfoResponse(response)
    ),
  createBroker: (data: AgentDetailsType): Promise<AgentResponse> =>
    agentPost<AgentRequest, AgentResponse>(BASEURL, CREATE_BROKER, createAgentRequest(data), (response: unknown) =>
      validateCreateAgentResponse(response)
    ),
};

export default agentApiService;
