import Ajv from "ajv";
import { agentPost } from "../../helpers/travel/new-policy/api/agent";
import { ContractorType } from "../../pages/contractor/type";
import { getNewContractorResponseSchema } from "../../schemas/contractor/NewContractorResponse";
import { NewContractorRequest, NewContractorResponse } from "../../types/contractor/NewContractor";
import { createContractorRequest } from "../../helpers/contractor/api/newContractorApi";

const ajv = new Ajv();
const validateCheckoutResponse = ajv.compile(getNewContractorResponseSchema);

const NEW_CONTRACTOR = "v1/contractor/submit";

const agentApiService = {
  createContractor: (data: ContractorType): Promise<NewContractorResponse> =>
    agentPost<NewContractorRequest, NewContractorResponse>(
      process.env.REACT_APP_BACKEND_API,
      NEW_CONTRACTOR,
      createContractorRequest(data),
      (response: unknown) => {
        return validateCheckoutResponse(response);
      }
    ),
};

export default agentApiService;
