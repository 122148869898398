import React from "react";

interface DeclarationTermsProps {
  productName: string;
}

const DeclarationTerms: React.FC<DeclarationTermsProps> = ({ productName }) => (
  <ol>
    <li>I/we declare</li>
    <ol type="a">
      <li>I/we have read and understood the {productName} Product Disclosure Statement and Policy Wording.</li>
      <li>The Target Market Determination of the Policy has been made available to me/us.</li>
      <li>I/we have read and understood the Financial Services Guide.</li>
      <li>I/we am authorized to sign this application by each insured person.</li>
      <li>The statements in this application are true and complete and no material information has been withheld.</li>
      <li>I/we have read and understood the Important Notices accompanying this application.</li>
      <li>I/we have diligently made all necessary enquiries in order to comply with the duty of disclosure.</li>
    </ol>
    <li>
      Where I/we have provided information about another individual, that individual has been made aware of that fact and the Australia Personal Data
      Privacy Statement of Allied World.
    </li>
    <li>
      I/we acknowledge that Allied World relies on the information and representations of this application and otherwise made by me or on my behalf in
      connection with this insurance.
    </li>
    <li>
      Except where indicated to the contrary, I/we understand that any statement made in this application will be treated as a statement made all
      persons to be insured.
    </li>
    <li>
      I/we undertake to notify Allied World of any material change to the information contained in this application before the proposed insurance
      cover.
    </li>
  </ol>
);

export default DeclarationTerms;
