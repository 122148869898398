import { FC } from "react";

interface PremiumLabelProps {
  label: string;
  value: string;
  currency: string;
}

const PremiumLabel: FC<PremiumLabelProps> = ({ label, value, currency }) => (
  <div className="flex-wrap pt-1 mr-1.5 flex-1 justify-end text-right">
    <span className="font-sans text-xxs peer-checked:text-white font-normal">{label}</span>
    <div className="flex ml-1 justify-end flex-wrap pb-2.5">
      <span className="font-sans font-bold text-xl">
        {currency} {value}
      </span>
    </div>
  </div>
);

export default PremiumLabel;
