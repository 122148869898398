export enum NavigationIndex {
  DETAILS = 0,
  PLAN = 1,
  PERSONAL = 2,
  SUMMARY = 3,
  TERMS = 4,
}

export type NavigationStep = {
  stepIndex: NavigationIndex;
  stepNumber: number;
  route: string;
  prev: string;
  next: string;
};

export enum NavigationType {
  NEXT,
  PREVIOUS,
  SUBMIT,
  NEXT_FROM_DIALOG,
}
