import { kebabCase, clamp } from "lodash";
import PlusMinButton from "./number-input/PlusMinButton";
import cls from "../../utils/cls";
import { HTMLAttributes } from "react";
import { ErrorMessage } from "../forms";

interface IProps extends HTMLAttributes<HTMLElement> {
  name: string;
  onValueChange: (value: number, name?: string) => void;
  value: number;
  label?: string;
  min?: number;
  error?: string | boolean | null;
  max?: number;
  className?: string;
  stepFactor?: number;
}

const NumberInput = ({ name, value, error, onValueChange, label, min = 0, max = 99, stepFactor = 1, ...props }: IProps) => {
  const id = kebabCase(name);

  const setValue = (newValue: number) => onValueChange(clamp(newValue, min, max), name);

  const roundToStepFactor = () => {
    const flooredValue = stepFactor * Math.floor(value / stepFactor);
    setValue(flooredValue);
  };

  const setValue1 = (newValue: any) => {
    setValue(newValue ? parseInt(newValue) : 0);
  };
  return (
    <div className="w-full">
      <label htmlFor={id} className={label ? "relative block mt-7 h-15 w-full" : "relative block h-15 w-full"}>
        <PlusMinButton
          className="icon-minus icon-inset-0 left"
          disabled={value <= min}
          onClick={() => setValue(value - stepFactor)}
          aria-label={`min-${id}`}
        />
        <PlusMinButton
          className="icon-plus icon-inset-0 right"
          disabled={value >= max}
          onClick={() => setValue(value + stepFactor)}
          aria-label={`plus-${id}`}
        />
        <input
          type="number"
          name={name}
          id={id}
          value={String(value)}
          onChange={e => setValue1(Number(e.target.value))}
          className={cls(
            "hide-input-indicators",
            "ringed py w-full h-15 border rounded-md text-center text-black bg-white border-grey-light text-xl"
          )}
          min={min}
          max={max}
          {...props}
          onBlur={roundToStepFactor}
        />
        {label && (
          <span className="absolute z-10 text-base text-grey transition-all duration-200 -top-7 left-0" data-testid="number-input-label">
            {label}
          </span>
        )}
      </label>
      <ErrorMessage error={error} />
    </div>
  );
};

export default NumberInput;
