import { EligibilityState, LimitState, PersonalDetailsState, TermsConditionsState } from "../../pages/professional_indemnity/Form/types";
import { LimitOfLiabilityState } from "../../pages/professional_indemnity/renewal/Form/types";
import { Validations } from "../../types/shared/Validation";

export const eligibilityValidations: Validations<EligibilityState> = {
  fields: {
    hasBeenDeregistered: {
      required: true,
    },
    hasClaims: {
      required: true,
    },
    pendingClaims: {
      required: true,
    },
    refusedRegularPaymentOrProposal: {
      required: true,
    },
    subjectToDisciplinaryActions: {
      required: true,
    },
  },
};

export const limitValidations: Validations<LimitState> = {
  fields: {
    limit: {
      required: true,
    },
  },
};

export const personalValidation: Validations<PersonalDetailsState> = {
  fields: {
    salutation: {
      required: true,
    },
    surname: {
      required: true,
    },
    givenName: {
      required: true,
    },
    otherNames: {},
    mobileNumber: {
      required: true,
      pattern: {
        regex: /^[0-9]{8}$/,
        message: "Must be 8 digits",
      },
    },
    email: {
      required: true,
      pattern: {
        regex: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
        message: "Please check email format",
      },
    },
    agentCode: {
      required: true,
      pattern: {
        regex: /^[0-9]{6}$/,
        message: "Must be 6 digits",
      },
    },
    districtCode: {
      required: true,
      pattern: {
        regex: /^[0-9]{5}$/,
        message: "Must be 5 digits",
      },
    },
    officeLocation: {
      required: true,
    },
    districtName: {
      required: true,
    },
    address: {},
    building: {},
    streetNumberAndName: {
      required: true,
    },
    district: {
      required: true,
    },
    territory: {
      required: true,
    },
  },
};

export const termsConditionsValidations: Validations<TermsConditionsState> = {
  fields: {
    accepted: {
      required: {
        message: "Please agree to proceed",
      },
    },
  },
};

export const limitOfLiabilityValidations: Validations<LimitOfLiabilityState> = {
  fields: {
    email: {
      required: true,
      pattern: {
        regex: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
        message: "Please check email format",
      },
    },
    agentCode: {
      required: true,
      pattern: {
        regex: /^[0-9]{6}$/,
        message: "Agent Code should have exactly 6 digits",
      },
    },
    limit: {
      required: true,
    },
  },
};
