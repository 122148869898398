import React from "react";
import { InputHTMLAttributes } from "react";
import cls from "../../utils/cls";
import { ErrorMessage } from "../forms";

interface SelectInputProps extends InputHTMLAttributes<HTMLSelectElement> {
  value: string;
  label?: string;
  className?: string;
  onValueChange: (value: string) => void;
  error?: string | boolean | null;
  options: string[];
  disabled?: boolean;
}

const SelectInput = ({ value, label, className, error, onValueChange, options, disabled, ...props }: SelectInputProps) => {
  return (
    <>
      <label htmlFor={props.id} className={cls("hover relative block", "px-4 h-15 w-full", className)}>
        <select
          aria-label={props["aria-label"]}
          value={value}
          aria-invalid={!!error}
          onChange={event => onValueChange(event.currentTarget.value)}
          className={cls(
            "peer ringed cursor-pointer",
            "block px-4 py w-full h-15 border rounded-md whitespace-nowrap",
            "text-base text-black border-grey-light bg-white",
            "absolute top-0 left-0",
            "appearance-none",
            { "text-red !border-red": !!error },
            { "!ring-0 bg-grey-light opacity-100 cursor-not-allowed": !!disabled },
            { "pt-5 pb-1": !!label }
          )}
          placeholder={label}
          disabled={disabled}
          required
          {...props}
        >
          {options.map(option => (
            <Option key={option}>{option}</Option>
          ))}
        </select>
        <span className={cls("block pointer-events-none icon-caret-down icon-black icon-4 icon-middle icon-right-0 w-full h-full")} />
        {label && (
          <span
            className={cls(
              "absolute z-10",
              "transition-all duration-200",
              { "text-grey-dark text-xs !top-2 pointer-events-none": !!value || !!disabled },
              {
                "text-black text-base pointer-events-auto top-4 peer-focus:text-grey-dark peer-focus:text-xs peer-focus:top-2 peer-focus:pointer-events-none peer-hover:text-grey-dark peer-hover:text-xs peer-hover:top-2 peer-hover:pointer-events-none":
                  !value,
              },
              { "text-red": !!error }
            )}
          >
            {label}
          </span>
        )}
      </label>
      <ErrorMessage error={error} />
    </>
  );
};

const Option = ({ id, children, ...props }: React.OptionHTMLAttributes<HTMLOptionElement>) => {
  return (
    <option id={id} {...props} className={cls("m-4")}>
      {children}
    </option>
  );
};

export default SelectInput;
