import Ajv from "ajv";
import { agentPost } from "../../../helpers/travel/new-policy/api/agent";
import { QuoteListResponse } from "../../../types/travel/Listing";
import { quoteListResponse } from "../../../schemas/travel/listing/QuoteListResponse";

const ajv = new Ajv();

const validateQuoteListResponse = ajv.compile(quoteListResponse);

const QUOTELIST = "v1/agent/quoteslist";

const agentApiService = {
  getQuoteList: (data: { productType: string }): Promise<QuoteListResponse> =>
    agentPost<{ productType: string }, QuoteListResponse>(process.env.REACT_APP_BACKEND_API, QUOTELIST, data, (response: unknown) =>
      validateQuoteListResponse(response)
    ),
};

export default agentApiService;
