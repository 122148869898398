import { isDate } from "date-fns";
import { GroupPersonalAccidentFormState } from "../../pages/group_personal_accident/Form/types";
import { Validations } from "../../types/shared/Validation";
import { validateDuration, validateStartCoverageDate } from "./dateValidations";
import { GroupPersonalAccidentBindState } from "../../pages/group_personal_accident/bind/types";

const isValidDate = (date: unknown): date is Date => isDate(date);

export const basicDetailsValidations: Validations<GroupPersonalAccidentFormState> = {
  fields: {
    familyTrust: {
      required: true,
    },
    occupationEligibility: {
      required: true,
    },
    domicileRegion: {
      required: true,
    },
    totalNumbersOfInsured: {
      required: true,
      custom: {
        validate: totalNumbersOfInsured => {
          if (typeof totalNumbersOfInsured === "number" && totalNumbersOfInsured < 10) {
            return "Must be more than 9";
          }
          return null;
        },
      },
    },
    coverageType: {
      required: true,
    },
    coverageStartDate: {
      required: true,
      custom: {
        validate: coverageStartDate => {
          let error: string | null = null;
          if (!isValidDate(coverageStartDate)) return error;
          error = error || validateStartCoverageDate(coverageStartDate);
          return error;
        },
      },
    },
    coverageEndDate: {
      required: true,
      custom: {
        validate: (coverageEndDate, { coverageStartDate }) => {
          let error: string | null = null;
          // Validate if the coverage start date and Coverage end date are correct
          if (!isValidDate(coverageEndDate)) return error;
          if (!isValidDate(coverageStartDate)) return error;
          error = error || validateDuration(coverageStartDate as Date, coverageEndDate as Date, 179, 546);
          return error;
        },
      },
    },
  },
};
export const planValidations: Validations<GroupPersonalAccidentFormState> = {
  fields: {
    planName: {
      validateIf: (_, { totalNumbersOfInsured }) => {
        const isNotReferral = (totalNumbersOfInsured as number) <= 100;
        return isNotReferral;
      },
      required: {
        message: "Please select a plan",
      },
    },
  },
};

export const additionalDetailsValidations: Validations<GroupPersonalAccidentFormState> = {
  fields: {
    policyHoldersName: {
      required: true,
      length: {
        maxLength: 50,
        message: "Text exceeds max. length",
      },
    },
    undergroundOrAbove5mHeight: {
      required: true,
    },
    nonScheduledFlightMoreThan10: {
      required: true,
    },
    over70YearsOld: {
      required: true,
    },
    average55YearsOld: {
      required: true,
    },
    previousIncomeProtectOrPAInsurance: {
      required: true,
    },
    moreThan25000Claims: {
      validateIf: (_, { previousIncomeProtectOrPAInsurance }) => {
        return previousIncomeProtectOrPAInsurance === "yes";
      },
      required: true,
    },
  },
};

export const policyHolderDetailsValidations: Validations<GroupPersonalAccidentBindState> = {
  fields: {
    policyHoldersName: {
      length: {
        maxLength: 50,
        message: "Text exceeds max. length",
      },
      required: true,
    },
    australiaBusinessNo: {
      required: true,
    },
    streetNumber: {
      length: {
        maxLength: 6,
        message: "Text exceeds max. length",
      },
      required: false,
    },
    unitNumber: {
      length: {
        maxLength: 6,
        message: "Text exceeds max. length",
      },
      required: false,
    },
    floorNumber: {
      length: {
        maxLength: 6,
        message: "Text exceeds max. length",
      },
      required: false,
    },
    streetName: {
      length: {
        maxLength: 30,
        message: "Text exceeds max. length",
      },
      required: true,
    },
    suburb: {
      length: {
        maxLength: 18,
        message: "Text exceeds max. length",
      },
      required: true,
    },
    state: {
      required: true,
    },
    postalCode: {
      pattern: {
        regex: /^[0-9]{4}$/,
        message: "Must be 4 digits",
      },
      required: true,
    },
  },
};
export const termsConditionsValidations: Validations<GroupPersonalAccidentBindState> = {
  fields: {
    accepted: {
      required: {
        message: "Please agree to proceed",
      },
    },
    acceptedNotice: {
      required: {
        message: "Please agree to proceed",
      },
    },
  },
};
