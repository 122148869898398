import { FC } from "react";
import { H2, H3 } from "../../../components/basics";
import { DateInput, CheckInput } from "../../../components/form-fields";
import { quoteStatuses } from "../constants";
import { Filters } from "../types";

type FilterProps = {
  filters: Filters;
  onChange: (filters: Filters) => void;
};

const toggleValue = <T,>(collection: T[], value: T, checked: boolean): T[] => {
  const copy = [...collection];
  if (checked) {
    copy.push(value);
  } else {
    copy.splice(copy.indexOf(value), 1);
  }
  return copy;
};

const DashboardFilters: FC<FilterProps> = ({ filters, onChange }) => (
  <>
    <H2>Filters</H2>
    <div className="flex flex-col mb-8 mt-2 space-y-2">
      <div className="flex space-x-2">
        <DateInput
          name="startDate"
          onValueChange={value => value && onChange({ ...filters, startDate: value })}
          value={filters.startDate}
          label="Start date"
        />
        <DateInput
          name="endDate"
          onValueChange={value => value && onChange({ ...filters, endDate: value })}
          value={filters.endDate}
          label="End date"
        />
      </div>
      <H3>Quote status</H3>
      <div className="flex flex-wrap">
        {quoteStatuses.map(statusName => (
          <div className="w-60 pr-2 pb-2" key={statusName}>
            <CheckInput
              label={statusName}
              name={statusName}
              checked={filters.quoteStatuses.includes(statusName)}
              onValueChange={value => onChange({ ...filters, quoteStatuses: toggleValue(filters.quoteStatuses, statusName, value) })}
            />
          </div>
        ))}
      </div>
    </div>
  </>
);

export default DashboardFilters;
