import { PricingRequest, PricingResponse } from "../../../types/travel/new-policy/api/Pricing";
import { ReferralDetails, TravelDetails, NewTravelPolicyState, FeedbackDetails } from "../../../types/travel/Policy";
import { createTravelPricingRequest } from "../../../helpers/travel/new-policy/api/pricingApi";
import {
  validateBlacklistCheckResponse,
  validateCheckoutResponse,
  validateCreateQuoteResponse,
  validateCreateReferralResponse,
  validateDiscountResponse,
  validatePricingResponse,
  validateCreateFeedbackResponse,
} from "../../../helpers/travel/new-policy/api/validators";
import { BlacklistCheckRequest, BlacklistCheckResponse } from "../../../types/travel/new-policy/api/BlacklistCheck";
import { createBlacklistCheckRequest } from "../../../helpers/travel/new-policy/api/blacklistCheckApi";
import { CreateQuoteResponse, CreateReferralRequest, PremiumQuote, CreateFeedbackRequest } from "../../../types/travel/new-policy/api/Quote";
import { createQuoteRequest } from "../../../helpers/travel/new-policy/api/quoteApi";
import { CheckoutRequest, CheckoutResponse } from "../../../types/travel/new-policy/api/Checkout";
import { checkoutRequest } from "../../../helpers/travel/new-policy/api/checkoutApi";
import { createReferralRequest } from "../../../helpers/travel/new-policy/api/referralApi";
import { createFeedbackRequest } from "../../../helpers/travel/new-policy/api/feedbackApi";
import { DiscountRequest, DiscountResponse } from "../../../types/travel/new-policy/api/Discount";
import { discountRequest } from "../../../helpers/travel/new-policy/api/discountApi";
import { agentPost } from "../../../helpers/travel/new-policy/api/agent";
import { ProductType } from "../../../enums/shared/api/Api";

const PRICING = "v1/agent/pricing";
const RATES = "v1/agent/discount-commission/rates";
const CHECK_BLACKLIST = "v1/agent/blacklist/check";
const CREATE_REFERRAL = "v1/agent/referral";
const CREATE_FEEDBACK = "v1/agent/feedback";
const CREATE_QUOTE = "v1/agent/submit";
const CHECKOUT = "v1/agent/payment/checkout";

const agentApiService = {
  getTravelPricing: (data: TravelDetails): Promise<PricingResponse> =>
    agentPost<PricingRequest, PricingResponse>(process.env.REACT_APP_BACKEND_API, PRICING, createTravelPricingRequest(data), (response: unknown) =>
      validatePricingResponse(response)
    ),
  getDiscount: (data: TravelDetails): Promise<DiscountResponse> =>
    agentPost<DiscountRequest, DiscountResponse>(process.env.REACT_APP_BACKEND_API, RATES, discountRequest(data), (response: unknown) => {
      return validateDiscountResponse(response);
    }),
  validateIdentificationNumber: (identificationNumber: string): Promise<BlacklistCheckResponse> =>
    agentPost<BlacklistCheckRequest, BlacklistCheckResponse>(
      process.env.REACT_APP_BACKEND_API,
      CHECK_BLACKLIST,
      createBlacklistCheckRequest(identificationNumber),
      (data: unknown) => {
        return validateBlacklistCheckResponse(data);
      }
    ),
  createReferral: (referralDetails: ReferralDetails, flowState: NewTravelPolicyState) =>
    agentPost<CreateReferralRequest, unknown>(
      process.env.REACT_APP_BACKEND_API,
      CREATE_REFERRAL,
      createReferralRequest(referralDetails, flowState),
      (data: unknown) => {
        return validateCreateReferralResponse(data);
      }
    ),
  createFeedback: (feedbackDetails: FeedbackDetails, quoteId: string, productType: ProductType) =>
    agentPost<CreateFeedbackRequest, unknown>(
      process.env.REACT_APP_BACKEND_API,
      CREATE_FEEDBACK,
      createFeedbackRequest(feedbackDetails, quoteId, productType),
      (data: unknown) => {
        return validateCreateFeedbackResponse(data);
      }
    ),
  checkout: (quoteId: string): Promise<CheckoutResponse> =>
    agentPost<CheckoutRequest, CheckoutResponse>(process.env.REACT_APP_BACKEND_API, CHECKOUT, checkoutRequest(quoteId), (data: unknown) => {
      return validateCheckoutResponse(data);
    }),
  createQuote: (data: NewTravelPolicyState): Promise<CreateQuoteResponse> =>
    agentPost<PremiumQuote, CreateQuoteResponse>(process.env.REACT_APP_BACKEND_API, CREATE_QUOTE, createQuoteRequest(data), (response: unknown) => {
      return validateCreateQuoteResponse(response);
    }),
};

export default agentApiService;
