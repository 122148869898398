import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "../../../../components/attentions";
import { H2 } from "../../../../components/basics";
import { IOption } from "../../../../components/form-fields/RadioInput";
import { limitValidations } from "../../../../helpers/professional_indemnity/validations";
import useForm from "../../../../hooks/useForm";
import classNamePresets from "../../../../utils/classNamePresets";
import LimitRadioGroup from "../../components/LimitRadioGroup";
import { IndemnityFormContext } from "../../NewProduct";
import IndemnityFormStepNavigation from "../IndemnityFormStepNavigation";
import { LimitState } from "../types";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const options: IOption<LimitState["limit"]>[] = [
  {
    value: 3_500_000,
    label: "HKD 3,500,000",
  },
  {
    value: 7_500_000,
    label: "HKD 7,500,000",
  },
  {
    value: 12_500_000,
    label: "HKD 12,500,000",
  },
];

const Limit: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.newProduct" });
  const { state, onChange } = useContext(IndemnityFormContext);
  const form = useForm(state, limitValidations, onChange);
  const {
    field: { limit },
  } = form;

  const handleNext = () => {
    if (onNext && form.validateAll()) {
      onNext();
    }
  };

  return (
    <>
      <div className="container-flow flex-1">
        <H2 className={classNamePresets.mainHeading}>{t("steps.limit")}</H2>
        <LimitRadioGroup
          mainClassName="flex justify-between flex-wrap mb-5 md:mb-12 gap-x-20"
          labelClassName="mt-8 flex-shrink max-w-[40rem]"
          fieldsClassName="min-w-[20rem] flex-1"
          state={state}
          options={options}
          limit={limit}
          ariaLabel="limit of liability"
          limitTranslationObject={t}
        />
        <Alert type="warning" icon="icon-info">
          <p className="space-y-4">{t("totalAggregateLimit")}</p>
        </Alert>
      </div>

      <IndemnityFormStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
    </>
  );
};

export default Limit;
