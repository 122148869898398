import { useEffect, useState } from "react";

const scrollToFirstError = () => {
  const firstErrorElement = document.querySelector("[aria-invalid=true]");
  if (firstErrorElement?.scrollIntoView) firstErrorElement.scrollIntoView({ behavior: "smooth", block: "center" });
};

const useScrollToFirstError = () => {
  const [scrolled, setScrolled] = useState(false);
  const doScroll = () => setScrolled(true);

  useEffect(() => {
    if (scrolled) {
      scrollToFirstError();
      setScrolled(false);
    }
  }, [scrolled]);

  return doScroll;
};

export default useScrollToFirstError;
