import { createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validate } from "../../helpers/shared/validation";
import { Validations } from "../../types/shared/Validation";
import useEvent from "../../hooks/useEvent";
import { QueryClient, QueryClientProvider } from "react-query";
import { windowScroll } from "../../helpers/shared/useNavigationEffects";
import { parseState } from "../../helpers/shared/serialization";
import { ContractorType } from "./type";
import Header from "../auth/layout/Header";
import FormStep from "../../components/forms/FormStep";
import createInitialState from "./state";
import { contractorDetailsValidations, foreignWorkerBondValidations } from "../../helpers/contractor/validations";
import ContractorRoutes from "./routes";
import { newContractor } from "../../helpers/contractor/api/newContractorApi";
import TravelFooter from "../travel/TravelFooter";
import { useStepNavigation } from "../../hooks/useStepNavigation";

type ChangeHandler = <T extends keyof ContractorType>(key: T, value: ContractorType[T]) => void;
type submitErr = (t: boolean) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};

export const ContractorContext = createContext<{
  state: ContractorType;
  onChange: ChangeHandler;
  setFormState: Dispatch<SetStateAction<ContractorType>>;
  isSubmitting: boolean;
  submitError: boolean;
  setSubmitError: submitErr;
}>({
  onChange: noop,
  state: createInitialState(),
  setFormState: noop,
  isSubmitting: false,
  submitError: false,
  setSubmitError: noop,
});

const getURLForStep = (step: string) => `/contractor/new${step}`;
const stepsURLS = [getURLForStep("/contractor-details"), getURLForStep("/fwb-details"), getURLForStep("/success")];
const stepValidations = [contractorDetailsValidations, foreignWorkerBondValidations, {}];

const findInvalidPage = (validations: Validations<unknown>[], state: ContractorType): number =>
  validations.findIndex(validationSchema => Object.keys(validate(state, validationSchema)).length > 0);

const storageKey = "contractor";
const getInitialState = () => {
  // session storage for Contractor
  try {
    const sessionStorageState = sessionStorage.getItem(storageKey);
    return createInitialState(sessionStorageState ? (parseState(sessionStorageState) as ContractorType) : {});
  } catch (e) {
    console.error("Session storage could not be parsed", e);
    return createInitialState();
  }
};

const handleSubmit = async (
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  formState: ContractorType,
  goToNextStep: () => void,
  setSubmitError: Dispatch<SetStateAction<boolean>>
) => {
  try {
    setIsSubmitting(true);
    await newContractor(formState);
    goToNextStep();
    setIsSubmitting(false);
  } catch {
    setIsSubmitting(false);
    setSubmitError(true);
  }
};

enum Steps {
  "ContractorDetails",
  "FWBDetails",
  "Success",
}

const CreateContractorForm: FC = () => {
  const [formState, setFormState] = useState<ContractorType>(getInitialState());
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const navigate = useNavigate();
  const location = useLocation();
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const steps: Steps[] = [Steps.ContractorDetails, Steps.FWBDetails, Steps.Success];
  const persistState = useEvent(() => sessionStorage.setItem(storageKey, JSON.stringify(formState)));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  useStepNavigation<ContractorType>(step, stepValidations, formState, stepsURLS);

  const onSubmit = async () => await handleSubmit(setIsSubmitting, formState, goToNextStep, setSubmitError);

  useEffect(() => {
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);

  useEffect(() => {
    const validationsToRun = stepValidations.slice(0);
    findInvalidPage(validationsToRun, formState);
  }, [formState]);

  useEffect(() => {
    windowScroll();
  }, [location]);

  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  return (
    <ContractorContext.Provider
      value={{
        setFormState: setFormState,
        onChange: onFieldChange,
        state: formState,
        isSubmitting: isSubmitting,
        submitError: submitError,
        setSubmitError: setSubmitError,
      }}
    >
      <main>
        <form>
          <div className="flex flex-col min-h-screen gap-0">
            <Header home="/contractor" />
            {(step === 0 || step === 1) && <FormStep current={step + 1} total={steps.length - 1} />}
            <ContractorRoutes onPrevious={goToPreviousStep} onNext={goToNextStep} onSubmit={onSubmit} />
            <TravelFooter />
          </div>
        </form>
      </main>
    </ContractorContext.Provider>
  );
};

const CreateContractor: FC = () => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <CreateContractorForm />
    </QueryClientProvider>
  );
};

export default CreateContractor;
