import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HkTermsConditions from "./TermsConditions";
import HkQuotationDetails from "../../hk/bind/QuotationDetails";
import EventDetails from "./EventDetails";
import { HkLiabilityBindContext } from "../BindQuote";
import HkSummary from "./Summary";
import ProposerDetails from "./ProposerDetails";
import ProposerAddress from "./ProposerAddress";

type RoutesProps = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
  sendPaymentLink?: VoidFunction | null;
  creditTerms?: VoidFunction | null;
  region: string;
};

const HkLiabilityBindRoutes: React.FC<RoutesProps> = props => {
  const { state, setFormState } = useContext(HkLiabilityBindContext);
  const contextProps = { state, setFormState, ...props };
  return (
    <Routes>
      <Route path={"quoteDetails"} element={<HkQuotationDetails {...contextProps} />} />
      <Route path={"proposerDetails"} element={<ProposerDetails {...contextProps} />} />
      <Route path={"proposerAddress"} element={<ProposerAddress {...contextProps} />} />
      <Route path={"eventDetails"} element={<EventDetails {...contextProps} />} />
      {/* new routes for hk epl */}
      <Route path={"summary"} element={<HkSummary {...contextProps} />} />
      <Route path={"terms"} element={<HkTermsConditions {...contextProps} />} />
      <Route path="" element={<Navigate to="quoteDetails" />} />
    </Routes>
  );
};

export default HkLiabilityBindRoutes;
