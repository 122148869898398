import { FC } from "react";
import cls from "../../utils/cls";

const Article: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <article
      className={cls(
        "max-w-none",
        "prose prose-a:text-red hover:prose-a:text-red-dark",
        "prose-h1:font-serif prose-h1:text-3 prose-h1:mb",
        "lg:prose-h1:text-4xl",
        "prose-h2:font-serif prose-h2:text-3xl prose-h2:mb",
        "lg:prose-h2:text-2xl",
        "marker:text-red marker:prose-ol:font-bold marker:prose-ul:text-xl"
      )}
    >
      {children}
    </article>
  );
};

export default Article;
