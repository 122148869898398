import { EMAIL_REGEX, NUMERIC } from "../../constants";
import { Validations } from "../../types/shared/Validation";

type typeString = string;

const emailValidation = {
  required: true,
  pattern: {
    regex: EMAIL_REGEX,
    message: "Please check email format",
  },
};

export const authenticationValidations: Validations<{ email: string; password: string }> = {
  fields: {
    email: emailValidation,
    password: {
      required: true,
    },
  },
};

export const authenticationValidationsForSignup: Validations<{
  email: string;
  givenName: string;
  surName: string;
  agentCode: string;
  agentName: string;
}> = {
  fields: {
    email: emailValidation,
    givenName: {
      length: {
        maxLength: 60,
        message: "Text exceeds max. length",
      },
      pattern: {
        regex: /^(?!\s*$)\S+(?: \S+)*$/,
        message: "Input value should not contain leading or trailing spaces",
      },
      required: true,
    },
    surName: {
      length: {
        maxLength: 60,
        message: "Text exceeds max. length",
      },
      required: true,
      pattern: {
        regex: /^(?!\s*$)\S+(?: \S+)*$/,
        message: "Input value should not contain leading or trailing spaces",
      },
    },
    agentCode: {
      required: true,
      pattern: {
        regex: /^(?!\s*$)\S+(?: \S+)*$/,
        message: "Input value should not contain leading or trailing spaces",
      },
    },
    agentName: {
      required: true,
      pattern: {
        regex: /^(?!\s*$)\S+(?: \S+)*$/,
        message: "Input value should not contain leading or trailing spaces",
      },
    },
  },
};

export const validationsForAuSignupForm: Validations<{
  email: typeString;
  firstName: typeString;
  lastName: typeString;
  afslNumber: typeString;
  brokerageName: typeString;
}> = {
  fields: {
    email: emailValidation,
    firstName: {
      required: true,
      length: {
        maxLength: 60,
        message: "Text exceeds max. length",
      },
    },
    lastName: {
      required: true,
      length: {
        maxLength: 60,
        message: "Text exceeds max. length",
      },
    },
    afslNumber: {
      required: true,
      pattern: {
        regex: NUMERIC,
        message: "Must be digits",
      },
    },
    brokerageName: {
      required: true,
    },
  },
};

export const authenticationValidationsForReset: Validations<{ resetEmail: string; resetAgentCode: string }> = {
  fields: {
    resetEmail: emailValidation,
  },
};
