import { FC } from "react";
import FormGroup from "../../../components/forms/FormGroup";
import TextInput from "../../../components/form-fields/TextInput";
import NumberInput from "../../../components/form-fields/NumberInput";
import RadioInput from "../../../components/form-fields/RadioInput";
import { removeExtraSpaces } from "../../../helpers/shared/formHandlers";

type Props = {
  state: any;
  t: any;
  proposerName: any;
  eventTitle: any;
  eventType: any;
  duration: any;
  attendees: any;
  setMoreInfo: any;
  setFormState: any;
  validateField: (name: string, value: unknown, newData?: Record<string, unknown>) => void;
};

const HkDetails: FC<Props> = ({ state, t, proposerName, eventTitle, eventType, duration, attendees, setMoreInfo, setFormState, validateField }) => {
  return (
    <>
      <FormGroup title={t("proposerName")} subtitle={t("subTitle.proposerName")} subTitleFontSize="md:text-lg">
        <TextInput
          label={t("companyName")}
          aria-label="proposer-name"
          value={state.proposerName}
          {...proposerName}
          onBlur={removeExtraSpaces("proposerName", setFormState, validateField)}
        />
      </FormGroup>
      <FormGroup title={t("EventTitle")}>
        <TextInput
          aria-label="event-title"
          value={state.eventTitle}
          {...eventTitle}
          label={t("EventTitle")}
          onBlur={removeExtraSpaces("eventTitle", setFormState, validateField)}
        />
      </FormGroup>
      <FormGroup title={t("EventType")} onMoreInfo={() => setMoreInfo("event-type")}>
        <RadioInput
          aria-label="Event-Type"
          {...eventType}
          value={state.eventType}
          options={[
            { value: "staticIndoor", label: "Static Event (Indoor Setting)" },
            { value: "staticOutdoor", label: "Static Event (Outdoor Setting)" },
            { value: "dynamicIndoor", label: "Dynamic Event (Indoor Setting)" },
          ]}
        />
      </FormGroup>
      <FormGroup title={t("duration")} subtitle={t("subTitle.duration")} subTitleFontSize="md:text-lg w-72">
        <NumberInput {...duration} value={state.duration} min={1} max={30} />
      </FormGroup>
      <FormGroup title={t("attendees")} onMoreInfo={() => setMoreInfo("attendees-cover")}>
        <RadioInput
          {...attendees}
          aria-label="attendees"
          value={state.attendees}
          options={[
            { value: "Up to 5,000", label: "Up to 5,000" },
            { value: "5,001 to 8,000", label: "5,001 to 8,000" },
          ]}
        />
      </FormGroup>
    </>
  );
};
export default HkDetails;
