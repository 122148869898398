import ManulifeFooter from "./components/ManulifeFooter";
import { useTranslation } from "react-i18next";
import ManulifeHeader from "./ManulifeHeader";
import Cancel from "./components/Cancelled";

const Cancelled = () => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.newProduct" });
  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <ManulifeHeader hideAccount home={t("header.home")} />
      <Cancel t={t} />
      <ManulifeFooter />
    </div>
  );
};

export default Cancelled;
