import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import BasicDetails from "./steps/BasicDetails";
import Plans from "./steps/PlanSection/Plans";
import AdditionalDetails from "./steps/AdditionalDetails";
import Summary from "./steps/QuoteSummary";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
  onSubmit: VoidFunction | null;
  accessPlans: boolean;
};

const CreateQuoteFormRoutes: FC<Props> = props => (
  <Routes>
    <Route element={<BasicDetails {...props} />} path="basic-details" />
    {props.accessPlans && <Route element={<Plans {...props} />} path="plans" />}
    <Route element={<AdditionalDetails {...props} />} path="additional-details" />
    <Route element={<Navigate to="basic-details" />} path="*" />
    <Route path="summary" element={<Summary />} />
  </Routes>
);

export default CreateQuoteFormRoutes;
