import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormStepNavigation, { PropsWithoutLabels } from "../../../components/forms/FormStepNavigation";

const CreateQuoteStepNavigation: FC<PropsWithoutLabels> = props => {
  // navigation for AU group Travel steps
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct.navigation.button" });
  const nextButtonLabel = props.nextButtonLabel ? props.nextButtonLabel : t("next");
  return <FormStepNavigation backButtonLabel={t("back")} nextButtonLabel={nextButtonLabel} {...props} />;
};

export default CreateQuoteStepNavigation;
