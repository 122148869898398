import { ButtonHTMLAttributes, FC } from "react";
import { ButtonType, createButtonClasses, createTextClasses } from "../../helpers/shared/buttonStyling";
import cls from "../../utils/cls";
import Spinner from "../icons/Spinner";

type Props = {
  /**
   * @deprecated
   */
  className?: string;
  textClassName?: string;
  type?: ButtonType;
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type">;

const BaseButton: FC<React.PropsWithChildren<Props>> = ({ type, htmlType, className, textClassName, disabled, children, ...props }) => (
  <button className={cls(createButtonClasses(type, disabled), className)} disabled={disabled} type={htmlType || "submit"} {...props}>
    <span className={cls(createTextClasses(type), "h-[100%]", textClassName)}>{children}</span>
  </button>
);

// Exposes a button element enforcing button type. Until we have more uses for the loading indicator blank styling we should not expose optional buttonType in the interface
/**
 * A basic button for use in the application which can be styled by setting the button Type
 * @see Props
 */
export const Button: FC<React.PropsWithChildren<Props & { type: ButtonType }>> = props => <BaseButton {...props} />;

const spinnerColor = "#3F9293";
const spinnerSize = 1.5; // rem
const loadingIndicator = <Spinner width={`${spinnerSize}rem`} height={`${spinnerSize}rem`} color={spinnerColor} />;

export const LoadingButton: FC<React.PropsWithChildren<Props & { type: ButtonType } & { isLoading: boolean; loadingText: string }>> = ({
  isLoading,
  loadingText,
  type,
  children,
  ...props
}) => {
  if (isLoading) {
    return (
      <BaseButton {...props} disabled>
        {/* The translateX value is the half of the spinner size (3) + half of the margin size (2 for small, 4 for medium+) */}
        <span className="flex items-center justify-center -translate-x-4 md:-translate-x-5">
          <span className="mx-auto md:mx-0 h-6 inline-block">{loadingIndicator}</span>
          <span className="ml-2 md:ml-4 text-green hidden md:inline">{loadingText}</span>
        </span>
      </BaseButton>
    );
  }

  return (
    <Button type={type} {...props}>
      {children}
    </Button>
  );
};
