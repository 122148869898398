import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/form-fields/TextInput";
import { FormGroup } from "../../../components/forms";
import { policyHolderDetailsValidations } from "../../../helpers/group_personal_accident/validations";
import useForm from "../../../hooks/useForm";
import AuGroupBusinessFormStepNavigation from "../../group_travel/Form/CreateQuoteStepNavigation";
import GroupTravelHeading from "../../group_travel/components/Heading";
import { BindContext } from "../BindQuote";
import SelectInput from "../../../components/form-fields/SelectInput";
import { States } from "../../group_travel/enums/forms/DomicileRegion";
import { removeExtraSpaces } from "../../../helpers/shared/formHandlers";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const PolicyHolderDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const { state, setFormState, onChange } = useContext(BindContext);
  const { field, validateField, validateForm } = useForm(state, policyHolderDetailsValidations, onChange);
  const { t } = useTranslation("pages", { keyPrefix: "groupPersonalAccident.newProduct.bind" });
  const handleNext = () => validateForm(() => onNext?.());
  const redirectToAbn = () => window.open("https://abr.business.gov.au/", "_blank", "noreferrer");

  return (
    <>
      <div className="container-flow flex-grow">
        <GroupTravelHeading title={t("policyHolder.policyHolderDetails")} alt="GroupPersonalAccident" />
        <FormGroup title={t("policyHolder.policyholderName")}>
          <TextInput
            aria-label="policyHolder-name"
            label={t("policyHolder.policyholderName")}
            disabled={true}
            value={state.policyHoldersName}
            {...field.policyHoldersName}
          />
        </FormGroup>
        <FormGroup title={t("policyHolder.policyHolderAddress")}>
          <TextInput
            aria-label={`streetNumber`}
            label={t("policyHolder.streetNumber")}
            value={state.streetNumber}
            {...field.streetNumber}
            onBlur={removeExtraSpaces("streetNumber", setFormState, validateField)}
          />
          <TextInput
            aria-label={`unitNumber`}
            label={t("policyHolder.unitNumber")}
            value={state.unitNumber}
            {...field.unitNumber}
            onBlur={removeExtraSpaces("unitNumber", setFormState, validateField)}
          />
          <TextInput
            aria-label={`floorNumber`}
            label={t("policyHolder.floorNumber")}
            value={state.floorNumber}
            {...field.floorNumber}
            onBlur={removeExtraSpaces("floorNumber", setFormState, validateField)}
          />
          <TextInput
            aria-label={`street`}
            label={t("policyHolder.streetName")}
            value={state.streetName}
            {...field.streetName}
            onBlur={removeExtraSpaces("streetName", setFormState, validateField)}
          />
          <TextInput
            aria-label={`suburb`}
            label={t("policyHolder.suburb")}
            value={state.suburb}
            {...field.suburb}
            onBlur={removeExtraSpaces("suburb", setFormState, validateField)}
          />
          <SelectInput
            aria-label={`region`}
            disabled={false}
            options={Object.values(States).sort()}
            label={t("formProperties.selectState")}
            value={state.state}
            {...field.state}
          />
          <TextInput
            aria-label={`postal`}
            label={t("policyHolder.postal")}
            value={state.postalCode}
            {...field.postalCode}
            onBlur={removeExtraSpaces("postalCode", setFormState, validateField)}
          />
        </FormGroup>
        <FormGroup
          className="relative whitespace-nowrap md:h-15"
          title={t("policyHolder.AustralianBusinessNumber")}
          onHyperLink={() => redirectToAbn()}
          hyperLinkText={t("policyHolder.abnLookUp")}
        >
          <TextInput
            aria-label="policyHolder-AustralianBusinessNumber"
            label={t("policyHolder.AustralianBusinessNumber")}
            value={state.australiaBusinessNo}
            {...field.australiaBusinessNo}
            onBlur={removeExtraSpaces("australiaBusinessNo", setFormState, validateField)}
          />
        </FormGroup>
      </div>
      <AuGroupBusinessFormStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
    </>
  );
};

export default PolicyHolderDetails;
