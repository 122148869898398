import { FC, PropsWithChildren } from "react";

type PropsChildren = PropsWithChildren<{ className?: string }>;

type Header = {
  name: string;
  className?: string;
};

const THead: FC<PropsChildren> = ({ className, children }) => <thead className={className}>{children}</thead>;

const Row: FC<PropsChildren> = ({ className, children }) => <tr className={className}>{children}</tr>;

const Cell: FC<PropsChildren> = ({ className, children }) => <td className={className}>{children}</td>;

const HeaderCell: FC<PropsChildren> = ({ children, className }) => <Cell className={`${className}`}>{children}</Cell>;

interface QuoteListHeaderType {
  headersList: Header[];
  className: string;
}

export const QuoteListHeader: React.FC<QuoteListHeaderType> = ({ headersList, className }) => (
  <THead>
    <Row className={className}>
      {headersList.map(({ name, className: headerRowStyle }) => (
        <HeaderCell className={headerRowStyle} key={name}>
          {name}
        </HeaderCell>
      ))}
    </Row>
  </THead>
);

export default QuoteListHeader;
