import { Link, To } from "react-router-dom";
import cls from "../../utils/cls";
import { FC, HTMLAttributes } from "react";

interface IProps extends HTMLAttributes<HTMLElement> {
  title?: string;
  subtitle?: string;
  info?: To;
  className?: string;
  omitMarginTop?: boolean;
}

const Group: FC<React.PropsWithChildren<IProps>> = ({ title, subtitle, info, className, children, omitMarginTop = false, ...props }) => (
  <div role="group" className={cls({ "mt-10": !omitMarginTop }, className)}>
    {title && <h3 className="col-span-full font-bold mb md:text-xl">{title}</h3>}
    {info && (
      <Link
        to={info}
        className={cls(
          "pt-1 pl-6 relative md:mt-2",
          "text-sm text-red underline self-start",
          "icon-info icon-4 icon-red icon-left-0",
          "hover:text-red-dark hover:icon-red-dark"
        )}
      >
        More Information
      </Link>
    )}
    <div className="flex justify-between flex-col gap-0 md:flex-col-reverse">
      {subtitle && (
        <div role="heading" aria-level={5} className="-mt-3 mb md:font-bold md:mt">
          {subtitle}
        </div>
      )}
      <div className="flex flex-col justify-between gap-1" {...props}>
        {children}
      </div>
    </div>
  </div>
);

export default Group;
