import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import BasicDetails from "./steps/BasicDetails";
import TravelPlans from "./steps/Plans";
import AdditionalDetails from "./steps/AdditionalDetails";
import Summary from "./steps/QuoteSummary";
import PolicyDetails from "./steps/PolicyDetails";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
  onSubmit: VoidFunction | null;
};

const CreateQuoteFormRoutes: FC<Props> = props => (
  <Routes>
    {/* list of paths */}
    <Route path="basic-details" element={<BasicDetails {...props} />} />
    <Route path="policy-details" element={<PolicyDetails {...props} />} />
    <Route path="plan" element={<TravelPlans {...props} />} />
    <Route path="additional-details" element={<AdditionalDetails {...props} />} />
    <Route path="summary" element={<Summary />} />
    <Route path="*" element={<Navigate to="basic-details" />} />
  </Routes>
);

export default CreateQuoteFormRoutes;
