import { Link } from "react-router-dom";
import { PropsWithChildren } from "react";
import { kebabCase } from "lodash";

const SummarySection = ({
  name,
  "link-aria-label": label,
  link,
  children,
}: PropsWithChildren<{ name: string; link: string; "link-aria-label"?: string }>) => {
  const ariaLabel = label || kebabCase(name.toLowerCase());

  return (
    <div key={name}>
      <div className="flex justify-between mt-8">
        <p className="text-xl font-bold">{name}</p>
        <Link aria-label={ariaLabel} to={link}>
          <div className="w-10 h-10 bg-transparent icon-edit icon-6 icon-right-2 icon-red cursor-pointer" />
        </Link>
      </div>
      <hr className="border-grey-light" />
      {children}
    </div>
  );
};

export default SummarySection;
