import { FC } from "react";
import { CheckInputSimple } from "../../../components/form-fields";

type Props = {
  t: any;
  label: any;
  name: any;
  id: any;
  checked: any;
  onValueChange: any;
};

const CheckBox: FC<Props> = ({ t, label, name, id, checked, onValueChange }) => {
  return <CheckInputSimple label={t(label)} name={name} id={id} checked={checked} onValueChange={onValueChange} />;
};

export default CheckBox;
