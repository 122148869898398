import { FC, useEffect } from "react";
import PreUnderwrittenCriteria from "../components/PreUnderwrittenCriteria";
import { windowScroll } from "../../../helpers/shared/useNavigationEffects";
import EventFooter from "../EventFooter";
import EventHeader from "../EventHeader";
import { useTranslation } from "react-i18next";
const HkEligiblityCheck: FC = () => {
  // EligiblityCheck Screen for HK Event Pl
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product" });
  useEffect(() => {
    windowScroll();
  });
  return (
    <>
      <EventHeader hideAccount={false} />
      <PreUnderwrittenCriteria t={t} />
      <EventFooter />
    </>
  );
};

export default HkEligiblityCheck;
