import { FC } from "react";
import { useTranslation } from "react-i18next";
import LiabilityBindStepNavigation from "./LiabilityBindStepNavigation";
import { liabilityBindState } from "./types";
import Quotation from "../components/Quotation";
import KeyExclusionsList from "../components/KeyExclusionsList";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
  state: liabilityBindState;
};
const eventRetrieveListLink = "/event_public_liability/retrieveList";

const QuotationDetails: FC<Props> = ({ onNext, onPrevious, state }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product" });
  return (
    <div className="container-flow flex-1">
      <div className="bg-white px-4 md:pr-24 md:pl-[4.375rem] md:mt-[3.75rem] mt-4 rounded shadow-xl">
        <Quotation state={state} t={t} eventRetrieveListLink={eventRetrieveListLink} renderExclusion={<KeyExclusionsList state={state} t={t} />} />
      </div>
      <LiabilityBindStepNavigation isLoading={false} onBack={onPrevious} onSubmit={onNext} />
    </div>
  );
};

export default QuotationDetails;
