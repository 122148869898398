import React, { FC, useCallback, useState } from "react";
import useForm from "../../../hooks/useForm";
import { authenticationValidationsForReset } from "../../../helpers/authentication/validations";
import useWithAsyncLoadingIndicator from "../../../hooks/useWithAsyncLoadingIndicator";
import { resetAgent } from "../../../helpers/reset/resetApi";
import ResetLayoutForm from "./ResetLayoutForm";
import { AgentResetResponse } from "../../../types/user/api/User";

export type AgentResetType = {
  resetEmail: string;
};

const agentResetInitialState = {
  resetEmail: "",
};

const onSuccessCall = (response: AgentResetResponse) => {
  let isResetSuccessful = false;
  let errorMessage = "";
  if (response.message === "Success") {
    isResetSuccessful = true;
  } else {
    isResetSuccessful = false;
    errorMessage = "Sorry, account reset cannot be completed.";
  }
  return { isResetSuccessful, errorMessage };
};

const ResetForm: FC = () => {
  const [resetFormState, setResetFormState] = useState<AgentResetType>(agentResetInitialState);
  const onFieldChange = useCallback(
    (key: "resetEmail", value: string) => {
      setResetFormState(prevState => ({ ...prevState, [key]: value }));
    },
    [setResetFormState]
  );
  const { errors, validateField, field, validateAll } = useForm(resetFormState, authenticationValidationsForReset, onFieldChange);
  const hasErrors = Object.values(errors).filter(Boolean).length > 0;
  const [resetSuccess, setResetSuccess] = useState(false);
  const [errorMessageForReset, setErrorMessageForReset] = useState("");
  const onClose = () => {
    setResetSuccess(false);
    setResetFormState(agentResetInitialState);
    setErrorMessageForReset("");
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      try {
        //to prevent form from reload when clicking on submit button
        e.preventDefault();
        if (!validateAll()) {
          return;
        }
        const response = await resetAgent(resetFormState);
        const result = onSuccessCall(response);
        setErrorMessageForReset(result.errorMessage);
        setResetSuccess(result.isResetSuccessful);
      } catch (error) {
        setErrorMessageForReset("Something went wrong. Please try again later.");
        setResetSuccess(false);
      }
    },
    [resetFormState, validateAll]
  );
  const { isLoading, action: onSubmit } = useWithAsyncLoadingIndicator(handleSubmit);
  return (
    /* Sending the props*/
    <ResetLayoutForm
      resetSuccess={resetSuccess} // sending a flag for checking reset is success
      errorMessageForReset={errorMessageForReset}
      errors={errors}
      resetFormState={resetFormState}
      field={field}
      validateField={validateField}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      hasErrors={hasErrors}
    />
  );
};

export default ResetForm;
