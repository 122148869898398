import { FC, useContext } from "react";
import Summary, { ContentPriceCell, LabelCell, NormalRow } from "../components/Summary";
import { BindContext } from "../BindQuote";
import BindQuoteStepNavigation from "./BindQuoteStepNavigation";
import { CommonTable } from "../../event_public_liability/components/CommonTable";
import SummarySection from "../../../components/forms/SummarySection";
import { useTranslation } from "react-i18next";
import { GroupTravelBindState } from "./types";
import GroupTravelHeading from "../components/Heading";

interface SummaryQuoteProps {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
}
interface Props {
  state: any;
  editProposalDetailsLink: string;
  t: any;
}
const formatToAddressLines = (state: GroupTravelBindState): string[] => {
  return [
    `${state.streetNumber} ${state.unitNumber} ${state.floorNumber} ${state.streetName},\n`,
    `${state.suburb}, ${state.state}, ${state.postalCode}`,
  ];
};
export const SummarySections: FC<Props> = ({ state, t, editProposalDetailsLink }) => {
  return (
    <SummarySection name={t("summaryLabels.policyholderDetails")} link={editProposalDetailsLink}>
      <CommonTable className="w-full mt-2 table-fixed">
        {/* PolicyHolder Details section*/}
        <NormalRow>
          <LabelCell>{t("summaryLabels.policyholdersName")}</LabelCell>
          <ContentPriceCell>{state.policyHoldersName}</ContentPriceCell>
        </NormalRow>
        <NormalRow>
          <LabelCell>{t("summaryLabels.policyholdersAddress")}</LabelCell>
          <ContentPriceCell>{formatToAddressLines(state)}</ContentPriceCell>
        </NormalRow>
        <NormalRow>
          <LabelCell>{t("summaryLabels.businessNumber")}</LabelCell>
          <ContentPriceCell>{state.australiaBusinessNo}</ContentPriceCell>
        </NormalRow>
      </CommonTable>
    </SummarySection>
  );
};

const ProposerSummary: React.FC<SummaryQuoteProps> = ({ onPrevious, onNext }) => {
  const { state } = useContext(BindContext);
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct" });

  return (
    <div className="container-flow flex-grow">
      <GroupTravelHeading title={t("summaryLabels.title")} className="pb-0" alt="GroupTravel" />
      <Summary
        state={state}
        renderSummarySection={<SummarySections state={state} t={t} editProposalDetailsLink={"/au/group_travel/bind/proposer-details"} />}
      />
      <BindQuoteStepNavigation onBack={onPrevious} onNext={onNext} />
    </div>
  );
};

export default ProposerSummary;
