import { isDate } from "date-fns";
import { liabilityBindState } from "../../pages/event_public_liability/bind/types";
import { DetailsState } from "../../pages/event_public_liability/form/types";
import { Validations } from "../../types/shared/Validation";
import { validateStartCoverageDate } from "./validations";
import { Recipients } from "../../pages/event_public_liability/components/dialog/QuotationEmailDialog";
import { EMAIL_REGEX } from "../../constants";

const isValidDate = (date: unknown): date is Date => isDate(date);

export const hkDetailsValidations: Validations<DetailsState> = {
  fields: {
    proposerName: {
      required: true,
      length: {
        maxLength: 50,
        message: "Text exceeds max. length",
      },
    },
    eventTitle: {
      required: true,
    },
    eventType: {
      required: true,
    },
    duration: {
      required: true,
    },
    attendees: {
      required: true,
    },
    setupAndDismantling: {
      required: true,
    },
  },
};

export const termsConditionsValidations: Validations<liabilityBindState> = {
  fields: {
    accepted: {
      required: {
        message: "Please agree to proceed",
      },
    },
    acceptedNotice: {
      required: {
        message: "Please agree to proceed",
      },
    },
  },
};

export const proposerCompanyDetailsValidations = {
  fields: {
    proposerName: {
      required: true,
    },
    uenNumber: {
      required: false,
    },
    mobileNumber: {
      required: true,
      pattern: {
        regex: /^[0-9]{8}$/,
        message: "Must be 8 digits",
      },
    },

    email: {
      required: true,
      pattern: {
        regex: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
        message: "Please check email format",
      },
      length: {
        maxLength: 40,
        message: "Text exceeds max. length",
      },
    },
  },
};

export const proposerAddressValidations = {
  fields: {
    building: {
      required: false,
      length: {
        maxLength: 21,
        message: "Text exceeds max. length",
      },
    },

    houseNumber: {
      required: false,
      length: {
        maxLength: 12,
        message: "Text exceeds max. length",
      },
    },

    streetName: {
      required: true,
      length: {
        maxLength: 35,
        message: "Text exceeds max. length",
      },
    },

    district: {
      required: true,
    },
    territory: {
      required: true,
    },
  },
};

export const eventDetailsValidations: Validations<liabilityBindState> = {
  fields: {
    eventBuilding: {
      required: false,
      length: {
        maxLength: 21,
        message: "Text exceeds max. length",
      },
    },
    eventDistrict: {
      required: true,
    },
    eventTerritory: {
      required: true,
    },
    eventHouseNumber: {
      required: false,
      length: {
        maxLength: 12,
        message: "Text exceeds max. length",
      },
    },

    eventStreetName: {
      required: true,
      length: {
        maxLength: 35,
        message: "Text exceeds max. length",
      },
    },
    eventTitle: {
      required: true,
    },

    limit: {
      required: true,
    },
    coverageStartDate: {
      required: true,
      custom: {
        validate: coverageStartDate => {
          if (!isValidDate(coverageStartDate)) return "Incorrect coverage start date format";
          let error = null;
          error = error || validateStartCoverageDate(coverageStartDate);
          return error;
        },
      },
    },
    additionalInsured: {
      required: true,
    },

    landlord: {
      validateIf: (_, { additionalInsured }) => {
        return additionalInsured !== "no";
      },
      required: true,
      pattern: {
        regex: /^[a-zA-Z0-9\u4e00-\u9fa5 /&\-'_()".\n]+[a-zA-Z0-9\u4e00-\u9fa5 /&\-'_()".\n]*$/,
        message: "Only (/&-'_\"). are allowed",
      },
    },

    principal: {
      validateIf: (_, { additionalInsured }) => {
        return additionalInsured !== "no";
      },
      required: true,
      pattern: {
        regex: /^[a-zA-Z0-9\u4e00-\u9fa5 /&\-'_()".\n]+[a-zA-Z0-9\u4e00-\u9fa5 /&\-'_()".\n]*$/,
        message: "Only (/&-'_\"). are allowed",
      },
    },
  },
};

export const proposerEventDetailsValidations = {
  fields: {
    ...proposerCompanyDetailsValidations.fields,
    ...proposerAddressValidations.fields,
    ...eventDetailsValidations.fields,
  },
};

export const emailRecipientValidations: Validations<Recipients> = {
  fields: {
    sendToSelf: {
      validateIf: (_, { clientEmail }) => !clientEmail,
      required: true,
    },
    clientEmail: {
      validateIf: (_, { sendToSelf }) => !sendToSelf,
      required: true,
      pattern: {
        regex: EMAIL_REGEX,
        message: "Please check email format",
      },
    },
  },
};
