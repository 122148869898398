import TextInput from "../../../components/form-fields/TextInput";
import SecondaryLayout from "../../../components/authentication/layout/SecondaryLayout";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import RawHTML from "../../../components/basics/RawHTML";
import { Region } from "../../../enums/shared/forms/Region";
import { ChallengeResponse, getRegionWithFallback } from "../Login";

interface IProps {
  cognitoUser: ChallengeResponse;
  onConfirm: () => void;
  cognitoUserRegion: Region | string;
}

const ConfirmAgentForm = ({ cognitoUser, onConfirm, cognitoUserRegion }: IProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "auth.login.confirmAgentForm" });
  const region = getRegionWithFallback(cognitoUserRegion) as Region;
  const attributes = cognitoUser?.challengeParam?.userAttributes || {};

  const showBillingCode = region !== Region.AU;

  return (
    <SecondaryLayout title={t("title")} addonBeforeTitle={t("step")}>
      <form
        onSubmit={e => {
          e.preventDefault();
          onConfirm();
        }}
        className="space-y-8 mb-10"
      >
        <RawHTML boldedLinks={true} content={t(`form.${region}.content`)} />
        <div className="space-y-3 md:max-w-xs">
          <TextInput label={t("form.givenName")} value={attributes?.given_name} disabled />
          <TextInput label={t("form.familyName")} value={attributes?.family_name} disabled />
          <TextInput label={t("form.email")} value={attributes?.email} disabled />
          {showBillingCode && <TextInput label={t(`form.${region}.billingCode`)} value={attributes["custom:billing_code"]} disabled />}
          <TextInput label={t(`form.${region}.agencyName`)} value={attributes["custom:agency_name"]} disabled />
        </div>
        <Button type={ButtonType.PRIMARY}>{t("button.confirm")}</Button>
      </form>
    </SecondaryLayout>
  );
};

export default ConfirmAgentForm;
