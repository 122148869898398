import { FC } from "react";
import { createPortal } from "react-dom";
import classNamePresets from "../../utils/classNamePresets";
import cls from "../../utils/cls";
import FocusTrap from "focus-trap-react";

interface IProps {
  onClose: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  title?: string;
  classNames?: string;
}

const DialogTitle: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <h2 className={cls(classNamePresets.dialogTitle, "mb-4")}>{children}</h2>
);

const Dialog: FC<React.PropsWithChildren<IProps>> = ({ children, title, onClose, classNames }) => {
  return createPortal(
    <FocusTrap focusTrapOptions={{ initialFocus: false, returnFocusOnDeactivate: true, escapeDeactivates: false }}>
      <div
        aria-label="dialog-backdrop"
        aria-modal="true"
        className={cls(
          {
            "fixed container-flow z-50 inset-0 bg-black bg-opacity-80 overflow-y-auto h-full w-full m-auto": !classNames,
          },
          classNames
        )}
        role="dialog"
        onClick={onClose}
      >
        <div
          className={"flex flex-col relative bg-white p-6 my-6 md:my-16 md:p-8 lg:min-w-auto rounded-lg"}
          onClick={event => event.stopPropagation()}
        >
          <div className="self-end mb-4">
            <button
              aria-label="close"
              className="block icon-6 icon-right-0 icon-top-0 icon-close icon-red cursor-pointer hover:icon-red-dark focus:icon-red-dark"
              onClick={onClose}
            />
          </div>
          {title && <DialogTitle>{title}</DialogTitle>}
          {children}
        </div>
      </div>
    </FocusTrap>,
    document.body
  );
};

export const DialogFooter: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="flex flex-col-reverse md:flex-row justify-between gap-4 mt-4">{children}</div>
);

export default Dialog;
