import Header from "./auth/layout/Header";
import { useTranslation } from "react-i18next";
import ContentContainer from "../components/basics/ContentContainer";
import Navbar from "./auth/layout/header/Navbar";
import { LoginState, useAuth } from "../hooks/useAuth";
import ProductsFooter from "./ProductsFooter";
import { DownloadDocuments } from "./DownloadDocuments";
import { getRegion } from "../helpers/shared/getRegion";

const Downloads = () => {
  const { user } = useAuth();
  const region = getRegion(user);
  const { t } = useTranslation("pages", { keyPrefix: "travel.overview" });
  const isLoggedOut = user === LoginState.LoggedOut || user === LoginState.Unknown;
  const products = <DownloadDocuments t={t} region={region} />;

  return (
    <div className="flex flex-col justify-start bg-white min-h-[inherit]">
      <Header home="/" hideAccount>
        <Navbar />
      </Header>
      <main>
        <div className="p-3 md:p-0">
          <ContentContainer flex>
            <div className={"md:pt-16 pt-8 xxl:pt-24"}>
              <h1 className="font-serif text-black text-4xl font-semibold md:text-5xl">{t("header.button.downloads")}</h1>
            </div>
          </ContentContainer>
        </div>
        <div>
          <div className="hidden md:block">
            <ContentContainer flex>{products}</ContentContainer>
          </div>
          <div className="md:hidden">
            <ContentContainer>{products}</ContentContainer>
          </div>
        </div>
      </main>
      {!isLoggedOut && <ProductsFooter region={region} />}
    </div>
  );
};

export default Downloads;
