import cls from "../../utils/cls";
import { ComponentProps } from "react";
import { ErrorMessage } from "../forms";

export interface IOption<T> extends Omit<ComponentProps<"input">, "value" | "onChange"> {
  value: T;
  visible?: boolean;
  label?: string;
  rightLabel?: string | JSX.Element;
  hidden?: boolean;
  labelClassName?: string;
  spanClassName?: string;
}

export interface IProps<T> {
  name: string;
  onValueChange: (value: T) => void;
  value: T;
  options: IOption<T>[];
  className?: string;
  error?: string | boolean | null;
  "aria-label": string;
  inputWidth?: string;
}

const RadioInput = <T extends string | number | null>({
  name,
  "aria-label": ariaLabel,
  value: currentValue,
  onValueChange,
  options,
  className,
  error,
  inputWidth = "w-full",
  ...props
}: IProps<T>) => {
  return (
    <div className={inputWidth}>
      <fieldset className={cls("grid gap", className)} role="radiogroup" aria-label={ariaLabel} aria-invalid={!!error} {...props}>
        {options.map(({ value, label, visible, rightLabel, labelClassName, spanClassName, ...optionProps }) => {
          const id = `${name}-${value}`;

          if (visible === false) return null;

          return (
            <label
              key={value}
              className={cls(
                {
                  "flex items-center relative w-full min-h-[3.75rem] rounded-md cursor-pointer border": !labelClassName,
                },
                { "ringed bg-white": !labelClassName && !optionProps.disabled },
                labelClassName,
                {
                  "bg-green-dark border-green-dark": !labelClassName && currentValue === value && !optionProps.disabled,
                },
                { "bg-grey-light text-black border-grey-light cursor-not-allowed": !!optionProps.disabled },
                { "border-grey-light": !labelClassName && !error },
                { "text-red border-red border": !!error }
              )}
            >
              <input
                onChange={() => onValueChange(value)}
                value={value || ""}
                checked={currentValue === value}
                type="radio"
                name={name}
                id={id}
                className={cls(
                  "hover:cursor-pointer",
                  "peer",
                  "relative z-10",
                  "w-6 h-6 ml-4",
                  "after:relative after:inline-block after:border after:rounded-full after:w-6 after:h-6 after:border-grey-light after:bg-grey-lightest",
                  "checked:after:border-none checked:after:ring-inset checked:after:ring-6 checked:after:ring-white",
                  { "checked:after:bg-green-dark": !optionProps.disabled },
                  { "checked:after:bg-grey-light": !!optionProps.disabled },
                  { "text-red": !!error }
                )}
                {...optionProps}
              />
              <span
                className={cls(
                  {
                    "h-full flex items-center flex-1 inset-0 px-4 text-base text-black": !spanClassName,
                  },
                  { "peer-checked:text-white peer-checked:font-bold": !spanClassName && !optionProps.disabled },
                  spanClassName,
                  { "border-2 hover:!border-0 hover:!rounded": !!spanClassName && currentValue === value },
                  { "peer-checked:text-black": !!optionProps.disabled }
                )}
              >
                {label && <span className={cls({ "text-red": !!error })}>{label}</span>}
                {rightLabel && <span className={"flex flex-wrap flex-1"}>{rightLabel}</span>}
              </span>
            </label>
          );
        })}
      </fieldset>
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default RadioInput;
