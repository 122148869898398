import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "./EventHeader";
import Footer from "./EventFooter";
import { windowScroll } from "../../helpers/shared/useNavigationEffects";
import PreUnderwrittenCriteria from "./components/PreUnderwrittenCriteria";
const EligiblityCheck: FC = () => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product" });
  useEffect(() => {
    windowScroll();
  });

  return (
    <>
      <Header hideAccount={false} />
      <PreUnderwrittenCriteria t={t} />
      <Footer />
    </>
  );
};

export default EligiblityCheck;
