const ImportantNoticesTerms = () => (
  <ol>
    <li>
      The questions in this application (this “Application”) relate to facts that Allied World Assurance Company, Ltd (Singapore Branch) (the
      “Company”), which is the Singapore branch of a company incorporated in Bermuda with limited liability, considers material to underwriting this
      insurance. As these questions are not exhaustive, please advise the Company if there is any other material information that could influence the
      Company’s assessment and acceptance of the proposal.
    </li>
    <li>
      Statement pursuant to the Insurance Act 1966 or any amendments thereof: You have a duty to fully and faithfully disclose to the Company every
      fact you know, or ought to know, otherwise, the Policy issued may be void and you may receive nothing from the Policy.
    </li>
    <li>
      You have a duty to disclose to the Company every fact you know, or could reasonably be expected to know, that may influence the Company’s
      assessment and acceptance of the risk and the terms of such acceptance. If you are uncertain as to whether or not a particular information is
      material, these facts should be disclosed to the Company.
    </li>
    <li>You have the same duty to disclose those matters to the Company before you renew, extend, vary or reinstate a contract of insurance.</li>
    <li>
      All information provided by you in support of your Application for insurance must be correct, as you will be bound by the answers and by the
      information you have provided. If you do not comply with your duty of disclosure or make a misrepresentation, the Company may be entitled to
      reduce its liability under the Policy in respect of a claim or may cancel or avoid the Policy from its inception. If the non-disclosure or
      misrepresentation is fraudulent, the Company may also have the additional option of avoiding the Policy from its inception and retaining the
      premiums paid.
    </li>
    <li>
      If your Application was submitted via an agent, broker or other licensed intermediary (individually an “insurance intermediary”) and is
      accepted, the Company will pay the insurance intermediary through whom your Policy is arranged commission during the continuance of the Policy,
      including renewals, for arranging the Policy.
    </li>
    <li>
      If your Application relates to a renewal of your Policy:
      <ol className="list-[lower-alpha]">
        <li>Your renewal premium(s) payable is based on existing terms and conditions of your expiring Policy.</li>
        <li>The renewal premium(s) and the terms and conditions of your renewal policy are subject to your claims record remaining unchanged.</li>
        <li>
          Any change in your claims records or your Policy details may result in a change in your renewal premium(s) and/or the policy terms and
          conditions, as well as the validity of the offer of renewal made by the Company. The Company reserves all rights to amend your Policy
          renewal premium(s) as well as your Policy renewal terms and conditions.
        </li>
      </ol>
    </li>
    <li>
      This product is underwritten by the Singapore branch of Allied World Assurance Company, Ltd, which is regulated by the Monetary Authority of
      Singapore.
    </li>
    <li>
      This product is for Singapore distribution only. It is not an offer to sell, a solicitation to buy nor provision of any insurance product
      outside Singapore. The Company does not offer or sell any insurance product in any jurisdictions outside Singapore in which such offering or
      sale of the insurance product is illegal under the laws of such jurisdictions.
    </li>
  </ol>
);

export default ImportantNoticesTerms;
