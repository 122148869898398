const DeclarationTerms = () => (
  <ol>
    <li>
      I/We declare to the best of my/our knowledge and belief that:
      <ol className="list-[lower-alpha]">
        <li>all the answers given in this Application are true, complete and accurate in every respect; and</li>
        <li>all material facts/information affecting the assessment of the risk have been disclosed to the Company.</li>
      </ol>
    </li>
    <li>
      I/We understand that the Policy will not become effective until this Application has been accepted by Allied World Assurance Company, Ltd
      (Singapore Branch) (the “Company”) and agree that this Application and Declaration shall form the basis of the insurance contract between myself
      and the Company.
    </li>
    <li>
      I/We understand that I/we have a duty to fully and faithfully disclose to the Company all the facts as I/we know them or ought to know them,
      otherwise, I/we may receive nothing from the Policy.
    </li>
    <li>
      I/We agree that should any of the information given by me/us alter before the inception date of the insurance to which this Application relates
      or during the period of insurance, I/We will give immediate notice thereof.
    </li>
    <li>I/We consent to the Company verifying the information provided in the Application with the relevant parties.</li>
    <li>
      If my/our Application was submitted via an insurance intermediary and is accepted, I/we acknowledge and agree to the Company paying the
      insurance intermediary through whom the Policy is arranged commission during the continuance of the Policy, including renewals, for arranging
      the Policy.
    </li>
    <li>I/We have read, understand and accept the terms, conditions and exclusions outlined in the Policy Wording.</li>
  </ol>
);

export default DeclarationTerms;
