import { AnimatedSidebar } from "../../../../components/interactives";
import { Button } from "../../../../components/basics/Button";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

const AdditionalTraveller = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose} aria-hidden={!show}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">Traveller 2</h2>
        <div className="space-y-3 > * + *">
          <p>
            If you are purchasing a family annual plan, the 2<sup>nd</sup> Insured Adult must be your spouse.
          </p>
          <p>
            If you are purchasing a single trip plan for family, the 2<sup>nd</sup> Adult need not be your spouse but traveling child(ren) must be
            legally related to either of the 2 travelling Adults insured under this policy.
          </p>
        </div>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};

export default AdditionalTraveller;
