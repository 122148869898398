import { AnimatedSidebar } from "../../../../components/interactives";
import { Button } from "../../../../components/basics/Button";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const TravellersInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">Traveller(s)</h2>
        <h3 className="text-md font-bold mt-6">Adult(s)</h3>
        <p>Insured Person between the age of 18 and 80 years old.</p>
        <h3 className="text-md font-bold mt-6">Child(ren)</h3>
        <p>Child(ren) means any legal child(ren), including step child(ren) and legally adopted child(ren) of an Insured Person who is</p>
        <ol className="py-1 list-alpha-parentheses-outside">
          <li>unmarried, and</li>
          <li>
            between 6 months and 18 years of age or up to 25 years of age if in full‐time education or has been accepted and is awaiting enrolment as
            a full time student in a recognized tertiary institution, and
          </li>
          <li>not engaged or serving in full‐time national service</li>
        </ol>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
