import { getDomiciledState } from "../group_travel/labels";

export const formatToAPAGCreateQuoteState = (quoteData: any) => {
  return {
    quoteNo: quoteData.quoteNo,
    quoteId: quoteData.quoteId,
    planName: quoteData.travelPlan.id,
    status: quoteData.status,
    policyHoldersName: quoteData.proposerName,
    validityDate: new Date(quoteData.validityDate),
    selectedPlan: quoteData.travelPlan,
    exemptedStampDuty: quoteData.travelDetails.stampDuty,
    regionCode: quoteData.travelDetails.domiciledState,
    domicileRegion: getDomiciledState(quoteData.travelDetails.domiciledState),
    totalNumbersOfInsured: quoteData.travelDetails.insuredCount,
    coverageType: quoteData.travelDetails.policyType,
    coverageStartDate: new Date(quoteData.travelDetails.startDate),
    coverageEndDate: new Date(quoteData.travelDetails.endDate),
    agentMail: quoteData.agentEmail,

    familyTrust: quoteData.mandatoryClause.auRegisteredBusinesss,
    occupationEligibility: quoteData.mandatoryClause.riskyOccupation,

    undergroundOrAbove5mHeight: quoteData.referalDetails.undergroundOrAbove5mHeight,
    nonScheduledFlightMoreThan10: quoteData.referalDetails.nonScheduledFlightMoreThan10,
    over70YearsOld: quoteData.referalDetails.over70YearsOld,
    average55YearsOld: quoteData.referalDetails.average55YearsOld,
    previousIncomeProtectOrPAInsurance: quoteData.referalDetails.previousIncomeProtectOrPAInsurance,
    moreThan25000Claims: quoteData.referalDetails.moreThan25000Claims,
  };
};
