import { ProductType } from "../../enums/shared/api/Api";
import { validateDocumentsResponse } from "../../helpers/tpa/api/validators";
import { agentPost } from "../../helpers/travel/new-policy/api/agent";

const POLICY_DOCUMENTS = "v1/agent/documentsList";

export type DocumentDetailsType = {
  filename: string;
  metadata: Record<any, any>;
  sourceSystem: string;
  year: number;
  createdAt: number;
  fileType: string;
  id: string;
  contentType: string;
  clientId: string;
  author: string;
  url: string;
  inlineUrl: string;
};

type PolicyDocumentsRequest = {
  policyNumber: string;
  productCode?: ProductType;
};

export type DocumentListResponse = DocumentDetailsType[];
export const tpaApiServices = {
  getPolicyDocuments: (payload: PolicyDocumentsRequest): Promise<DocumentListResponse> =>
    agentPost(process.env.REACT_APP_BACKEND_API, POLICY_DOCUMENTS, payload, (response: unknown) => validateDocumentsResponse(response)),
};
