import React from "react";
import { FC } from "react";
import { RadioInput } from "../../../components/form-fields";
import { FormGroup } from "../../../components/forms";
import PremiumLabel from "../../components/PremiumLabel";
import { formatToTwoDecimalsWithThousandSeparator } from "../../../helpers/shared/format";

type Props = {
  limit: any;
  limitTranslationObject: any;
  ariaLabel: string;
  options: any;
  state: any;
  mainClassName: string;
  labelClassName: string;
  fieldsClassName: string;
};

const LimitPerOccurrenceRadioGroup: FC<React.PropsWithChildren<Props>> = ({
  limit,
  limitTranslationObject,
  ariaLabel,
  options,
  state,
  mainClassName,
  labelClassName,
  fieldsClassName,
}) => {
  const TotalPremiumLabel: FC<{ premium: number }> = ({ premium }) => (
    <PremiumLabel
      label={limitTranslationObject(`premiumLabelTax`)}
      currency={limitTranslationObject(`currency`)}
      value={formatToTwoDecimalsWithThousandSeparator(premium)}
    />
  );

  const radioOptions: { value: string; label: string; rightLabel: JSX.Element }[] = [];
  options.map((option: { id: string; currency: string; price: number; total: number }) => {
    radioOptions.push({
      value: option.id.replace(/_/g, ","),
      label: `${option.currency} ${option.id.replace(/_/g, ",")}`,
      rightLabel: <TotalPremiumLabel premium={option.price} />,
    });
  });

  return (
    <div className={mainClassName}>
      <div className={labelClassName}>
        <p className="font-bold md:text-xl">{limitTranslationObject("limitPerOccurrence")}</p>
      </div>
      <div className={fieldsClassName}>
        <FormGroup title="" margin="md:mt-8">
          <RadioInput
            error={limit.error}
            name={limit.name}
            onValueChange={limit.onValueChange}
            value={state.limit}
            aria-label={ariaLabel}
            options={radioOptions}
          />
        </FormGroup>
      </div>
    </div>
  );
};
export default LimitPerOccurrenceRadioGroup;
