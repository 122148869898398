import cls from "../../utils/cls";

interface IProps<T> {
  checked?: T;
  onValueChange: (value: boolean) => void;
  name: string;
  id?: string;
  label?: string;
  rightLabel?: string;
  className?: string;
}

const CheckInput = <T extends boolean>({ name, id, label, rightLabel, onValueChange, checked, className }: IProps<T>) => {
  return (
    <div className={cls("flex items-center relative", "w-full h-15", className)}>
      <input
        type="checkbox"
        name={name}
        id={id || name}
        className={cls(
          "peer",
          "cursor-pointer",
          "relative z-10",
          "w-6 h-6 ml-4",
          "before:absolute before:inline-block",
          "before:border before:rounded-sm",
          "before:w-6 before:h-6",
          "before:border-grey-light before:bg-grey-lightest",
          "before:transition-all before:duration-200",
          "after:transition-all after:duration-200",
          "icon-check icon-6 icon-transparent icon-inset-0",
          "checked:icon-3 checked:icon-green-dark",
          "checked:before:border-white checked:before:bg-white"
        )}
        onChange={e => onValueChange(e.target.checked)}
        checked={checked}
      />
      <label
        htmlFor={id || name}
        className={cls(
          "cursor-pointer",
          "inset-0 absolute",
          "pl-13 pr-4 border rounded-md",
          "bg-white border-grey-light",
          "text-base text-black",
          "transition-all duration-200",
          "peer-checked:bg-green-dark peer-checked:border-green-dark",
          "peer-checked:text-white peer-checked:font-bold"
        )}
      >
        {label && <span className={cls("absolute-middle")}>{label}</span>}
        {rightLabel && <span className={cls("absolute-middle right-4")}>{rightLabel}</span>}
      </label>
    </div>
  );
};

export default CheckInput;
