import cls from "../../utils/cls";

interface SegmentedButtonsProps {
  onValueChange: (option: number) => void;
  labelName?: string;
  value: number | null;
  defaultOptions: number[];
  xsAppendLabel?: string;
  mdAppendOption?: string;
  endLabelWith?: string;
}

const SegmentedButtons: React.FC<SegmentedButtonsProps> = ({
  onValueChange,
  value,
  labelName,
  defaultOptions,
  xsAppendLabel,
  mdAppendOption,
  endLabelWith,
}) => {
  return (
    <div className="flex md:flex-row flex-col md:justify-between md:items-center items-stretch md:h-12 gap-4 md:gap-1 mb-12" role="group">
      {labelName && (
        <span className="text-xl font-bold  flex flex-nowrap whitespace-pre">
          {labelName} <span className="md:hidden block">{`${xsAppendLabel}`}</span>
          {endLabelWith}
        </span>
      )}
      <div className="border-2 rounded-full border-red flex">
        {defaultOptions?.map(option => (
          <button
            key={option}
            type="button"
            className={`${value === option ? cls("bg-red hover:!bg-red-dark text-white font-bold") : "bg-white"} ${" hover:bg-pink-error"}
            text-xl focus:ring-2 focus:ring-red py-2 px-2 md:px-4 justify-center items-center
            w-12 md:w-20 border-r-2 border-red last:border-none last:rounded-r-full first:rounded-l-full flex flex-1`}
            onClick={() => onValueChange(option)}
            aria-label={`btn-option-${option}`}
          >
            <span>{option}</span>
            <span className="hidden md:block">{mdAppendOption}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default SegmentedButtons;
