import cls from "../../utils/cls";
import { H3 } from "../basics";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNamePresets from "../../utils/classNamePresets";

interface IProps {
  title?: string;
  subtitle?: string;
  onMoreInfo?: () => void;
  className?: string;
  subTitleFontSize?: string;
  margin?: string;
  hasVerticalFieldsOrientation?: boolean;
  fullWidth?: boolean;
  hyperLinkText?: string;
  onHyperLink?: () => void;
  justifyEnd?: boolean;
}

const FormGroup: FC<React.PropsWithChildren<IProps>> = ({
  title,
  subtitle,
  onMoreInfo,
  className,
  margin,
  hasVerticalFieldsOrientation,
  children,
  fullWidth,
  subTitleFontSize,
  hyperLinkText,
  onHyperLink,
  justifyEnd,
  ...props
}) => {
  const { t } = useTranslation("components", { keyPrefix: "forms.formGroup" });
  const isHyperLink = !onMoreInfo && onHyperLink;
  const isMoreInfo = onMoreInfo && !onHyperLink;

  return (
    <div className={cls("flex justify-between flex-col md:flex-row", className, { "mt-8": !margin }, margin)}>
      <div className="flex flex-col md:max-w-xs">
        {title && <H3 className={classNamePresets.quaternary}>{title}</H3>}
        {subtitle && (
          <div className={subTitleFontSize} role="heading" aria-level={5}>
            {subtitle}
          </div>
        )}
        {(isHyperLink || isMoreInfo) && (
          <button
            type="button"
            onClick={() => (isHyperLink ? isHyperLink?.() : onMoreInfo?.())}
            className={cls(
              "py-1 mb-4 relative md:mt-2",
              "text-sm text-red font-bold underline self-start",
              { "icon-info icon-4 icon-red icon-middle icon-left-0": !!isMoreInfo },
              { "pl-6": !!isMoreInfo },
              "hover:text-red-dark hover:icon-red-dark",
              "focus:text-red-dark focus:icon-red-dark"
            )}
          >
            {isHyperLink ? hyperLinkText : t("moreInfo")}
          </button>
        )}
      </div>
      <div
        className={cls("flex w-full gap-2 flex-wrap", {
          "md:w-100": !fullWidth,
          "flex-col": !!hasVerticalFieldsOrientation,
          "md:justify-end": !!justifyEnd,
        })}
        {...props}
      >
        {children}
      </div>
    </div>
  );
};

export default FormGroup;
