import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import file from "../../assets/icons/file.svg";
import Spinner from "../icons/Spinner";

interface PricingDetailsSectionProps {
  name: string;
  onLinkClick: () => void; // Callback function type
  showSpinner: boolean;
}

export const QuotationDetailsSection = ({ name, children, onLinkClick, showSpinner }: PropsWithChildren<PricingDetailsSectionProps>) => {
  const handleLinkClick = () => {
    onLinkClick();
  };

  return (
    <div key={name}>
      <div className="flex flex-col md:flex-row justify-between">
        <p className="md:text-6xl text-2xl font-serif font-semibold mb-4 md:mb-0" aria-level={1} role="heading" aria-label={name}>
          {name}
        </p>
        {showSpinner ? (
          <span className="flex items-center justify-center -translate-x-4 md:-translate-x-5">
            <span className={"mx-auto md:mx-0 h-6 inline-block"}>
              <Spinner width="1.5rem" height="1.5rem" color="#3F9293" />
            </span>
          </span>
        ) : (
          <Link to="" className="font-bold text-red underline underline-offset-2 mt-0 " onClick={handleLinkClick}>
            <span className="flex items-center">
              <img src={file} alt="Icon" className="mr-2 mb:0" />
              <span>Email PDF</span>
            </span>
          </Link>
        )}
      </div>
      {children}
    </div>
  );
};
