import { GroupTravelBindState } from "../../../pages/group_travel/bind/types";
import { GroupPersonalAccidentBindState } from "../../../pages/group_personal_accident/bind/types";
import { CheckoutRequest } from "../../../types/group_travel/api/Quote";
import { createQuoteRequest } from "./quoteApi";

export const getProposerDetails = (_state: GroupTravelBindState | GroupPersonalAccidentBindState) => ({
  abn: _state.australiaBusinessNo,
  address: {
    streetNumber: _state.streetNumber,
    unitNumber: _state.unitNumber,
    floorNumber: _state.floorNumber,
    streetName: _state.streetName,
    suburb: _state.suburb,
    state: _state.state,
    postalCode: _state.postalCode,
  },
});

export const getTerms = (_state: GroupTravelBindState | GroupPersonalAccidentBindState) => ({
  acceptedAt: new Date(),
});

export const createCheckoutRequest = (state: GroupTravelBindState): CheckoutRequest => ({
  ...createQuoteRequest(state),
  quoteId: state.quoteId,
  quoteNo: state.quoteNo,
  proposerDetails: getProposerDetails(state),
  termsConditions: getTerms(state),
  paymentDetails: {
    method: "credit",
  },
});
