interface IProps {
  error?: string | boolean | null;
}

const ErrorMessage = ({ error }: IProps) => {
  // boolean error doesn't get an error message
  if (typeof error === "boolean") return null;
  if (!error) return null;

  return (
    <div className="text-red pl-1 mt-0.5" role="alert">
      {error}
    </div>
  );
};

export default ErrorMessage;
