import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormStepNavigation, { PropsWithoutLabels } from "../../components/forms/FormStepNavigation";

const LiabilityFormStepNavigation: FC<PropsWithoutLabels> = props => {
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product.navigation.button" });
  const nextButtonLabel = props.onNext ? t("next") : t("submit");
  return <FormStepNavigation backButtonLabel={t("back")} nextButtonLabel={nextButtonLabel} {...props} />;
};

export default LiabilityFormStepNavigation;
