import { AnimatedSidebar } from "./../../../components/interactives";
import { Button } from "./../../../components/basics/Button";
import { ButtonType } from "./../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const SetupDismantlingInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">Setting up and/or Dismantling of Event Venue</h2>
        <p>Cover for setting up and/or dismantling of the event venue.</p>
        <h3 className="text-md font-bold mt-6">Subjectivities:</h3>
        <ul className="list-disc pl-4">
          <li>Contract Value not exceeding SGD 100,000</li>
          <li>Max Period: 3 days in total for setting up and dismantling</li>
          <li>Area to be cordoned off</li>
        </ul>
        <p className="text-md font-bold mt-6">Example:</p>
        <ul className={"list-disc pl-4"}>
          <li>Setting Up of Chairs/Tables</li>
          <li>Staging</li>
          <li>Booth</li>
          <li>Tentage</li>
          <li>Banners</li>
        </ul>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
