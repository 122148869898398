const levyAttachment = "//donline.alliedworldgroup.com.hk/file/IALevy.pdf";

const ImportantNoticesTerms = () => (
  <ol>
    <li>
      {/* Point1 */}
      For the full terms, conditions and exclusions please read the Policy Wording, a copy of which is available from your insurance representative
      for this scheme.
    </li>
    <li>
      {/* Point2 */}
      This Professional Indemnity Insurance Policy is issued on a claims made and notified basis:
      <ol type="a">
        <li>
          This means that the Policy responds to claims first made against you during the Policy period and notified to Allied World Assurance Company
          Ltd (Hong Kong Branch), a company incorporated in Bermuda with limited liability (the "Company") during that Policy period.
        </li>
        <li>
          The Policy does not respond to any claim arising out of facts or circumstances of which you were aware at any time prior to the Policy
          period which would have put a reasonable person in your position on notice that a claim may be made against you (Some cover may be available
          under the Continuous Cover extension for innocent non-disclosure of facts and circumstances – but only if you are continuously insured under
          a professional indemnity policy issued by the Company from the time when you first become aware of such facts or circumstances to the time
          when you notify a claim arising from those facts and circumstances to the Company, subject to the terms and conditions of the Policy).
        </li>
        {/* Point2c */}
        <li>
          The Policy does not respond to any claim resulting from an act, error or omission occurring or committed by you prior to the retroactive
          date, where a retroactive date is specified in the Policy terms which are offered to you.
        </li>
        <li>
          Once the Policy period has expired, no notification of claims or facts or circumstances can be made on the Policy even though:
          <ol type="a">
            <li>the event giving rise to the claim against you may have occurred during the Policy period</li>
            <li>you may have first become aware of the facts or circumstances giving rise to the claim during the Policy period.</li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      {/* Point3 */}
      The questions in this application (this “Application” or “your Application”) relate to facts which Allied World Assurance Company, Ltd (Hong
      Kong Branch) (the “Company”), which is the Hong Kong branch of a company incorporated in Bermuda with limited liability considers material to
      underwriting this insurance. As these questions are not exhaustive, please advise the Company if there is any other material information that
      could influence the Company’s assessment and acceptance of the proposal.
    </li>
    <li>
      {/* Point4 */}
      Prior to entering into a contract of insurance with the Company, you have a duty to fully and faithfully disclose to the Company, every fact you
      know, or could reasonably be expected to know, that may influence the Company’s assessment and acceptance of the risk and the terms of such
      acceptance. Otherwise, the Policy issued may be void and you may receive nothing from the Policy. If you are uncertain as to whether or not
      particular information is material, these facts should be disclosed to the Company.
    </li>
    <li>
      {/* Point5 */}
      Your duty does not require disclosure of any fact:
      <ol type="a">
        <li>that diminishes the risk to be undertaken by the Company;</li>
        <li>that is of common knowledge;</li>
        <li>that the Company knows or, in the ordinary course of its business, ought to know; or</li>
        <li>as to which compliance with your duty is waived by the Company.</li>
      </ol>
    </li>
    <li>
      {/* Point6 */}
      You have the same duty to disclose those matters to the Company before you renew, extend, vary or reinstate a contract of insurance.
    </li>
    <li>
      {/* Point7 */}
      All information provided by you in support of your Application for insurance must be correct, as you will be bound by the answers and by the
      information you have provided. If you do not comply with your duty of disclosure or make a misrepresentation, the Company may be entitled to
      reduce its liability under the Policy in respect of a claim or may cancel or avoid the Policy from its inception. If the non-disclosure or
      misrepresentation is fraudulent, the Company may also have the additional option of avoiding the Policy from its inception and retaining the
      premiums paid.
    </li>
    <li>
      {/* Point8 */}
      If your Application was submitted via an insurance intermediary and is accepted, the Company will pay the authorised insurance intermediary
      through whom your Policy is arranged commission during the continuance of the Policy, including renewals, for arranging the Policy.
    </li>
    <li>
      {/* Point9*/}
      If your Application relates to a renewal of your Policy:
      <ol type="a">
        <li>Your renewal premium(s) payable is based on existing terms and conditions of your expiring Policy;</li>
        <li>The renewal premiums(s) and the terms and conditions of your renewal Policy are subject to your claims record remaining unchanged;</li>
        <li>
          Any change in your claims records or your Policy details may result in a change in your renewal premium(s) and/or the Policy terms and
          conditions, as well as the validity of the offer of renewal made by the Company. The Company reserves all rights to amend your Policy
          renewal premium(s) as well as your Policy renewal terms and conditions.
        </li>
      </ol>
    </li>
    <li>
      {/* Point10 */}
      This product is underwritten by the Hong Kong branch of Allied World Assurance Company, Ltd, which is regulated by the Insurance Authority.
    </li>
    <li>
      {/* Point11 */}
      This product is for Hong Kong distribution only. It is not an offer to sell, a solicitation to buy nor provision of any insurance product
      outside Hong Kong. The Company does not offer or sell any insurance product in any jurisdictions outside Hong Kong in which such offering or
      sale of the insurance product is illegal under the laws of such jurisdictions.
    </li>
    <li>
      {/* Point12 */}
      If you accept the quotation, it is a condition precedent to the Company’s liability under the Policy that the premium must be paid to and
      received by the Company within 60 days from the inception of the Policy, failing which the Policy shall be deemed to be automatically terminated
      and a pro-rata premium will be charged for the period that the Company is on risk.
    </li>
    <li>
      {/* Point13 */}
      This Policy shall be governed by and construed in accordance with the laws of Hong Kong and any dispute or difference that arises under this
      Policy shall be settled in accordance with the laws of Hong Kong.
    </li>
    <li>
      {/* Point14*/}
      The IA Levy imposed by the Insurance Authority applies to this Policy at the applicable rate. You can find further information on the IA Levy{" "}
      <a href={levyAttachment} rel="noreferrer" target="_blank">
        here
      </a>
      .
    </li>
  </ol>
);

export default ImportantNoticesTerms;
