import agentApiService from "./../../../services/event_public_liability/agentApiService";
import { DetailsState } from "../../../pages/event_public_liability/form/types";
import { PricingRequest } from "../../../types/event_public_liability/api/Pricing";
import { Region } from "../../../enums/shared/forms/Region";

export const createPricingRequest = (detailsState: DetailsState, region: string): PricingRequest => {
  return {
    productType: region === Region.HK ? "CLV" : "APL",
    branch: region === Region.HK ? "HK" : "SG",
    eventType: detailsState.eventType,
    duration: detailsState.duration,
    drone: detailsState.drone,
    setupAndDismantling: detailsState.setupAndDismantling,
    volunteers: detailsState.volunteers,
    attendees: detailsState.attendees,
  };
};
export const fetchEPLPrices = async (detailsState: DetailsState, region: string) => {
  const planPricing = await agentApiService.getPricing(detailsState, region);
  return planPricing;
};
