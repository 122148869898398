import { Button } from "../components/basics/Button";
import { ButtonType } from "../helpers/shared/buttonStyling";
import { useNavigate } from "react-router-dom";
import travelImg from "../assets/images/travel.svg";
import gpaImage from "../assets/images/gpa.svg";
import eventImg from "../assets/images/event.svg";
import fwbImg from "../assets/images/fwb.svg";
import Product from "./Product";
import { Region } from "../enums/shared/forms/Region";
import { isFwbEnabled } from "../helpers/shared/featureFlags";

interface IProps {
  t: any;
  region: string;
}
interface ProductsForHKProps {
  t: any;
  eventLink: JSX.Element;
}

interface ProductsForSGProps {
  t: any;
  eventLink: JSX.Element;
  travelLink: JSX.Element;
  fwbLink: JSX.Element;
  isFwbActive: boolean;
}

interface ProductsForAUProps {
  t: any;
  eventLink: JSX.Element;
  groupPersonalAccidentLink: JSX.Element;
}
const retrieveLink: Record<string, string> = {
  [Region.SG]: "/event_public_liability/retrieveList",
  [Region.HK]: "/event_public_liability/hk/retrieveList",
  [Region.AU]: "/au/group_travel/retrieve-list",
};
const quoteLink: Record<string, string> = {
  [Region.SG]: "/event_public_liability",
  [Region.HK]: "/event_public_liability/hk",
  [Region.AU]: "/au/group_travel/form/basic-details",
};

const ProductsForSG: React.VFC<ProductsForSGProps> = ({ t, eventLink, travelLink, fwbLink, isFwbActive }) => (
  <div className="mb-20 md:w-full">
    <div className="md:flex md:flex-row">
      <div className="md:w-3/6 md:pt-10">
        <Product
          alt={"travel logo"}
          type="travel"
          img={travelImg}
          text={t("header.button.travelProtector")}
          topLinks={travelLink}
          download={false}
          className="md:h-60"
        />
      </div>

      <div className="md:w-3/6 md:pt-10 md:pl-8 pt-4">
        <Product img={eventImg} alt={"event logo"} text={t("header.button.eventPublic")} topLinks={eventLink} download={false} className="md:h-60" />
      </div>
    </div>
    {isFwbActive && (
      <div className="md:flex md:flex-row md:pt-8 pt-4">
        <div className="md:w-3/6">
          <Product img={fwbImg} alt={"fwb logo"} text={t("header.button.fwbTitle")} topLinks={fwbLink} download={false} className="md:h-60" />
        </div>
      </div>
    )}
  </div>
);

const ProductsForHK: React.VFC<ProductsForHKProps> = ({ t, eventLink }) => (
  <div className="md:flex md:flex-row mb-20 md:w-full">
    {/* Product for HK */}
    <div className="md:w-3/6 md:pt-10">
      <Product img={eventImg} alt={"event logo"} text={t("header.button.eventPublic")} topLinks={eventLink} download={false} className="md:h-60" />
    </div>
  </div>
);

const ProductsForAU: React.VFC<ProductsForAUProps> = ({ t, eventLink, groupPersonalAccidentLink }) => (
  //Product for AU
  <div className="md:flex md:flex-row mb-20 md:w-full">
    <div className="md:w-3/6 md:pt-10">
      <Product img={travelImg} alt={"travel logo"} text={t("header.button.groupTravel")} topLinks={eventLink} download={false} className="md:h-60" />
    </div>

    <div className="md:w-3/6 md:pt-10 md:pl-8 pt-4">
      <Product
        alt={"group personal accident logo"}
        img={gpaImage}
        text={t("header.button.groupPersonalAccident")}
        topLinks={groupPersonalAccidentLink}
        download={false}
        className="md:h-60"
      />
    </div>
  </div>
);

export const Products = ({ region, t }: IProps) => {
  const navigate = useNavigate();
  const isFwbActive = isFwbEnabled();

  const findPolicy = (
    <Button className="max-h-11 md:max-w-[203px] md:px-4" onClick={() => navigate("/travel/retrieveList")} type={ButtonType.SECONDARY}>
      {t("header.button.findPolicy")}
    </Button>
  );

  const travelLink = (
    <>
      <Button
        className="max-h-11 md:max-w-[203px] md:px-4"
        onClick={() => {
          navigate("/travel/details");
        }}
        type={ButtonType.PRIMARY}
      >
        {t("header.button.buyNow")}
      </Button>
      {findPolicy}
    </>
  );
  const eventLink = (
    <>
      <Button
        className="max-h-11 md:max-w-[203px] md:px-4"
        onClick={() => {
          navigate(quoteLink[region]);
        }}
        type={ButtonType.PRIMARY}
      >
        {t("header.button.getQuote")}
      </Button>
      <Button className="max-h-11 md:max-w-[203px] md:px-4" type={ButtonType.SECONDARY} onClick={() => navigate(retrieveLink[region])}>
        {t("header.button.retrieveQuote")}
      </Button>
    </>
  );

  const fwbLink = (
    <>
      <Button
        className="max-h-11 md:max-w-[203px] md:px-4"
        onClick={() => {
          navigate("fwb/details");
        }}
        type={ButtonType.PRIMARY}
      >
        {t("header.button.buyNow")}
      </Button>
      <Button
        className="max-h-11 md:max-w-[203px] md:px-4"
        type={ButtonType.SECONDARY}
        onClick={() => {
          navigate("fwb/retrieveList");
        }}
      >
        {t("header.button.retrieveQuote")}
      </Button>
    </>
  );

  const groupPersonalAccidentLink = (
    <>
      <Button
        className="max-h-11 md:max-w-[203px] md:px-4"
        onClick={() => {
          navigate("/au/group_personal_accident/form/basic-details");
        }}
        type={ButtonType.PRIMARY}
      >
        {t("header.button.getQuote")}
      </Button>
      <Button
        className="max-h-11 md:max-w-[203px] md:px-4"
        type={ButtonType.SECONDARY}
        onClick={() => navigate("/au/group_personal_accident/retrieve-list")}
      >
        {t("header.button.retrieveQuote")}
      </Button>
    </>
  );

  let productsToDisplay = null;
  if (region === Region.SG)
    productsToDisplay = <ProductsForSG t={t} eventLink={eventLink} travelLink={travelLink} fwbLink={fwbLink} isFwbActive={isFwbActive} />;
  if (region === Region.HK) productsToDisplay = <ProductsForHK t={t} eventLink={eventLink} />;
  if (region === Region.AU) productsToDisplay = <ProductsForAU t={t} eventLink={eventLink} groupPersonalAccidentLink={groupPersonalAccidentLink} />;

  return productsToDisplay;
};
