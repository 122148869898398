import { TravelDetails } from "../../../../types/travel/Policy";
import { PricingRequest, PricingResponse } from "../../../../types/travel/new-policy/api/Pricing";
import { Branch, ProductType } from "../../../../enums/shared/api/Api";
import agentApiService from "../../../../services/travel/new-policy/agentApiService";
import { applyPercentages } from "../../../shared/pricing";
import clientApiService from "../../../../services/travel/new-policy/clientApiService";
import { DiscountResponse } from "../../../../types/travel/new-policy/api/Discount";
import { formatToServerDate } from "../../../../utils/date";

const processPlans = (pricing: PricingResponse, percentages: DiscountResponse) => {
  const plans = pricing.plans.map(plan => ({ ...plan, price: applyPercentages(plan.price, percentages) }));

  return { plans };
};

export const createTravelPricingRequest = (travelDetails: TravelDetails): PricingRequest => {
  return {
    product_type: ProductType.ATR,
    start_date: formatToServerDate(travelDetails.startDate),
    end_date: formatToServerDate(travelDetails.endDate),
    branch: Branch.SG,
    product_details: {
      coverage_type: travelDetails.policyType,
      group_type: travelDetails.groupType,
      region: travelDetails.location,
    },
  };
};

const fetchAgentPricesWithDiscounts = async (travelDetails: TravelDetails) => {
  const pricing = await agentApiService.getTravelPricing(travelDetails);
  const percentages = await agentApiService.getDiscount(travelDetails);

  return processPlans(pricing, percentages);
};

const fetchClientPricesWithDiscount = async (clientId: string, travelDetails: TravelDetails) => {
  const pricing = await clientApiService.getTravelPricing(clientId, travelDetails);
  const percentages = await clientApiService.getDiscount(clientId, travelDetails);

  return processPlans(pricing, percentages);
};

export const fetchPricesWithDiscount = (travelDetails: TravelDetails, clientId?: string) =>
  clientId ? fetchClientPricesWithDiscount(clientId, travelDetails) : fetchAgentPricesWithDiscounts(travelDetails);
