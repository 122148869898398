import { AnimatedSidebar } from "../../../../components/interactives";
import { Button } from "../../../../components/basics/Button";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const TravelRegionInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">Region of Travel</h2>
        <h3 className="text-md font-bold mt-6">ASEAN</h3>
        <p>Brunei, Cambodia, Indonesia, Laos, Malaysia, Myanmar, Philippines, Thailand and Vietnam</p>
        <h3 className="text-md font-bold mt-6">Asia Pacific</h3>
        <p>
          ASEAN countries and Australia, Bangladesh, Bhutan, China, Hong Kong, India, Japan, South Korea, Macau, Maldives, Mongolia, Nepal, New
          Zealand, Pakistan, Sri Lanka, Taiwan and Tibet
        </p>
        <h3 className="text-md font-bold mt-6">Worldwide</h3>
        <p>Travel to or through any destinations in the world (refer to Policy PART III GENERAL POLICY CONDITIONS – SANCTION CLAUSE)</p>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
