import TextInput from "./TextInput";
import { Dispatch, InputHTMLAttributes, SetStateAction, useEffect, useRef } from "react";
import cls from "../../utils/cls";
import { capitalize } from "lodash";
import useCancelWhenUnmounted from "../../hooks/useCancelWhenUnmounted";
import Spinner from "../icons/Spinner";
import { CorrespondenceAddress, ProposerDetails, liabilityBindState } from "../../pages/event_public_liability/bind/types";
import { getUenInfo } from "../../helpers/event_public_liability/api/retriveList";
import { UenInfoResponse } from "../../types/event_public_liability/UenInfo";
import { getInitialCorrespondenceAddress, isUenNumberStatusVerified } from "../../pages/event_public_liability/bind/ProposerDetails";
import { ProductType } from "../../enums/shared/api/Api";
import { removeExtraSpaces } from "../../helpers/shared/formHandlers";

type ValidateType = (name: string, value: unknown, newData?: Record<string, unknown>) => void;
interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  uenNumber: string;
  uenNumberStatus: ProposerDetails["uenNumberStatus"];
  validate: ValidateType;
  onValueChange: (value: string) => void;
  setUenNumberStatus: (value: ProposerDetails["uenNumberStatus"]) => void;
  error?: string | boolean | null;
  setCorrespondenceAddress: Dispatch<SetStateAction<CorrespondenceAddress>>;
  setDisableAddress?: Dispatch<SetStateAction<boolean>>;
  state: liabilityBindState;
  field: any;
  setCorrAddressFetchedByUen: Dispatch<SetStateAction<boolean>>;
  corrAddressFetchedByUen?: boolean;
  setFormState: (t: any) => void;
}

const identificationStatusClassNameMapping = {
  checking: "text-green-dark delayed-show animate-duration-1000",
  unknown: "",
  verified: "",
  notVerified: "",
};

const fieldsToValidate: Array<keyof CorrespondenceAddress> = ["postalCode", "houseNumber", "streetName", "unitNumber", "floorNumber"];

const validateCorrespondenceAddressFields = (field: any) => field && fieldsToValidate.forEach(key => field[key]?.onBlur());
const getAddressFromResponse = (response: UenInfoResponse, setUenNumberStatus: (value: ProposerDetails["uenNumberStatus"]) => void) => {
  const { message, result } = response;
  const address: CorrespondenceAddress = message === "Success" ? result : getInitialCorrespondenceAddress();
  setUenNumberStatus(message === "Success" ? "verified" : "notVerified");
  return address;
};

const UenNumberTextInput = ({
  label,
  uenNumber,
  uenNumberStatus,
  onValueChange,
  error,
  setUenNumberStatus: setIdStatus,
  setDisableAddress,
  setCorrespondenceAddress,
  validate,
  state,
  field,
  setCorrAddressFetchedByUen,
  corrAddressFetchedByUen,
  setFormState,
}: IProps) => {
  //revalidate the correspondence address when it gets autofill by fetching details from UEN no.
  useEffect(() => {
    isUenNumberStatusVerified(state?.uenNumberStatus) && validateCorrespondenceAddressFields(field);
  }, [corrAddressFetchedByUen]);

  const setUenNumberStatus = useCancelWhenUnmounted(setIdStatus);

  // This is needed so we can refer to the most up to date value when updating
  const currentUenNumber = useRef(uenNumber);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    removeExtraSpaces("uenNumber", setFormState, validate)(event);
    handleUenCheck();
  };

  const handleUenCheck = async () => {
    setUenNumberStatus("checking");
    setDisableAddress && setDisableAddress(true);
    try {
      const response: UenInfoResponse = await getUenInfo({ uenNumber, productType: ProductType.APL });
      const address = getAddressFromResponse(response, setUenNumberStatus);
      setCorrespondenceAddress(address);
    } catch (err) {
      console.error(err);
      setCorrespondenceAddress(getInitialCorrespondenceAddress());
      setUenNumberStatus("notVerified");
    } finally {
      setCorrAddressFetchedByUen(true);
      setDisableAddress && setDisableAddress(false);
    }
  };

  return (
    <TextInput
      label={label}
      name="identificationNumber"
      value={uenNumber}
      onValueChange={value => {
        currentUenNumber.current = value;
        onValueChange(value);
      }}
      onBlur={onBlur}
      error={error}
    >
      {uenNumberStatus !== "unknown" && (
        <div
          role="status"
          className={cls(
            "!absolute flex gap-1 font-light text-xs right-4 top-2 pointer-events-none icon-3 icon-left icon-top-0.5 pl-7",
            identificationStatusClassNameMapping.checking
          )}
        >
          {uenNumberStatus === "checking" && (
            <div className="m-auto w-3 h-3">
              <Spinner width="0.75rem" height="0.75rem" color="#3F9293" />
            </div>
          )}
          {uenNumberStatus === "checking" && capitalize(uenNumberStatus)}
        </div>
      )}
    </TextInput>
  );
};

export default UenNumberTextInput;
