import { MutableRefObject, useLayoutEffect, useRef } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = <T extends unknown[]>(...args: T) => any;

/**
 * Suppress the warning when using useLayoutEffect with SSR
 * https://reactjs.org/link/uselayouteffect-ssr
 */

/**
 * Similar to useCallback, with a few subtle differences:
 * - The returned function is a stable reference, and will always be the same between renders
 * - No dependency lists required
 * - Properties or state accessed within the callback will always be "current"
 * Note: using useLayoutEffect means this component is not SSR ready should we ever go there. We'd need to check for its presence/browser presence and fallback to useEffect otherwise in that case
 */
export function useEvent<TCallback extends AnyFunction>(callback: TCallback): TCallback {
  // Keep track of the latest callback:
  const latestRef = useRef<TCallback>(useEvent_shouldNotBeInvokedBeforeMount as TCallback);
  useLayoutEffect(() => {
    latestRef.current = callback;
  }, [callback]);

  const stableRef = useRef<TCallback>(null) as MutableRefObject<TCallback>;
  if (!stableRef.current) {
    stableRef.current = function (this: ThisParameterType<unknown>) {
      // eslint-disable-next-line prefer-rest-params
      return latestRef.current.apply(this, arguments as unknown as Parameters<TCallback>);
    } as TCallback;
  }

  return stableRef.current;
}

/**
 * Render methods should be pure, especially when concurrency is used,
 * so we will throw this error if the callback is called while rendering.
 */
function useEvent_shouldNotBeInvokedBeforeMount(): AnyFunction {
  throw new Error("INVALID_USEEVENT_INVOCATION: the callback from useEvent cannot be invoked before the component has mounted.");
}

export default useEvent;
