import "./assets/css/main.css";
// Direct import used due to an issue in the types, see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/43848#issuecomment-1120406623
import ReactDOM from "react-dom/client";
import App from "./App";
import { Amplify } from "aws-amplify";
import "./i18n";
import React from "react";

const amplifyConfig: Record<string, unknown> = {
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_POOL_CLIENT_ID,
};

if (process.env.REACT_APP_COGNITO_URL && process.env.REACT_APP_COGNITO_AUTH_TYPE) {
  amplifyConfig.endpoint = process.env.REACT_APP_COGNITO_URL;
  amplifyConfig.authenticationFlowType = process.env.REACT_APP_COGNITO_AUTH_TYPE;
}

Amplify.configure(amplifyConfig);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
