import { Dispatch, SetStateAction, useEffect, useState } from "react";

type Payload = any;
type FetchFunctionType<T> = (payload?: Payload) => Promise<T>;

interface UseFetchProp<T> {
  data: T | [];
  isLoading: boolean;
  loadingError: boolean;
  setData: Dispatch<SetStateAction<T | []>>;
}
export const useFetch = <T>(fetchFunction: FetchFunctionType<T>, payload?: Payload): UseFetchProp<T> => {
  const [data, setData] = useState<T | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await fetchFunction(payload);
        setData(result);
      } catch (error) {
        console.error(error);
        setLoadingError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  return { data, isLoading, loadingError, setData };
};
