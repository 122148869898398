import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import AuSignupRequest from "./AuSignupRequest";
import AuSignupSuccess from "./AuSignupSuccess";
import NotFound from "../../errors/NotFound";

const AuSignupRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/request" element={<AuSignupRequest />} />
      <Route path="/success" element={<AuSignupSuccess />} />
      <Route path="*" element={<NotFound homePage="/" />} />
    </Routes>
  );
};

export default AuSignupRoutes;
