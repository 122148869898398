import errorImg from "../../assets/images/error.svg";
import Header from "../auth/layout/Header";
import cls from "../../utils/cls";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/basics/Button";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import TravelFooter from "../travel/TravelFooter";

const Error = ({ inline = false }) => {
  const { t } = useTranslation("pages", { keyPrefix: "errors.error" });

  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      {!inline && <Header home="/" className="gap-4" hideAccount />}
      <div className={cls("container-flow flex-grow mt-6", { "lg:mt-40": !inline })}>
        <div className="flex">
          <div className="w-full lg:w-3/5 lg:pr-12">
            <h1 className="font-serif font-bold text-3xl md:text-4xl mb-3">{t("title")}</h1>
            <p className="my-6">{t("content")}</p>
            <Button className="mt-8" type={ButtonType.PRIMARY} onClick={() => window.location.reload()}>
              {t("button.refresh")}
            </Button>
          </div>
          <div className="w-2/5 hidden lg:block">
            <img src={errorImg} alt="" />
          </div>
        </div>
      </div>
      <TravelFooter />
    </div>
  );
};

export default Error;
