import { useTranslation } from "react-i18next";
import HkDetailsDetails from "../../components/Details";
import Heading from "../../components/Heading";
import { FC, useContext, useState } from "react";
import useForm from "../../../../hooks/useForm";
import RadioInput, { IOption } from "../../../../components/form-fields/RadioInput";
import FormGroup from "../../../../components/forms/FormGroup";
import LiabilityFormStepNavigation from "../../LiabilityFormStepNavigation";
import { EventTypeInfo } from "../../more-info/EventTypeInfo";
import { HkLiabilityFormContext } from "../CreateQuote";
import AttendessInfo from "../../more-info/AttendeesInfo";
import { HKSetupDismantlingInfo } from "../../more-info/HkSetupDismantlingInfo";
import { hkDetailsValidations } from "../../../../helpers/event_public_liability/HkValidations";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};
const HkDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const [moreInfo, setMoreInfo] = useState<string | null>(null);
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product.details" });
  const { state, setFormState, onChange } = useContext(HkLiabilityFormContext);
  const form = useForm(state, hkDetailsValidations, onChange);
  const {
    field: { proposerName, eventTitle, eventType, duration, attendees, setupAndDismantling },
    validateField,
  } = form;
  const handleNext = () => {
    onNext && form.validateForm(onNext);
  };
  const yesNoOptions: IOption<"yes" | "no">[] = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];
  return (
    <>
      <div className="container-flow flex-grow md:pt-[72px] pt-8">
        <Heading headingTitle={t("title")} />
        <HkDetailsDetails
          // passing props to details component
          t={t}
          eventTitle={eventTitle}
          eventType={eventType}
          duration={duration}
          proposerName={proposerName}
          attendees={attendees}
          state={state}
          setMoreInfo={setMoreInfo}
          setFormState={setFormState}
          validateField={validateField}
        />
        <FormGroup // FormGroup for Set up & Dismantling (Optional Cover)
          title={t("setupAndDismantling")}
          subTitleFontSize="md:text-lg w-72"
          onMoreInfo={() => setMoreInfo("setup-dismantling")}
        >
          <RadioInput aria-label="setupAndDismantling" {...setupAndDismantling} value={state.setupAndDismantling} options={yesNoOptions} />
        </FormGroup>
      </div>
      <LiabilityFormStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
      <EventTypeInfo show={moreInfo === "event-type"} onClose={() => setMoreInfo(null)} />
      <AttendessInfo show={moreInfo === "attendees-cover"} onClose={() => setMoreInfo(null)} />
      <HKSetupDismantlingInfo show={moreInfo === "setup-dismantling"} onClose={() => setMoreInfo(null)} />
    </>
  );
};

export default HkDetails;
