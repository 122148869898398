import { AnimatedSidebar } from "./../../../components/interactives";
import { Button } from "./../../../components/basics/Button";
import { ButtonType } from "./../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const ClaimsCoverInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">Claims Arising From Volunteers</h2>
        <p>Cover for claims arising from volunteers involved in minimal manual works.</p>
        <p className="text-md font-bold mt-6">Example:</p>
        <ul className={"list-disc pl-4"}>
          <li>Ushering</li>
          <li>Parents volunteers helping children to prepare for their concerts</li>
          <li>Assisting in packing of goodies bag/gift packs</li>
          <li>Distributing water</li>
        </ul>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};
