import { differenceInDays, format, subDays, addYears, isDate, isValid } from "date-fns";

export const today = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

const isValidDate = (date: unknown): date is Date => !!date && isValid(date) && isDate(date);

export const isDateBefore = (dateA: Date, dateB: Date) => {
  if ([dateA, dateB].every(isValidDate)) {
    return dateA < dateB;
  }

  return false;
};

export const getDaysBetweenDates = (dateA: Date, dateB: Date) => {
  if (isValidDate(dateA) && isValidDate(dateB)) {
    return Math.abs(differenceInDays(dateA, dateB));
  }

  return null;
};

export const calculateAnnualEndDate = (startDate: Date | null) => {
  if (!isValidDate(startDate)) return null;

  return subDays(addYears(startDate, 1), 1);
};

export const formatToDisplayDate = (date: Date | null) => {
  if (!isValidDate(date)) return "";
  return format(date, "dd-MM-yyyy");
};

export const formatToDisplayQuoteDate = (date: Date | null) => {
  if (!isValidDate(date)) return "";
  return format(date, "dd/MM/yyyy");
};

export const formatToServerDate = (date: Date | null) => {
  if (!isValidDate(date)) return "";

  return format(date, "yyyy-MM-dd");
};

export const formatToDisplayQuoteReadableDate = (date: Date | null) => {
  if (!isValidDate(date)) return "";
  return format(date, "dd MMMM yyyy");
};
