import { AnimatedSidebar } from "../../../components/interactives";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";

interface IProps {
  show: boolean;
  onClose: () => void;
}

const FlightsInfo = ({ show, onClose }: IProps) => {
  return (
    <AnimatedSidebar show={show} onClose={onClose}>
      <div className="px-6 md:pt-14 md:px-18">
        <h2 className="text-xl font-bold mb-4">More Information</h2>
        <p>
          A non-scheduled flight is a flight that does not operate according to a fixed timetable and is not necessarily part of a regular or
          scheduled route
        </p>
        <Button className="mt-10" type={ButtonType.PRIMARY} onClick={onClose}>
          Back
        </Button>
      </div>
    </AnimatedSidebar>
  );
};

export default FlightsInfo;
