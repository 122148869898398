import { Link as RouterLink, To } from "react-router-dom";
import React, { FC } from "react";
import { ButtonType, createButtonClasses, createTextClasses } from "../../helpers/shared/buttonStyling";
import cls from "../../utils/cls";

interface IProps {
  /**
   * The link to navigate to
   */
  to: To;
  /**
   * The type of link this is. Use this to let a link assume button styling for example
   */
  type?: ButtonType;
}

const createLinkButtonClasses = () => "flex justify-center items-center";

/**
 * A basic styled link for in the application
 * Accepts links to external pages by prepending the url with a double slash, eg //google.com
 * @param {IProps} props The props of the component
 * @see IProps
 */
const Link: FC<React.PropsWithChildren<IProps>> = ({ to, children, type = ButtonType.LINK, ...props }) => (
  <RouterLink to={to} className={cls(createButtonClasses(type), createLinkButtonClasses(), { underline: type === ButtonType.LINK })} {...props}>
    <span className={createTextClasses(type)}>{children}</span>
  </RouterLink>
);

export default Link;
