import { useState } from "react";

type VariableFunction<T extends unknown[], TResponse extends Promise<unknown>> = (...args: T) => TResponse;

/**
 * Wraps the given function and calls the success or error handler based on the output of it.
 * Also adds a loading indicator to it to signal whether the function is executing or done executing
 * @param action The promise based (/ async) function action to execute
 * @param {Record<string, unknown>=} options The options to provide to the hook
 * @param {boolean=} options.defaultLoadingState If supplied, sets this as default loading state. This is useful when you use the loading indicator to hide content and don't want to have flashing elements from state changes. Defaults to false.
 * @returns A wrapped function with the same signature as the supplied action function
 */
const useWithAsyncLoadingIndicator = <T extends unknown[], TResponse>(
  action: VariableFunction<T, Promise<TResponse>>,
  options: { defaultLoadingState?: boolean } = {}
) => {
  const [isLoading, setIsLoading] = useState(options.defaultLoadingState || false);

  return {
    isLoading,
    action: async (...args: T) => {
      setIsLoading(true);
      try {
        const response = await action(...args);
        return response;
      } finally {
        setIsLoading(false);
      }
    },
  };
};

export default useWithAsyncLoadingIndicator;
