import { Route, Routes } from "react-router-dom";
import Details from "./steps/Details";
import Plan from "./steps/Plan";
import Personal from "./steps/Personal";
import Terms from "./steps/Terms";
import Summary from "./steps/Summary";
import { ReferralDetails, NewTravelPolicyState, NewTravelPolicyStateSliceChange } from "../../../types/travel/Policy";
import PaymentLinkSent from "./steps/PaymentLinkSent";
import { CheckoutResponse } from "../../../types/travel/new-policy/api/Checkout";

export const ROUTES = ["details", "plan", "personal-details", "summary", "terms-and-conditions", "payment-link-sent"];

interface TravelRoutesProps {
  flowState: NewTravelPolicyState;
  onFieldChange: NewTravelPolicyStateSliceChange;
  isSubmitting: boolean;
  onBack: VoidFunction;
  onNext: VoidFunction;
  onSubmit: () => Promise<CheckoutResponse>;
  onReferral: (details: ReferralDetails) => Promise<void>;
  resetState: VoidFunction;
}

const NewPolicyRoutes = ({ flowState, isSubmitting, onFieldChange, onNext, onBack, onSubmit, onReferral, resetState }: TravelRoutesProps) => {
  return (
    <Routes>
      <Route
        path="details"
        element={
          <Details
            flowStateSlice={flowState.travelDetails}
            onFieldChange={(key, value) => onFieldChange("travelDetails", key, value)}
            onNext={onNext}
          />
        }
      />
      <Route
        path="plan"
        element={
          <Plan
            flowStateSlice={flowState.travelPlan}
            travelDetails={flowState.travelDetails}
            onFieldChange={(key, value) => onFieldChange("travelPlan", key, value)}
            onBack={onBack}
            onNext={onNext}
          />
        }
      />
      <Route
        path="personal-details"
        element={
          <Personal
            flowStateSlice={flowState.personalDetails}
            onFieldChange={(key, value) => onFieldChange("personalDetails", key, value)}
            onBack={onBack}
            onNext={onNext}
            onReferral={onReferral}
          />
        }
      />
      <Route path="summary" element={<Summary flowState={flowState} onBack={onBack} onNext={onNext} />} />
      <Route
        path="terms-and-conditions"
        element={
          <Terms
            flowStateSlice={flowState.termsConditions}
            isSubmitting={isSubmitting}
            onFieldChange={(key, value) => onFieldChange("termsConditions", key, value)}
            onBack={onBack}
            onSubmit={onSubmit}
          />
        }
      />
      <Route path="payment-link-sent" element={<PaymentLinkSent onCompletion={resetState} />} />
    </Routes>
  );
};

export default NewPolicyRoutes;
