import { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { H2 } from "../../../../components/basics";
import SkeletonText from "../../../../components/basics/SkeletonText";
import clientApiService from "../../../../services/professional_indemnity/clientApiService";
import classNamePresets from "../../../../utils/classNamePresets";
import { formatToDisplayDate, formatToServerDate } from "../../../../utils/date";
import ServerError from "../../../errors/ServerError";
import SummarySection from "../../../../components/forms/SummarySection";
import { SummaryTable, NormalRow, LabelCell, ContentPriceCell } from "../../../travel/new-policy/steps/summary/SummaryTable";
import { IndemnityFormContext } from "../../NewProduct";
import { IndemnityFormState } from "../types";
import { useTranslation } from "react-i18next";
import IndemnityFormStepNavigation from "../IndemnityFormStepNavigation";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const SummaryRow: FC<{ label: string; value: string }> = ({ label, value }) => (
  <NormalRow>
    <LabelCell>{label}</LabelCell>
    <ContentPriceCell>{value}</ContentPriceCell>
  </NormalRow>
);

const getProperty =
  (key: keyof IndemnityFormState, fallback = "") =>
  (state: IndemnityFormState) =>
    String(state[key]) || fallback;

const personalDetailsFields: [label: string | null, property: (state: IndemnityFormState) => string][] = [
  ["salutation", getProperty("salutation")],
  ["surname", getProperty("surname")],
  ["givenName", getProperty("givenName")],
  ["otherNames", getProperty("otherNames", "-")],
  ["mobileNumber", getProperty("mobileNumber")],
  ["email", getProperty("email")],
  ["agentCode", getProperty("agentCode")],
  ["officeLocation", getProperty("officeLocation")],
  ["districtCode", getProperty("districtCode")],
  ["districtName", getProperty("districtName")],
  ["address", state => [state.address, state.building].filter(Boolean).join(", ") || "-"],
  [null, getProperty("streetNumberAndName")],
  [null, state => `${state.district}, ${state.territory}`],
];

const PriceSkeleton: FC<{ value?: number; formatter: Intl.NumberFormat }> = ({ value, formatter }) => {
  if (!value) {
    return <SkeletonText />;
  }
  return <div>{formatter.format(value)}</div>;
};

const TotalLabel: FC<PropsWithChildren<unknown>> = ({ children }) => <div className="min-w-[18rem]">{children}</div>;

const Summary: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.newProduct" });
  const formatter = useRef(Intl.NumberFormat("en-HK", { style: "currency", currency: "HKD", currencyDisplay: "code" }));
  const { state, clientId, setFormState } = useContext(IndemnityFormContext);
  const { coverageStartDate, coverageEndDate } = state;

  const query = useQuery(
    `${clientId}/professional_indemnity/pricing`,
    () =>
      clientApiService.getPricing(clientId, {
        coverageStartDate: formatToServerDate(coverageStartDate),
        coverageEndDate: formatToServerDate(coverageEndDate),
        limit: state.limit as number,
      }),
    {
      cacheTime: 0,
      retry: (failureCount, error) => {
        return failureCount < 3 && (error as Record<string, number>)?.status >= 500;
      },
      onSuccess: data => {
        setFormState({ ...state, ...data });
      },
    }
  );

  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    if (query.isLoadingError) {
      setServerError(true);
    }
  }, [query.isLoadingError]);

  const handleNext = () => {
    query.isLoading || query.isLoadingError || onNext?.();
  };

  return (
    <>
      <div className="container-flow flex-1">
        <H2 className={classNamePresets.mainHeading}>{t("steps.summary")}</H2>

        <div className="mx-[-1.5rem] md:mx-0">
          <div className="bg-white px-8 md:px-16 py-2 mt-8 md:mt-10 rounded shadow-xl">
            <SummarySection link-aria-label="limit" name={t("summaryLabels.policySummary")} link="/professional_indemnity/manulife/form/limit">
              <SummaryTable className="w-full md:mt-4 table-fixed">
                <SummaryRow label={t("summaryLabels.coverageStartDate")} value={formatToDisplayDate(state.coverageStartDate)} />
                <SummaryRow label={t("summaryLabels.coverageEndDate")} value={formatToDisplayDate(state.coverageEndDate)} />
                <SummaryRow label={t("summaryLabels.liabilityLimit")} value={formatter.current.format(Number(state.limit))} />
              </SummaryTable>
            </SummarySection>

            <SummarySection
              link-aria-label="personal-details"
              name={t("steps.personalDetails")}
              link="/professional_indemnity/manulife/form/personal"
            >
              <SummaryTable className="w-full md:mt-4 table-fixed">
                {personalDetailsFields.map(([label, getProp], index) => (
                  <SummaryRow key={`${label}-${index}`} label={label ? t(`formLabels.${label}` as any) : ""} value={getProp(state)} />
                ))}
              </SummaryTable>
            </SummarySection>
          </div>

          <div className="bg-red rounded-b text-white px-8 md:px-16 py-8 font-semibold">
            {!query.isLoadingError ? (
              <div>
                <div className="flex justify-between flex-wrap">
                  <TotalLabel>{t("summaryLabels.insurance")}</TotalLabel>
                  <PriceSkeleton value={query.data?.gp} formatter={formatter.current} />
                </div>

                <div className="flex justify-between flex-wrap mt-2 xs:mt-0">
                  <TotalLabel>{t("summaryLabels.levy")}</TotalLabel>
                  <PriceSkeleton value={query.data?.levy} formatter={formatter.current} />
                </div>

                <div className="flex justify-between flex-wrap mt-4">
                  <div className="font-bold text-xl min-w-[18rem]">{t("summaryLabels.total")}</div>
                  <div className="font-bold text-xl">
                    <PriceSkeleton value={query.data?.total} formatter={formatter.current} />
                  </div>
                </div>
              </div>
            ) : (
              <div>{t("serverErrors.summary")}</div>
            )}
          </div>
        </div>
      </div>
      {serverError && <ServerError supportEmailId={t("customerSupport.emailId")} displayType="modal" onClose={() => setServerError(false)} />}

      <IndemnityFormStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
    </>
  );
};

export default Summary;
