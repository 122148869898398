import Header from "./auth/layout/Header";
import { useTranslation } from "react-i18next";
import ContentContainer from "../components/basics/ContentContainer";
import { LoginState, useAuth } from "../hooks/useAuth";
import Overview from "./travel/Overview";
import Navbar from "./auth/layout/header/Navbar";
import { Products } from "./Products";
import { clearPISessionStorage } from "../helpers/shared/sessionStorage";
import { useEffect } from "react";
import ProductsFooter from "./ProductsFooter";
import { getRegion } from "../helpers/shared/getRegion";
import { getRole } from "../helpers/shared/getRole";
import { Role } from "../enums/shared/forms/Role";
import { Alert } from "../components/attentions";
import RawHTML from "../components/basics/RawHTML";
import { Region } from "../enums/shared/forms/Region";

const Main = () => {
  const { user } = useAuth();
  const { t } = useTranslation("pages", { keyPrefix: "travel.overview" });
  const isLoggedOut = user === LoginState.LoggedOut || user === LoginState.Unknown;
  const region = getRegion(user);
  const role = getRole(user);
  const products = <Products region={region} t={t} />;
  useEffect(() => {
    clearPISessionStorage("event_public_liability");
    clearPISessionStorage("event_public_liability/hk");
    clearPISessionStorage("au/group_travel/form");
    clearPISessionStorage("au/group_travel/bind");
    clearPISessionStorage("/event_public_liability/retrieveList");
    clearPISessionStorage("/event_public_liability/hk/retrieveList");
    clearPISessionStorage("au/group_personal_accident/form");
    clearPISessionStorage("au/group_personal_accident/bind");
  }, []);

  return (
    <div className="flex flex-col justify-start bg-white min-h-[inherit]">
      <Header home="/" hideAccount>
        <Navbar />
      </Header>
      <main className="drop-shadow-landing">
        <Overview />

        {role !== Role.Unknown && (role === Role.Agent || role === Role.AdminAgent) && (
          <>
            {region === Region.SG && (
              <div className="container-lg -mt-11 md:mt-0">
                <Alert type="warning" icon="icon-info" className="md:mt-12 -mt-11">
                  <div className="space-y-4">
                    <RawHTML boldedLinks content={t("agree.giaNotice")} />
                  </div>
                </Alert>
              </div>
            )}
            <div className="hidden md:block">
              <ContentContainer flex>{products}</ContentContainer>
            </div>
            <div className="md:hidden">
              <ContentContainer>{products}</ContentContainer>
            </div>
          </>
        )}
      </main>
      {!isLoggedOut && <ProductsFooter region={region} />}
    </div>
  );
};

export default Main;
