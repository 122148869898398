//The data is obtained from AW-Singapore-Business-Protector-Travel-Protector.pdf
import { getPlanLabel } from "./labels";
import { PricingPlan } from "../../types/travel/new-policy/api/Pricing";
import { formatToTwoDecimalsWithThousandSeparator } from "../shared/format";

const priceTableLabels = [
  "Accidental Death & Permanent Disablement",
  "Overseas Medical Expenses",
  "Post Journey Medical Expenses",
  "Travel Cancellation",
  "Travel Delay",
  "Delayed Baggage",
];

const addOnsTableLabels = [
  "Postponement Before Trip",
  "Cancellation Before Trip",
  "Disruption During Trip",
  "Medical Expenses",
  "Emergency Repatriation",
  "Hospitalisation Allowance",
  "Overseas Quarantine Allowance",
];

// TODO: Why is this in the frontend????
const defaultPriceTables: Table[] = [
  {
    header: { label: "", value: "" },
    rows: [
      { value: "SGD 150,000" },
      { value: "SGD 200,000" },
      { value: "SGD 10,000" },
      { value: "SGD 5,000" },
      { value: "SGD 1,000" },
      { value: "SGD 1,000" },
    ],
  },
  {
    header: { label: "", value: "" },
    rows: [
      { value: "SGD 250,000" },
      { value: "SGD 500,000" },
      { value: "SGD 30,000" },
      { value: "SGD 10,000" },
      { value: "SGD 1,000" },
      { value: "SGD 1,000" },
    ],
  },
  {
    header: { label: "", value: "" },
    rows: [
      { value: "SGD 500,000" },
      { value: "SGD 500,000" },
      { value: "SGD 50,000" },
      { value: "SGD 15,000" },
      { value: "SGD 1,000" },
      { value: "SGD 1,000" },
    ],
  },
];

// TODO: Why is this in the frontend????
const defaultCovidAddOnsTables: Table[] = [
  {
    header: { label: "", value: "" },
    rows: [
      { value: "SGD 1,000" },
      { value: "SGD 1,000" },
      { value: "SGD 2,000" },
      { value: "SGD 100,000" },
      { value: "SGD 100,000" },
      { value: "SGD 1,000" },
      { value: "SGD 700" },
    ],
  },
  {
    header: { label: "", value: "" },
    rows: [
      { value: "SGD 2,000" },
      { value: "SGD 2,000" },
      { value: "SGD 4,000" },
      { value: "SGD 150,000" },
      { value: "SGD 300,000" },
      { value: "SGD 1,500" },
      { value: "SGD 1,000" },
    ],
  },
  {
    header: { label: "", value: "" },
    rows: [
      { value: "SGD 3,000" },
      { value: "SGD 6,000" },
      { value: "SGD 6,000" },
      { value: "SGD 250,000" },
      { value: "SGD 500,000" },
      { value: "SGD 2,000" },
      { value: "SGD 1,500" },
    ],
  },
];

export interface ITable {
  headers: ICell[];
  rows: IRows[];
}

interface IRows {
  cells: ICell[];
}

interface ICell {
  value: string;
}

interface Table {
  header: TableHeader;
  rows: TableRow[];
}

interface TableHeader {
  label: string;
  value: string;
}

interface TableRow {
  value: string;
}

export const createTravelPlanPriceTable = (plans: PricingPlan[]) => {
  const tables: ITable[] = [];

  defaultPriceTables.forEach((priceTable, index) => {
    const rows = priceTable.rows.map((row, rowIndex) => ({
      cells: [{ value: priceTableLabels[rowIndex] }, { value: row.value }],
    }));

    tables.push({
      headers: [{ value: getPlanLabel(plans[index].id) }, { value: `SGD ${formatToTwoDecimalsWithThousandSeparator(plans[index].price.price)}` }],
      rows: rows,
    });
  });

  return tables;
};

export const createTravelPlanCovidAddOnsTable = (plans: PricingPlan[]) => {
  const tables: ITable[] = [];

  defaultCovidAddOnsTables.forEach((covidAddOnsTable, index) => {
    const rows = covidAddOnsTable.rows.map((row, rowIndex) => ({
      cells: [{ value: addOnsTableLabels[rowIndex] }, { value: row.value }],
    }));

    tables.push({
      headers: [{ value: getPlanLabel(plans[index].id) }],
      rows: rows,
    });
  });

  return tables;
};
