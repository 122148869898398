import { ProductType } from "../../../enums/shared/api/Api";
import { GroupPersonalAccidentFormState } from "./types";

export const createInitialState = (persistedState: Partial<GroupPersonalAccidentFormState> = {}): GroupPersonalAccidentFormState => {
  return {
    productType: ProductType.APAG,
    familyTrust: null,
    occupationEligibility: null,
    domicileRegion: "",
    regionCode: "",
    totalNumbersOfInsured: 10,
    coverageType: null,
    coverageStartDate: null,
    coverageEndDate: null,
    domesticTravelDays: 2,
    internationalTravelDays: 10,
    planName: null,
    gstAmount: null,
    brokerageRate: 20,
    stampDutyRate: null,
    allPlans: null,
    selectedPlan: null,
    planCurrency: null,
    policyHoldersName: "",
    undergroundOrAbove5mHeight: null,
    nonScheduledFlightMoreThan10: null,
    over70YearsOld: null,
    average55YearsOld: null,
    previousIncomeProtectOrPAInsurance: null,
    moreThan25000Claims: null,
    validityDate: null,
    status: null,
    quoteNo: "",
    quoteId: "",
    agentMail: "",
    createdAt: null,
    ...persistedState,
  };
};

export default createInitialState;
