import { useContext } from "react";
import SummarySection from "../components/Summary";
import { BindContext } from "../BindQuote";
import BindQuoteStepNavigation from "./BindQuoteStepNavigation";
import { useTranslation } from "react-i18next";

interface SummaryQuoteProps {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
}

const QuoteSummary: React.FC<SummaryQuoteProps> = ({ onNext, onPrevious }) => {
  const { state } = useContext(BindContext);
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct.bind.navigation.button" });

  return (
    <div className="container-flow flex-grow">
      <SummarySection state={state} />
      <BindQuoteStepNavigation nextButtonLabel={t("continueToBind")} backButtonLabel={t("goToListing")} onNext={onNext} onBack={onPrevious} />
    </div>
  );
};

export default QuoteSummary;
