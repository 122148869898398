import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormStepNavigation, { PropsWithoutLabels } from "../../../components/forms/FormStepNavigation";

const IndemnityFormStepNavigation: FC<PropsWithoutLabels> = props => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.newProduct.navigation.button" });
  const nextButtonLabel = props.onNext ? t("next") : t("pay");
  const backButtonLabel = t("back");

  return <FormStepNavigation {...props} backButtonLabel={backButtonLabel} nextButtonLabel={nextButtonLabel} />;
};

export default IndemnityFormStepNavigation;
