import { useEffect } from "react";
import { Alert } from "../../components/attentions";
import cls from "../../utils/cls";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../components/interactives";
import { Button } from "../../components/basics/Button";
import { DialogFooter } from "../../components/interactives/Dialog";
import { ButtonType } from "../../helpers/shared/buttonStyling";
import { ServerApiError } from "../../types/shared/ServerApiError";
import RawHTML from "../../components/basics/RawHTML";

const LocalizedServerError: React.VFC<{ error: Exclude<ServerApiError["details"], undefined>[0] }> = ({ error: { errorCode, ...rest } }) => {
  const { t } = useTranslation("pages", { keyPrefix: "errors.serverFormErrors" });
  // Since the key comes from the backend it's a bit illogical to type it. If a key does not exist, it will fallback to defaultValue
  const errorText = t(errorCode as never, { defaultValue: "unknown error", ...rest }) as string;
  return <div>{errorText}</div>;
};

type Props =
  | {
      displayType: "modal";
      error?: ServerApiError;
      supportEmailId?: string;
      contactDetailsWithMailId?: string;
      /**
       * In case of a modal, an onClose has to be supplied as this modal does not control its state
       */
      onClose: () => void;
    }
  | {
      displayType?: "inline";
      error?: ServerApiError;
      supportEmailId?: string;
      contactDetailsWithMailId?: string;
    };

const ErrorBody: React.VFC<{ error?: ServerApiError; supportEmailId?: string; t: any; contactDetailsWithMailId?: string }> = ({
  error,
  supportEmailId,
  t,
  contactDetailsWithMailId,
}) => {
  return (
    <>
      {error && error.details?.length && (
        <p className="mb-8">
          The following errors occurred:
          <ul className="list-disc">
            {error.details.map(err => (
              <li className="ml-6" key={`${err.errorCode}-${err.field}`}>
                <LocalizedServerError error={err} />
              </li>
            ))}
          </ul>
        </p>
      )}
      <RawHTML boldedLinks={true} content={contactDetailsWithMailId ?? t("defaultContactDetails", { supportEmailId })} />
    </>
  );
};

const InlineServerError: React.VFC<{ supportEmailId?: string; contactDetailsWithMailId?: string }> = ({
  supportEmailId,
  contactDetailsWithMailId,
}) => {
  const { t } = useTranslation("pages", { keyPrefix: "errors.serverError" });

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Alert type="error" icon="icon-warning" className={cls("mb-4 mt")}>
      <p className="mb-3">{t("title")}</p>
      <ErrorBody supportEmailId={supportEmailId} t={t} contactDetailsWithMailId={contactDetailsWithMailId} />
    </Alert>
  );
};

/**
 * Displays a generic server error
 * Defaults to showing it inline as 'alert', but can also be shown as dialog
 * @param {Props} props
 * @see Props
 * @returns JSX
 */
const ServerError: React.VFC<Props> = props => {
  // TS has a bit of trouble inferring types if destructured beforehand
  const { t } = useTranslation("pages", { keyPrefix: "errors.serverError" });

  if (props.displayType === "modal") {
    return (
      <Dialog onClose={props.onClose} title={t("title")}>
        <ErrorBody error={props.error} supportEmailId={props?.supportEmailId} t={t} contactDetailsWithMailId={props?.contactDetailsWithMailId} />
        <DialogFooter>
          <Button className="whitespace-nowrap ml-auto" type={ButtonType.PRIMARY} onClick={props.onClose}>
            {t("close")}
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }

  return <InlineServerError supportEmailId={props?.supportEmailId} contactDetailsWithMailId={props.contactDetailsWithMailId} />;
};

export default ServerError;
