import { PricePayload, MPIPriceResponse, validatePricingResponse } from "../../api/professional-indemnity/PricingApi";
import { ReferralRequest, ReferralResponse, validateReferralResponse, validateCheckoutResponse } from "../../api/professional-indemnity/ReferralApi";
import { clientPost } from "../../helpers/travel/new-policy/api/client";
import { IndemnityFormState, ReferralDetails } from "../../pages/professional_indemnity/Form/types";
import { ProfessionalIndemnity, CheckoutProfessionalIndemnity } from "../../pages/professional_indemnity/models/professionalIndemnity";
import { InsuredDetails, InsuredResponse, validateRenewalResponse } from "../../api/professional-indemnity/renewalCheckApi";
import {
  CheckoutrenewalProfessionalIndemnity,
  RenewalProfessionalIndemnity,
} from "../../pages/professional_indemnity/renewal/models/renewalProfessionalIndemnity";

const CLIENT_PRICING = "v1/client/pricing";
const CREATE_REFERRAL = "v1/client/referral";
const CLIENT_CHECKOUT = "v1/client/payment/checkout";
const RENEWAL_CHECK = "v1/client/renewalCheck";
const productType = "MPI";

export type RenewalCheckoutPayload = CheckoutrenewalProfessionalIndemnity & { productType: "MPIR" };
export type CheckoutPayload = CheckoutProfessionalIndemnity & { productType: "MPI" };
export type CheckoutResponse = { quoteId: string; checkoutUrl: string };

const clientApiService = {
  getPricing: (clientId: string, payload: Omit<PricePayload, "productType">): Promise<MPIPriceResponse> => {
    const payloadWithProductType: PricePayload = {
      productType,
      ...payload,
    };
    return clientPost<PricePayload, MPIPriceResponse>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CLIENT_PRICING,
      payloadWithProductType,
      validatePricingResponse
    );
  },
  createReferral: (clientId: string, referralDetails: ReferralDetails, flowState: IndemnityFormState) => {
    const payload: ReferralRequest = {
      productType,
      name: referralDetails.name,
      email: referralDetails.email,
      eligibility: {
        subjectToDisciplinaryActions: flowState.subjectToDisciplinaryActions,
        hasClaims: flowState.hasClaims,
        pendingClaims: flowState.pendingClaims,
        hasBeenDeregistered: flowState.hasBeenDeregistered,
        refusedRegularPaymentOrProposal: flowState.refusedRegularPaymentOrProposal,
      },
    };

    return clientPost<ReferralRequest, ReferralResponse>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CREATE_REFERRAL,
      payload,
      validateReferralResponse
    );
  },
  checkout: (clientId: string, payload: ProfessionalIndemnity) => {
    const payloadWithCheckoutAndType: CheckoutPayload = {
      startDate: payload.startDate,
      endDate: payload.endDate,
      eligibility: payload.eligibility,
      personalDetails: {
        salutation: payload.personalDetails.salutation,
        lastName: payload.personalDetails.surname,
        firstName: payload.personalDetails.givenName,
        otherNames: payload.personalDetails.otherNames,
        mobileNumber: payload.personalDetails.mobileNumber,
        email: payload.personalDetails.email,
        agentCode: payload.personalDetails.agentCode,
        officeLocation: payload.personalDetails.officeLocation,
        districtCode: payload.personalDetails.districtCode,
        districtName: payload.personalDetails.districtName,
        address: payload.personalDetails.address,
        building: payload.personalDetails.building,
        streetNumberAndName: payload.personalDetails.streetNumberAndName,
        district: payload.personalDetails.district,
        territory: payload.personalDetails.territory,
      },
      limit: payload.limit,
      terms: payload.terms,
      pricing: payload.pricing,
      productType: "MPI",
    };
    return clientPost<CheckoutPayload, CheckoutResponse>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CLIENT_CHECKOUT,
      payloadWithCheckoutAndType,
      validateCheckoutResponse
    );
  },
  renewalCheck: (clientId: string, data: InsuredDetails) => {
    const payload: InsuredDetails = {
      email: data.email,
      agentCode: data.agentCode,
    };
    return clientPost<InsuredDetails, InsuredResponse>(clientId, process.env.REACT_APP_BACKEND_API, RENEWAL_CHECK, payload, validateRenewalResponse);
  },
  renewalCheckout: (clientId: string, payload: RenewalProfessionalIndemnity) => {
    const payloadWithCheckoutAndType: RenewalCheckoutPayload = {
      limit: payload.limit,
      terms: payload.terms,
      pricing: payload.pricing,
      productType: "MPIR",
      quoteId: payload.quoteId,
    };
    return clientPost<RenewalCheckoutPayload, CheckoutResponse>(
      clientId,
      process.env.REACT_APP_BACKEND_API,
      CLIENT_CHECKOUT,
      payloadWithCheckoutAndType,
      validateCheckoutResponse
    );
  },
};

export default clientApiService;
