import { Article } from "../../../components/groups";
import photosImg from "../../../assets/images/photos-thank-you.png";
import TravelHeader from "../TravelHeader";
import { useTranslation } from "react-i18next";
import Link from "../../../components/basics/Link";
import { useClientId } from "../../../hooks/useClientId";
import { ButtonType, createTextClasses } from "../../../helpers/shared/buttonStyling";
import TravelFooter from "../TravelFooter";

const externalAWLink = "//alliedworldinsurance.com/singapore/";

const Success = () => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.payment.success" });
  const clientId = useClientId();
  const logoLink = clientId ? externalAWLink : undefined;
  const travelLink = clientId ? `/client/${clientId}/travel` : "/";

  return (
    <div className="flex flex-col justify-start min-h-[inherit]">
      <TravelHeader hideAccount logoLink={logoLink} />
      <div className="container-flow flex-grow mt-6 lg:mt-20">
        <div className="flex gap-10">
          <div className="w-full lg:w-3/5">
            <h2 className="font-serif font-bold text-4xl mb">{t("title")}</h2>
            <Article>
              <p>Your travel insurance documents will be sent to the email address you have provided.</p>
              <p>
                Should you have any questions, please do not hesitate to call us at +65 6423 0888, during regular office hours (8.30am - 5.30pm,
                Mondays to Fridays, excluding Public Holidays).
              </p>
              <p className="mt-12 mb-12">
                Yours sincerely,
                <br />
                The Allied World Customer Service Team
              </p>
              <Link to={travelLink} type={ButtonType.PRIMARY}>
                <span className={createTextClasses(ButtonType.PRIMARY)}>{t("button.back")}</span>
              </Link>
            </Article>
          </div>
          <div className="w-2/5 hidden lg:block">
            <img src={photosImg} alt="" />
          </div>
        </div>
      </div>
      <TravelFooter />
    </div>
  );
};

export default Success;
