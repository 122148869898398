import { PersonalDetails } from "../../types/travel/Policy";

export const hasTravellersGroupInvalidId = (flowStateSlice: PersonalDetails) => {
  //Check first for requester
  const result = flowStateSlice.requester.identificationNumberStatus === "invalid";
  flowStateSlice.travellers.find(traveller => {
    return traveller.identificationNumberStatus === "invalid";
  });

  return result;
};
