import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import AppRoutes from "./pages/AppRoutes";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";

const useDataDog = () => {
  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const appVersion = process.env.REACT_APP_GIT_SHA || process.env.REACT_APP_GIT_COMMIT_HASH;
    const applicationId = process.env.REACT_APP_DD_APPLICATION_ID;
    const clientToken = process.env.REACT_APP_DD_APPLICATION_TOKEN;

    if (!applicationId || !clientToken) {
      return;
    }

    datadogRum.init({
      applicationId,
      clientToken,
      site: "datadoghq.com",
      service: "apac-portal",
      env: environment,
      version: appVersion,
      sampleRate: 100,
      sessionReplaySampleRate: 20,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();

    return () => datadogRum.stopSessionReplayRecording();
  }, []);
};

const App = () => {
  useDataDog();

  return (
    <AuthProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
