import { useState } from "react";
import Header from "./layout/Header";
import { ForgotPasswordForm } from "./forgot/ForgotPasswordForm";
import { ResetPasswordForm } from "./forgot/ResetPasswordForm";
import { Success } from "./forgot/Success";
import A11yH1 from "../../components/basics/A11YH1";
import { useTranslation } from "react-i18next";

enum Step {
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  SUCCESS = "SUCCESS",
}

export const ForgotPassword = () => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.header" });
  const [step, setStep] = useState<Step>(Step.FORGOT_PASSWORD);
  const [email, setEmail] = useState("");

  const onForgotPassword = (submittedEmail: string) => {
    setEmail(submittedEmail);
    setStep(Step.RESET_PASSWORD);
  };

  const onResetPassword = () => {
    setStep(Step.SUCCESS);
  };

  return (
    <main className="flex flex-col justify-start min-h-[inherit]">
      <A11yH1>{t("title")}</A11yH1>
      <Header home="/" className="gap-4" hideAccount />
      {step === "FORGOT_PASSWORD" && <ForgotPasswordForm onSubmit={onForgotPassword} />}
      {step === "RESET_PASSWORD" && <ResetPasswordForm onSubmit={onResetPassword} email={email} />}
      {step === "SUCCESS" && <Success />}
    </main>
  );
};
