import { Traveller } from "../../../../../types/travel/Policy";
import { ContentCell, LabelCell, NormalRow, SummaryTable } from "./SummaryTable";
import { useTranslation } from "react-i18next";
import { formatToDisplayDate } from "../../../../../utils/date";

interface IProps {
  traveller: Traveller;
}

const TravellerDetailsSummary = ({ traveller }: IProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.summary.summarySection.travellerDetails" });

  return (
    <SummaryTable className="w-full mb-4 md:mt-4 table-fixed">
      <NormalRow>
        <LabelCell>{t("fullName.label")}</LabelCell>
        <ContentCell>{traveller.fullName}</ContentCell>
      </NormalRow>
      <NormalRow>
        <LabelCell>{t("identificationNumber.label")}</LabelCell>
        <ContentCell>{traveller.identificationNumber}</ContentCell>
      </NormalRow>
      <NormalRow>
        <LabelCell>{t("dateOfBirth.label")}</LabelCell>
        <ContentCell>{formatToDisplayDate(traveller.dateOfBirth)}</ContentCell>
      </NormalRow>
    </SummaryTable>
  );
};

export default TravellerDetailsSummary;
