import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import QuotationSentDialog from "../../../components/basics/QuotationSentDialog";
import { QuotationDetailsSection } from "../../../components/forms/QuotationSection";
import { formatWithThousandSeparator, formatToTwoDecimalsWithThousandSeparator } from "../../../helpers/shared/format";
import { getPlanLabel } from "../../../helpers/group_travel/labels";
import cls from "../../../utils/cls";
import { formatToDisplayQuoteReadableDate } from "../../../utils/date";
import ServerError from "../../errors/ServerError";
import { useEmailPdf } from "../../event_public_liability/components/useQuotation";
import { SummaryTable } from "../../travel/new-policy/steps/summary/SummaryTable";
import { ProductType } from "../../../enums/shared/api/Api";
import { sendEmailPDF } from "../../../helpers/group_travel/api/quoteApi";

interface SummarySectionProps {
  state: Record<string, any>;
  renderSummarySection?: JSX.Element;
}
const getProperty = (key: string) => (state: Record<string, any>) => String(state[key] ? state[key] : "-");
const summaryFields: [label: string | null, property: (state: Record<string, any>) => string, marginTop?: string][] = [
  ["policyHoldersName", getProperty("policyHoldersName")],
  ["quotationNumber", getProperty("quoteNo")],
  ["planName", state => `${getPlanLabel(state.planName as string)}`, "mt-4"],
  ["validityDate", state => `${formatToDisplayQuoteReadableDate(state.validityDate)}`],
  ["coverageStartDate", state => `${formatToDisplayQuoteReadableDate(state.coverageStartDate)}`],
  ["coverageEndDate", state => `${formatToDisplayQuoteReadableDate(state.coverageEndDate)}`],
  ["domicileRegion", getProperty("domicileRegion")],
  ["internationalTravelDays", state => `${formatWithThousandSeparator(state.internationalTravelDays)}`, "mt-4"],
  ["domesticTravelDays", state => `${formatWithThousandSeparator(state.domesticTravelDays)}`],
  ["totalNumbersOfInsured", state => `${formatWithThousandSeparator(state.totalNumbersOfInsured)}`, "mt-4"],
  ["coverageType", getProperty("coverageType")],
];
type RowColumnProps = PropsWithChildren<unknown>;
const Cell: FC<PropsWithChildren<{ className: string }>> = ({ children, className }) => <td className={className}>{children}</td>;
const Row: FC<PropsWithChildren<{ className: string }>> = ({ children, className }) => <tr className={className}>{children}</tr>;
export const NormalRow: FC<PropsWithChildren<{ marginTop?: string }>> = ({ children, marginTop }) => (
  <Row className={cls("flex flex-col md:flex-row", marginTop)}>{children}</Row>
);
export const LabelCell: FC<RowColumnProps> = ({ children }) => <Cell className="md:align-baseline pt-4 md:py-2 md:w-2/4 font-bold">{children}</Cell>;
export const ContentPriceCell: FC<RowColumnProps> = ({ children }) => (
  <Cell className="md:align-baseline md:py-2 md:w-2/4 break-words whitespace-pre-line">{children}</Cell>
);
const TotalLabel: FC<PropsWithChildren<unknown>> = ({ children }) => <div className="min-w-[12rem]">{children}</div>;
const SummaryRow: FC<{ label: string; value: string; marginTop?: string }> = ({ label, value, marginTop }) => (
  // Summary details
  <NormalRow marginTop={marginTop}>
    <LabelCell>{label}</LabelCell>
    <ContentPriceCell>{value}</ContentPriceCell>
  </NormalRow>
);
const CalculationRows: FC<PropsWithChildren<{ margin?: string }>> = ({ margin, children }) => (
  <div className={`flex justify-between flex-wrap mt-4 ${margin}`}>{children}</div>
);
const formatAmount = (value: number | null) => formatToTwoDecimalsWithThousandSeparator(Number(value));
type ProductAU = ProductType.ACTL | ProductType.APAG;
type PlansBreakup = {
  basePrice: number;
  gstAmount: number;
  brokerageRate: number;
  brokerageAmount: number;
  gstRate: number;
  stampDutyRate: number;
  stampDutyAmount: number;
  totalPrice: number;
};
const goUrlPath: Record<ProductAU, string> = {
  [ProductType.APAG]: "/au/group_personal_accident/retrieve-list",
  [ProductType.ACTL]: "/au/group_travel/retrieve-list",
};

const getAdjustSummary = (state: Record<string, any>, renderSummarySection: any) => {
  const adjustedSummaryField = renderSummarySection ? summaryFields.slice(1) : summaryFields;
  const hiddenPaFields = renderSummarySection
    ? ["validityDate", "internationalTravelDays", "domesticTravelDays"]
    : ["internationalTravelDays", "domesticTravelDays"];
  return state.productType === ProductType.APAG
    ? adjustedSummaryField.filter(label => !hiddenPaFields.includes(label[0] as string))
    : adjustedSummaryField.filter(label => !["totalNumbersOfInsured", "coverageType"].includes(label[0] as string));
};
const getPlanPricing = (state: Record<string, any>) => {
  if (state.productType === ProductType.ACTL) {
    return {
      basePrice: state.basePrice,
      gstAmount: state.gstAmount,
      brokerageRate: state.brokerageRate,
      brokerageAmount: state.brokerageAmount,
      gstRate: state.gstRate,
      stampDutyRate: state.stampDutyRate,
      stampDutyAmount: state.stampDutyAmount,
      totalPrice: state.totalPrice,
    };
  } else {
    return {
      basePrice: state.selectedPlan?.price,
      gstAmount: state?.selectedPlan?.gst?.amount,
      brokerageRate: state?.selectedPlan?.brokerage?.percent,
      brokerageAmount: state?.selectedPlan?.brokerage?.amount,
      gstRate: state?.selectedPlan?.gst?.percent,
      stampDutyRate: state?.selectedPlan?.stampDuty?.percent,
      stampDutyAmount: state?.selectedPlan?.stampDuty?.amount,
      totalPrice: state?.selectedPlan?.total,
    };
  }
};

const SummarySection: React.FC<SummarySectionProps> = ({ state, renderSummarySection }) => {
  const { t } = useTranslation("pages", {
    keyPrefix: state.productType === ProductType.APAG ? "groupPersonalAccident.newProduct" : "groupTravel.newProduct",
  });
  const navigate = useNavigate();
  const goToListing = () => navigate(goUrlPath[state.productType as ProductAU]);
  const onHome = () => navigate("/products");

  const planState = getPlanPricing(state) as PlansBreakup;
  const adjustedSummaryFields = getAdjustSummary(state, renderSummarySection);

  const { onClose, showSpinner, showUserInput, serverError, handleLinkClick, setServerError } = useEmailPdf({ quoteId: state.quoteId }, sendEmailPDF);
  return (
    <div className="container-flow flex-grow top-2">
      <div className="mx-[-1.5rem] md:mx-0">
        <div className="bg-white px-8 md:px-[4.375rem] py-8 rounded-lg shadow-xl mt-8">
          <div className="font-semibold md:text-base text-xs opacity-30">{t("summaryLabels.heading")}</div>
          {!renderSummarySection && <QuotationDetailsSection name={"Quotation Summary"} onLinkClick={handleLinkClick} showSpinner={showSpinner} />}
          <SummaryTable className="w-full md:mt-2 table-fixed">
            {adjustedSummaryFields.map(([label, getProp, marginTop], index) => (
              <SummaryRow key={`${label}-${index}`} label={t(`summaryLabels.${label}` as any)} value={getProp(state)} marginTop={marginTop} />
            ))}
            {!!renderSummarySection && renderSummarySection}
          </SummaryTable>
        </div>
        <div className="bg-red rounded-b text-white px-4 md:px-[4.375rem] py-8">
          <CalculationRows margin="mt-0">
            <TotalLabel>{t("summaryLabels.basePremium")}</TotalLabel>
            {`${t("currency")} ${formatAmount(planState.basePrice)}`}
          </CalculationRows>
          <CalculationRows>
            <TotalLabel>{`${t("summaryLabels.brokerageRate")} (${planState.brokerageRate}%)`}</TotalLabel>
            {`${t("currency")} ${formatAmount(planState.brokerageAmount)}`}
            {/* formatted amount */}
          </CalculationRows>
          <CalculationRows>
            {/* formatted with thousand separators */}
            <TotalLabel>{`${t("summaryLabels.gst")} (${planState.gstRate}%)`}</TotalLabel>
            {`${t("currency")} ${formatAmount(planState.gstAmount)}`}
          </CalculationRows>

          <CalculationRows>
            <TotalLabel>{`${t("summaryLabels.stampDuty")} (${planState.stampDutyRate}%)`}</TotalLabel>
            {`${t("currency")} ${formatAmount(planState.stampDutyAmount)}`}
          </CalculationRows>

          <div className="flex justify-between flex-wrap mt-4">
            <div className="font-bold md:text-xl">{t("summaryLabels.totalDue")}</div>
            <div className="font-bold md:text-xl">{`${t("currency")} ${formatAmount(planState.totalPrice)}`}</div>
          </div>
        </div>
      </div>
      {serverError && (
        <ServerError displayType="modal" onClose={() => setServerError(false)} contactDetailsWithMailId={t("serverError.contactDetailsWithMailId")} />
      )}
      {showUserInput && <QuotationSentDialog onClose={onClose} onHome={onHome} onRetrieve={goToListing} error={state.agentMail} t={t} />}
    </div>
  );
};

export default SummarySection;
