import { FC, PropsWithChildren } from "react";
import { H2 } from "../../../components/basics";

type PlotContainerProps = {
  title: string;
};
const PlotContainer: FC<PropsWithChildren<PlotContainerProps>> = ({ title, children }) => (
  <div className="mt-4">
    <H2>{title}</H2>
    <div className="mt-4">{children}</div>
  </div>
);

export default PlotContainer;
