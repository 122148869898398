import { Region } from "../../enums/shared/forms/Region";
import { getRegion } from "../../helpers/shared/getRegion";
import { useAuth } from "../../hooks/useAuth";
import Footer from "../auth/layout/Footer";
import { useTranslation } from "react-i18next";

const EventFooter: React.FC = () => {
  const { user } = useAuth();
  const region = getRegion(user);
  const keyPrefix = region === Region.HK ? `eventplHk.footer` : `eventpl.footer`;
  const { t } = useTranslation("pages", { keyPrefix });
  return <Footer footer={t} />;
};

export default EventFooter;
