type IProps = {
  title: string;
  children?: React.ReactNode;
};

const RedCurve = () => (
  <>
    <div className="hidden md:block -z-10 absolute top-0 left-0 w-7/12 h-[36rem]" />
    <div className="hidden md:block -z-10 absolute top-0 left-1/2 w-2/3 h-[36rem]" style={{ clipPath: "ellipse(90% 100% at 7.5% 0.2%)" }} />
  </>
);

const PrimaryLayout = ({ title, children }: IProps) => {
  return (
    <div className="flex justify-center my-6 md:mt-28 md:mb-44">
      <RedCurve />
      <div className="inline-block flex-grow max-w-lg mx-4 sm:mx-0">
        <h2 className="font-serif font-bold text-black md:text-black text-3xl md:text-4xl mb-7">{title}</h2>
        <div className="bg-white shadow px-6 py-8 md:px-16 md:py-16 mt-3 rounded">{children}</div>
      </div>
    </div>
  );
};

export default PrimaryLayout;
