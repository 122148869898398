import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import ServerError from "../../../errors/ServerError";
import { AccordionSection, Accordion } from "../../../../components/interactives";
import { termsConditionsValidations } from "../../../../helpers/event_public_liability/HkValidations";
import useForm from "../../../../hooks/useForm";
import { HkLiabilityBindContext } from "../../hk/BindQuote";
import { H2 } from "../../../../components/basics";
import classNamePresets from "../../../../utils/classNamePresets";
import TermButtons from "../../components/TermButtons";
import HkExtensions from "../../components/HkExtensions";
import RawHTML from "../../../../components/basics/RawHTML";
import CheckInputField from "../../../../components/form-fields/CheckInputField";
import DeclarationTerms from "../terms/DeclarationTerms";
import ImportantNoticesTerms from "../terms/ImportantNotices";
import AgreementSection from "../../../../components/terms/AgreementSection";

type Props = {
  sendPaymentLink?: VoidFunction | null;
  creditTerms?: VoidFunction | null;
};

const Terms: FC<Props> = ({ creditTerms }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product.terms" });
  const { state, onChange, submitError, setSubmitError, isSubmitting } = useContext(HkLiabilityBindContext);
  const navigateBackUrl = "/event_public_liability/hk/bind/summary";
  const form = useForm(state, termsConditionsValidations, onChange);
  const policyWordingUrl = "/documents/HK_EventPL_PolicyWording.pdf";
  const {
    field: { accepted, acceptedNotice, promotionalServices },
  } = form;
  const handleCreditTerms = () => {
    creditTerms && form.validateForm(creditTerms);
  };
  const personalInformationCollectionUrl = "https://assets.alliedworld.cloud/awac.com/marketing/asiapacretail/hk/pics.pdf";
  const optOutEmail = t("optOutEmailAddress");
  return (
    <>
      <div className="container-flow flex-1">
        <H2 className={classNamePresets.mainHeading}>{t("title")}</H2>
        <HkExtensions />
        <Accordion id="terms-info" omitMarginTop>
          <AccordionSection id="important-notices" label="Important Notices">
            <ImportantNoticesTerms />
          </AccordionSection>
          <AccordionSection id="declaration" label="Declaration">
            <DeclarationTerms />
          </AccordionSection>
          <AccordionSection initialState={"expanded"} id="personal-information-collection-statement" label={t("personalInfoCollection.title")}>
            <RawHTML boldedLinks content={t("personalInfoCollection.header", { personalInformationCollectionUrl })} />
            <CheckInputField
              checked={!state.promotionalServices}
              inline
              id={"promotional-services"}
              {...promotionalServices}
              onValueChange={(value: boolean) => promotionalServices.onValueChange(!value)}
            >
              {t("personalInfoCollection.consent")}
            </CheckInputField>
            <RawHTML boldedLinks content={t("personalInfoCollection.footer", { optOutEmail })} />
          </AccordionSection>
          <AgreementSection t={t} accepted={accepted} acceptedNotice={acceptedNotice} state={state} policyWordingUrl={policyWordingUrl} />
        </Accordion>
        <TermButtons
          creditTerms={creditTerms}
          handleCreditTerms={handleCreditTerms}
          isSubmitting={isSubmitting}
          t={t}
          creditTermLabel={"Submit"}
          navigateBackUrl={navigateBackUrl}
        />
      </div>
      {submitError && <ServerError supportEmailId={t("customerSupportEmailId")} displayType="modal" onClose={() => setSubmitError(false)} />}
    </>
  );
};

export default Terms;
