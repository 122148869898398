import cls from "../../utils/cls";

interface IProps<T> {
  checked?: T;
  onValueChange: (value: boolean) => void;
  id: string;
  label: string;
  className?: string;
  error?: string | boolean | null;
}

const CheckInputAgree = <T extends boolean>({ id, label, onValueChange, checked, className, error }: IProps<T>) => {
  return (
    <>
      <input
        type="checkbox"
        name={id}
        id={id}
        className="peer cursor-pointer relative z-10 w-6 h-6 before:absolute before:inline-block before:border before:rounded-sm before:w-6 before:h-6 before:border-grey-light before:bg-grey-lightest before:transition-all before:duration-200 after:transition-all after:duration-200 icon-check icon-transparent icon-inset-0 after:w-6 after:h-6 checked:icon-3 checked:icon-white checked:before:border-green checked:before:bg-green"
        onChange={e => onValueChange(e.target.checked)}
        checked={checked}
        aria-invalid={!!error}
      />
      <label htmlFor={id} className={cls("cursor-pointer relative -top-1.5 ml", { "!text-red": !!error }, className)}>
        {label}
      </label>
    </>
  );
};

export default CheckInputAgree;
