export const trimAndNormalizeSpaces = (str: string): string => {
  return str.trim().replace(/[ ]{2,}/g, " ");
};
export const removeExtraSpaces =
  (fieldName: string, setFormState: (t: any) => void, validateField: (name: string, value: unknown) => void) =>
  (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue = trimAndNormalizeSpaces(event.target.value);
    setFormState((prevState: any) => ({ ...prevState, [fieldName]: trimmedValue }));
    validateField(fieldName, trimmedValue);
  };
