import { FC } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { getRegion } from "../../../helpers/shared/getRegion";
import { Region } from "../../../enums/shared/forms/Region";

type Props = {
  t: any;
};
const PricingLabels: FC<Props> = ({ t }) => {
  const { user } = useAuth();
  const region = getRegion(user);
  return (
    <div className="mt-4">
      {region !== Region.HK && (
        <div className="flex items-center">
          <p className="font-bold mr-2">{t("PricingLabels.label1")}</p>
          <p>{t("PricingLabels.label2")}</p>
        </div>
      )}
      <div className="mt-4 flex items-stretch pb-8">
        <p className="font-bold mr-2">{t("PricingLabels.label3")}</p>
        <p>{t(`PricingLabels.label4`)}</p>
      </div>
    </div>
  );
};

export default PricingLabels;
