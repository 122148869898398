import { FC, useContext, useEffect } from "react";
import TextInput from "../../../../components/form-fields/TextInput";
import { FormGroup } from "../../../../components/forms";
import useForm from "../../../../hooks/useForm";
import { CreateQuoteContext } from "../../CreateQuote";
import { useTranslation } from "react-i18next";
import AuGroupBusinessFormStepNavigation from "../../../group_travel/Form/CreateQuoteStepNavigation";
import GroupPersonalAccidentHeading from "../../../group_travel/components/Heading";
import AdditionalDetailsOptions from "../../../group_travel/components/AdditionalDetailsOptions";
import { additionalDetailsValidations } from "../../../../helpers/group_personal_accident/validations";
import ReferralDialog from "../../../group_travel/dialog/ReferralDialog";
import ServerError from "../../../errors/ServerError";
import { removeExtraSpaces } from "../../../../helpers/shared/formHandlers";

type Props = {
  onSubmit: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const AdditionalDetails: FC<Props> = ({ onSubmit, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupPersonalAccident.newProduct" });
  const { state, setFormState, onChange, isSubmitting, serverError, setServerError, showReferralDialog, setShowReferralDialog } =
    useContext(CreateQuoteContext);
  const { field, validateField, validateForm } = useForm(state, additionalDetailsValidations, onChange);
  const handleNext = () => validateForm(() => onSubmit?.());

  useEffect(() => {
    if (state.previousIncomeProtectOrPAInsurance === "no") {
      onChange("moreThan25000Claims", null);
    }
  }, [state.previousIncomeProtectOrPAInsurance]);

  return (
    <>
      <div className="container-flow flex-grow">
        <GroupPersonalAccidentHeading title={t("steps.AdditionalDetails")} alt="GroupPersonalAccident" />
        <FormGroup title={t("additionalDetailLabels.policyHolder")} subTitleFontSize="md:text-xl">
          <TextInput
            aria-label="policyholder-name"
            label={t("additionalDetailLabels.policyHolder")}
            value={state.policyHoldersName}
            {...field.policyHoldersName}
            onBlur={removeExtraSpaces("policyHoldersName", setFormState, validateField)}
          />
        </FormGroup>
        <AdditionalDetailsOptions
          formLabel="undergroundOrAbove5mHeight"
          label={t("additionalDetailLabels.undergroundOrAbove5mHeight")}
          value={state.undergroundOrAbove5mHeight}
          {...field.undergroundOrAbove5mHeight}
        />
        <AdditionalDetailsOptions
          formLabel="nonScheduledFlightMoreThan10"
          label={t("additionalDetailLabels.nonScheduledFlightMoreThan10")}
          value={state.nonScheduledFlightMoreThan10}
          {...field.nonScheduledFlightMoreThan10}
        />
        <AdditionalDetailsOptions
          formLabel="over70YearsOld"
          label={t("additionalDetailLabels.over70YearsOld")}
          value={state.over70YearsOld}
          {...field.over70YearsOld}
        />

        <AdditionalDetailsOptions
          formLabel="average55YearsOld"
          label={t("additionalDetailLabels.average55YearsOld")}
          value={state.average55YearsOld}
          {...field.average55YearsOld}
        ></AdditionalDetailsOptions>

        <AdditionalDetailsOptions
          formLabel="previousIncomeProtectOrPAInsurance"
          label={t("additionalDetailLabels.previousIncomeProtectOrPAInsurance")}
          value={state.previousIncomeProtectOrPAInsurance}
          {...field.previousIncomeProtectOrPAInsurance}
        />
        {state.previousIncomeProtectOrPAInsurance === "yes" && (
          <AdditionalDetailsOptions
            formLabel="moreThan25000Claims"
            label={t("additionalDetailLabels.moreThan25000Claims")}
            value={state.moreThan25000Claims}
            {...field.moreThan25000Claims}
          />
        )}
      </div>

      <AuGroupBusinessFormStepNavigation isLoading={isSubmitting} onBack={onPrevious} onSubmit={handleNext} nextButtonLabel="Get Quotation" />

      {showReferralDialog && <ReferralDialog onCancel={() => setShowReferralDialog(false)} showLink={false} />}
      {serverError && (
        <ServerError displayType="modal" onClose={() => setServerError(false)} contactDetailsWithMailId={t("serverError.contactDetailsWithMailId")} />
      )}
    </>
  );
};

export default AdditionalDetails;
