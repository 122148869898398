import { kebabCase } from "lodash";
import cls from "../../utils/cls";
import { ErrorMessage } from "../forms";
import Datepicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FC } from "react";
import { addYears } from "date-fns";

interface IProps {
  name: string;
  value: Date | null;
  onValueChange: (value: Date | null) => void;
  onBlur?: () => void;
  label?: string;
  className?: string;
  error?: string | boolean | null;
}

const dateFormat = "dd-MM-yyyy";

const Container: FC<React.PropsWithChildren<unknown>> = ({ children }) => <div className="z-50 relative">{children}</div>;

const DateInput = ({ name, value, onValueChange, onBlur, label, className, error, ...props }: IProps) => {
  const id = kebabCase(name);

  return (
    <div className="w-full">
      <div className={cls("relative block", "w-full", className)}>
        <div data-testid="date-input">
          <Datepicker
            popperContainer={Container}
            showYearDropdown
            dropdownMode="select"
            popperPlacement="top-end"
            showPopperArrow={false}
            scrollableYearDropdown
            minDate={addYears(new Date(), -100)}
            yearDropdownItemNumber={100}
            placeholderText={label || "Select or enter a date"}
            required
            dateFormat={dateFormat}
            ariaInvalid={error ? "true" : "false"}
            shouldCloseOnSelect
            selected={value}
            onBlur={onBlur}
            onChange={onValueChange}
            className={cls(
              "flex peer bg-white h-15 date cursor-pointer",
              "ringed block px-4 border w-full rounded-md whitespace-nowrap",
              "text-base text-black",
              "inset-0 hide-input-indicators",
              { "pt-5 pb-0 focus:pt-5 focus:pb-0 hover:pt-5 hover:pb-0": !!label },
              { "border-grey-light": !error },
              { "text-red border-red border": !!error }
            )}
            {...props}
          />
          <div className="absolute-middle z-1 right-3 icon-calendar icon-6 icon-black pointer-events-none" />
        </div>
        {label && (
          <label
            htmlFor={id}
            aria-label={label}
            className={cls(
              "absolute z-10 px-4",
              "transition-all duration-200",
              "text-xs top-2 pointer-events-none",
              " peer-placeholder-shown:text-base peer-placeholder-shown:pointer-events-auto peer-placeholder-shown:top-4",
              "peer-focus:text-xs peer-focus:top-2 peer-focus:pointer-events-none",
              "peer-hover:text-xs peer-hover:top-2 peer-hover:pointer-events-none",
              { "text-grey-dark peer-focus:text-grey-dark peer-hover:text-grey-dark peer-placeholder-shown:text-black": !error },
              { "text-red": !!error }
            )}
            data-testid="date-label"
          >
            {label}
          </label>
        )}
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default DateInput;
