import React from "react";
import cls from "../../utils/cls";
import DOMPurify from "dompurify";

/**
 * Ensures no malicious contents comes through when dangerously setting inner HTML
 * @param htmlString the html string to sanitize
 * @returns sanitized html string
 */
export const sanitizeHtml = (htmlString: string): string => DOMPurify.sanitize(htmlString, { ADD_ATTR: ["target"] });

/**
 * The types we want to support as tags
 * dev note: Feel free to expand this if we need more tags as HTMLElement
 */
type SupportedTags = "p" | "article";
type Props = {
  /**
   * Which tag to use for the element. Defaults to 'p'
   */
  tagName?: SupportedTags;
  /**
   * The raw text/html content. It will be interpreted as HTML.
   */
  content: string;
  /**
   * If set to true, links the the raw content will be bolded.
   * default: false
   */
  boldedLinks?: boolean;
  /**
   * If set to true, links do not wrap to a newline.
   * default: true
   */
  wrap?: boolean;
};

/**
 * Applies styling to the html without requiring the user/translations to start writing css
 * @param boldedLinks whether to make links bolded
 * @returns a string of classNames
 */
const getProseClassNames = (boldedLinks = false) =>
  cls("prose prose-a:text-red hover:prose-a:text-red-dark max-w-none", { "prose-a:font-bold": boldedLinks });

/**
 * Interprets the content as a raw string and sets up html nodes for it
 * A tagName can be supplied to wrap the contents in another element. It defaults to a html 'p' tag.
 * This element sanitizes its input before rendering it
 * @see SupportedTags
 */
const RawHTML = ({ tagName: TagName = "p", boldedLinks, wrap = true, content }: Props) => (
  <TagName
    dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }}
    className={cls(getProseClassNames(boldedLinks), { "md:whitespace-nowrap": !wrap })}
  />
);

export default RawHTML;
