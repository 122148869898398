import { FC } from "react";
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns";
import LiabilityBindStepNavigation from "../../bind/LiabilityBindStepNavigation";
import { liabilityBindState, proposerEventDetailsState } from "../../bind/types";
import SummaryHeader from "../../components/SummaryHeader";
import SummaryCalculations from "../../components/SummaryCalculations";
import SummaryMain from "../../components/SummaryMain";
import useOnComponentDidMount from "../../../../hooks/useOnComponentDidMount";
import HkKeyExclusionsList from "../../components/HkKeyExclusionsList";

type Props = {
  onPrevious: VoidFunction | null;
  onNext: VoidFunction | null;
  state: proposerEventDetailsState;
  setFormState: (t: liabilityBindState) => void;
};

const getEndDate = (date: Date | null, duration: number) => {
  return date && addDays(new Date(date), duration - 1);
};

const HkSummary: FC<Props> = ({ onPrevious, onNext, state, setFormState }) => {
  useOnComponentDidMount(() =>
    setFormState({
      ...state,
      coverageEndDate: getEndDate(state.coverageStartDate, state.duration),
    })
  );
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product" });
  return (
    <>
      <SummaryHeader />
      {/* passing exclusion as renderProps to SummaryMain */}
      <SummaryMain
        state={state}
        t={t}
        editProposalDetailsLink="/event_public_liability/hk/bind/proposerDetails"
        renderExclusion={<HkKeyExclusionsList state={state} t={t} />}
      />
      <SummaryCalculations state={state} t={t} />
      <LiabilityBindStepNavigation isLoading={false} onBack={onPrevious} onNext={onNext} />
    </>
  );
};

export default HkSummary;
