import { FC } from "react";
import LiabilityBindStepNavigation from "../../bind/LiabilityBindStepNavigation";
import { liabilityBindState } from "../../bind/types";
import { useTranslation } from "react-i18next";
import Quotation from "../../components/Quotation";
import HkKeyExclusionsList from "../../components/HkKeyExclusionsList";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
  state: liabilityBindState;
};

const eventRetrieveListLink = "/event_public_liability/hk/retrieveList";

const HkQuotationDetails: FC<Props> = ({ onNext, onPrevious, state }) => {
  const { t } = useTranslation("pages", { keyPrefix: "eventplHk.product" });
  return (
    <div className="container-flow flex-1">
      <div className="bg-white px-4 md:pr-24 md:pl-[4.375rem] md:mt-[3.75rem] mt-4 rounded shadow-xl">
        {/* Quotation details for Hk */}
        <Quotation state={state} t={t} eventRetrieveListLink={eventRetrieveListLink} renderExclusion={<HkKeyExclusionsList state={state} t={t} />} />
      </div>
      <LiabilityBindStepNavigation isLoading={false} onBack={onPrevious} onSubmit={onNext} />
    </div>
  );
};

export default HkQuotationDetails;
