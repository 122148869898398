import { Link } from "react-router-dom";
import { FC } from "react";
import ContentContainer from "../../../components/basics/ContentContainer";
import { useTranslation } from "react-i18next";

type FooterProps = {
  footer: any;
};

const TpaFooter = () => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.tpaFooter" });
  return <Footer footer={t} />;
};
export default TpaFooter;
export const Footer: FC<React.PropsWithChildren<FooterProps>> = ({ footer }) => {
  return (
    <footer>
      <div className="py-8 bg-white">
        <ContentContainer omitTopPadding={true}>
          {/* No Note in the footer */}
          <div className="flex flex-col md:flex-row">
            <p className="text-black text-sm">{footer("copyright")}</p>
            <div className="flex md:justify-between text-black text-sm pt-4 md:pt-0">
              <p className="px-2 hidden md:block">|</p>
              {/* Tpa footer */}
              <Link to={footer("privacyPolicyUrl")} className="hover:text-red" target="_blank">
                {footer("button.privacyPolicy")}
              </Link>
              <p className="px-2">|</p>
              <Link to={footer("termsConditionsUrl")} className="hover:text-red" target="_blank">
                {footer("button.termsConditions")}
              </Link>
            </div>
          </div>
        </ContentContainer>
      </div>
    </footer>
  );
};
