import { useTranslation } from "react-i18next";
import { useState } from "react";
import PolicyDetails from "./PolicyDetails";
import { useParams } from "react-router-dom";
import agentApiService, { CheckoutRequest, RenewalPolicyRequest, RenewalPolicyResponse } from "../../../services/pay_now/agentApiService";
import useOnComponentDidMount from "../../../hooks/useOnComponentDidMount";
import Spinner from "../../../components/icons/Spinner";
import PageNotFound from "./PageNotFound";
import PaymentAlreadyMade from "./PaymentAlreadyMade";
import { CreatePolicyType } from "./type";
import { createInitialState } from "./state";
import RenewalHeader from "./RenewalHeader";
import classNamePresets from "../../../utils/classNamePresets";
import { H2 } from "../../../components/basics";
import RenewalLinkExpire from "./RenewalLinkExpire";
import { Footer } from "../../tpa/components/Footer";

export enum PolicyStatus {
  SUCCESS = "success",
  ALREADY_PAID = "alreadyPaid",
  NOT_EXIST = "notExist",
  LINK_EXPIRE = "linkExpired",
}

const getPolicy = (
  policyDetails: RenewalPolicyResponse,
  setPolicyStatus: (status: PolicyStatus | null) => void,
  setState: (state: CreatePolicyType) => void,
  state: CreatePolicyType
) => {
  setPolicyStatus(policyDetails?.status);

  const policyData = policyDetails?.data && policyDetails.data.length > 0 ? policyDetails.data[0] : createInitialState;

  const { insuredName, policyNumber, contactNumber, email, amount } = policyData;

  setState({
    ...state,
    insuredName,
    policyNumber,
    contactNumber,
    email,
    amount: parseFloat(amount.toString()).toFixed(2),
  });
};

const getPolicyDetails = async (
  renewalId: string | undefined,
  setPolicyLoading: (submitting: boolean) => void,
  setPolicyStatus: (status: PolicyStatus | null) => void,
  setState: (state: CreatePolicyType) => void,
  state: CreatePolicyType
) => {
  try {
    setPolicyLoading(true);
    const policyDetails = await agentApiService.renewalPolicy({ renewalId } as RenewalPolicyRequest);
    getPolicy(policyDetails, setPolicyStatus, setState, state);
    setPolicyLoading(false);
  } catch (error) {
    setPolicyLoading(false);
  }
};

const handleSubmit = async (
  renewalId: string | undefined,
  setSubmitting: (submitting: boolean) => void,
  setPolicyStatus: (status: PolicyStatus | null) => void,
  email: string
) => {
  try {
    setSubmitting(true);
    const policyDetails = await agentApiService.renewalPolicy({ renewalId } as RenewalPolicyRequest);
    if (policyDetails?.status === PolicyStatus.ALREADY_PAID) {
      setPolicyStatus(PolicyStatus.ALREADY_PAID);
    } else if (policyDetails?.status === PolicyStatus.NOT_EXIST) {
      setPolicyStatus(PolicyStatus.NOT_EXIST);
    } else {
      const response = await agentApiService.checkout({ renewalId, email } as CheckoutRequest);
      window.location.href = response.url;
    }
    setSubmitting(false);
  } catch (error) {
    console.error(error);
    setSubmitting(false);
  }
};

const RenewalPolicyDetails = () => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.payNow" });
  const { t: footer } = useTranslation("pages", { keyPrefix: "travel.payNow.sgFooter" });
  const [state, setState] = useState<CreatePolicyType>(createInitialState);
  const [policyStatus, setPolicyStatus] = useState<PolicyStatus | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [policyLoading, setPolicyLoading] = useState<boolean>(false);
  const { renewalId } = useParams();

  useOnComponentDidMount(() => {
    renewalId && getPolicyDetails(renewalId, setPolicyLoading, setPolicyStatus, setState, state);
  });

  let content;

  if (policyLoading) {
    content = (
      <div className="py-32 flex justify-around">
        <Spinner width="100" height="100" color="#3F9293" />
      </div>
    );
  } else if (policyStatus === PolicyStatus.NOT_EXIST) {
    content = <PageNotFound />;
  } else if (policyStatus === PolicyStatus.ALREADY_PAID) {
    content = <PaymentAlreadyMade />;
  } else if (policyStatus === PolicyStatus.LINK_EXPIRE) {
    content = <RenewalLinkExpire />;
  } else {
    content = (
      <div className="flex flex-col min-h-screen gap-0">
        <RenewalHeader />
        <div className="container-flow flex-grow md:pt-[64px] pt-8">
          <H2 className={classNamePresets.mainHeading}>{t("title")}</H2>
          <p>{t("subtitle")}</p>
          <PolicyDetails
            t={t}
            state={state}
            setState={setState}
            disabled={true}
            onSubmit={() => handleSubmit(renewalId, setSubmitting, setPolicyStatus, state.email)}
            submitting={submitting}
          />
        </div>
        <Footer footer={footer} />
      </div>
    );
  }
  return content;
};

export default RenewalPolicyDetails;
