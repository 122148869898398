import { Article } from "../../../components/groups";

const HkExtensions = () => (
  <Article>
    <p className="font-bold md:pt-8 pt-4 text-xl">Automatic Extensions</p>
    <p className="font-bold mt-6 mb-2">Extensions:</p>
    <ol className="list-decimal">
      {/* EPL Common KeyHkExtensions */}
      <li>
        Care, Custody and Control Extension (Sublimit : HKD 500,000 per occurrence and in the aggregate ; excess : HKD 10,000 of each and every claim)
      </li>
      <li>Food Poisoning Clause</li>
      <li>Host Liquor Liability Extension (Sublimit: HKD 500,000 per occurrence and in the aggregate)</li>
      <li>Guest Effects Extension (Sublimit: HKD 10,000 per guest and HKD 500,000 in the aggregate)</li>
      <li className="md:pb-13 pb-5">
        Non-Owned & Hired Automobile Liability Extension (Sublimit: HKD 1,000,000 per occurrence and in the aggregate)
      </li>
    </ol>
  </Article>
);

export default HkExtensions;
