import { Dispatch, FC, SetStateAction } from "react";
import TextInput from "../../../components/form-fields/TextInput";
import { FormGroup } from "../../../components/forms";

import UenNumberTextInput from "../../../components/form-fields/UenNumberTextInput";
import { CorrespondenceAddress, proposerEventDetailsState } from "../bind/types";

type Props = {
  state: proposerEventDetailsState;
  field: any;
  setDisableAddress?: Dispatch<SetStateAction<boolean>>;
  setCorrAddressFetchedByUen: Dispatch<SetStateAction<boolean>>;
  setCorrespondenceAddress: Dispatch<SetStateAction<CorrespondenceAddress>>;
  validateField: (name: string, value: unknown, newData?: Record<string, unknown>) => void;
  corrAddressFetchedByUen: boolean;
  t: any;
  setFormState: (t: any) => void;
};

const ProposerCompanyDetails: FC<Props> = ({
  state,
  field,
  setDisableAddress,
  setCorrespondenceAddress,
  validateField,
  t,
  setCorrAddressFetchedByUen,
  corrAddressFetchedByUen,
  setFormState,
}) => {
  return (
    <FormGroup title={t("proposerGroupName")} subtitle={t("subTitle.proposerGroupName")} subTitleFontSize="md:text-lg">
      <TextInput label={t("companyName")} disabled value={state.proposerName} {...field.proposerName} aria-label="proposer-name" readOnly />
      <UenNumberTextInput
        label={t("uenNumber")}
        uenNumber={state.uenNumber}
        state={state}
        uenNumberStatus={state.uenNumberStatus}
        onValueChange={field.uenNumber.onValueChange}
        setUenNumberStatus={field.uenNumberStatus.onValueChange}
        setDisableAddress={setDisableAddress}
        error={field.uenNumber.error}
        setCorrespondenceAddress={setCorrespondenceAddress}
        validate={validateField}
        field={field}
        setCorrAddressFetchedByUen={setCorrAddressFetchedByUen}
        corrAddressFetchedByUen={corrAddressFetchedByUen}
        setFormState={setFormState}
      />
      <TextInput label={t(`mobileNumber`)} value={state.mobileNumber} {...field.mobileNumber} aria-label="proposer-mobileno" />
      <TextInput label={t("email")} value={state.email} {...field.email} aria-label="proposer-email" />
    </FormGroup>
  );
};

export default ProposerCompanyDetails;
