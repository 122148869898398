import { NavigationIndex, NavigationStep } from "../../types/shared/Navigation";
import { Data, Validations } from "../../types/shared/Validation";
import { validate } from "./validation";

type ValidatingPage<T> = {
  data: T;
  validations: Validations<T>;
};

export const getFirstInvalidPageIndex = <T extends Data>(pageIndex: NavigationIndex, validatingPages: ValidatingPage<T>[]) => {
  const invalidPageIndex = validatingPages.findIndex(
    ({ data, validations }, index) => Object.keys(validate(data, validations)).length > 0 && pageIndex > index
  );
  return invalidPageIndex > -1 ? invalidPageIndex : null;
};

export const createNavigationSteps = (routes: string[]): NavigationStep[] => {
  return routes.map((route, index) => ({
    stepIndex: index,
    stepNumber: index + 1,
    route: route,
    prev: routes[index - 1] ? routes[index - 1] : "",
    next: routes[index + 1] ? routes[index + 1] : "",
  }));
};

export const navigateToExternalPage = (url: string) => {
  window.location.replace(url);
};
