import { Data, Validations } from "../../types/shared/Validation";
import { PersonalDetails, ReferralDetails, TermsConditions, TravelDetails, TravelPlan, FeedbackDetails } from "../../types/travel/Policy";
import { getDaysBetweenDates, isDateBefore, today } from "../../utils/date";
import { differenceInYears, isDate } from "date-fns";
import { EMAIL_REGEX } from "../../constants";

export const LETTERS_AND_NUMBERS_REGEX = /^[A-Za-z0-9]+$/;
const isValidDate = (date: unknown): date is Date => isDate(date);

const emailValidation = {
  required: true,
  pattern: {
    regex: EMAIL_REGEX,
    message: "Please check email format",
  },
};

const identificationNumberStatusValidation = {
  required: true,
  pattern: {
    regex: /^valid$/,
    message: "Has to be valid",
  },
};

const validateDateNotInPast = (value: Date) => {
  if (value.getTime() < today().getTime()) {
    return `Must be in the future`;
  }

  return null;
};

const validateDateNotInFuture = (date: Date) => {
  if (date.getTime() > today().getTime()) {
    return `Must be in the past`;
  }

  return null;
};

const dateOfBirthValidation = {
  required: true,
  custom: {
    validate: (date: unknown) => {
      if (!isValidDate(date)) return "Incorrect date format";

      let error: string | null = null;
      error = error || validateDateNotInFuture(date);
      error = error || validateAge(date, 18, "min");
      error = error || validateAge(date, 80, "max");
      return error;
    },
  },
};

export const referralDetailsValidations: Validations<ReferralDetails> = {
  fields: {
    name: {
      required: true,
    },
    email: emailValidation,
  },
};

export const feedbackValidations: Validations<FeedbackDetails> = {
  fields: {
    isEasy: {
      required: true,
    },
    isUseful: {
      required: true,
    },
    scale: {
      required: true,
    },
  },
};

export const identificationNumberValidation = {
  required: true,
  custom: {
    validate: (identificationNumber: unknown, personalDetails: Data) => {
      let error: string | null = null;
      error = error || validateOnlyLettersAndNumbers(identificationNumber as string);
      error = error || validateUniqueIdentificationNumber(identificationNumber as string, personalDetails as PersonalDetails);
      return error;
    },
  },
};

export const personalDetailsValidations: Validations<PersonalDetails> = {
  fields: {
    requester: {
      nested: {
        title: {
          required: true,
        },
        givenName: {
          required: true,
          length: {
            maxLength: 60,
            message: "Text exceeds max. length",
          },
        },
        familyName: {
          required: true,
          length: {
            maxLength: 60,
            message: "Text exceeds max. length",
          },
        },
        phoneNumber: {
          required: true,
          pattern: {
            regex: /^[0-9]{8}$/,
            message: "Must be 8 digits",
          },
        },
        email: emailValidation,
        identificationNumber: identificationNumberValidation,
        identificationNumberStatus: identificationNumberStatusValidation,
        dateOfBirth: dateOfBirthValidation,
      },
    },
    address: {
      nested: {
        postalCode: {
          required: true,
        },
        number: {
          required: true,
          pattern: {
            regex: /^[a-zA-Z0-9!@#$%^&*)/(+=._-]{0,6}$/,
            message: "Maximum 6 digits",
          },
        },
        unitNumber: {
          required: false,
          length: {
            maxLength: 40,
            message: "Text exceeds max. length",
          },
        },
        streetName: {
          required: true,
          length: {
            maxLength: 60,
            message: "Text exceeds max. length",
          },
        },
        floorNumber: {
          required: false,
          length: {
            maxLength: 16,
            message: "Text exceeds max. length",
          },
        },
      },
    },
    travellers: {
      array: {
        fullName: {
          required: true,
          length: {
            maxLength: 60,
            message: "Text exceeds max. length",
          },
        },
        identificationNumber: identificationNumberValidation,
        identificationNumberStatus: identificationNumberStatusValidation,
        dateOfBirth: dateOfBirthValidation,
      },
    },
  },
};

export const planValidations: Validations<TravelPlan> = {
  fields: {
    planName: {
      required: {
        message: "Please select a plan",
      },
    },
  },
};

export const detailsValidations: Validations<TravelDetails> = {
  fields: {
    policyType: {
      required: true,
    },
    startDate: {
      required: true,
      custom: {
        validate: (startDate: unknown) => {
          if (!isValidDate(startDate)) return "Incorrect start date format";
          let error: string | null = null;
          error = error || validateDateNotInPast(startDate);
          error = error || validateMaxDaysFromToday(startDate, 120);
          return error;
        },
      },
    },
    endDate: {
      validateIf: (_, { policyType }) => {
        return policyType !== "annual-trip";
      },
      required: true,
      custom: {
        validate: (endDate, { startDate }) => {
          if (!isValidDate(endDate)) return null;
          let error: string | null = null;

          if (!isValidDate(startDate)) return error;

          error = error || validateReturnDateAfterStartDate(startDate, endDate);
          error = error || validateDuration(startDate, endDate, 182);
          return error;
        },
      },
    },
    location: {
      required: true,
    },
  },
};

export const termsValidations: Validations<TermsConditions> = {
  fields: {
    accepted: {
      required: {
        message: "Please agree to proceed",
      },
    },
  },
};

const validateAge = (date: Date, age: number, type: "max" | "min") => {
  const calculatedAge = calculateAge(date);
  switch (type) {
    case "max":
      return checkMaxAge(calculatedAge, age);
    case "min":
      return checkMinAge(calculatedAge, age);
  }
};

const checkMaxAge = (inputAge: number, maxAge: number) => {
  return inputAge >= maxAge ? `Maximum age is ${maxAge}` : null;
};

const checkMinAge = (inputAge: number, minAge: number) => {
  return inputAge < minAge ? `Minimum age is ${minAge}` : null;
};

const calculateAge = (date: Date): number => {
  return differenceInYears(new Date(), date);
};

const validateMaxDaysFromToday = (date: Date, days: number) => {
  const maxDate = today();
  maxDate.setDate(maxDate.getDate() + days - 1);

  if (date && date.getTime() > maxDate.getTime()) {
    return `Must be within ${days} days from today`;
  }

  return null;
};

const validateDuration = (startDateString: Date, endDateString: Date, maxDays: number) => {
  const days = getDaysBetweenDates(startDateString, endDateString);

  // daysDiff + 1 because if you leave today and return today, the duration is 1 day (not 0)
  if (days !== null && days + 1 > maxDays) {
    return `Duration exceeds ${maxDays} days`;
  }

  return null;
};

const validateReturnDateAfterStartDate = (startDate: Date, endDate: Date) => {
  if (isDateBefore(endDate, startDate)) {
    return "Must be after departure date";
  }

  return null;
};

const validateOnlyLettersAndNumbers = (value: string) => {
  if (!value.match(LETTERS_AND_NUMBERS_REGEX)) {
    return "Only letters and numbers are allowed";
  }

  return null;
};

const getNumberOfOccurrences = (values: string[], value: string) => {
  return values.reduce((count, v) => (v === value ? count + 1 : count), 0);
};

const validateUniqueIdentificationNumber = (identificationNumber: string, personalDetails: PersonalDetails) => {
  const ids: string[] = [
    personalDetails.requester.identificationNumber,
    personalDetails.travellers?.[0]?.identificationNumber,
    personalDetails.travellers?.[1]?.identificationNumber,
  ];

  if (identificationNumber !== "" && identificationNumber !== undefined && getNumberOfOccurrences(ids, identificationNumber) > 1) {
    return "Duplicated NRIC/Passport/FIN";
  }

  return null;
};
