import axios from "axios";
import { useState, useMemo, useEffect } from "react";
import { addAuthorizationHeader } from "../../../helpers/shared/authorization";
import { HEADER_CONTENT_TYPE } from "../../../helpers/travel/new-policy/api/http";
import { matchesFilters } from "../plotData";
import { Filters, Row } from "../types";

export const useData = (filters: Filters) => {
  const [data, setData] = useState<Row[]>([]);
  const [status, setStatus] = useState<string>("not-loaded");
  const filteredData = useMemo(() => data.filter(row => matchesFilters(row, filters)), [data, filters]);

  useEffect(() => {
    if (status !== "not-loaded") {
      return;
    }
    setStatus("loading");
    addAuthorizationHeader(HEADER_CONTENT_TYPE)
      .then(headers => axios.get<Row[]>(`${process.env.REACT_APP_BACKEND_API}v1/admin/dashboard`, { headers }))
      .then(response => {
        setStatus("loaded");
        setData(response.data);
      })
      .catch(() => {
        setStatus("error");
      });
  }, [status]);
  return { data: filteredData, status };
};
