import { FC } from "react";
import { Article } from "../../components/groups";
import RawHTML from "../../components/basics/RawHTML";

interface ThankYouMessageProps {
  t: any;
  additionalcontent?: boolean;
  applyTitleStyle?: boolean;
}

const ThankYouMessage: FC<ThankYouMessageProps> = ({ t, additionalcontent, applyTitleStyle }) => (
  <div className="container-flow flex-grow mt-6 md:mt-[8rem]">
    <div className="flex gap-10">
      <div className="w-full">
        <h2 className={applyTitleStyle ? "font-serif font-bold mb-4 text-6xl" : "font-serif font-bold text-4xl mb-4"}>{t("success.title")}</h2>
        <Article>
          {additionalcontent && <p className="mb-0 mt-4">{t("success.content1")}</p>}
          <p className="mb-0 mt-4">
            <RawHTML boldedLinks={true} content={t(`success.content`)} />
          </p>
          <p className="mt-8 mb-8 md:mb-0">
            <div>{t("success.sub-title")}</div>
            <div>{t("success.paragraph")}</div>
          </p>
        </Article>
      </div>
    </div>
  </div>
);

export default ThankYouMessage;
