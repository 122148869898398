import { BasicDetailsState, GroupTravelFormState } from "../../pages/group_travel/Form/types";
import { Validations } from "../../types/shared/Validation";
import { isDate } from "date-fns";
import { GroupTravelBindState } from "../../pages/group_travel/bind/types";
import { validateDuration, validateStartCoverageDate, validateTravelDaysNotZero } from "../group_personal_accident/dateValidations";

const isValidDate = (date: unknown): date is Date => isDate(date);

export const basicDetailsValidations: Validations<BasicDetailsState> = {
  fields: {
    familyTrust: {
      required: true,
    },
    annualRenewableBusiness: {
      required: true,
    },
    donNotTravelAdvice: {
      required: true,
    },
  },
};

export const policyDetailsValidation: Validations<GroupTravelFormState> = {
  fields: {
    exemptedStampDuty: {
      required: true,
    },

    domicileRegion: {
      validateIf: (_, { exemptedStampDuty }) => {
        return exemptedStampDuty === "no";
      },
      required: true,
    },

    internationalTravelDays: {
      required: true,
      custom: {
        validate: (internationalTravelDays, { domesticTravelDays }) => {
          let error: string | null = null;
          error = error || validateTravelDaysNotZero(internationalTravelDays as number, domesticTravelDays as number, "international");
          return error;
        },
      },
    },
    domesticTravelDays: {
      required: true,
      custom: {
        validate: (domesticTravelDays, { internationalTravelDays }) => {
          let error: string | null = null;
          //  Check if both the travel days are not zero
          error = error || validateTravelDaysNotZero(domesticTravelDays as number, internationalTravelDays as number, "domestic");
          return error;
        },
      },
    },
    coverageStartDate: {
      required: true,
      custom: {
        validate: coverageStartDate => {
          let error: string | null = null;
          if (!isValidDate(coverageStartDate)) return error;
          error = error || validateStartCoverageDate(coverageStartDate);
          return error;
        },
      },
    },
    coverageEndDate: {
      required: true,
      custom: {
        validate: (coverageEndDate, { coverageStartDate }) => {
          let error: string | null = null;
          if (!isValidDate(coverageEndDate)) return error;
          if (!isValidDate(coverageStartDate)) return error;
          error = error || validateDuration(coverageStartDate as Date, coverageEndDate as Date, 179, 546);
          return error;
        },
      },
    },
  },
};

export const planValidations: Validations<GroupTravelFormState> = {
  fields: {
    planName: {
      required: {
        message: "Please select a plan",
      },
    },
  },
};
export const additionalDetailsValidations: Validations<GroupTravelFormState> = {
  fields: {
    policyHoldersName: {
      required: true,
      length: {
        maxLength: 50,
        message: "Text exceeds max. length",
      },
    },
    excessClaimsCover: {
      required: true,
    },
    exceedDuration: {
      required: true,
    },
    morePeopleInTrip: {
      required: true,
    },
    engagedActivities: {
      required: true,
    },
    privateTravel: {
      required: true,
    },
    collectiveFlights: {
      required: true,
    },
  },
};
export const policyHolderDetailsValidations: Validations<GroupTravelBindState> = {
  fields: {
    policyHoldersName: {
      required: true,
      length: {
        maxLength: 50,
        message: "Text exceeds max. length",
      },
    },
    australiaBusinessNo: {
      required: true,
    },
    streetNumber: {
      required: false,
      length: {
        maxLength: 6,
        message: "Text exceeds max. length",
      },
    },
    unitNumber: {
      required: false,
      length: {
        maxLength: 6,
        message: "Text exceeds max. length",
      },
    },
    floorNumber: {
      required: false,
      length: {
        maxLength: 6,
        message: "Text exceeds max. length",
      },
    },

    streetName: {
      required: true,
      length: {
        maxLength: 30,
        message: "Text exceeds max. length",
      },
    },

    suburb: {
      required: true,
      length: {
        maxLength: 18,
        message: "Text exceeds max. length",
      },
    },

    state: {
      required: true,
    },
    postalCode: {
      required: true,
      pattern: {
        regex: /^[0-9]{4}$/,
        message: "Must be 4 digits",
      },
    },
  },
};
export const termsConditionsValidations: Validations<GroupTravelBindState> = {
  fields: {
    accepted: {
      required: {
        message: "Please agree to proceed",
      },
    },
    acceptedNotice: {
      required: {
        message: "Please agree to proceed",
      },
    },
  },
};
