import { Auth } from "aws-amplify";
import { useCallback, useState, VFC } from "react";
import TextInput from "../../../components/form-fields/TextInput";
import { ErrorMessage } from "../../../components/forms";
import SecondaryLayout from "../../../components/authentication/layout/SecondaryLayout";
import { errorMessage, isPendingLoginExpiredError } from "../../../utils/error";
import { OnLoginResponse } from "../Login";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import SessionExpiredDialog from "../../../components/authentication/SessionExpiredDialog";
import useWithAsyncLoadingIndicator from "../../../hooks/useWithAsyncLoadingIndicator";

type MfaType = "SMS_MFA" | "SOFTWARE_TOKEN_MFA";
type MfaTranslationKey = "sms" | "software";

interface IProps {
  onLoginResponse: OnLoginResponse;
  cognitoUser: object;
  mfaType: MfaType;
  onLoginReset: VoidFunction;
}

const MfaText = ({ type }: { type: string }) => {
  const { t } = useTranslation("pages", { keyPrefix: "auth.login.mfaForm" });

  const text = { SMS_MFA: "sms", SOFTWARE_TOKEN_MFA: "software" }[type] as MfaTranslationKey;
  return <p>{t(`form.mfaType.${text}`)}</p>;
};

type FormProps = {
  error: string;
  setError: (error: string) => void;
  cognitoUser: object;
  mfaType: MfaType;
  onLoginResponse: OnLoginResponse;
  handleError: (error: unknown) => void;
};

const Form: VFC<FormProps> = ({ error, setError, mfaType, cognitoUser, onLoginResponse, handleError }) => {
  const [state, setState] = useState({ code: "" });
  const { t } = useTranslation("pages", { keyPrefix: "auth.login.mfaForm" });

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (state.code === "") return setError(errorMessage(t("emptyError")));
      try {
        const response = await Auth.confirmSignIn(cognitoUser, state.code, mfaType);
        return onLoginResponse({ ...response, challengeName: null });
      } catch (err) {
        handleError(err);
      }
    },
    [state, setError, onLoginResponse, cognitoUser, mfaType, handleError, t]
  );

  const { isLoading, action: onSubmit } = useWithAsyncLoadingIndicator(handleSubmit);

  return (
    <form onSubmit={onSubmit} className="space-y-8">
      <MfaText type={mfaType} />
      <div className="space-y-4 md:max-w-xs">
        <TextInput autoFocus label={t("form.code")} value={state.code} onValueChange={value => setState({ ...state, code: value })} />
        <ErrorMessage error={error} />
      </div>
      <LoadingButton isLoading={isLoading} loadingText="Authenticating" type={ButtonType.PRIMARY}>
        {t("form.button.login")}
      </LoadingButton>
    </form>
  );
};

export const MfaForm = ({ onLoginReset, ...props }: IProps) => {
  const [error, setError] = useState("");
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const { t } = useTranslation("pages", { keyPrefix: "auth.login.mfaForm" });

  const handleError = useCallback(
    (err: unknown) => {
      if (isPendingLoginExpiredError(err)) {
        setIsSessionExpired(true);
      } else {
        setError(errorMessage(err));
      }
    },
    [setIsSessionExpired, setError]
  );

  const goToLogin = useCallback(() => {
    setIsSessionExpired(false);
    onLoginReset();
  }, [onLoginReset, setIsSessionExpired]);

  return (
    <SecondaryLayout title={t("title")}>
      {isSessionExpired && <SessionExpiredDialog onClose={goToLogin} />}
      <Form error={error} setError={setError} handleError={handleError} {...props} />
    </SecondaryLayout>
  );
};
