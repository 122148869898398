import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../../../components/basics";
import SelectInput from "../../../../components/form-fields/SelectInput";
import TextInput from "../../../../components/form-fields/TextInput";
import { FormGroup } from "../../../../components/forms";
import { Districts } from "../../../../enums/shared/forms/Districts";
import { PersonalTitle } from "../../../../enums/shared/forms/NewPolicy";
import { Territories } from "../../../../enums/shared/forms/Territories";
import { personalValidation } from "../../../../helpers/professional_indemnity/validations";
import useForm from "../../../../hooks/useForm";
import classNamePresets from "../../../../utils/classNamePresets";
import { IndemnityFormContext } from "../../NewProduct";
import IndemnityFormStepNavigation from "../IndemnityFormStepNavigation";
import { removeExtraSpaces } from "../../../../helpers/shared/formHandlers";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const PersonalDetails: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.newProduct" });
  const { state, setFormState, onChange } = useContext(IndemnityFormContext);
  const form = useForm(state, personalValidation, onChange);
  const {
    field: {
      salutation,
      surname,
      givenName,
      otherNames,
      mobileNumber,
      email,
      agentCode,
      officeLocation,
      districtCode,
      districtName,
      address,
      building,
      streetNumberAndName,
      district,
      territory,
    },
    validateField,
  } = form;

  const handleNext = () => {
    onNext && form.validateForm(onNext);
  };

  return (
    <>
      <div className="container-flow flex-1">
        <H2 className={classNamePresets.mainHeading}>{t("steps.personalDetails")}</H2>

        <div className="flex justify-between flex-wrap">
          <div className="flex-1">
            <div className="min-w-[20rem]">
              <FormGroup hasVerticalFieldsOrientation title={t("formGroupLabels.detailsOfInsured")}>
                <SelectInput
                  aria-label="title"
                  label={t("formLabels.salutation")}
                  value={state.salutation}
                  options={Object.values(PersonalTitle)}
                  {...salutation}
                />

                <TextInput
                  aria-label="surname"
                  label={t("formLabels.surname")}
                  value={state.surname}
                  {...surname}
                  onBlur={removeExtraSpaces("surname", setFormState, validateField)}
                />
                <TextInput
                  aria-label="given-name"
                  label={t("formLabels.givenName")}
                  value={state.givenName}
                  {...givenName}
                  onBlur={removeExtraSpaces("givenName", setFormState, validateField)}
                />
                <TextInput
                  aria-label="other-names"
                  label={`${t("formLabels.otherNames")} ${t("formProperties.optional")}`}
                  value={state.otherNames}
                  {...otherNames}
                  onBlur={removeExtraSpaces("otherNames", setFormState, validateField)}
                />
                <TextInput aria-label="mobile-number" label={t("formLabels.mobileNumber")} value={state.mobileNumber} {...mobileNumber} />
                <TextInput aria-label="email" label={t("formLabels.email")} value={state.email} {...email} />
              </FormGroup>

              <FormGroup hasVerticalFieldsOrientation>
                <TextInput aria-label="agent-code" label={t("formLabels.agentCode")} value={state.agentCode} {...agentCode} />
                <TextInput
                  aria-label="office-location"
                  label={t("formLabels.officeLocation")}
                  value={state.officeLocation}
                  {...officeLocation}
                  onBlur={removeExtraSpaces("officeLocation", setFormState, validateField)}
                />
                <TextInput aria-label="district-code" label={t("formLabels.districtCode")} value={state.districtCode} {...districtCode} />
                <TextInput
                  aria-label="district-name"
                  label={t("formLabels.districtName")}
                  value={state.districtName}
                  {...districtName}
                  onBlur={removeExtraSpaces("districtName", setFormState, validateField)}
                />
              </FormGroup>

              <FormGroup hasVerticalFieldsOrientation title={t("formGroupLabels.addressOfInsured")}>
                <TextInput
                  aria-label="address"
                  label={`${t("formLabels.roomFloorBlock")} ${t("formProperties.optional")}`}
                  value={state.address}
                  {...address}
                  onBlur={removeExtraSpaces("address", setFormState, validateField)}
                />
                <TextInput
                  aria-label="building"
                  label={`${t("formLabels.building")} ${t("formProperties.optional")}`}
                  value={state.building}
                  {...building}
                  onBlur={removeExtraSpaces("building", setFormState, validateField)}
                />
                <TextInput
                  aria-label="street-number-and-name"
                  label="Street Number, Street Name"
                  value={state.streetNumberAndName}
                  {...streetNumberAndName}
                  onBlur={removeExtraSpaces("streetNumberAndName", setFormState, validateField)}
                />
                <SelectInput
                  aria-label="district"
                  label={t("formLabels.district")}
                  value={state.district}
                  options={Object.values(Districts).sort()}
                  {...district}
                />
                <SelectInput
                  aria-label="territory"
                  label={t("formLabels.territory")}
                  value={state.territory}
                  options={Object.values(Territories)}
                  {...territory}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <IndemnityFormStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
    </>
  );
};

export default PersonalDetails;
