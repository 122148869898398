import { FC } from "react";
import Article from "../../../components/basics/Article";
import PolicyWordings from "../PolicyWordings";
import { liabilityFormState } from "../form/types";

type Props = {
  state: liabilityFormState;
  t: any;
};

const KeyExclusionsList: FC<Props> = ({ state, t }) => {
  const policyWordingUrl = "/documents/EventPL_PolicyWording.pdf";
  return (
    <Article>
      <p className="font-bold mt-6 mb-2">Key Exclusions:</p>
      <ol className="list-decimal pl-4">
        <li>Exclude any claims arising from performance artist and 3rd party vendors/contractors</li>
        <li>Exclude any liability to volunteers, performance artist and 3rd party vendors/contractors</li>
        <li>
          Exclude any liability arising from the use of any explosives, flashes, smoke, flames, fireworks or any other pyrotechnics driven effect
        </li>
        <li>Products Liability and/or Completed Operations Exclusion</li>
        <li>Cyber Exclusion</li>
        <li>Infectious Disease Exclusion</li>
        {state.setupAndDismantling === "no" && <li>Exclude any claims arising from setting up and/or dismantling of event venue</li>}
        {state.volunteers === "no" && <li>Exclude any claims arising from volunteers</li>}
      </ol>
      <PolicyWordings t={t} policyWordingUrl={policyWordingUrl} />
    </Article>
  );
};
export default KeyExclusionsList;
