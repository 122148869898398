import { endOfMonth, format, getMonth } from "date-fns";

// JS months starts at index 0.. so january is 0, december is 11
const MONTH_AUGUST = 7;
const MONTH_SEPTEMBER = 8;

const getEndOfPolicyPeriod = (year: number) => endOfMonth(new Date(year, MONTH_AUGUST, 1));

export const formatDate = (date: Date) => format(date, "MMMM d yyyy");

export const getCurrentPolicyPeriod = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  if (getMonth(currentDate) === MONTH_AUGUST) {
    return { startDate: new Date(currentYear, MONTH_SEPTEMBER, 1), endDate: getEndOfPolicyPeriod(currentYear + 1) };
  }
  if (getMonth(currentDate) > MONTH_AUGUST) {
    return { startDate: new Date(), endDate: getEndOfPolicyPeriod(currentYear + 1) };
  }
  return { startDate: new Date(), endDate: getEndOfPolicyPeriod(currentYear) };
};

export const getRenewalPolicyPeriod = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return { startDate: new Date(currentYear, MONTH_SEPTEMBER, 1), endDate: getEndOfPolicyPeriod(currentYear + 1) };
};
