import { Auth } from "aws-amplify";
import { useState } from "react";
import TextInput from "../../../components/form-fields/TextInput";
import { ErrorMessage } from "../../../components/forms";
import SecondaryLayout from "../../../components/authentication/layout/SecondaryLayout";
import { errorMessage } from "../../../utils/error";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/basics/Button";
import { ButtonType } from "../../../helpers/shared/buttonStyling";

interface IProps {
  email: string;
  onSubmit: () => void;
}

/* An attempt to block autofill.... see https://gist.github.com/niksumeiko/360164708c3b326bd1c8?permalink_comment_id=3925346#gistcomment-3925346
 * Yes I know how horrible this is
   Adds 2 elements to which chrome will autofill credentials, keep them shown so they're not ignored... Hide them outside of the screen
 */
const BrowserAutoFillBlocker = () => (
  <div style={{ zIndex: "-1" }} className="fixed left-[-5000px] h-1 w-1">
    <input autoComplete="off" aria-hidden="true" tabIndex={-1} type="text" name="fakeuseremail" />
    <input autoComplete="off" aria-hidden="true" tabIndex={-1} type="password" name="fakeuserpassword" />
  </div>
);
const userCode = "UserNotFoundException";
const verificationErrMsg = "Invalid verification code provided, please try again.";
const getModifiedErrMsg = (error: any) => (error.code === userCode ? verificationErrMsg : errorMessage(error));
export const ResetPasswordForm = ({ email, onSubmit }: IProps) => {
  const [state, setState] = useState({ code: "", password: "", passwordConfirmation: "" });
  const [error, setError] = useState("");
  const { t } = useTranslation("pages", { keyPrefix: "auth.forgot.resetPasswordForm" });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (state.password !== state.passwordConfirmation) {
      setError(t("matchingError"));
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(email, state.code, state.password);
      onSubmit();
    } catch (err) {
      setError(getModifiedErrMsg(err));
    }
  };

  return (
    <SecondaryLayout title={t("title")}>
      <form onSubmit={handleSubmit} className="space-y-8" autoComplete="off">
        <p>{t("content")}</p>
        <div className="space-y-4 md:max-w-xs">
          <BrowserAutoFillBlocker />
          <TextInput
            name="code"
            autoComplete="off"
            label={t("form.code")}
            value={state.code}
            onValueChange={value => setState({ ...state, code: value })}
          />
          <TextInput
            name="password"
            autoComplete="off"
            type="password"
            label={t("form.newPassword")}
            value={state.password}
            onValueChange={value => setState({ ...state, password: value })}
          />
          <TextInput
            type="password"
            autoComplete="off"
            name="password_confirmation"
            label={t("form.confirmPassword")}
            value={state.passwordConfirmation}
            onValueChange={value => setState({ ...state, passwordConfirmation: value })}
          />
          <ErrorMessage error={error} />
        </div>
        <Button type={ButtonType.PRIMARY}>{t("button.submit")}</Button>
      </form>
    </SecondaryLayout>
  );
};
