import { FC } from "react";
import eventPl from "../../../assets/icons/eventpl.svg";

type Props = {
  headingTitle: string;
};

const Heading: FC<Props> = ({ headingTitle }) => {
  return (
    <div className="flex items-center justify-between md:text-4xl text-3xl font-serif font-bold text-black">
      <span>{headingTitle} </span>
      <div className="hidden md:block">
        <img src={eventPl} alt="event public liability logo" className="md:ml-2 h-[50px]" />
      </div>
    </div>
  );
};

export default Heading;
