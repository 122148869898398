import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/logo.svg";
import logoLg from "../../../assets/images/logo-lg.svg";
import briefcaseImage from "../../../assets/icons/briefcase.svg";
const RenewalHeader = () => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity" });
  return (
    <div className="flex justify-between container-lg bg-white">
      <div className="h-16 md:h-24 py-4 flex-shrink-0 mr-12">
        <picture>
          <source media="(min-width:768px)" srcSet={logoLg} />
          <img src={logo} alt="Allied World logo" className="h-full" />
        </picture>
      </div>
      <div className="my-auto text-xl font-serif md:text-3xl whitespace-nowrap hidden md:block ml-auto">{t("header.title")}</div>
      <img src={briefcaseImage} alt="professional indemnity logo" className="ml-2 h-10 md:h-16 mt-4" />
    </div>
  );
};
export default RenewalHeader;
