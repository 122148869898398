import { ReactNode } from "react";
import cls from "../../utils/cls";

interface CheckProps {
  checked?: boolean;
  onValueChange: (value: boolean) => void;
  name: string;
  id?: string;
  label?: string;
  rightLabel?: string;
  className?: string;
  children?: ReactNode;
  inline?: boolean;
}

const CheckInputField = ({ name, id, onValueChange, checked, className, children, inline = false, ...props }: CheckProps) => {
  return (
    <div className={className}>
      <label
        className={cls("flex ", "cursor-pointer", "bg-white", "items-start", {
          "border border-grey-light ringed rounded-md": !inline,
        })}
      >
        <input
          type="checkbox"
          name={name}
          id={id}
          className={cls(
            "peer",
            "relative",
            "after:cursor-pointer",
            "w-6 h-6 mt-4 ml-4 mr-2",
            "before:absolute before:inline-block",
            "before:border before:rounded-sm",
            "before:w-6 before:h-6",
            "before:border-grey-light before:bg-grey-lightest",
            "before:transition-all before:duration-200",
            "after:transition-all after:duration-200",
            "icon-check icon-3 icon-transparent icon-inset-0",
            { "icon-3 icon-white": checked || false },
            { "before:border-green before:bg-green": checked || false }
          )}
          onChange={e => onValueChange(e.target.checked)}
          checked={checked}
        />
        <span
          className={cls(
            "pr-4 py-4 pl-2",
            "text-base text-black",
            "transition-all duration-200",
            "cursor-pointer relative ml peer-checked:font-bold",
            "peer-focus:after:absolute peer-focus:after:border-green peer-focus:after:border-2 peer-focus:after:-left-1 peer-focus:after:-inset-2"
          )}
          {...props}
        >
          {children}
        </span>
      </label>
    </div>
  );
};

export default CheckInputField;
