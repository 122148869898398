import { useTranslation } from "react-i18next";
import { FormGroup } from "../../../components/forms";
import { RadioInput } from "../../../components/form-fields";
import { FC, useContext, useState } from "react";
import { EventTypeInfo } from "../more-info/EventTypeInfo";
import DronCoverInfo from "../more-info/DroneCoverInfo";
import AttendessInfo from "../more-info/AttendeesInfo";
import { SetupDismantlingInfo } from "../more-info/SetupDismantlingInfo";
import { ClaimsCoverInfo } from "../more-info/ClaimsCoverInfo";
import LiabilityFormStepNavigation from "../LiabilityFormStepNavigation";
import { IOption } from "../../../components/form-fields/RadioInput";
import useForm from "../../../hooks/useForm";
import { LiabilityFormContext } from "../CreateQuote";
import { detailsValidations } from "../../../helpers/event_public_liability/validations";
import Heading from "../components/Heading";
import HkDetailsDetails from "../components/Details";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};
const Details: FC<Props> = ({ onNext, onPrevious }) => {
  const [moreInfo, setMoreInfo] = useState<string | null>(null);
  const { t } = useTranslation("pages", { keyPrefix: "eventpl.product.details" });
  const { state, setFormState, onChange } = useContext(LiabilityFormContext);
  const form = useForm(state, detailsValidations, onChange);
  const {
    field: { proposerName, eventTitle, eventType, duration, attendees, drone, setupAndDismantling, volunteers },
    validateField,
  } = form;
  const handleNext = () => {
    onNext && form.validateForm(onNext);
  };
  const yesNoOptions: IOption<"yes" | "no">[] = [
    { value: "yes", label: "Yes, see more information for details" },
    { value: "no", label: "No" },
  ];
  return (
    <>
      <div className="container-flow flex-grow md:pt-[72px] pt-8">
        <Heading headingTitle={t("title")} />
        <HkDetailsDetails
          t={t}
          proposerName={proposerName}
          eventTitle={eventTitle}
          eventType={eventType}
          duration={duration}
          attendees={attendees}
          state={state}
          setMoreInfo={setMoreInfo}
          setFormState={setFormState}
          validateField={validateField}
        />
        <FormGroup title={t("drone")} onMoreInfo={() => setMoreInfo("dron-cover")}>
          <RadioInput
            aria-label="drone"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            {...drone}
            value={state.drone}
          />
        </FormGroup>
        <FormGroup
          title={t("setupAndDismantling")}
          subtitle={t("subTitle.setupAndDismantling")}
          subTitleFontSize="md:text-lg w-72"
          onMoreInfo={() => setMoreInfo("setup-dismantling")}
        >
          <RadioInput aria-label="setupAndDismantling" {...setupAndDismantling} value={state.setupAndDismantling} options={yesNoOptions} />
        </FormGroup>
        <FormGroup title={t("volunteers")} onMoreInfo={() => setMoreInfo("claims-cover")}>
          <RadioInput aria-label="volunteers" options={yesNoOptions} {...volunteers} value={state.volunteers} />
        </FormGroup>
      </div>
      <LiabilityFormStepNavigation isLoading={false} onBack={onPrevious} onNext={handleNext} />
      <EventTypeInfo show={moreInfo === "event-type"} onClose={() => setMoreInfo(null)} />
      <AttendessInfo show={moreInfo === "attendees-cover"} onClose={() => setMoreInfo(null)} />
      <DronCoverInfo show={moreInfo === "dron-cover"} onClose={() => setMoreInfo(null)} />
      <SetupDismantlingInfo show={moreInfo === "setup-dismantling"} onClose={() => setMoreInfo(null)} />
      <ClaimsCoverInfo show={moreInfo === "claims-cover"} onClose={() => setMoreInfo(null)} />
    </>
  );
};

export default Details;
