import { FC, useMemo } from "react";
import { QuoteListHeader } from "./QuoteListHeader";
import TableCell from "./TableCell";
import { FilterPanelState } from "../../../../../components/interactives/FilterPanel";

interface NoRecordFoundRowProps {
  colSpan: number;
}

const NoRecordFoundRow: FC<NoRecordFoundRowProps> = ({ colSpan }) => (
  <tr className="flex justify-between pl-4 md:pl-[1.875rem] pr-[1.125rem] py-2 text-sm bg-white border-b border-color">
    <td className={`font-bold md:text-left`} colSpan={colSpan}>
      No Record Found!
    </td>
  </tr>
);

interface QuotationTableProps {
  state: any[] | [];
  filterState: FilterPanelState;
}

const generateHeadersList = () => [
  { name: "POLICYHOLDER'S NAME", className: "md:w-[15rem] w-[4.875rem] hidden md:block" },
  { name: "POLICYHOLD..", className: "w-[5.315rem] md:hidden" },
  { name: "QUOTE/POLICY NO.", className: "w-[9rem] hidden md:block" },
  { name: "QUOTE/POLI..", className: "w-[5.315rem] md:hidden" },
  { name: "PRODUCT TYPE", className: "w-[10rem] hidden md:block" },
  { name: "VALIDITY DATE", className: "w-[5.125rem] hidden md:block" },
  { name: "STATUS", className: "w-[5.125rem]" },
  { name: "" },
];

const QuotationTable: FC<QuotationTableProps> = ({ state }) => {
  const headersList = useMemo(() => generateHeadersList(), []);

  return (
    <table className="flex flex-col table-shadow overflow-hidden">
      <QuoteListHeader
        headersList={headersList}
        className="flex justify-between mt-8 pl-4 md:pl-[1.875rem] 
        pr-[1.125rem] pt-2 pb-2 text-left md:text-[0.625rem]
         text-[0.7rem] th-text-color leading-3 font-bold"
      />
      <tbody>
        {state && state.length <= 0 ? (
          <NoRecordFoundRow colSpan={headersList.length} />
        ) : (
          state?.map((row, index) => <TableCell key={index} row={row} />)
        )}
      </tbody>
    </table>
  );
};

export default QuotationTable;
