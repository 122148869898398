import { LoginState, useAuth } from "../../hooks/useAuth";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import NotFound from "../../pages/errors/NotFound";
import { Role } from "../../enums/shared/forms/Role";
import { getRole } from "../../helpers/shared/getRole";

interface IProps {
  redirectPath: string;
}

const ProtectedRoute: FC<React.PropsWithChildren<IProps>> = ({ redirectPath }) => {
  const { user } = useAuth();
  const isTPARole = (role: string) => role !== Role.Unknown && role === Role.TPA;
  const role = getRole(user);

  if (isTPARole(role)) return <NotFound homePage="/tpa" />;
  if (user === LoginState.LoggedOut) return <Navigate to={redirectPath} replace />;
  if (user === LoginState.Unknown) return null;

  return <Outlet />;
};

export default ProtectedRoute;
