import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LiabilityFormRoutes from "./form/routes";
import { detailsValidations } from "../../helpers/event_public_liability/validations";
import { validate } from "../../helpers/shared/validation";
import { Validations } from "../../types/shared/Validation";
import { liabilityFormState } from "./form/types";
import createInitialState from "./form/state";
import useEvent from "../../hooks/useEvent";
import Header from "./EventHeader";
import Footer from "./EventFooter";
import { QueryClient, QueryClientProvider } from "react-query";
import { windowScroll } from "../../helpers/shared/useNavigationEffects";
import { createQuote } from "../../helpers/event_public_liability/api/quoteApi";
import { parseState } from "../../helpers/shared/serialization";
import { useAuth } from "../../hooks/useAuth";
import { getRegion } from "../../helpers/shared/getRegion";

type ChangeHandler = <T extends keyof liabilityFormState>(key: T, value: liabilityFormState[T]) => void;
type test = (t: liabilityFormState) => void;
type submitErr = (t: boolean) => void;

/* istanbul ignore next reason: case that never happens. Testing this would be a bit pointless */
const noop = () => {
  console.log("no-op");
};

export const LiabilityFormContext = createContext<{
  state: liabilityFormState;
  onChange: ChangeHandler;
  setFormState: test;
  isSubmitting: boolean;
  submitError: unknown;
  setSubmitError: submitErr;
}>({
  onChange: noop,
  state: createInitialState(),
  setFormState: noop,
  isSubmitting: false,
  submitError: null,
  setSubmitError: noop,
});

const getURLForStep = (step: string) => `/event_public_liability${step}`;
const stepsURLS = [getURLForStep("/"), getURLForStep("/form/details"), getURLForStep("/form/pricing")];
const stepValidations = [detailsValidations];

const findInvalidPage = (validations: Validations<unknown>[], state: liabilityFormState): number =>
  validations.findIndex(validationSchema => Object.keys(validate(state, validationSchema)).length > 0);

const storageKey = "event_public_liability";
const getInitialState = () => {
  try {
    const sessionStorageState = sessionStorage.getItem(storageKey);
    return createInitialState(sessionStorageState ? (parseState(sessionStorageState) as liabilityFormState) : {});
  } catch (e) {
    console.error("Session storage could not be parsed", e);
    return createInitialState();
  }
};

const submit = async (formtate: liabilityFormState, region: string) => {
  try {
    const response = await createQuote(formtate, region);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const CreateQuoteForm: FC = () => {
  const [formState, setFormState] = useState<liabilityFormState>(getInitialState());
  const onFieldChange = useCallback<ChangeHandler>((key, value) => setFormState(state => ({ ...state, [key]: value })), []);
  const navigate = useNavigate();
  const location = useLocation();
  const step = useMemo(() => stepsURLS.indexOf(location.pathname), [location.pathname]);
  const persistState = useEvent(() => sessionStorage.setItem(storageKey, JSON.stringify(formState)));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const { user } = useAuth();
  const region = getRegion(user);

  useEffect(() => {
    window.addEventListener("beforeunload", persistState);
    return () => {
      persistState();
      window.removeEventListener("beforeunload", persistState);
    };
  }, [persistState]);

  useEffect(() => {
    const validationsToRun = stepValidations.slice(0);
    findInvalidPage(validationsToRun, formState);
  }, [formState]);

  useEffect(() => {
    windowScroll();
  }, [location]);

  const goToNextStep = useCallback(() => {
    const newURL = stepsURLS[step + 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const goToPreviousStep = useCallback(() => {
    const newURL = stepsURLS[step - 1];
    newURL && navigate(newURL);
  }, [step, navigate]);

  const onSubmit = async () => {
    //onSubmit of Event PL SG
    try {
      //onSubmit of Event PL SG
      setIsSubmitting(true);
      const response = await submit(formState, region);
      setFormState({
        ...formState,
        quoteId: response.quoteId,
        quoteNo: response.quoteNo,
        validityDate: response.validityDate,
        status: response.status,
        agentEmail: response.agentEmail,
      });
      setSubmitError(false);
      navigate("/event_public_liability/form/quoteDetails");
    } catch {
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <LiabilityFormContext.Provider
      value={{
        setFormState: setFormState,
        onChange: onFieldChange,
        state: formState,
        isSubmitting: isSubmitting,
        submitError: submitError,
        setSubmitError: setSubmitError,
      }}
    >
      <main>
        <form>
          <div className="flex flex-col min-h-screen gap-0">
            <Header hideAccount={false} />
            <LiabilityFormRoutes onPrevious={goToPreviousStep} onNext={step === 2 ? onSubmit : goToNextStep} />
            <Footer />
          </div>
        </form>
      </main>
    </LiabilityFormContext.Provider>
  );
};

const CreateQuote: FC = () => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <CreateQuoteForm />
    </QueryClientProvider>
  );
};

export default CreateQuote;
