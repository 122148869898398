import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MfaForm } from "./login/MfaForm";
import { LoginForm } from "./login/LoginForm";
import { CognitoUser } from "@aws-amplify/auth";
import Header from "./layout/Header";
import { getOriginatingPath } from "../../helpers/shared/location";
import { TotpSetupForm } from "./login/TotpSetupForm";
import { useAuth } from "../../hooks/useAuth";
import { ActivateAgentForm } from "./login/ActivateAgentForm";
import A11yH1 from "../../components/basics/A11YH1";
import { useTranslation } from "react-i18next";
import ProductsFooter from "../ProductsFooter";
import { Region } from "../../enums/shared/forms/Region";
import TpaFooter from "../tpa/components/Footer";

export interface ChallengeResponse {
  username: string;
  challengeName?: string;
  challengeParam?: {
    requiredAttributes: string[];
    userAttributes: Record<string, string>;
  };
}

interface LoginResponse extends CognitoUser, ChallengeResponse {}

export type OnLoginResponse = (response: LoginResponse) => void;

enum Step {
  LOGIN = "LOGIN",
  SMS_MFA = "SMS_MFA",
  NEW_PASSWORD = "NEW_PASSWORD",
  MFA_SETUP = "MFA_SETUP",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
}

export const getRegionWithFallback = (region: Region | string) => {
  return region ? region : (Region.SG as Region);
};

const getFooter = (cognitoUserRegion: Region | string, isTpa: boolean) => {
  return isTpa ? <TpaFooter /> : cognitoUserRegion === "" ? null : <ProductsFooter region={getRegionWithFallback(cognitoUserRegion)} />;
};
export const Login = () => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.header" });
  const [step, setStep] = useState<Step>(Step.LOGIN);
  const [isTpa, setIsTpa] = useState<boolean>(false);
  const [cognitoUserRegion, setCognitoUserRegion] = useState<Region | string>("");
  const [cognitoUser, setCognitoUser] = useState<ChallengeResponse>({ username: "" });
  const navigate = useNavigate();
  const location = useLocation();
  const from = getOriginatingPath(location, "/");
  const { clearAuthState } = useAuth();
  const resetLoginState = useCallback(() => setStep(Step.LOGIN), [setStep]);

  const onSuccess = () => {
    clearAuthState();
    navigate(from, { replace: true });
  };

  const onLoginResponse: OnLoginResponse = response => {
    setCognitoUser(response);
    const challenge = response.challengeName || false;
    const homeBranch = response?.challengeParam?.userAttributes && response.challengeParam?.userAttributes["custom:home_branch"];
    response?.challengeParam?.userAttributes && setCognitoUserRegion(response?.challengeParam?.userAttributes["custom:region"] as Region);
    const groupName = homeBranch?.split("-")[1];
    groupName === "TPA" && setIsTpa(true);

    switch (challenge) {
      case "SMS_MFA":
        setStep(Step.SMS_MFA);
        break;
      case "NEW_PASSWORD_REQUIRED":
        setStep(Step.NEW_PASSWORD);
        break;
      case "MFA_SETUP":
        setStep(Step.MFA_SETUP);
        break;
      case "SOFTWARE_TOKEN_MFA":
        setStep(Step.SOFTWARE_TOKEN_MFA);
        break;
      case false:
        onSuccess();
        break;
      default:
        console.error(`Unknown challenge ${response.challengeName}`);
    }
  };
  return (
    <main className="flex flex-col justify-start min-h-[inherit]">
      <A11yH1>{t("title")}</A11yH1>
      <Header home="/" className="gap-4" hideAccount />
      {step === Step.LOGIN && <LoginForm onLoginResponse={onLoginResponse} />}
      {step === Step.SMS_MFA && (
        <MfaForm onLoginReset={resetLoginState} mfaType="SMS_MFA" onLoginResponse={onLoginResponse} cognitoUser={cognitoUser} />
      )}
      {step === Step.SOFTWARE_TOKEN_MFA && (
        <MfaForm onLoginReset={resetLoginState} mfaType="SOFTWARE_TOKEN_MFA" onLoginResponse={onLoginResponse} cognitoUser={cognitoUser} />
      )}
      {step === Step.NEW_PASSWORD && (
        <ActivateAgentForm onLoginResponse={onLoginResponse} cognitoUser={cognitoUser} isGuestTpa={isTpa} cognitoUserRegion={cognitoUserRegion} />
      )}
      {step === Step.MFA_SETUP && <TotpSetupForm onLoginReset={resetLoginState} onSuccess={onSuccess} cognitoUser={cognitoUser} isGuestTpa={isTpa} />}
      {getFooter(cognitoUserRegion, isTpa)}
    </main>
  );
};
