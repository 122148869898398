import { TextInputProps, InputElement } from "./text-input/InputElement";

const TextAreaInput = ({ value, className, label, onValueChange, error, children, disabled, ...props }: TextInputProps) => {
  return (
    <InputElement
      value={value}
      className={className}
      label={label}
      type="textarea"
      onValueChange={onValueChange}
      error={error}
      disabled={disabled}
      {...props}
    >
      {children}
    </InputElement>
  );
};

export default TextAreaInput;
