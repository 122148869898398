import { useEffect, useRef } from "react";

type OnComponentDidMountCallback = Parameters<typeof useEffect>[0];

/**
 * Performs an action only when a component is mounted, without having to deal with dependencies in the useEffect hook.
 * @param {OnComponentDidMountCallback} callback The callback to perform for when a component is mounted
 */
const useOnComponentDidMount = (callback: OnComponentDidMountCallback) => {
  const didMount = useRef(false);
  const cleanup = useRef<ReturnType<OnComponentDidMountCallback>>(undefined);
  useEffect(() => {
    if (!didMount.current) {
      cleanup.current = callback();
    }
    didMount.current = true;
    return cleanup.current;
  }, [callback]);
};

export default useOnComponentDidMount;
