import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormStepNavigation, { PropsWithoutLabels } from "../../../components/forms/FormStepNavigation";

const BindQuoteStepNavigation: FC<PropsWithoutLabels> = props => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct.bind.navigation.button" });
  const nextButtonLabel = props.nextButtonLabel ? props.nextButtonLabel : t("next");
  const backButtonLabel = props.backButtonLabel ? props.backButtonLabel : t("back");
  return <FormStepNavigation backButtonLabel={backButtonLabel} nextButtonLabel={nextButtonLabel} {...props} />;
};

export default BindQuoteStepNavigation;
