import { useState } from "react";
import { ChallengeResponse, OnLoginResponse } from "../Login";
import ConfirmAgentForm from "./ConfirmAgentForm";
import { NewPasswordForm } from "./NewPasswordForm";
import { Region } from "../../../enums/shared/forms/Region";

interface IProps {
  onLoginResponse: OnLoginResponse;
  cognitoUser: ChallengeResponse;
  isGuestTpa: boolean;
  cognitoUserRegion: Region | string;
}

enum Step {
  CONFIRM = "CONFIRM",
  NEW_PASSWORD = "NEW_PASSWORD",
}

export const ActivateAgentForm = ({ cognitoUser, onLoginResponse, isGuestTpa, cognitoUserRegion }: IProps) => {
  const initialStep = isGuestTpa ? Step.NEW_PASSWORD : Step.CONFIRM;
  const [step, setStep] = useState<Step>(initialStep);

  return (
    <>
      {step === Step.CONFIRM && (
        <ConfirmAgentForm cognitoUser={cognitoUser} onConfirm={() => setStep(Step.NEW_PASSWORD)} cognitoUserRegion={cognitoUserRegion} />
      )}
      {step === Step.NEW_PASSWORD && (
        <NewPasswordForm
          cognitoUser={cognitoUser}
          onBack={() => setStep(Step.CONFIRM)}
          onLoginResponse={onLoginResponse}
          isGuestTpa={isGuestTpa}
          cognitoUserRegion={cognitoUserRegion}
        />
      )}
    </>
  );
};
