import { Branch, ProductType } from "../../../enums/shared/api/Api";
import { GroupPersonalAccidentFormState } from "../../../pages/group_personal_accident/Form/types";
import { GroupTravelFormState } from "../../../pages/group_travel/Form/types";
import agentApiService from "../../../services/group_travel/agentApiService";
import { PremiumQuote, SendEmailQuoteRequest } from "../../../types/group_travel/api/Quote";
import { formatToServerDate } from "../../../utils/date";

export const sendEmailPDF = (quoteId: SendEmailQuoteRequest) => agentApiService.sendEmailPDF(quoteId);

export const convertToBoolean = (valueToConvert: string | null) => (valueToConvert === "no" || !valueToConvert ? false : true);

const establishTravelDetails = (details: GroupTravelFormState): PremiumQuote["travelDetails"] => {
  return {
    stampDuty: convertToBoolean(details.exemptedStampDuty),
    domiciledState: details.regionCode,
    internationalTravelDays: details.internationalTravelDays,
    internationalAvgTripDuration: details.internationalAvgTripDuration,
    numberOfInternationalTrip: details.numberOfInternationalTrip,
    domesticTravelDays: details.domesticTravelDays,
    domesticAvgTripDuration: details.domesticAvgTripDuration,
    numberOfDomesticTrip: details.numberOfDomesticTrip,
    startDate: formatToServerDate(details.coverageStartDate) || null,
    endDate: formatToServerDate(details.coverageEndDate) || null,
  };
};
export const travelPlan = (data: GroupTravelFormState | GroupPersonalAccidentFormState): PremiumQuote["travelPlan"] => {
  let planDetails;
  if (data.productType === ProductType.ACTL) {
    planDetails = {
      id: data.planName as string,
      price: data.basePrice as number,
      gst: {
        percent: data.gstRate as number,
        amount: data.gstAmount as number,
      },
      brokerage: {
        percent: data.brokerageRate as number,
        amount: data.brokerageAmount as number,
      },
      stampDuty: {
        percent: data.stampDutyRate as number,
        amount: data.stampDutyAmount as number,
      },
      total: data.totalPrice as number,
    };
  } else {
    planDetails = data.selectedPlan;
  }
  return planDetails as PremiumQuote["travelPlan"];
};
export const createQuoteRequest = (data: GroupTravelFormState): PremiumQuote => {
  return {
    productType: ProductType.ACTL,
    proposerName: data.policyHoldersName,
    branch: Branch.AU,
    travelPlan: travelPlan(data),
    travelDetails: establishTravelDetails(data),
    mandatoryClause: {
      auRegisteredBusinesss: convertToBoolean(data.familyTrust),
      annuallyRenewableBTP: convertToBoolean(data.annualRenewableBusiness),
      doNotTravelLocation: convertToBoolean(data.donNotTravelAdvice),
    },
    referalDetails: {
      greaterThan$15000: convertToBoolean(data.excessClaimsCover),
      greaterThan180days: convertToBoolean(data.exceedDuration),
      moreThan10people: convertToBoolean(data.morePeopleInTrip),
      travelActivity: convertToBoolean(data.engagedActivities),
      privateTravel50percent: convertToBoolean(data.privateTravel),
      collective10Flights: convertToBoolean(data.collectiveFlights),
    },
  };
};
