type ClassItem = string | undefined | { [key: string]: boolean };
type ClassList = ClassItem[];

const cls = (...classList: ClassList) =>
  classList
    .map((classPart: ClassItem) =>
      typeof classPart === "object"
        ? Object.entries(classPart)
            .filter(([_, val]) => val)
            .map(([k, _]) => k)
            .join(" ")
        : classPart
    )
    .filter((val: string | undefined) => val)
    .join(" ");

export default cls;
