import { FC, useMemo, useState } from "react";
import { FormGroup } from "../../../../components/forms";
import { Carousel } from "../../../../components/interactives";
import { FieldChange, TravelDetails, TravelPlan } from "../../../../types/travel/Policy";
import { H2 } from "../../../../components/basics";
import { Group } from "../../../../components/groups";
import { Table } from "../../../../components/basics";
import { RadioInput } from "../../../../components/form-fields";
import { createTravelPlanCovidAddOnsTable, createTravelPlanPriceTable, ITable } from "../../../../helpers/travel/planTables";
import { PricingPlan } from "../../../../types/travel/new-policy/api/Pricing";
import ResidentDialog from "../dialog/ResidentDialog";
import { getPlanLabel } from "../../../../helpers/travel/labels";
import { planValidations } from "../../../../helpers/travel/validations";
import { formatToTwoDecimalsWithThousandSeparator } from "../../../../helpers/shared/format";
import { useNavigate } from "react-router-dom";
import ServerError from "../../../errors/ServerError";
import useForm from "../../../../hooks/useForm";
import { fetchPricesWithDiscount } from "../../../../helpers/travel/new-policy/api/pricingApi";
import { useTranslation } from "react-i18next";
import { IOption, IProps as RadioButtonProps } from "../../../../components/form-fields/RadioInput";
import Spinner from "../../../../components/icons/Spinner";
import RawHTML from "../../../../components/basics/RawHTML";
import { useClientId } from "../../../../hooks/useClientId";
import classNamePresets from "../../../../utils/classNamePresets";
import useWithAsyncLoadingIndicator from "../../../../hooks/useWithAsyncLoadingIndicator";
import useOnComponentDidMount from "../../../../hooks/useOnComponentDidMount";
import { PriceObject } from "../../../../types/shared/Pricing";
import PolicyFormStepNavigation from "../PolicyFormStepNavigation";
import cls from "../../../../utils/cls";
import PlanTableGroup from "../../../../components/basics/PlanTableGroup";

export interface PlanProps {
  flowStateSlice: TravelPlan;
  travelDetails: TravelDetails;
  onFieldChange: FieldChange<TravelPlan>;
  onBack: () => void;
  onNext: () => void;
}
const DiscountLabel: FC<{ originalPrice: number; newPrice: number; discountPercentage: number; isActive: boolean }> = ({
  originalPrice,
  newPrice,
  discountPercentage,
  isActive,
}) => (
  <div className="flex flex-wrap ml-1 flex-1 justify-end text-right">
    {discountPercentage ? (
      <span className={cls("line-through md:text-xs !leading-[1.625rem]", { "text-grey": !isActive, "text-grey-light": isActive })}>
        SGD {formatToTwoDecimalsWithThousandSeparator(originalPrice)}
      </span>
    ) : null}
    <div className="flex ml-1 justify-end flex-wrap">
      <span className="font-bold">SGD {formatToTwoDecimalsWithThousandSeparator(newPrice)}</span>
      {discountPercentage ? <span className="font-bold ml-1">({discountPercentage}% off)</span> : null}
    </div>
  </div>
);

const toOption = (value: string, label: string, price: PriceObject, activeValue: string | null): IOption<string> => ({
  value,
  label,
  rightLabel: (
    <DiscountLabel
      isActive={activeValue === value}
      discountPercentage={price.discountPercentage}
      originalPrice={price.originalPrice}
      newPrice={price.price}
    />
  ),
});

const plansToOptions = (plans: PricingPlan[], activePlanName: string | null): IOption<string>[] =>
  plans.map(plan => toOption(plan.id, getPlanLabel(plan.id), plan.price, activePlanName));

const RadioFormGroup = <T extends string | null>({ title, ...props }: RadioButtonProps<T> & { title: string }) => (
  <FormGroup title={title}>
    <RadioInput {...props} />
  </FormGroup>
);
const getTravelLink = (clientId: any) => {
  return clientId ? `/client/${clientId}/travel` : "/";
};

const Plan: FC<React.PropsWithChildren<PlanProps>> = ({ flowStateSlice, travelDetails, onFieldChange, onBack, onNext }) => {
  const { validateForm, field } = useForm(flowStateSlice, planValidations, onFieldChange);
  const handleNext = () => validateForm(() => setShowDialog(true));
  const [showDialog, setShowDialog] = useState(false);

  const [plans, setPlans] = useState<PricingPlan[]>([]);
  const [planTables, setPlanTables] = useState<ITable[]>([]);
  const [serverError, setServerError] = useState(false);
  const [addOnsTables, setAddOnsTables] = useState<ITable[] | undefined>([]);

  const clientId = useClientId();

  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy" });
  const navigate = useNavigate();

  const { action: fetchPrices, isLoading } = useWithAsyncLoadingIndicator(fetchPricesWithDiscount, { defaultLoadingState: true });

  useOnComponentDidMount(() => {
    const setPrices = async () => {
      try {
        const { plans: travelPlans } = await fetchPrices(travelDetails, clientId);
        setServerError(false);
        setPlans(travelPlans);
        setPlanTables(createTravelPlanPriceTable(travelPlans));
        setAddOnsTables(createTravelPlanCovidAddOnsTable(travelPlans));
      } catch (error) {
        console.error(error);
        setServerError(true);
      }
    };

    setPrices();
  });

  const planOptions: IOption<string>[] = useMemo(() => plansToOptions(plans, flowStateSlice.planName), [plans, flowStateSlice.planName]);

  const handlePlanUpdate = (value: string | null) => {
    field.planName.onValueChange(value);

    const plan = plans.find(({ id }) => id === value);
    plan && onFieldChange("planPrice", plan.price);
    plan && field.planCurrency.onValueChange(plan.currency);
  };

  const onCancel = () => {
    setShowDialog(false);
    const travelLink = getTravelLink(clientId);
    navigate(travelLink);
  };

  const arePricesLoaded = planOptions.length > 0;
  const priceTitle = travelDetails.policyType === "single-trip" ? t("steps.plan.table.price.title") : t("steps.plan.table.price.annualTitle");
  return (
    <>
      <div className="container-flow flex-grow">
        <H2 className={classNamePresets.mainHeading}>{t("steps.plan.planTitle")}</H2>
        {serverError && <ServerError supportEmailId={t("customerSupport.emailId")} />}
        {!arePricesLoaded ? (
          <div className="flex justify-around mt-32">
            <Spinner width="100" height="100" color="#3F9293"></Spinner>
          </div>
        ) : (
          <>
            <Group aria-label="plan-table" role="list" title={priceTitle} className="gap-0">
              <PlanTableGroup planTables={planTables} />
            </Group>
            {!!addOnsTables && addOnsTables.length > 0 && (
              <Group aria-label="addon-table" role="list" title={t("steps.plan.table.covidAddOn.title")} className="gap-0">
                <Carousel>
                  {addOnsTables?.map(table => (
                    <div key={table.headers[0].value} role="listitem">
                      <Table data={table} />
                    </div>
                  ))}
                </Carousel>
              </Group>
            )}
            <div className="mt inline-flex items-center font-bold">
              <RawHTML boldedLinks wrap={false} content={t("steps.plan.table.price.subtitle.text")} />
            </div>
            <RadioFormGroup
              aria-label="plan-selection"
              title={t("steps.plan.form.groupTitle.price")}
              {...field.planName}
              value={flowStateSlice.planName}
              options={planOptions}
              onValueChange={handlePlanUpdate}
            />
          </>
        )}
      </div>
      {showDialog && <ResidentDialog onConfirm={onNext} onCancel={onCancel} onClose={() => setShowDialog(false)} />}
      <PolicyFormStepNavigation onBack={onBack} onNext={isLoading ? undefined : handleNext} />
    </>
  );
};

export default Plan;
