import { FC, SetStateAction, useContext, useState, Dispatch } from "react";
import TextInput from "../../../../components/form-fields/TextInput";
import { FormGroup } from "../../../../components/forms";
import { additionalDetailsValidations } from "../../../../helpers/group_travel/validations";
import useForm from "../../../../hooks/useForm";
import { CreateQuoteContext } from "../../CreateQuote";
import { useTranslation } from "react-i18next";
import AuGroupBusinessFormStepNavigation from "../CreateQuoteStepNavigation";
import { EngagedInfo } from "../../more-info/EngagedInfo";
import PrivateTravelInfo from "../../more-info/PrivateTravelInfo";
import FlightsInfo from "../../more-info/FlightsInfo";
import GroupTravelHeading from "../../components/Heading";
import ServerError from "../../../errors/ServerError";
import ReferralDialog from "../../dialog/ReferralDialog";
import AdditionalDetailsOptions from "../../components/AdditionalDetailsOptions";
import { removeExtraSpaces } from "../../../../helpers/shared/formHandlers";

type MoreInfoValues = "engaged-activities" | "private-travel" | "collective-flights" | null;
type Props = {
  onSubmit: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

interface SideBarInfoProps {
  moreInfo: MoreInfoValues;
  setMoreInfo: Dispatch<SetStateAction<MoreInfoValues>>;
}
const SideBarInfo: React.FC<SideBarInfoProps> = ({ moreInfo, setMoreInfo }) => (
  <>
    <EngagedInfo show={moreInfo === "engaged-activities"} onClose={() => setMoreInfo(null)} />
    <PrivateTravelInfo show={moreInfo === "private-travel"} onClose={() => setMoreInfo(null)} />
    <FlightsInfo show={moreInfo === "collective-flights"} onClose={() => setMoreInfo(null)} />
  </>
);
const AdditionalDetails: FC<Props> = ({ onSubmit, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "groupTravel.newProduct" });
  const [moreInfo, setMoreInfo] = useState<MoreInfoValues>(null);
  const { state, setFormState, onChange, serverError, isSubmitting, setServerError, showReferralDialog, setShowReferralDialog } =
    useContext(CreateQuoteContext);
  const { field, validateField, validateForm } = useForm(state, additionalDetailsValidations, onChange);
  const handleNext = () => validateForm(() => onSubmit?.());

  return (
    <>
      <div className="container-flow flex-grow">
        <GroupTravelHeading title={t("steps.AdditionalDetails")} alt="GroupTravel" />
        <FormGroup title={t("additionalDetailLabels.policyHolder")} subTitleFontSize="md:text-xl">
          <TextInput
            label={t("additionalDetailLabels.policyHolder")}
            value={state.policyHoldersName}
            {...field.policyHoldersName}
            onBlur={removeExtraSpaces("policyHoldersName", setFormState, validateField)}
            aria-label="policyholder-name"
          />
        </FormGroup>
        <AdditionalDetailsOptions
          formLabel="excessClaimsCover"
          label={t("additionalDetailLabels.excessClaimsCover")}
          value={state.excessClaimsCover}
          {...field.excessClaimsCover}
        />
        <AdditionalDetailsOptions
          formLabel="exceedDuration"
          label={t("additionalDetailLabels.exceedDuration")}
          value={state.exceedDuration}
          {...field.exceedDuration}
        />
        <AdditionalDetailsOptions
          formLabel="morePeopleInTrip"
          label={t("additionalDetailLabels.morePeopleInTrip")}
          value={state.morePeopleInTrip}
          {...field.morePeopleInTrip}
        />

        <AdditionalDetailsOptions
          formLabel="engagedActivities"
          label={t("additionalDetailLabels.engagedActivities")}
          value={state.engagedActivities}
          {...field.engagedActivities}
          moreInfoStyle=" !justify-end"
          onMoreInfo={() => setMoreInfo("engaged-activities")}
        >
          <ul className=" list-[lower-alpha] ml-[2em] text-xl">
            {t("assistoryLabels.engagedActivitiesList", { returnObjects: true }).map(label => (
              <li key={label}>{label}</li>
            ))}
          </ul>
        </AdditionalDetailsOptions>

        <AdditionalDetailsOptions
          formLabel="privateTravel"
          label={t("additionalDetailLabels.privateTravel")}
          value={state.privateTravel}
          {...field.privateTravel}
          moreInfoStyle="!justify-end"
          onMoreInfo={() => setMoreInfo("private-travel")}
        />
        <AdditionalDetailsOptions
          formLabel="collectiveFlights"
          label={t("additionalDetailLabels.collectiveFlights")}
          value={state.collectiveFlights}
          {...field.collectiveFlights}
          moreInfoStyle="!justify-end"
          onMoreInfo={() => setMoreInfo("collective-flights")}
        />
      </div>

      <AuGroupBusinessFormStepNavigation isLoading={isSubmitting} onBack={onPrevious} onSubmit={handleNext} nextButtonLabel="Get Quotation" />
      <SideBarInfo setMoreInfo={setMoreInfo} moreInfo={moreInfo} />

      {showReferralDialog && <ReferralDialog onCancel={() => setShowReferralDialog(false)} showLink={true} />}
      {serverError && (
        <ServerError displayType="modal" onClose={() => setServerError(false)} contactDetailsWithMailId={t("serverError.contactDetailsWithMailId")} />
      )}
    </>
  );
};

export default AdditionalDetails;
