import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HkDetails from "./Details";
import PricingDetails from "./PricingDetails";
import QuotationDetail from "./QuotationDetails";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const LiabilityFormRoutes: FC<Props> = props => (
  <Routes>
    {/* Routes for Hk event pl */}
    <Route path="details" element={<HkDetails {...props} />} />
    <Route path="pricing" element={<PricingDetails {...props} />} />
    <Route path="quoteDetails" element={<QuotationDetail />} />
    <Route path="" element={<Navigate to="details" />} />
  </Routes>
);

export default LiabilityFormRoutes;
