import { FC } from "react";
import vector from "../../assets/icons/vector.svg";

export const ArrowCell: FC<{ isClickable: boolean }> = ({ isClickable }) => (
  <td className="md:pl-0 pl-2.5 md:pt-1">
    {isClickable ? (
      <div aria-label="record">
        <button type="button" data-testid="btn-click" aria-label="arrow-btn" name="arrow-btn">
          <img className="w-[0.375rem]" src={vector} alt="" />
        </button>
      </div>
    ) : (
      <div aria-label="record" className="w-[0.375rem]"></div>
    )}
  </td>
);
