const incrementCount = (char: string) => (/[\u4e00-\u9fa5]/.test(char) ? 2 : 1);

export const textEllipsis = <T extends string>(text: T, truncateAfterLength: number, numberOfDots: number): T => {
  let currentLength = 0;
  let truncatedString = "";
  for (let i = 0; i < text.substring(0, truncateAfterLength).length; i++) {
    const char = text[i];
    currentLength += incrementCount(char);
    if (currentLength <= truncateAfterLength) {
      truncatedString += char;
    } else break;
  }
  if (currentLength > truncateAfterLength - 1) {
    truncatedString += ".".repeat(Math.max(0, numberOfDots));
  }

  return truncatedString as T;
};
