import { Dialog } from "../../../../components/interactives";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/basics/Button";
import { DialogFooter } from "../../../../components/interactives/Dialog";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";

interface IProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmLogoutDialog = ({ onConfirm, onCancel }: IProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "auth.layout.header.confirmLogoutDialog" });

  return (
    <Dialog onClose={onCancel} title={t("title")}>
      <DialogFooter>
        <Button className="whitespace-nowrap" type={ButtonType.SECONDARY} onClick={onCancel}>
          {t("button.no")}
        </Button>
        <Button className="whitespace-nowrap" type={ButtonType.PRIMARY} onClick={onConfirm}>
          {t("button.yes")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
