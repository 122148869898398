import { LoadingButton } from "../../../components/basics/Button";
import TextInput from "../../../components/form-fields/TextInput";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import AgentFormDialog from "../../travel/new-policy/dialog/AgentFormDialog";
import { AgentResetType } from "./ResetForm";

type ResetInputFormProps = {
  isLoading: boolean;
  hasErrors: boolean;
  errorMessageForReset?: string;
  errors: {
    resetEmail?: string;
  };
  resetFormState: AgentResetType;
  field: any;
  validateField: (name: string, value: unknown, newData?: Record<string, unknown>) => void;
  onClose: () => void;
};
const ResetInputForm = ({
  isLoading,
  hasErrors,
  errorMessageForReset,
  errors,
  resetFormState,
  field,
  validateField,
  onClose,
}: ResetInputFormProps) => {
  return (
    <>
      <div className="space-y-6">
        <TextInput
          aria-label="resetEmail"
          error={errors.resetEmail}
          label={"Email"}
          backgroundColor="bg-grey-lighter"
          autoComplete="resetEmail"
          value={resetFormState.resetEmail}
          onBlur={field.resetEmail.onBlur}
          onValueChange={value => {
            const trimmedResetEmail = value.trim();
            field.resetEmail.onValueChange(trimmedResetEmail);
            errors.resetEmail && validateField("resetEmail", trimmedResetEmail);
          }}
        />
      </div>
      {errorMessageForReset && <AgentFormDialog onClose={onClose} message="Something went wrong. Please try again later." />}
      <LoadingButton disabled={hasErrors} isLoading={isLoading} className="mt-6 md:!w-full" type={ButtonType.PRIMARY} loadingText={"Loading"}>
        Reset
      </LoadingButton>
    </>
  );
};

export default ResetInputForm;
