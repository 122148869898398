import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, LoadingButton } from "../../../components/basics/Button";
import TextInput from "../../../components/form-fields/TextInput";
import { Dialog } from "../../../components/interactives";
import { DialogFooter } from "../../../components/interactives/Dialog";
import { EMAIL_REGEX } from "../../../constants";
import { ButtonType } from "../../../helpers/shared/buttonStyling";
import useForm from "../../../hooks/useForm";
import { Validations } from "../../../types/shared/Validation";
import ServerError from "../../errors/ServerError";
import { ReferralDetails } from "../Form/types";
import { removeExtraSpaces } from "../../../helpers/shared/formHandlers";

type Props = {
  onConfirm: (details: ReferralDetails) => Promise<void>;
  onClose: VoidFunction;
};

const validations: Validations<ReferralDetails> = {
  fields: {
    name: {
      required: true,
    },
    email: {
      required: true,
      pattern: {
        regex: EMAIL_REGEX,
        message: "Please check email format",
      },
    },
  },
};

const ReferralDialog: FC<Props> = ({ onClose, onConfirm }) => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.newProduct" });
  const [details, setdetails] = useState<ReferralDetails>({
    name: "",
    email: "",
  });

  const { field, validateField, validateForm } = useForm(details, validations, (k, v) => setdetails({ ...details, [k]: v }));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    validateForm(async () => {
      setLoading(true);
      setError(false);
      try {
        await onConfirm(details);
        onClose();
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <Dialog onClose={onClose} title={t("referral.dialogTitle")}>
      {error && <ServerError supportEmailId={t("customerSupport.emailId")} />}
      <p>{t("referral.content")}</p>

      <form className="flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-8 mt-4">
        <div className="flex lg:flex-col w-full">
          <TextInput label="Name" {...field.name} value={details.name} onBlur={removeExtraSpaces("name", setdetails, validateField)} />
        </div>

        <div className="flex lg:flex-col w-full">
          <TextInput label="Email" {...field.email} value={details.email} />
        </div>
      </form>

      <DialogFooter>
        <Button type={ButtonType.SECONDARY} onClick={onClose}>
          {t("referral.cancel")}
        </Button>
        <LoadingButton type={ButtonType.PRIMARY} onClick={handleConfirm} isLoading={loading} loadingText={t("referral.loadingButtonText")}>
          {t("referral.proceed")}
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  );
};

export default ReferralDialog;
