import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LimitOfLiability from "../steps/LimitOfLiability";
import TermsAndConditions from "../steps/TermsAndConditions";

type Props = {
  onNext: VoidFunction;
  onPrevious: VoidFunction | null;
};

const RenewalFormRoutes: FC<Props> = props => (
  <Routes>
    <Route path="limit" element={<LimitOfLiability {...props} />} />
    <Route path="terms" element={<TermsAndConditions {...props} />} />
    <Route path="" element={<Navigate to="limit" />} />
  </Routes>
);

export default RenewalFormRoutes;
