import { FC, PropsWithChildren } from "react";
import { LabelCell, NormalRow } from "./CommonTable";
import { PricingPlan } from "../../../types/event_public_liability/api/Pricing";
import { formatToTwoDecimalsWithThousandSeparator } from "../../../helpers/shared/format";

type Props = PropsWithChildren<{
  plans: PricingPlan[];
}>;
export const Pricing: FC<Props> = ({ plans }) => {
  return (
    <>
      {plans.map((items, index) => {
        return (
          <NormalRow key={index}>
            <LabelCell>
              {items.currency} {items.id.replace(/_/g, ",")}
            </LabelCell>
            <LabelCell>
              {items.currency} {formatToTwoDecimalsWithThousandSeparator(items.price)}
            </LabelCell>
          </NormalRow>
        );
      })}
    </>
  );
};
