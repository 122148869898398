import { FC, PropsWithChildren, useState } from "react";
import { liabilityFormState } from "../form/types";
import { QuotationDetailsSection } from "../../../components/forms/QuotationSection";
import QuotationInfoSection from "../components/QuotationInfoSection";
import QuotationLiabilitySection from "../components/QuotationLiabilitySection";
import { useNavigate } from "react-router-dom";
import QuotationEmailDialog from "./dialog/QuotationEmailDialog";
import UserInput from "../../../components/interactives/UserInput";

export type Props = PropsWithChildren<{
  state: liabilityFormState;
  renderExclusion: JSX.Element;
  eventRetrieveListLink: string;
  t: any;
}>;

const QuotationDetails: FC<Props> = ({ state, t, renderExclusion, eventRetrieveListLink }) => {
  const [showEmailDialog, setShowEmailDialog] = useState<boolean>(false);
  const [showUserInput, setShowUserInput] = useState<boolean>(false);
  const navigate = useNavigate();
  const onRetrieve = () => {
    navigate(eventRetrieveListLink);
  };
  const onHome = () => {
    navigate("/products");
  };
  const onSuccessEmailDialog = () => {
    setShowUserInput(true);
    setShowEmailDialog(false);
  };
  const onCloseEmailDialog = () => {
    setShowEmailDialog(false);
  };

  return (
    <>
      <div className="font-semibold md:text-base text-xs opacity-30 md:pt-8 pt-4">EVENT PUBLIC LIABILITY</div>
      <QuotationDetailsSection name={t("PricingLabels.quotation")} onLinkClick={() => setShowEmailDialog(true)} showSpinner={false}>
        <QuotationInfoSection t={t} state={state} />
        {renderExclusion}
      </QuotationDetailsSection>
      <QuotationLiabilitySection t={t} state={state} />

      {showEmailDialog && <QuotationEmailDialog quoteId={state.quoteId} onClose={onCloseEmailDialog} onSuccess={onSuccessEmailDialog} t={t} />}
      {showUserInput && <UserInput onClose={() => setShowUserInput(false)} onHome={onHome} onRetrieve={onRetrieve} />}
    </>
  );
};
export default QuotationDetails;
