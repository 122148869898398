import { useState } from "react";
import { SendEmailQuoteRequest } from "../../../types/group_travel/api/Quote";

export const useEmailPdf = (
  emailPayload: SendEmailQuoteRequest,
  fnToSendEmail: (emailPayload: SendEmailQuoteRequest) => void,
  onSuccess?: () => void
) => {
  const [showUserInput, setShowUserInput] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [serverError, setServerError] = useState(false);
  const handleLinkClick = async () => {
    try {
      setServerError(false);
      setShowSpinner(true);
      await fnToSendEmail(emailPayload);
      setShowUserInput(true);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      setServerError(true);
      setShowSpinner(false);
    } finally {
      setShowSpinner(false);
    }
  };
  const onClose = () => {
    setShowUserInput(false);
  };
  return { onClose, showSpinner, showUserInput, serverError, handleLinkClick, setServerError };
};
