import { Validation, Validations, Errors, Data } from "../../types/shared/Validation";

const validateRequired = (value: unknown, validation?: Validation<Data>) => {
  if (validation?.required && (value === null || value === undefined || value === "" || value === false)) {
    return typeof validation.required == "boolean" ? validation.required : validation.required.message;
  }

  return null;
};
const validateLength = (value: unknown, validation?: Validation<Data>) => {
  if (validation?.length && typeof value === "string" && value.length > validation.length.maxLength) {
    return `${validation.length.message}`;
  }
  return null;
};

const validatePattern = (value: unknown, validation?: Validation<Data>) => {
  if (validation?.pattern && (typeof value !== "string" || !value.match(validation.pattern.regex))) {
    return validation.pattern.message;
  }

  return null;
};

const validateCustom = (value: unknown, validation: Validation<Data> | undefined, data: Data) => {
  if (validation?.custom) {
    return validation.custom.validate(value, data);
  }

  return null;
};

const validateIf = (value: unknown, data: Data, validation?: Validation<Data>): boolean => {
  if (validation?.validateIf && !validation?.validateIf(value, data)) {
    return false;
  }

  return true;
};

const isRecord = (value: unknown): value is Record<string, unknown> => {
  return typeof value === "object" && value !== null;
};

const isValidations = (value: unknown): value is Record<string, Validation<Data>> => {
  return typeof value === "object" && value !== null;
};

const validateNested = (
  nestedData: Record<string, unknown>,
  validations: Record<string, Validation<Data>>,
  path: string,
  data: Data,
  errors: Errors
) => {
  for (const key in validations) {
    const validation = validations?.[key];
    const value = nestedData[key];
    validateField(value, validation, `${path}.${key}`, data, errors);
  }
};

const runNestedValidations = (value: unknown, validation: Validation<Data> | undefined, path: string, data: Data, errors: Errors) => {
  if (validation?.nested && isRecord(value) && isValidations(validation.nested)) {
    validateNested(value, validation.nested, path, data, errors);
  }
};

const runArrayValidations = (value: unknown, validation: Validation<Data> | undefined, path: string, data: Data, errors: Errors) => {
  const arrayValidations = validation?.array;
  if (arrayValidations && Array.isArray(value)) {
    value.forEach((item, index) => {
      validateNested(item, arrayValidations, `${path}.${index}`, data, errors);
    });
  }
};

export const getValidation = (validations: Record<string, Validation<Data>> | undefined, path: string): Validation<Data> | undefined => {
  const parts = path.split(".");

  let target = validations;
  let validation: Validation<Data> | undefined = undefined;

  parts.forEach(part => {
    const index = parseInt(part);
    if (!isNaN(index) && validation) {
      target = validation?.array;
    } else {
      validation = target?.[part];
      if (validation?.nested && isValidations(validation.nested)) {
        target = validation?.nested;
      }
    }
  });

  return validation;
};

const validateField = (value: unknown, validation: Validation<Data> | undefined, path: string, data: Data, errors: Errors) => {
  runNestedValidations(value, validation, path, data, errors);
  runArrayValidations(value, validation, path, data, errors);

  const error = validateValue(value, validation, data);

  if (error) {
    errors[path] = error;
  }
};

export const validateValue = (value: unknown, validation: Validation<Data> | undefined, data: Data) => {
  let error: string | boolean | null = null;

  if (!validateIf(value, data, validation)) return null;

  error = error || validateRequired(value, validation);
  error = error || validateLength(value, validation);
  error = error || validatePattern(value, validation);
  error = error || validateCustom(value, validation, data);

  return error;
};

export const validate = (data: Data, validations: Validations<Data>) => {
  const errors: Errors = {};

  for (const key in validations.fields) {
    const value = data[key];
    const validation = validations.fields?.[key];
    validateField(value, validation, key, data, errors);
  }

  return errors;
};
