import { Address } from "../../types/travel/Policy";
const COUNTRY = "Singapore";

const formatToDisplayFloorAndUnitNumber = (floorNumber: string, unitNumber: string) => {
  if (!unitNumber && !floorNumber) return "";
  if (!floorNumber) return `#${unitNumber},`;
  if (!unitNumber) return `#${floorNumber},`;
  return `#${floorNumber}-${unitNumber},`;
};

const formatToAddressLines = (address: Address): string[] => {
  return [
    `${address.number} ${address.streetName},`,
    `${formatToDisplayFloorAndUnitNumber(address.floorNumber, address.unitNumber)} ${COUNTRY} ${address.postalCode}`,
  ];
};

export default formatToAddressLines;
