import Ajv from "ajv";
import { agentPost } from "../../helpers/travel/new-policy/api/agent";
import { getNewRenewalPolicyResponse, checkoutResponse, genericCheckoutResponse } from "../../schemas/pay_now/RenewalPolicy";
import { PolicyStatus } from "../../pages/components/pay_now/RenewalPolicy";

const ajv = new Ajv();
const validateRenewalPolicyResponse = ajv.compile(getNewRenewalPolicyResponse);
const validateCheckoutResponse = ajv.compile(checkoutResponse);
const validateGenericCheckoutResponse = ajv.compile(genericCheckoutResponse);

const RENEWAL_POLICY = "v1/policy/detail";
const CHECKOUT_API = "v1/renewal/payment/checkout";
const GENERIC_CHECKOUT_API = "v1/generic/payment/checkout";

export type RenewalPolicyRequest = {
  renewalId: string;
};

export type CheckoutResponse = {
  renewalId: string;
  url: string;
};

export type GenericCheckoutResponse = {
  status: string;
  url: string;
};

export type Policy = {
  insuredName: string;
  policyNumber: string;
  policyEndDate: string;
  contactNumber: string;
  email: string;
  amount: number;
  status: string;
  renewalCount: number;
  endorsementCount: number;
  validity: string;
};

export type RenewalCheckoutRequest = {
  insuredName: string;
  policyNumber: string;
  contactNumber: string;
  email: string;
  amount: number;
  type: string;
};

export type RenewalPolicyResponse = {
  data: Policy[];
  status: PolicyStatus;
};

export type CheckoutRequest = {
  renewalId: string;
  email: string;
};

const agentApiService = {
  renewalPolicy: (data: RenewalPolicyRequest): Promise<RenewalPolicyResponse> =>
    agentPost<RenewalPolicyRequest, RenewalPolicyResponse>(process.env.REACT_APP_BACKEND_API, RENEWAL_POLICY, data, (response: unknown) => {
      return validateRenewalPolicyResponse(response);
    }),

  checkout: (data: CheckoutRequest): Promise<CheckoutResponse> =>
    agentPost<CheckoutRequest, CheckoutResponse>(process.env.REACT_APP_BACKEND_API, CHECKOUT_API, data, (response: unknown) => {
      return validateCheckoutResponse(response);
    }),

  genericCheckout: (data: RenewalCheckoutRequest): Promise<GenericCheckoutResponse> =>
    agentPost<RenewalCheckoutRequest, GenericCheckoutResponse>(process.env.REACT_APP_BACKEND_API, GENERIC_CHECKOUT_API, data, (response: unknown) => {
      return validateGenericCheckoutResponse(response);
    }),
};

export default agentApiService;
