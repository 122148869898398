import { useState } from "react";
import { Validations, Validation, Errors, Data } from "../types/shared/Validation";
import { getValidation, validateValue, validate } from "../helpers/shared/validation";

export interface Validator {
  validateField: (name: string, value: unknown, newData?: Record<string, unknown>) => void;
  validateAll: () => boolean;
  clearErrors: () => void;
}

export const useValidation = <T extends Data>(data: T, validations: Validations<T>): [Validator, Errors] => {
  const [errors, setErrors] = useState<Errors>({});

  const validateField = (name: string, value: unknown, newData?: Record<string, unknown>) => {
    if (!isValidations(validations.fields)) return;

    const validation: Validation<Data> | undefined = getValidation(validations.fields, name);
    const error = validateValue(value, validation, { ...data, ...newData });
    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };

  const validateAll = () => {
    const validationErrors = validate(data, validations);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const clearErrors = () => {
    setErrors({});
  };

  return [{ validateField, validateAll, clearErrors }, errors];
};

const isValidations = (value: unknown): value is Record<string, Validation<Data>> => {
  return typeof value === "object" && value !== null;
};
