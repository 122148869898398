import { Dialog } from "../../../../components/interactives";
import { useState } from "react";
import TextInput from "../../../../components/form-fields/TextInput";
import { useValidation } from "../../../../hooks/useValidation";
import { referralDetailsValidations } from "../../../../helpers/travel/validations";
import { ReferralDetails } from "../../../../types/travel/Policy";
import ServerError from "../../../errors/ServerError";
import { useTranslation } from "react-i18next";
import { Button, LoadingButton } from "../../../../components/basics/Button";
import { DialogFooter } from "../../../../components/interactives/Dialog";
import { ButtonType } from "../../../../helpers/shared/buttonStyling";
import { removeExtraSpaces } from "../../../../helpers/shared/formHandlers";

interface IProps {
  onConfirm: (details: ReferralDetails) => void;
  onCancel: () => void;
  serverError: boolean;
  isLoading: boolean;
}

const ReferralDialog = ({ onConfirm, onCancel, serverError, isLoading }: IProps) => {
  const [referralDetails, setReferralDetails] = useState<ReferralDetails>({
    name: "",
    email: "",
  });
  const [{ validateField, validateAll }, errors] = useValidation(referralDetails, referralDetailsValidations);

  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy" });

  const handleReferralConfirm = () => {
    const valid = validateAll();
    valid && onConfirm(referralDetails);
  };

  const updateReferralDetails = (key: keyof ReferralDetails, value: string) => {
    setReferralDetails(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fieldProps = <K extends keyof ReferralDetails>(key: K) => {
    return {
      value: referralDetails[key],
      onValueChange: (v: string) => updateReferralDetails(key, v),
      onBlur: (event: React.FocusEvent<HTMLInputElement>) => removeExtraSpaces(key, setReferralDetails, validateField)(event),
      error: errors[key],
    };
  };

  return (
    <Dialog onClose={onCancel} title={t("dialog.referral.title")}>
      {serverError && <ServerError supportEmailId={t("customerSupport.emailId")} />}
      <p>{t("dialog.referral.content")}</p>

      <form className="flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-8 mt-4">
        <div className="flex lg:flex-col w-full">
          <TextInput label={t("dialog.referral.form.label.name")} {...fieldProps("name")} />
        </div>

        <div className="flex lg:flex-col w-full">
          <TextInput label={t("dialog.referral.form.label.email")} {...fieldProps("email")} />
        </div>
      </form>

      <DialogFooter>
        <Button type={ButtonType.SECONDARY} onClick={onCancel}>
          {t("dialog.referral.button.cancel")}
        </Button>
        <LoadingButton type={ButtonType.PRIMARY} onClick={handleReferralConfirm} isLoading={isLoading} loadingText={"Submitting"}>
          {t("dialog.referral.button.send")}
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  );
};

export default ReferralDialog;
