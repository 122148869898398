import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../../../components/basics";
import { Accordion, AccordionSection } from "../../../../components/interactives";
import { termsConditionsValidations } from "../../../../helpers/professional_indemnity/validations";
import useForm from "../../../../hooks/useForm";

import classNamePresets from "../../../../utils/classNamePresets";
import ServerError from "../../../errors/ServerError";
import TermsConditions from "../../components/Terms";
import { IndemnityFormContext } from "../../NewProduct";
import IndemnityFormStepNavigation from "../IndemnityFormStepNavigation";
import DeclarationTerms from "./terms/DeclarationTerms";
import ImportantNoticesTerms from "./terms/ImportantNoticesTerms";

type Props = {
  onNext: VoidFunction | null;
  onPrevious: VoidFunction | null;
};

const Terms: FC<Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation("pages", { keyPrefix: "professionalIndemnity.newProduct" });
  const { state, onChange, isSubmitting, submitError } = useContext(IndemnityFormContext);
  const form = useForm(state, termsConditionsValidations, onChange);
  const [serverError, setServerError] = useState(false);
  const policyWordingUrl = "/documents/ManulifeProfessionalIndemnityPolicyWordings.pdf";
  const {
    field: { accepted, promotionalServices },
  } = form;
  const handleNext = () => {
    onNext && form.validateForm(onNext);
  };

  useEffect(() => {
    if (submitError) {
      setServerError(true);
    }
  }, [submitError]);

  return (
    <>
      <div className="container-flow flex-1">
        <H2 className={classNamePresets.mainHeading}>{t("steps.terms")}</H2>
        <p className="mt-8">{t("terms.reviewInformation")}</p>
        <Accordion id="terms-info" omitMarginTop>
          <AccordionSection id="important-notices" label={t("terms.importantNotices")}>
            <ImportantNoticesTerms />
          </AccordionSection>
          <AccordionSection id="declaration" label={t("terms.declaration")}>
            <DeclarationTerms />
          </AccordionSection>
          <TermsConditions
            isAccordionExpanded={true}
            state={state}
            t={t}
            promotionalServices={promotionalServices}
            accepted={accepted}
            policyWordingUrl={policyWordingUrl}
          />
        </Accordion>
      </div>
      {serverError && <ServerError supportEmailId={t("customerSupport.emailId")} displayType="modal" onClose={() => setServerError(false)} />}
      <IndemnityFormStepNavigation isLoading={isSubmitting} onBack={onPrevious} onSubmit={handleNext} />
    </>
  );
};

export default Terms;
