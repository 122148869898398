import { getPlanLabel } from "../../../../../helpers/travel/labels";
import { TravelPlan } from "../../../../../types/travel/Policy";
import { ContentPriceCell, LabelCell, NormalRow, SummaryTable } from "./SummaryTable";
import { formatToTwoDecimalsWithThousandSeparator } from "../../../../../helpers/shared/format";
import { useTranslation } from "react-i18next";

const TravelPlanSummary = ({ planName, planPrice, planCurrency }: TravelPlan) => {
  const { t } = useTranslation("pages", { keyPrefix: "travel.newPolicy.steps.summary.summarySection.plan" });

  if (!planName || !planCurrency || !planPrice) return null;

  return (
    <SummaryTable className="w-full md:mt-4 table-fixed">
      <NormalRow>
        <LabelCell>{t("plan.label")}</LabelCell>
        <ContentPriceCell>
          <div>{getPlanLabel(planName)}</div>
          <div>{`${planCurrency} ${formatToTwoDecimalsWithThousandSeparator(planPrice.price)}`}</div>
        </ContentPriceCell>
      </NormalRow>
    </SummaryTable>
  );
};

export default TravelPlanSummary;
